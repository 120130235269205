/* globals zc _ */

(function () {
  'use strict'

  zc.views.LocalBackupPopupView = zc.views.PopupView.extend({

    // initialize: function(options) {
    //   zc.views.PopupView.prototype.initialize.call(this, options);
    // },

    events: {
      'click .ok': 'initLocalBackup'
    },

    className: 'popup modal',

    template: _.template($('.local-backup-popup-template').html()),

    exit: function () {
      // reset model to default
      this.$el.remove()
      $('.app').removeClass('blur')
    },

    bodyClick: function () {
      // do nothing
    },

    initLocalBackup: function (e) {
      e.preventDefault()
      console.log('local backup popup forcing downlaod', new Date())
      this.model.forceDownload()
      this.exit()
    },

    center: function () {
      var marginLeft = -(this.$localBackup.outerWidth() / 2)
      var marginTop = -(this.$localBackup.outerHeight() / 2)
      this.$localBackup.css({marginLeft: marginLeft, marginTop: marginTop})
    },

    render: function () {
      zc.views.PopupView.prototype.render.call(this)
      this.$localBackup = this.$('.local-backup')

      this.center()

      // this.delegateEvents();
      $('.app').addClass('blur')
    }
  })
})()
