/* globals zc */

(function () {
  'use strict'

  zc.views.VoipSettingView = zc.views.DropdownSelectView.extend({
    /**
     * @type {(options: {
     *   [key: string]: unknown
     *   onChange: () => Promise<void>
     * }) => void}
     */
    initialize: function (options) {
      this.settings = options.settings
      this.onChange = options.onChange
      
      this.listenTo(this.settings, 'change:voip', this.updateSelection)

      this._initialize(options)
      this.createModel({
        iconClass: 'ic-cloud',
        selectState: 'success',
        defaultErrorTitle: ''
      })

      var voipEnabled = this.getVoipSetting()
      var items = [
        {id: '1', title: 'Enabled - Use Zencastr\'s VoIP', selected: voipEnabled === 1},
        {id: '0', title: 'Disabled - Use Zoom/Skype/etc', selected: voipEnabled === 0}
      ]
      this.model.set('items', items)
    },

    getVoipSetting: function () {
      return Number(this.settings.get('voip'))
    },

    updateSelection: function () {
      this.setSelected(this.getVoipSetting())
    },

    onItemSelect: async function (item) {
      var enabled = !!Number(item.id)
      await this.onChange(enabled)
      this.settings.set({voip: enabled})
      console.log('Changed voip setting: ', enabled ? 'on' : 'off')
    }
  })
})()
