/* globals zc _ app Backbone */

(function () {
  'use strict'

  zc.views.AccountPageView = zc.views.PageView.extend({
    initialize: function (options) {
      zc.views.PageView.prototype.initialize.call(this, options)
      this.billing = new zc.models.Billing()
    },

    template: _.template($('.account-template').html()),

    className: 'account-page page',

    renderPaymentSource: function () {
      var paymentSourceView = new zc.views.PaymentSourceView({
        model: app.user.paymentSource,
        el: this.$paymentSource
      })
      paymentSourceView.render()
    },

    renderPlanInfo: function () {
      var planInfoView = new zc.views.PlanInfoView({model: app.user, el: this.$planInfo})
      planInfoView.render()
    },

    renderUserInfo: function () {
      var userInfoView = new zc.views.UserInfoView({model: app.user, el: this.$userInfo})
      userInfoView.render()
    },

    renderBillingAddress: function () {
      var userInfoView = new zc.views.BillingAddressView({model: app.user, el: this.$billingAddress})
      userInfoView.render()
    },

    renderHeader: function () {
      this.recordingHeaderView = new zc.views.ProjectHeaderView({ configType: 'dashboard', model: new Backbone.Model(), el: this.$header })
      this.recordingHeaderView.render()
    },

    render: function () {
      this._render()
      this.$paymentSource = this.$('.payment-source')
      this.$planInfo = this.$('.plan-info')
      this.$userInfo = this.$('.user-info')
      this.$billingAddress = this.$('.billing-address')
      this.$header = this.$('> .header-container')

      this.renderHeader()
      this.renderPaymentSource()
      this.renderUserInfo()
      this.renderPlanInfo()
      this.renderBillingAddress()

      return this
    }
  })
})()
