/* globals zc Backbone */

(function () {
  'use strict'

  zc.models.Chat = Backbone.Model.extend({
    initialize: function () {
      this.messages = new zc.collections.Messages()
    }
  })
})()
