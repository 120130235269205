/* globals zc _ */

(function () {
  'use strict'

  zc.views.TrackGroupTooltipView = zc.views.TooltipView.extend({
    className: 'tooltip',

    render: function () {
      var self = this

      this.model.forEach(function (track) {
        var trackView = new zc.views.TrackView({
          model: track
        })
        trackView.render()
        if (track.has('error')) {
          trackView.showError()
        }

        self.$el.append(trackView.el)
      })

      this._render(this.$el)
    }
  })

  zc.views.TrackGroupView = zc.views.TrackView.extend({
    initialize: function (options) {
      this.format = options.format
      this.tracks = new zc.collections.Tracks(options.tracks)

      this.uploadingTracks = 0

      this.listenTo(this.tracks, 'change:progress', this.progressChange)
      this.listenTo(this.tracks, 'change:uploading', this.render)
      this.listenTo(this.tracks, 'change:error', this.render)
      this.listenTo(this.tracks, 'change:processing', this.render)
      this.listenTo(this.tracks, 'change:finalized', this.render)
    },

    template: _.template($('.track-group-template').html()),

    className: 'track track-group',

    events: {
      'click ': 'onClick'
    },

    onClick: function () {
      this.tooltip.toggle()
    },

    isUploading: function () {
      return this._countTracks('uploading')
    },

    hasError: function () {
      return this._countTracks('error')
    },

    isProcessing: function () {
      return this._countTracks('processing')
    },

    isFinalized: function () {
      return this._countTracks('finalized')
    },

    /**
     * Used to count how many tracks we have with a specific attribute
     * @param  {String} filter The attribute we want to look at
     * @return {Boolean}
     */
    _countTracks: function (filter) {
      var filteredTracks = this.tracks.filter(function (track) {
        return track.get(filter)
      }).length

      return filteredTracks > 0
    },

    progressChange: function () {
      var uploadingTracks = this.tracks.length
      var totalProgress = this.tracks.reduce(function (acc, track) {
        var progress = track.get('progress') || 0
        if (track.get('finalized')) {
          progress = 100
        }
        return acc + progress
      }, 0)

      var progress = Math.round(totalProgress / uploadingTracks)
      var progressPercentString = progress + '%'

      this.$progressBar.css({ width: progressPercentString })
      var mesage = uploadingTracks + (uploadingTracks > 1 ? ' tracks | ' : ' track | ') +
        progressPercentString + ' Uploaded'

      this.$progressPercent.text(mesage)
    },

    showProcessing: function () {
      this.hideUploading()
      this.hideError()
      this.hideFinalized()
      this.$el.addClass('progress-indeterminate')
      this.$el.addClass('processing')
    },

    /**
     * Override the track view's hideProcessing to do additional things
     */
    hideProcessing: function () {
      this.$el.removeClass('processing')
      this.$el.removeClass('progress-indeterminate')
    },

    showError: function () {
      var tracksWithErrors = this.tracks.filter(function (track) {
        return track.get('error')
      }).length

      var message = 'Error: 1 Unfinalized track'
      if (tracksWithErrors > 1) {
        message = 'Error: ' + tracksWithErrors + ' Unfinalized tracks'
      }

      this.hideFinalized()
      this.hideUploading()

      this.$el.addClass('has-error')
      this.$errorMessage.text(message)
    },

    render: function () {
      var attrs = {format: this.format}
      this.$el.html(this.template(attrs))

      this.$progressBar = this.$('.progress-bar')
      this.$progressPercent = this.$('.progress-percent')
      this.$errorMessage = this.$('.error-message')

      // add format class
      this.$el.addClass(this.format)

      // the order in which this pill should display: uploading, error, processing, finalized
      if (this.isUploading()) {
        this.showUploading()
        this.progressChange()
      } else if (this.hasError()) {
        this.showError()
      } else if (this.isProcessing()) {
        this.showProcessing()
      } else if (this.isFinalized()) {
        this.showFinalized()
      }

      if (!this.tooltip) {
        this.tooltip = new zc.views.TrackGroupTooltipView({model: this.tracks, parent: this.$el})
        this.tooltip.render()
      }

      return this
    }

  })
})()
