/* globals zc _ Backbone */

(function () {
  'use strict'

  zc.views.FootnoteView = Backbone.View.extend({
    template: _.template($('.footnote-template').html()),

    className: 'message',

    render: function () {
      this.$el.html(this.template(this.model.attrs()))

      return this
    }
  })
})()
