/* globals zc Backbone utils */

(function () {
  'use strict'

  zc.models.Footnote = Backbone.Model.extend({
    defaults: {
      time: null,
      text: null
    },

    attrs: function () {
      var attrs = this.toJSON()
      attrs.hmsTime = utils.msToHms(attrs.time)
      return attrs
    }

  })
})()
