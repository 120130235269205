/* globals zc zc2 _ Backbone app utils moment debug analytics */

(function () {
  'use strict'

  var dbg = debug('zc:recording')

  zc.models.Recording = Backbone.Model.extend({
    initialize: function (attrs) {
      attrs = attrs || {}
      this.tentativeTracks = new zc.collections.Tracks()
      attrs.uploads = this.filterTentativeTracks(attrs.uploads)
      this.tracks = new zc.collections.Tracks(attrs.uploads)
      this.postproductions = new zc.collections.Tracks(attrs.postproductions)
      this.transcriptions = new zc.collections.Tracks(attrs.transcriptions || [{ finalized: true }])
      this.footnotes = new zc.collections.Footnotes(attrs.footnotes)
      this.participants = new zc.collections.Users(attrs.participants)

      this.listenTo(this.tracks, 'change:finalized', this.checkAllUploaded)
      this.listenTo(this, 'change:videoRecordingMode', this.videoRecordingModeChange)
      this.listenTo(this, 'change:isRecording', this.recordingChange)

      zc2.store.dispatch(zc2.actions.call.VIDEO_RECORDING_MODE_CHANGE(this.get('videoRecordingMode')))
      zc2.store.dispatch(zc2.actions.call.RECORDING_CHANGE(this.get('isRecording')))
      this.checkAllUploaded()
    },

    defaults: {
      duration: 0,
      hostVoip: true,
      isRecording: false,
      videoRecordingMode: 'enabled',

      // postproduction algorithms
      loudnessTarget: -19,
      leveler: true,
      gate: true,
      crossGate: false,
      transcript: false,
      separateTracks: false
    },

    attrs: function () {
      var attrs = this.toJSON()
      attrs.hmsDuration = utils.msToHms(attrs.duration)
      attrs.trackCount = this.tracks.length
      attrs.tentativeTrackCount = this.tentativeTracks.length
      attrs.participantCount = this.participants.length
      attrs.postproductionCount = this.postproductions.length
      attrs.transcriptionCount = this.transcriptions.length
      attrs.footnoteCount = this.footnotes.length
      attrs.createdAtReadable = attrs.createdAt ? moment(attrs.createdAt).format('dddd MMM Do YYYY - h:mmA') : null
      attrs.updatedAtReadable = attrs.updatedAt ? moment(attrs.updatedAt).format('dddd MMM Do YYYY - h:mmA') : null
      attrs.name = this.getName()
      attrs.slug = this.getSlug()
      attrs.idPath = this.getIdPath()

      return attrs
    },

    videoRecordingModeChange: function (model, videoRecordingMode) {
      zc2.store.dispatch(zc2.actions.call.VIDEO_RECORDING_MODE_CHANGE(this.get('videoRecordingMode')))
      if (app.user.isHost()) {
        app.socket.emit('change:videoRecordingMode', { recordingId: model.id, videoRecordingMode: videoRecordingMode })
      }
    },

    getIdPath: function () {
      var project = this.collection.project
      return ['', project.get('owner'), project.get('slug'), this.id].join('/')
    },

    getName: function () {
      return this.get('name') || 'Recording ' + (this.collection.indexOf(this) + 1)
    },

    getSlug: function () {
      return utils.slugify(this.getName())
    },

    getCloudFolder: function () {
      var project = app.location
      return project.get('slug') + '/' + this.attrs().slug
    },

    // We are phasing out tentative tracks so we are filtering them all out
    filterTentativeTracks: function (tracks) {
      return _.filter(tracks, function (track) {
        return !track.tentative
      })
    },

    isFormatAllowed: function (format) {
      var allowed = ['mp3']
      if (app.user.settings.get('wavRecording')) {
        allowed.push('wav')
      }

      var hasVideoRecording = true
      if (hasVideoRecording) {
        allowed.push('mov')
        allowed.push('webm')
      }
      return allowed.indexOf(format) > -1
    },

    calcLocalStorageUsed: function () {
      var self = this
      return new Promise(function (resolve, reject) {
        var promises = self.tracks.map(function (track) {
          return track.calcLocalStorageUsed()
        })

        Promise.all(promises).then(function (sizes) {
          var size = _.reduce(sizes, function (a, b) { return a + b }, 0)
          resolve(size)
        }, function (err) {
          reject(err)
        })
      })
    },

    deleteLocalStorage: function () {
      var self = this

      var promises = self.tracks.map(function (track) {
        return track.deleteLocalStorage()
      })

      return Promise.all(promises)
    },

    addFootnote: function (attrs) {
      var model = this

      attrs.recordingId = this.id

      app.socket.emit('recording:addFootnote', attrs, function (err, footnote) {
        if (err) {
          utils.nofity('error', err)
        } else {
          model.footnotes.add(footnote)

          analytics.track('Footnote Created', {
            userId: app.user.id,
            projectId: app.project.id,
            recordingId: app.project.recorder.recording.id
          })
        }
      })
    },

    canAllowPostproduction: function () {
      return this.hasFinished()
    },

    getRawTracks: function () {
      return this.tracks ? this.tracks.toJSON() : this.get('uploads')
    },

    hasFinished: function () {
      var rawTracks = this.getRawTracks()
      return !!_.findWhere(rawTracks, { finalized: true })
    },

    hasNotStarted: function () {
      return !this.get('duration') && !this.hasFinished() && !this.tracks.length
    },

    checkAllUploaded: function () {
      var allUploaded = this.allUploadsComplete()
      if (allUploaded) {
        this.set('allUploaded', allUploaded)
      }
    },

    allUploadsComplete: function () {
      var allUploaded = true
      if (!this.tracks.length) {
        allUploaded = false
      }

      this.tracks.each(function (track) {
        if (!track.get('finalized')) {
          allUploaded = false
        }
      })

      return allUploaded
    },

    recordingChange: function (m, isRecording) {
      zc2.store.dispatch(zc2.actions.call.RECORDING_CHANGE(isRecording))
    },

    createPostproduction: function (args, cb) {
      var self = this
      var trackGroups = args.selectedTrackGroups
      var multiTrackOutput = this.get('separateTracks')
      var recordingMode = this.get('videoRecordingMode')
      var recordingId = self.id
      var multiTrackFormat = 'wav'
      var inputTracks = []
      var hasSoundboard = false

      // Default to WAV
      var audioFormat = 'wav'
      trackGroups.forEach(function (trackGroup) {
        // if the track group is selected, add all of its tracks to the postproduction
        if (trackGroup.get('selected')) {
          trackGroup.tracks.forEach(function (track) {
            if (!track.get('selected')) return
            // As soon as we encounter a non-wav audio track, change to mp3 output
            if (track.get('format') === 'mp3') {
              audioFormat = 'mp3'
            }

            if (track.get('type') === 'soundboard') {
              hasSoundboard = true
            }

            inputTracks.push({
              id: track.id,
              format: track.get('format'),
              path: track.get('path'),
              filename: track.get('filename'),
              url: track.get('url'),
              cloudDrive: track.get('cloudDrive'),
              username: track.get('username'),
              userId: app.user.id,
              trackUserId: track.get('userId')
            })
          })
        }
      })

      var track = trackGroups[0].tracks.at(0)
      var finalMixFolder = track.getFinalMixFolder()
      var finalMixFilename = track.getFinalMixFilename(audioFormat)

      var options = {
        metadata: { title: app.location.get('name') + ' ' + 'Final Mix' },
        format: audioFormat,
        algorithms: {
          leveler: this.get('leveler'),
          normloudness: true,
          loudnesstarget: this.get('loudnessTarget'),
          hipfilter: true,
          denoise: true,
          denoiseamount: 0,
          gate: this.get('gate'),
          crossgate: this.get('crossGate')
        },
        output_basename: finalMixFilename.replace(/\.[^.]*$/, ''),
        longPauseThreshold: args.longPauseThreshold,
        longPauseReplace: args.longPauseReplace,
        centeredCrop: args.centeredCrop,
        skipOverlayonZen: args.skipOverlayonZen
      }

      options.outputFiles = [
        { format: audioFormat, mono_mixdown: true }
      ]

      if (multiTrackOutput) {
        options.outputFiles.push({ format: 'tracks', ending: multiTrackFormat + '.zip' })
        // also update the format
        options.format = 'wav'
      }

      console.log('createPostproduction options: ', options)
      dbg('Creating postproduction:', options.metadata.title, options.outputFiles)

      var data = {
        recordingId: recordingId,
        userId: app.user.id,
        destFolder: finalMixFolder,
        inputTracks: inputTracks,
        credits: args.credits,
        transcript: this.get('transcript'),
        transcriptLanguage: this.get('transcriptLanguage'),
        projectName: app.location.get('name'),
        recordingName: this.getName(),
        audioOnly: recordingMode !== 'enabled',
        options: options,
        layout: args.layout || 'side-by-side'
      }
      app.socket.emit('postproduction:create', data, function (err, response) {
        if (err) {
          utils.notify('error', 'There was a problem generating your final mix track. ' + err)
        } else {
          var postproduction = response.postproduction
          var transcription = response.transcription

          postproduction.progress = 33
          postproduction.uploading = true
          self.postproductions.add(postproduction)

          if (transcription) {
            self.transcriptions.set(transcription)
          }

          app.user.incPostproductionCredits(-args.credits)
          utils.notify('success', 'Your postproduction mix is processing and will be available for download shortly.<br><br>  We will send an email to ' + app.user.get('email') + ' when it is ready.', { ttl: 10000 })
          self.set('transcript', null)
          self.set('transcriptLanguage', null)
        }

        cb(err, postproduction)

        analytics.track('RunPostproduction', {
          projectId: app.project.id,
          recordingId: app.project.recorder.recording.id,
          userId: app.user.id,
          noOfParticipants: trackGroups.length,
          audioFormat: audioFormat,
          hasSoundboard: hasSoundboard,
          leveler: self.get('leveler'),
          loudnessTarget: self.get('loudnessTarget'),
          gate: self.get('gate'),
          crossGate: self.get('crossGate'),
          separateWavs: self.get('separateTracks'),
          layout: postproduction.layout,
          isZencastrProduction: postproduction.isZencastrProduction,
          enable_lp: postproduction.longPauseThreshold !== null,
          set_lp_threshold: (postproduction.longPauseThreshold || 0) * 1000,
          set_lp_shorten: (postproduction.longPauseReplace || 0) * 1000
        })
      })
    }
  })
})()
