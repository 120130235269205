/* globals zc _ */

(function () {
  'use strict'

  zc.views.FilterableSelectView = zc.views.BaseView.extend({
    template: _.template($('.filterable-select-template').html()),

    className: 'filterable-select',

    initialize: function () {
      // TODO: Handle the model
      this.blurVal = ''
    },

    events: {
      'focus input': 'showOptions',
      'click .ic.ic-arrow-down': 'showOptions',
      'blur input': 'hideOptions',
      'input input': 'handleInput',
      'mousedown .filterable-select-root': 'handleSelect'
    },

    showOptions: function () {
      this.$options.removeClass('hidden')
      this.updateOptions(this.model.get('options'))
    },

    hideOptions: function () {
      this.$options.addClass('hidden')
      this.$input.val(this.blurVal)
    },

    handleInput: _.debounce(function (e) {
      var renderOptions = this.model.get('options').filter(function (option) {
        return option.label.toLowerCase().includes(e.target.value.toLowerCase())
      })
      this.updateOptions(renderOptions)
    }, 100),

    handleSelect: function (e) {
      // Did not click an option, we don't care
      if (!e.target.classList.contains('option')) return
      this.select(e.target.innerText, e.target.getAttribute('data-value'))
    },

    select: function (label, value) {
      this.$input.val(label)
      this.model.set('value', value)
      this.blurVal = label
      this.$input.attr('data-lang-code', value)
    },

    /**
     * @param values {{label: string, value: string}[]}
     */
    updateOptions: function (values) {
      this.$options.empty()
      values.forEach(function (v) {
        var option = document.createElement('span')
        option.classList.add('option')
        option.textContent = v.label
        option.setAttribute('data-value', v.value)
        this.$options.append(option)
      }.bind(this))
    },

    render: function () {
      this._render()

      this.$input = this.$('input')
      this.$options = this.$('.options')
      this.updateOptions(this.model.get('options'))
      return this.$el
    }
  })
})()
