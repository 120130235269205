/* globals zc _ Backbone */

(function () {
  'use strict'

  zc.views.RecordingLinkView = Backbone.View.extend({
    initialize: function (opts) {
      this.index = opts.index + 1 // +1 since its a zero based idx
      this.listenTo(this.model, 'change:duration', this.render)
      this.listenTo(this.model, 'change:active', this.render)
      this.listenTo(this.model, 'change:disabled', this.render)
    },

    template: _.template($('.recording-link-template').html()),

    className: 'recording-link',

    events: {
      'click': 'triggerClick'
    },

    triggerClick: function () {
      this.model.trigger('click', this.model)
    },

    render: function () {
      this.$el.html(this.template(Object.assign({index: this.index}, this.model.attrs())))

      if (this.model.get('active')) {
        this.$el.addClass('active')
      } else {
        this.$el.removeClass('active')
      }

      if (this.model.get('disabled')) {
        this.$el.addClass('disabled')
      } else {
        this.$el.removeClass('disabled')
      }

      return this
    }

  })
})()
