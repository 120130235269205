/* globals _ zc Backbone utils app analytics */

(function () {
  'use strict'

  zc.views.FootnotesView = Backbone.View.extend({
    initialize: function (options) {
      this.listenTo(this.getFootnoteCollection(), 'add', this.renderFootnote)
      this.listenTo(this.model, 'change:recording', this.updateFootnotes)
      this.listenTo(this.model, 'change:isRecording', this.recordingChange)
      var self = this
      $(window).bind('keypress', function (e) {
        var shouldTrigger = self.handleHotkeyKeypress(e)
        if (shouldTrigger) {
          options.onFootnoteHotkey(e)
        }
      })
    },

    template: '',

    className: 'footnote-form',

    events: {
      'keypress .footnote-input': 'handleInputKeypress'
    },

    recordingChange: function (model, isRecording) {
      if (!isRecording) {
        this.renderFootnotes()
      }
    },

    handleInputKeypress: function (e) {
      // so we don't trigger global hotkeys while typing in the field
      e.stopPropagation()

      if (e.keyCode === 13) { // enter
        this.submit(e)

        analytics.track('AddFootnote', {
          projectId: app.location.id,
          recordingId: app.location.recorder.recording.id,
          userId: app.user.id
        })
      }
    },

    handleHotkeyKeypress: function (e) {
      var self = this
      if (e.keyCode === 102 && app.user.isHost()) { // f hotkey
        _.defer(function () {
          self.$input.focus()
        })
        return true
      }
      return false
    },

    submit: function (e) {
      e.preventDefault()
      var recorder = this.model
      var footnote = {
        text: this.$input.val() || 'No Text',
        ownerId: app.user.get('_id'),
        time: recorder.timer.duration()
      }
      recorder.recording.addFootnote(footnote)
      this.$input.val('')
    },

    getFootnoteCollection: function () {
      return this.model.recording.footnotes
    },

    updateFootnotes: function (recorder, recording) {
      // Pass in the updated recorder's footnotes, relying on 'this.model' wasn't working
      this.renderFootnotes(recording.footnotes)
    },

    /**
     * @param footnotes Optional footnotes collection to override the ones on the model.
     *                  Needed when changing between recordings in case the model is not up to date
     */
    renderFootnotes: function (footnotes) {
      var view = this

      if (!footnotes) {
        footnotes = this.getFootnoteCollection()
      }
      // Ensure we remove all existing footnotes
      this.$footnotes.empty()

      footnotes.each(function (footnote) {
        view.renderFootnote(footnote)
      })

      // Toggle between download button and input field
      if (this.model.hasFinishedRecording()) {
        this.$el.find('.download-footnotes').css('display', 'block')
        this.$el.find('.footnote-input').hide()
      } else {
        this.$el.find('.download-footnotes').hide()
        this.$el.find('.footnote-input').css('display', 'block')
      }

      this.checkIfEmpty()
    },

    renderFootnote: function (footnote) {
      var footnoteView = new zc.views.FootnoteView({ model: footnote })
      this.$footnotes.append(footnoteView.render().el)
      this.checkIfEmpty()
      this.scrollToBottom()
    },

    checkIfEmpty: function () {
      var footnotes = this.getFootnoteCollection()
      if (!footnotes.length) {
        this.$el.addClass('empty')
      } else {
        if (this.$el.hasClass('empty')) {
          this.$el.removeClass('empty')
        }
      }
    },

    scrollToBottom: function () {
      this.$footnotes.scrollTop(this.$footnotes.prop('scrollHeight'))
    },

    render: function () {
      var self = this
      var time = this.model.timer.duration()
      this.attrs = {
        time: time,
        hmsTime: utils.msToHms(time)
      }

      this.$footnotes = this.$('.messages-list')
      this.$input = this.$('.footnote-input')
      if (app.user.isHost()) {
        this.renderFootnotes()
        _.defer(function () {
          self.scrollToBottom()
        })
      }

      return this
    }
  })
})()
