/* globals app $ zc Backbone utils Dropbox */

// Set the login anchors href using dbx.getAuthenticationUrl()

(function () {
  'use strict'

  zc.models.CloudDrive = Backbone.Model.extend({
    initialize: function (attrs, options) {
      attrs = attrs || {}
      this.client = attrs.client || null

      if (attrs.provider === 'dropbox') {
        this.set('clientId', zc.conf.dropbox.appKey)
      }

      // if the user already has a cloud provider connected, first init the client
      // when we'll get the quota we'll fire cloudDrive:ready
      if (attrs.provider && attrs.token) {
        this.set('authenticated', true)
        this.initClient()
      } else {
        this.trigger('cloudDrive:ready')
      }
    },

    defaults: {
      connecting: false,
      provider: null,
      clientId: null,
      authenticated: false,
      cloudToken: null,
      cloudProviderName: '',
      quota: 0,
      usedQuota: 0
    },

    attrs: function () {
      var attrs = this.toJSON()
      attrs.freeQuota = attrs.quota - attrs.usedQuota
      attrs.formattedQuota = this.formattedDiskSpace(attrs.quota)
      attrs.formattedUsedQuota = this.formattedDiskSpace(attrs.usedQuota)
      attrs.formattedFreeQuota = this.formattedDiskSpace(attrs.freeQuota)
      return attrs
    },

    formattedDiskSpace: utils.formattedDiskSpace,

    initClient: function () {
      var self = this
      if (this.get('provider') === 'dropbox') {
        return this.dropbox.createDropboxClient(this.get('token')).then(this.populateData.bind(self))
      } else {
        self.populateData()
      }
    },

    disconnect: function () {
      var self = this

      app.socket.emit('user:cloudDrive:disconnect', {}, function (err) {
        if (err) utils.notify('error', err)

        self.set(self.defaults)
        self.trigger('cloudDrive:change')
      })
    },

    getRedirectUrl: function () {
      // if the user is on app.zencastr.com, force him to zencastr.com
      var domain = window.location.hostname === 'app.zencastr.com' ? 'zencastr.com' : window.location.hostname
      return window.location.protocol + '//' + domain + '/cloud-drive'
    },

    getReusableToken: function () {
      return this.client.authTokenFromOauth1()
    },

    getSpaceUsage: function () {
      var self = this
      if (this.get('provider') === 'dropbox') {
        return this.dropbox.usersGetSpaceUsage().then(function (usage) {
          return {
            used: usage.used,
            quota: usage.allocation.allocated
          }
        })
      } else {
        return this.google.getToken().then(function (accessToken) {
          self.set('token', accessToken.access_token)
          return self.google.getQuota(self.get('token')).then(function (response) {
            var usage = response.storageQuota
            return {
              used: Number(usage.usage),
              quota: Number(usage.limit)
            }
          })
        })
        // if this happens then the user revoked our access
        // or the token somehow expired
        .catch(function () {
          // there's no need to keep it, so just remove it and notify the user to connect drive again
          self.disconnect()
          utils.notify('error', 'There was an error while connecting to your Google Drive. Access for Zencastr might have been revoked. You can recreate the connection from Dashboard.')
        })
      }
    },

    populateData: function () {
      var self = this
      return this.getSpaceUsage().then(function (usage) {
        self.set({usedQuota: usage.used, quota: usage.quota})
        self.trigger('cloudDrive:change')
      })
    },

    connectCloudDrive: function (provider) {
      var self = this
      this.set('provider', provider)
      this.set('connecting', true)

      if (provider === 'dropbox') {
        this.dropbox.createDropboxClient(this.get('token')).then(function () {
          window.location.href = self.dropbox.getAuthUrl(self.getRedirectUrl())
        })
      } else if (provider === 'google') {
        window.location.href = this.google.getAuthUrl(this.getRedirectUrl())
      }
    },

    dropbox: {
      // keep track of the client instance
      client: null,

      createDropboxClient: function (token) {
        var self = this
        return new Promise(function (resolve, reject) {
          self.client = new Dropbox({clientId: zc.conf.dropbox.appKey, accessToken: token})
          resolve()
        })
      },

      getAuthUrl: function (redirectUrl) {
        return this.client.getAuthenticationUrl(redirectUrl)
      },

      usersGetSpaceUsage: function () {
        return this.client.usersGetSpaceUsage()
      }
    },

    google: {

      getAuthUrl: function (redirectUrl) {
        // base url for the google api oauth
        var base = 'https://accounts.google.com/o/oauth2/v2/auth'

        var queryParams = {
          'client_id': zc.conf.google.clientId,
          'scope': zc.conf.google.scope,
          'redirect_uri': redirectUrl,
          'access_type': 'offline',
          'response_type': 'code',
          'include_granted_scopes': true,
          'state': 'google'
        }

        return base + '?' + $.param(queryParams)
      },

      getToken: function () {
        return new Promise(function (resolve, reject) {
          app.socket.emit('user:cloudDrive:token:get', {}, function (err, token) {
            err ? reject(err) : resolve(token)
          })
        })
      },

      getQuota: function (token) {
        return new Promise(function (resolve, reject) {
          var url = 'https://www.googleapis.com/drive/v3/about?'
          var params = $.param({
            'access_token': token,
            'key': zc.conf.google.clientId,
            'fields': 'storageQuota'
          })
          url += params

          return $.get(url).success(resolve).fail(reject)
        })
      }
    }
  })
})()
