/* globals servars zc zc2 _ ua utils Backbone app analytics debug AudioRecorder InsertableStreamRecorder CanvasRecorder Logger ResumableUploadGcs WebCodecsVideoRecorder optimizelyClientInstance recordingTransactionsClient */
(function () {
  'use strict'

  var dbg = debug('zc:recorder')

  let recordingCheckIntervalId = null

  function getDisplayNamesByIds (ids, lobbyModel) {
    ids = [].concat(ids) // make sure ids is an array
    var displayNames = ids.map(id => {
      var user = lobbyModel.getUserById(id)
      if (user == null) {
        return id // use id if we can't find the user in the lobby (that's all we got)
      }
      return user.displayName
    })
    return displayNames.join(', ')
  }

  function isParticipantInTheRecording (id, lobbyModel) {
    var user = lobbyModel.getUserById(id)
    return user !== null
  }

  function isParticipantInGreenRoom (id, lobbyModel) {
    var user = lobbyModel.getUserById(id)
    return user && user.greenroom
  }

  zc.models.Recorder = Backbone.Model.extend({
    initialize: function (attrs, options) {
      dbg('Initializing recorder')

      this.recording = attrs.recording
      this.actx = options.actx
      this.project = options.project
      this.socket = options.socket
      this.userMedia = this.project.userMedia

      this.timer = new zc.models.Timer({ duration: attrs.recording.get('duration') })

      if (app.user.id === this.project.get('ownerId')) {
        // Just don't even make the monitor available if you aren't the host
        this.monitorService = new zc2.services.MonitorService(zc2.store, app.user.id, this.userMedia)
      }

      this.healthCheckManager = new zc.models.HealthCheckManager({}, { app: app, project: this.project, recorder: this })

      this.analyserNode = this.actx.createAnalyser()
      this.compressor = this.actx.createDynamicsCompressor()

      // dry input without compression or other effects
      this.input = this.actx.createGain()

      this.input.channelCountMode = 'explicit'
      this.input.channelInterpretation = 'speakers'
      this.input.channelCount = 1

      this.input.connect(this.analyserNode) // data viz

      /**
       * Keep track of the WakeLock instance
       */
      this.wakeLock = null

      this.hasSoundboard = false

      this.hasVideoRecording = this.recording.get('videoRecordingMode') === 'enabled'

      /**
       * Us insertable streams on Windows 10, or if we specifically turn it on
       * @type {Boolean}
       */
      // var isWindows10 = ua.os.name === 'Windows' && ua.os.version === '10'
      // var preferInsertableStreams = localStorage.getItem('useInsertableStreams') === 'true'
      // TODO: reenable IS on windows on Chrome 89
      // this.useInsertableStreams = isWindows10 || preferInsertableStreams
      this.useInsertableStreams = false

      // Timestamps used to know when the media recorder has started/stopped
      // used to verify that we've got the correct number of samples
      this.stats = {
        'microphone': {
          // helps use know how much delay was added when a mic was unplugged
          'delay': 0,
          'start': null,
          'stop': []
        },
        'soundboard': {
          'delay': 0,
          'start': null,
          'stop': []
        }
      }

      /**
       * Manually set to true to start saving the webn files for input
       * should only be used in DEV
       * @type {Boolean}
       */
      this.debugWebmFiles = !!localStorage.getItem('debugWebmFiles')
      this._debugWebmData = {}

      /**
       * Used to tel MediaRecorder how long do we want to webm chunks for the soundboard
       * @type {Number}
       */
      this._soundboardMediarecorderChunkSize = 190

      /**
       * Used to keep track of timestamps for chunks that are emitted by the MediaRecorder
       * @type {Number}
       */
      this._mediaRecorderLastSeenChunkTimestamp = null

      // get the wavRecording setting from the recording model, if it has it
      // this is case when the recording has started and the flag is saved in the DB
      if (this.recording.has('wavRecording')) {
        app.user.settings.set({ wavRecording: this.recording.get('wavRecording') })
      }

      this.on('change:isRecording', this.isRecordingChange)
      this.on('change:recording', this.recordingChange)
      this.on('change:paused', this.pausedChange)

      this.listenTo(this.recording, 'change:allUploaded', this.allUploadedChange)
      this.listenTo(app.user, 'change:muted', this.mutedChange)
      this.listenTo(app.user, 'change:greenroom', this.greenroomChanged)
      this.listenTo(app.user.tracks, 'chunkUploaded', this.updateRecording)
      this.listenTo(app.user.tracks, 'change:processing', this.trackProcessingChange)
      this.listenTo(app.user.settings, 'change:wavRecording', this.wavRecordingSettingChange)
      this.listenTo(app.user.settings, 'change:videoRecordingResolution', this.videoRecordingResolutionChanged)

      _.bindAll(this, 'gotLocalStream', 'startMicrophone')

      this.userMedia.on('streamAvailable', this.gotLocalStream)

      this.reduxWatcher = new zc2.utils.ReduxWatcher(zc2.store)
      this.reduxWatcher.watch('media_manager.preferredDevices.audioOutput', this.audioOutputChange.bind(this))

      this._registerHandlerForStartRecordingAction()
      this._registerHandlerForAbortStartAction()
      this._registerHandlerForStopRecordingAction()
      this._registerHandlerForHealthChecksAction()
      this._registerHandlerForPause()
      this._registerHandlerForAbortPause()
      this._registerHandlerForResume()
      this._registerHandlerForAbortResume()
    },

    destroy: function () {
      this.reduxWatcher.destroy()
    },

    defaults: {
      cloudDrive: null, // dropbox or google
      micArmed: false,
      micGain: 1,
      isRecording: false,
      paused: false,
      aborted: false,
      // deferUploads: false,
      recording: null,
      recordingSilence: false,
      duration: 0,
      allArmed: false,
      ownerId: null,
      recordingStoppedTime: null // Used for analytics
    },

    handleStartRecording: async function () {
      if (this.isRecordingPromise) {
        // this prevents staring a recording twice
        console.log('Tried to start a recording twice, ignoring the second')
        return
      }
      var self = this
      console.log('====> Started recording')
      self.isRecordingPromise = utils.promiseSerial([
        self.prepareToRecord.bind(self),
        self.initAudioContext.bind(self),
        self.initStreamConsumers.bind(self),
        // these 2 methods are also called in .prepareTracks()
        // it's safe to also call them again here
        // this is useful when the user lands on a page which is already recording
        self.createUserTracks.bind(self),
        self.initializeTracksForRecording.bind(self),
        self.addTrackEventListeners.bind(self),
        self.addStreamTrackEventListeners.bind(self),
        self.addWakeLock.bind(self),
        self.startRecording.bind(self),
        function () {
          var videoTrack = self.project.userMedia.stream.getVideoTracks()[0]
          if (videoTrack) {
            analytics.track('VideoRecordingResolution', {
              resolution: videoTrack.getSettings().height,
              projectId: self.project.id,
              recordingId: self.recording.id,
              userId: app.user.id
            })
          }
        }
      ])

      this.saveHardwareMetrics()

      await this.isRecordingPromise
      // pass the isRecording state down
      app.user.set({ isRecording: true })
      this.set({ isRecording: true })

      if (app.user.isHost()) {
        this._startAskingForRecordingChecks()
        this.project.call.startRecordingSfuNotify()
        console.log(`handleStartRecording from socket (host): ${self.project.id}, ${new Date().toISOString()}`)
      } else {
        console.log(`handleStartRecording from socket (client): ${self.project.id}, ${new Date().toISOString()}`)
      }

      this.socket.emit('change:isRecording', {
        isRecording: true,
        recordingId: this.recording.id,
        path: this.recording.getCloudFolder(),
        trackIds: app.user.tracks.pluck('_id'),
        user: app.user.toExtendedJSON()
      })
    },

    handleStopRecording: async function () {
      var self = this
      if (app.user.isHost()) {
        self._stopAskingForRecordingChecks()
      }
      console.log('=====> Stopping recording')
      this.stopRecording()
      this.updateRecording()
      this.removeWakeLock()

      // pass the isRecording state down
      app.user.set({ isRecording: false })
      self.set({ isRecording: false })

      this.socket.emit('change:isRecording', {
        isRecording: false,
        recordingId: self.recording.id,
        path: self.recording.getCloudFolder(),
        trackIds: app.user.tracks.pluck('_id'),
        user: app.user.toExtendedJSON()
      })
    },
    _registerHandlerForStartRecordingAction: function () {
      var self = this;
      /** @type {typeof import('@zencastr/socket-transactions').recordingTransactionsClient}  */
      (recordingTransactionsClient).registerActionHandler('start', async function (recordingId) {
        console.log(`Received a start recording transaction action for recording: ${recordingId}`)
        if (recordingId !== self.recording.id) {
          throw new Error('Local recording is different that room\'s')
        }
        if (!app.user.get('greenroom')) {
          await self.handleStartRecording()
        } else {
          console.warn(`STRNC: Not starting to record because the user is in the greenroom`)
          // recording check will pick this up if the participant doesn't automatically start the recording when leaving the greenroom
        }
      })
    },
    _registerHandlerForAbortStartAction: function () {
      var self = this;

      /** @type {typeof import('@zencastr/socket-transactions').recordingTransactionsClient}  */
      (recordingTransactionsClient).registerAbortHandler('start', async function (recordingId) {
        console.log(`Received an abort start recording transaction action for recording: ${recordingId}`)
        if (recordingId !== self.recording.id) {
          console.warn('Received an abort for start recording transcation for a recording that doesn\'t match the local recording id, stopping anyway')
        }

        await self.handleStopRecording()
      })
    },
    _registerHandlerForStopRecordingAction: function () {
      var self = this;

      /** @type {typeof import('@zencastr/socket-transactions').recordingTransactionsClient}  */
      (recordingTransactionsClient).registerActionHandler('stop', async function (recordingId) {
        console.log(`Received a stop recording transaction action for recording: ${recordingId}`)
        if (recordingId !== self.recording.id) {
          console.warn('Received a stop recording transaction action for a recording that doesn\'t match the local recording id, stopping anyway')
        }
        await self.handleStopRecording()
      })
    },
    _registerHandlerForPause: function () {
      var self = this;
      /** @type {typeof import('@zencastr/socket-transactions').recordingTransactionsClient}  */
      (recordingTransactionsClient).registerActionHandler('pause', async function (recordingId) {
        console.log(`Received a pause recording transaction action for recording: ${recordingId}`)
        if (recordingId !== self.recording.id) {
          throw new Error('Local recording is different that room\'s')
        }

        if (app.user.get('greenroom')) {
          console.warn(`STRNC: Not pausing because the user is in the greenroom`)
          // recording check will pick this up if the participant doesn't automatically start+pause when leaving the greenroom
          return
        }

        if (!self.get('isRecording')) {
          throw new Error('Trying to pause but not recording')
        }

        if (self.get('paused')) {
          console.warn('Trying to pause but already paused, ignoring')
          return
        }

        await self.handlePause()
      })
    },
    _registerHandlerForAbortPause: function () {
      var self = this;
      /** @type {typeof import('@zencastr/socket-transactions').recordingTransactionsClient}  */
      (recordingTransactionsClient).registerAbortHandler('pause', async function (recordingId) {
        console.log(`Received a abort pause transaction action for recording: ${recordingId}, stopping the recording`)
        await self.handleStopRecording()
      })
    },
    handlePause: async function () {
      var self = this
      self.set({ paused: true })
      self.recording.set({ paused: true })
      self.pause()
      app.user.set({ paused: true })
      self.socket.emit('change:paused', {
        recordingId: self.recording.id,
        paused: true
      })
      if (app.user.isHost()) {
        self._stopAskingForRecordingChecks()
      }
    },
    _registerHandlerForResume: function () {
      var self = this;
      /** @type {typeof import('@zencastr/socket-transactions').recordingTransactionsClient}  */
      (recordingTransactionsClient).registerActionHandler('resume', async function (recordingId) {
        console.log(`Received a resume recording transaction action for recording: ${recordingId}`)
        if (recordingId !== self.recording.id) {
          throw new Error('Local recording is different that room\'s')
        }

        if (app.user.get('greenroom')) { // note that host is never in the greenroom
          console.warn(`STRNC: Not resuming because the user is in the greenroom`)
          // recording check will pick this up if the participant doesn't automatically start recording when leaving the greenroom
          return
        }

        if (!self.get('isRecording')) {
          throw new Error('Trying to resume a recording, but not recording')
        }

        if (!self.get('paused')) {
          console.warn('Trying to resume but not paused, ignoring')
          return
        }
        await self.handleResume()

        if (app.user.isHost()) {
          self._startAskingForRecordingChecks()
        }
      })
    },
    _registerHandlerForAbortResume: function () {
      var self = this;
      /** @type {typeof import('@zencastr/socket-transactions').recordingTransactionsClient}  */
      (recordingTransactionsClient).registerAbortHandler('resume', async function (recordingId) {
        console.log(`Received a abort resume recording transaction action for recording: ${recordingId}, stopping the recording`)
        await self.handleStopRecording()
      })
    },
    handleResume: async function () {
      var self = this
      self.set({ paused: false })
      self.recording.set({ paused: false })
      self.resume()
      app.user.set({ paused: false })
      self.socket.emit('change:paused', {
        recordingId: self.recording.id,
        paused: false
      })
    },
    _registerHandlerForHealthChecksAction: function () {
      var self = this
      console.log('Registering for recording-check');
      /** @type {typeof import('@zencastr/socket-transactions').recordingTransactionsClient}  */
      (recordingTransactionsClient).registerActionHandler('recording-check', async function (recordingId) {
        console.log('STRNC: Performing recording-check')

        if (app.user.get('greenroom')) {
          console.log('STRNC: reporting recording check passed because currently in greenroom')
          return
        }

        if (self.recording.id !== recordingId) {
          throw new Error('Local and room\'s recording id doens\'t match')
        }
        if (!self.get('isRecording')) { throw new Error('Not recording') }

        if (self.mediaRecorder.state !== 'recording') {
          throw new Error('Media recorder is in an invalid state: ' + self.mediaRecorder.state)
        }

        if (self._mediaRecorderLastSeenChunkTimestamp) {
          var diff = Date.now() - self._mediaRecorderLastSeenChunkTimestamp
          if (diff > 45 * 1000) {
            throw new Error('No more content is being recorded.')
          }
        }

        console.log('STRNC: Recording-check passed')
      })
    },
    _startAskingForRecordingChecks: function () {
      var self = this
      self._stopAskingForRecordingChecks()
      var consecutiveFailedCount = 0
      recordingCheckIntervalId = setInterval(async function () {
        try {
          const projectId = self.project.id
          const recordingId = self.recording.id
          const memberIds = app.project.lobby.users.map(m => m.toJSON()._id)
          console.log(`Recording-check: `, { projectId, recordingId, memberIds })

          /** @type {typeof import('@zencastr/socket-transactions').recordingTransactionsClient}  */
          await (recordingTransactionsClient).startTransaction({
            actionType: 'recording-check',
            members: memberIds,
            recordingId,
            roomId: projectId
          })
          consecutiveFailedCount = 0
        } catch (error) {
          if (error.isInitiatorTimeout) {
            console.warn('STRNC: Recording check transaction timeout out client side', error)
            return
          }
          if (error.isTimeout) {
            console.warn('STRNC: Could not validate that some of the participants are actually recording (' + getDisplayNamesByIds(error.membersWhoDidntComplete || [], app.project.lobby) + ')', error)
            return
          }

          if (error.failedMemberId) {
            if (isParticipantInTheRecording(error.failedMemberId, app.project.lobby) && !isParticipantInGreenRoom(error.failedMemberId, app.project.lobby)) {
              consecutiveFailedCount++
            }
            if (!isParticipantInTheRecording(error.failedMemberId, app.project.lobby)) {
              console.warn('STRNC: Received a failed recording check from a participant that is not in the hosts\'s lobby: ' + error.failedMemberId)
            }
          }

          console.warn('Record-check failed:', error)
          if (consecutiveFailedCount > 3) {
            consecutiveFailedCount = 0
            var errorMessage = 'Participant ' + getDisplayNamesByIds([error.failedMemberId], app.project.lobby) + ' is having a problem: ' + error.description
            utils.notify('alert', errorMessage)
          }
        }
      }, 5000)
    },
    _stopAskingForRecordingChecks: function () {
      if (recordingCheckIntervalId !== null) {
        clearInterval(recordingCheckIntervalId)
        recordingCheckIntervalId = null
      }
    },

    attrs: function () {
      var attrs = this.toJSON()
      attrs.slug = this.project.get('slug') + '/' + this.recording.getSlug()
      attrs.cloudDrive = attrs.recording.get('cloudDrive')
      if (attrs.cloudDrive) {
        attrs.cloudDriveLink = attrs.cloudDrive === 'dropbox' ? 'https://www.dropbox.com/home/Apps/zencastr/' + attrs.slug : 'https://drive.google.com/drive/my-drive'
      }
      attrs.projectName = this.project.get('name')
      attrs.finishedRecording = this.hasFinishedRecording()
      return attrs
    },

    /**
     * Start recording methods
     */
    startRecording: function () {
      // set recording state on tracks
      app.user.tracks.forEach(function (track) {
        track.set({ recording: true })
      })

      // this.compressor.connect(this.actx.destination) // for debugging so we can hear
      this.healthCheckManager.startMidRecordingHealthChecks()

      if (this.processor) {
        // if the user has soundboard on
        if (this.hasSoundboard) {
          // create a channel merger that will merge the streams
          // for both mic and soundboard
          this.merger = this.actx.createChannelMerger(2)

          this.input.connect(this.merger, 0, 0)
          this.project.soundboardOut.connect(this.merger, 0, 1)

          this.merger.connect(this.processor)
        } else {
          // if not soundboard, just connect the mic
          this.input.connect(this.processor)
        }

        this.outputNode = this.actx.createMediaStreamDestination()
        this.processor.connect(this.outputNode)
      }

      // start the mediaRecorder it exists
      if (this.mediaRecorder) {
        this.mediaRecorder.start()
        console.log('Started Recording with MediaRecorder State:  ', this.mediaRecorder.state)
      }

      if (this.soundboardMediaRecorder) {
        this.soundboardMediaRecorder.start(this._soundboardMediarecorderChunkSize)
      }

      // start record timer
      this.timer.start()

      this.recording.set('isRecording', true)

      // start getting the transcribed data for the audio only
      // @TODO: re-enable when we have the new platform server
      // this.webSpeechService.startRecording(Date.now(), this.recording.id)
    },

    checkHealth: function () {
      return this.healthCheckManager.preRecordingHealthCheck()
    },

    prepareToRecord: function () {
      var self = this
      dbg('prepareToRecord called')

      // We may already be preparing or prepared to record
      if (this.preparingPromise) return this.preparingPromise
      app.user.set('readyToRecord', false)

      this.preparingPromise = utils.promiseSerial([
        this.initAudioContext.bind(this),
        this.initAudioRecordingPipeline.bind(this),
        this.initUploadWorker.bind(this),
        this.getSampleRate.bind(this),
        this.checkHealth.bind(this)
      ]).then(function () {
        self.setOnCloseLogFlushHandler()
        console.log('Ready to record', app.user.get('displayName'))
        app.user.set('readyToRecord', true)
      }).catch(function (err) {
        console.error(app.user.get('displayName'), 'Error preparing to record', err)
        return self.abortRecordingAndReportError(err)
      })

      return this.preparingPromise
    },

    initAudioContext: function () {
      var self = this
      dbg('initAudioContext')
      return new Promise(function (resolve) {
        if (app.actx.state === 'suspended') {
          self.once('audioContextResumed', resolve)
        } else {
          resolve()
        }
      })
    },

    /**
     * Used to either start the MediaRecorder or ScriptProcessorNode
     * deppending on browser and needs
     */
    initRecordingNode: function () {
      dbg('Initializing media recorder')

      // if already initialized, return
      if (this.mediaRecorder || this.processor) return

      try {
        // if we have pcm support it's most likely Chrome
        if (AudioRecorder.mimeTypeSupport) {
          // create the soundboard media recorder first
          if (this.hasSoundboard) {
            this.initAudioRecorder('soundboard')
          }

          // in chrome with video recording, we'll use this media recorder
          if (this.hasVideoRecording) {
            return this.initVideoRecorder()
          }
          // for all other cases use the MediaRecorder api
          this.initAudioRecorder('microphone')

          // else, most likely firefox
        } else {
          // in this case we need the ScriptProcessorNode for audio
          this.initScriptProcessorNode()

          // and media recorder for video
          if (this.hasVideoRecording) {
            return this.initVideoRecorder()
          }
        }
      } catch (err) {
        console.error('Exception while creating MediaRecorder: ' + err)
        throw err
      }
    },

    initStreamConsumers: function () {
      var self = this
      dbg('initStreamConsumers called')

      if (this.initStreamConsumersPromise) return this.initStreamConsumersPromise

      this.initStreamConsumersPromise = utils.promiseSerial([
        this.initRecordingNode.bind(this)
      ]).then(function () {
        console.log('Stream consumers initialized', app.user.get('displayName'))
      }).catch(function (err) {
        console.error(app.user.get('displayName'), 'Error preparing to record', err)
        return self.abortRecordingAndReportError(err)
      })

      return this.initStreamConsumersPromise
    },

    initAudioRecorder: function (tag) {
      var self = this
      var stream

      if (tag === 'microphone') {
        stream = this.project.userMedia.stream
      } else if (tag === 'soundboard') {
        var merger = this.actx.createChannelMerger(2)
        var streamDestination = this.actx.createMediaStreamDestination()

        this.project.soundboardOut.connect(merger, 0, 0)
        this.project.soundboardOut.connect(merger, 0, 1)

        merger.connect(streamDestination)

        stream = streamDestination.stream
      }

      // make sure the stream is a MediaStream
      if (!(stream instanceof MediaStream)) {
        throw new Error('stream is not an instance of MediaStream')
      }

      var mediaRecorder = new AudioRecorder(stream)
      console.log('Created MediaRecorder for', tag, 'with mime type', AudioRecorder.mimeType)
      mediaRecorder.ondataavailable = function (e) {
        if (e.data.size === 0) {
          console.error('Received a 0 sized blob')
          self.abortRecordingAndReportError('Error with recording input.')
          return
        }

        self.audioPipeline.inputPort.postMessage({
          chunk: e.data,
          tag: tag
        })

        // if we have debug on, save all the webm chunks and download them at the end
        if (self.debugWebmFiles) {
          self._debugWebmData[tag] = self._debugWebmData[tag] ? new Blob([self._debugWebmData[tag], e.data]) : e.data
        }
      }
      mediaRecorder.onstart = function (e) {
        if (self._stopMediaReocrderTimestamp) {
          // if this is the second mediaRecorder, also fire `reinit`
          // we get the end timestamp of the previous media recorder as a resolve value
          self._stopMediaReocrderTimestamp.then(function (timestamp) {
            var delay = Date.now() - timestamp
            self.stats['microphone'].delay += delay

            console.warn('Reinitializing media recorder. Time delay to add (ms):', delay)
            // we need to restart just the microphone processor
            self.audioPipeline.inputPort.postMessage({
              reinit: true,
              tag: 'microphone',
              delay: delay
            })
          })
        } else {
          // start timestamp
          self.stats[tag].start = performance.now()
        }
      }
      mediaRecorder.onstop = function (e) {
        self.audioPipeline.inputPort.postMessage({
          isLast: true,
          tag: tag
        })
        self.stats[tag].stop[1] = performance.now()

        if (self.debugWebmFiles) {
          if (self._debugWebmData[tag]) {
            utils.forceDownload(self._debugWebmData[tag], tag + '.webm')
            self._debugWebmData[tag] = null
          }
        }
      }

      mediaRecorder.onpause = function () {
        var message = 'AudioRecorder has been paused'
        if (self.get('paused')) {
          console.log(message)
        } else {
          console.warn(message)
        }
      }

      mediaRecorder.onresume = function () {
        var message = 'AudioRecorder has resumed'
        if (!self.get('paused')) {
          console.log(message)
        } else {
          console.warn(message)
        }
      }

      if (tag === 'microphone') {
        this.mediaRecorder = mediaRecorder
      } else if (tag === 'soundboard') {
        this.soundboardMediaRecorder = mediaRecorder
      }
    },

    initScriptProcessorNode: function () {
      var self = this
      console.log('Initializing script processor node')

      var inputChannels = this.hasSoundboard ? 2 : 1
      this.processor = this.actx.createScriptProcessor(16384, inputChannels, 1) // 16384 is max

      this.processor.onaudioprocess = function (e) {
        var buff = e.inputBuffer

        var message = {
          microphone: buff.getChannelData(0),
          sampleRate: buff.sampleRate
        }

        if (self.hasSoundboard) {
          message['soundboard'] = buff.getChannelData(1)
        }

        self.audioPipeline.inputPort.postMessage(message)

        if (!self.stats.microphone.start) {
          self.stats.microphone.start = self.stats.soundboard.start = performance.now()
        }

        // Fake output
        for (var channel = 0; channel < e.outputBuffer.numberOfChannels; channel++) {
          var outputData = e.outputBuffer.getChannelData(channel)
          for (var n = 0; n < outputData.length; n++) {
            outputData[n] = (n & 1) / 10000
          }
        }
      }
    },

    initVideoRecorder: function (streamObject) {
      var self = this

      var stream = this.project.userMedia.stream

      // make sure the stream is a MediaStream
      if (!(stream instanceof MediaStream)) {
        throw new Error('stream is not an instance of MediaStream')
      }

      var videoTrack = stream.getVideoTracks()[0]
      var audioTrack = stream.getAudioTracks()[0]

      if (!audioTrack) {
        throw new Error('Stream does not have audio track')
      }

      if (audioTrack.readyState === 'ended') {
        throw new Error('Received ended audio track')
      }

      var constructor = this.useInsertableStreams ? InsertableStreamRecorder : CanvasRecorder

      try {
        this.mediaRecorder = new constructor({
          mediaStream: stream,
          // if we have videoTrack then canvas mode should be off
          canvasMode: !videoTrack
        })
      } catch (err) {
        // if we got an error and we were using insertable streams
        if (this.useInsertableStreams) {
          console.error('Error starting InsertableStreamRecorder', err)
          // fallback to media recorder
          this.mediaRecorder = new CanvasRecorder({
            mediaStream: stream,
            // if we have videoTrack then canvas mode should be off
            canvasMode: !videoTrack
          })
        } else {
          throw err
        }
      }

      this.mediaRecorder.ondataavailable = function (e) {
        self._mediaRecorderLastSeenChunkTimestamp = Date.now()

        // if we got a 0 size blob or an empty array buffer, then it's bad
        // just abort the recording
        if (e.data.size === 0 || e.data.length === 0) {
          console.error('Received a 0 sized blob')
          self.abortRecordingAndReportError('Error with recording input.')
          return
        }

        if (self.useInsertableStreams || CanvasRecorder.hasH264Support) {
          var data = e.data
          var transferables = []

          if (data instanceof Uint8Array) {
            transferables.push(data.buffer)
          }

          self.audioPipeline.inputPort.postMessage({
            chunk: data,
            // the microphone processor is the one handling video recording
            tag: 'microphone'
          }, transferables)
        }

        if (self.debugWebmFiles) {
          self._debugWebmData['microphone'] = self._debugWebmData['microphone'] ? new Blob([self._debugWebmData['microphone'], e.data]) : e.data
        }
      }

      /** @param {MediaRecorderErrorEvent} e */
      this.mediaRecorder.onerror = function (e) {
        let errorMessage = ''
        // error types: https://developer.mozilla.org/en-US/docs/Web/API/MediaRecorder/onerror
        switch (e.error.name) {
          case 'InvalidStateError':
            errorMessage = 'An attempt was made to stop or pause a inactive recording.'
            break
          case 'SecurityError':
            errorMessage = 'No permissions to record'
            break
          case 'NotSupportedError':
            errorMessage = 'Device does not support this type of recording'
            break
          case 'InvalidModificationError':
            errorMessage = 'Number of tracks being recorded changed while recording. This might happen due to a hardware issue, for example when a microphone or camera disconnects'
            break
          case 'UnknownError':
            errorMessage = 'Failed to read recording data.'
            break
          default:
            // this should not happen, but adding a different message in case so that you can trace it back to this case
            errorMessage = 'Browser error. Failed to read recording data'
            break
        }

        console.error('Media recorder failed with error ' + e.error.name + ': ' + errorMessage)
        console.error(e.error)

        self.abortRecordingAndReportError(errorMessage)
        analytics.track('MediaRecorderError', {
          userId: app.user.id,
          recordingId: self.recording.id,
          message: e.error.name + ': ' + errorMessage
        })
      }

      this.mediaRecorder.onstop = function () {
        if (self.useInsertableStreams || CanvasRecorder.hasH264Support) {
          self.audioPipeline.inputPort.postMessage({
            isLast: true,
            tag: 'microphone'
          })
        }

        if (self.debugWebmFiles) {
          if (self._debugWebmData['microphone']) {
            utils.forceDownload(self._debugWebmData['microphone'], 'microphone.webm')
            self._debugWebmData['microphone'] = null
          }
        }

        if (self.useInsertableStreams) {
          var debugData = self.mediaRecorder.tsDebugData
          // if we have debug data, upload it
          if (debugData) {
            self.uploadDebugData(debugData)
          }
        }
      }

      // log when the MR is paused
      // in theory we only care if the MR is paused ourside of the host's action
      this.mediaRecorder.onpause = function () {
        var message = 'CanvasRecorder has been paused'
        if (self.get('paused')) {
          console.log(message)
        } else {
          console.warn(message)
        }
      }

      this.mediaRecorder.onresume = function () {
        var message = 'CanvasRecorder has resumed'
        if (!self.get('paused')) {
          console.log(message)
        } else {
          console.warn(message)
        }
      }
    },

    /**
     * Initialize the web worker that handles the track uploads
     */
    initUploadWorker: async function () {
      var self = this
      if (this.uploadWorker) {
        console.warn('Tried to intantiate an uploadWorker again')
        return
      }

      return new Promise(function (resolve, reject) {
        console.log('Setting up uploadWorker')

        self.uploadWorker = new Worker(servars.uploadWorker)

        self.uploadWorker.addEventListener('message', function (e) {
          var command = e.data.command
          if (command === 'ready') {
            console.log('uploadWorker is ready')
            resolve()
          } else if (command === 'error') {
            console.error(e.data.error)
          }
        })
        self.uploadWorker.addEventListener('error', function (err) {
          var message = err.message ? err.message : err
          console.error('Upload worker error:', message)
          analytics.track('UploadWorkerError', {
            userId: app.user.id,
            recordingId: self.recording.id,
            message: message
          })

          // if the upload worker fails to load this callback will get called and we should reject the main promise
          // the problem is if subsequent errors happen inside the worker, we will also try and reject this promise
          // but nothing will happen because the promise would have been resolved already
          reject(new Error('Could not load worker'))
        })

        // metada object that will be added to logs from inside the service worker
        var metaData = Logger.createMetadataObject({
          context: 'uploadWorker',
          url: document.location.href
        })

        self.uploadWorker.postMessage({
          command: 'initialize',
          config: {
            logging: Object.assign({}, servars.logging, { metaData: metaData })
          }
        })

        const connectionStatusChanged = (isConnected) => self.uploadWorker.postMessage({
          command: 'connectionStatusChanged',
          isConnected
        })

        window.addEventListener('online', () => connectionStatusChanged(true))
        window.addEventListener('offline', () => connectionStatusChanged(false))
        connectionStatusChanged(navigator.onLine)
      })
    },

    /**
     * Used to check if the browser supports WebCodecs API
     * @return {Promise | void}
     */
    checkWebcodecsSupport: function () {
      var self = this
      // if we plan on using web codecs, check for support
      if (this.useInsertableStreams) {
        return WebCodecsVideoRecorder.checkVideoEncoder().catch(function () {
          console.warn('WebCodecs not supported on this platform')
          self.useInsertableStreams = false
        })
      }
    },

    initAudioRecordingPipeline: function () {
      var self = this

      dbg('initAudioRecordingPipeline')

      if (this.initAudioRecordingPipelinePromise) return this.initAudioRecordingPipelinePromise

      this.initAudioRecordingPipelinePromise = new Promise(function (resolve, reject) {
        dbg('Initializing audio recording pipeline')

        // create resampler worker
        self.audioPipeline = new Worker(servars.audioPipelineWorker)

        // Create an input and output channel for the resampler pipeline.
        // The input port will be given to the audioWorkletNode or the
        // scriptProcessorNode depending on browser support.
        var pipelineInputChannel = new MessageChannel()
        self.audioPipeline.inputPort = pipelineInputChannel.port1

        self.audioPipeline.addEventListener('error', function (err) {
          console.error('Audio pipeline error:', err.message ? err.message : err)
        })

        self.audioPipeline.addEventListener('message', function (e) {
          var command = e.data.command
          var track

          switch (command) {
            case 'resamplerProfiles':
              app.user.tracks.forEach(function (track) {
                track.set('resamplerProfile', e.data.profiles[0])
              })
              break
            case 'lastChunkProcessed':
              track = app.user.getTrack(e.data.format, e.data.type)
              if (track) {
                track.set({ lastChunkProcessed: true })
              }
              break
            case 'stats':
              var tag = e.data.tag

              var samplesDecoded = e.data.message.samples_decoded
              var samplesEncoded = e.data.message.samples_encoded
              var framesEncoded = e.data.message.frames_encoded
              var framesDecoded = e.data.message.frames_decoded
              var videoMetadata = e.data.message.video

              var sampleRate = e.data.message.sampleRate

              var stopTimestamp = self.stats[tag].stop
              var expectedTime = (stopTimestamp[1] - self.stats[tag].start) / 1000
              var expectedSamples = expectedTime * sampleRate
              var recordedTime = samplesDecoded / sampleRate

              var args = [
                'Recording end stats:',
                // tag | start time | end 1 | end 2 |
                tag,
                self.stats[tag].start,
                stopTimestamp[0],
                stopTimestamp[1],
                // expectedTime | recordedTime | mic delay added (if present)
                expectedTime,
                recordedTime,
                self.stats[tag].delay,
                // expected samples | samples decoded out of webm | samples encoded to from mp3 |
                expectedSamples,
                samplesDecoded,
                samplesEncoded,
                // frames decoded | frames encoded |
                framesDecoded,
                framesEncoded,
                sampleRate,
                // recordingId | browser | platform
                self.recording.id,
                ua.browser.name + ' ' + ua.browser.version,
                ua.os.name
              ]
              console.log.apply(null, args)

              track = app.user.getTrack('mp3', tag)
              if (track) {
                var data = Object.assign(e.data.message, {
                  startTimestamp: self.stats[tag].start,
                  stopTimestamp: stopTimestamp[1],
                  expectedTime: expectedTime,
                  recordedTime: recordedTime,
                  delay: self.stats[tag].delay,
                  expectedSamples: expectedSamples,
                  recordedSamples: samplesDecoded,
                  timeDifference: (recordedTime / expectedTime - 1) * 100,
                  sampleDifference: (samplesDecoded / expectedSamples - 1) * 100
                })
                track.saveDriftStats(data)
              }

              // if we have video
              if (videoMetadata) {
                track = app.user.getTrack('mov', 'video')
                if (track) {
                  track.saveTrackMetadata(videoMetadata)
                }
              }
              break
            case 'abort':
              // something went wrong with encoding and we need to abort the recording
              console.error(e.data.error)
              self.abortRecordingAndReportError('Error with encoding pipeline.')
              analytics.track('WASMError', {
                userId: app.user.id,
                recordingId: self.recording.id,
                message: e.data.error
              })
              break
            case 'error':
              console.error(e.data.error)
              break
            case 'ready':
              // wait for the pipeline to initialize
              resolve()
              break
          }

          // only log commands that are not logs
          if (command !== 'log' && command !== 'stats') {
            console.log('Audio pipeline message:', e.data)
          }
        })

        var metaData = Logger.createMetadataObject({
          context: 'audioPipelineWorker',
          url: document.location.href
        })

        var debugPipeline = !servars.bundleMedia
        // TODO: we should move this to a method
        var hasSoundboard = app.user.isHost() && app.user.settings.get('soundboard')
        var config = {
          name: 'audioPipeline',
          debug: debugPipeline,
          rawOutput: app.user.settings.get('wavRecording'),
          recordSoundboard: hasSoundboard,
          videoRecording: self.hasVideoRecording && CanvasRecorder.hasH264Support,
          useInsertableStreams: self.useInsertableStreams,
          mp3ChunkSize: 32768, // 32768 saving every 2s, 65536 every 4s
          rawChunkSize: 262144,
          dataPort: pipelineInputChannel.port2,
          commonScripts: servars.pipelineScripts.common,
          logging: Object.assign({}, servars.logging, { metaData: metaData }),
          wasmLocation: servars.wasmLocation,
          cameraOn: app.user.get('cameraOn')
        }

        // init worker
        self.audioPipeline.postMessage({
          command: 'init',
          config: config
        }, [pipelineInputChannel.port2])

        // listen to the users speech and transcribe it if we can (chrome only)
        // @TODO: re-enable when we have the new platform server
        // this.webSpeechService = new WebSpeech()
      })

      return this.initAudioRecordingPipelinePromise
    },

    setOnCloseLogFlushHandler: function () {
      var self = this

      Logger.addOnCloseFlushCallback(() => {
        self.audioPipeline.postMessage({ command: 'flushLogsOnClose' })
        self.uploadWorker.postMessage({ command: 'flushLogsOnClose' })

        // We need this because the workers above might have logs that wasn't flushed yet.
        // This is dirty, but as this is running from the pagehide or unload event, it's the only way I
        // could find that makes these workers above to receive the flush message before being destroyed.
        const time = Date.now()
        while (Date.now() - time < 1000);
      })
    },

    /**
     * Stop recording methods
     */
    stopRecording: function () {
      this.endRecorderStream()

      if (this.project.soundboardSamples) {
        this.project.soundboardSamples.stopAll()
      }

      // stop listening for transcriptions
      // @TODO: re-enable when we have the new platform server
      // this.webSpeechService.stopRecording()

      this.healthCheckManager.stopMidRecordingHealthChecks()
      this.set('recordingStoppedTime', new Date())
    },

    allUploadedChange: function () {
      var recordingStopTime = this.get('recordingStoppedTime')
      var self = this

      // If recordingStopTime is a date
      var duration = recordingStopTime instanceof Date
        // Get diff in ms between now and the recordingStopTime
        ? new Date().getTime() - this.get('recordingStoppedTime').getTime()
        // recordingStopTime is unavailable, default to -1
        : -1

      analytics.track('UploadDuration', {
        userId: app.user.id,
        recordingId: self.recording.id,
        duration: duration
      })
    },

    endRecorderStream: function () {
      var self = this

      if (this.mediaRecorder) {
        this.stopMediaRecorder(this.mediaRecorder)

        if (this.soundboardMediaRecorder) {
          this.stopMediaRecorder(this.soundboardMediaRecorder)
        }

        this.stats.microphone.stop[0] = this.stats.soundboard.stop[0] = performance.now()

        dbg('MediaRecorder stopped and in state: ' + this.mediaRecorder.state)
      }

      // if we're using a ScriptProcessorNode
      if (this.processor) {
        if (this.merger) {
          this.merger.disconnect(this.processor)
        } else {
          this.input.disconnect(this.processor)
        }

        this.processor.disconnect(this.outputNode)
        this.audioPipeline.inputPort.postMessage({ isLast: true })
        this.stats.microphone.stop[1] = this.stats.soundboard.stop[1] = performance.now()
      }

      console.log('Stop recording. Timer:', this.timer.formattedDuration())
      this.timer.stop()

      /**
       *
       * @param track Track to remove
       * @param collection Collection to remove track from
       * @returns {Promise<boolean>} Resolves when track is finished
       */
      var queueTrackRemoval = async function (track, collection) {
        return new Promise(function (resolve) {
          // Remove the soundboard tracks
          var removeTrack = function () {
            // and remove it from the collection
            collection.remove(track)
            // Make sure the track is also removed from recording tracks
            if (self.recording.tracks.contains(track)) {
              self.recording.tracks.remove(track)
            }
            // else, delete the track in the db
            track.deleteUpload()
            // Resolve with track to populate a list of removed tracks
            resolve(track)
          }

          // if track is finalized, just remove it
          if (track.get('finalized')) {
            removeTrack()
          } else {
            // if we are uploading, wait for it to finish and then remove it
            // if we remove while it's uploading, the chunk upload will fail
            // and it will enter in exponential backoff retry
            track.on('uploadCompleted', function () {
              removeTrack()
            })
            if (track.get('error') === 'Unfinalized Track') {
              track.forceFinalizeUpload()
            }
          }
        })
      }

      var soundboardUsed = this.isSoundboardUsed()

      // This audio context is used for decoding soundboard track data
      // To identify if there is content
      var soundboardAudioContext = new AudioContext()

      /**
       *
       * @param track {zc.models.Track}
       * @returns {Promise<boolean>}
       */
      var trackHasSound = async function (track) {
        var blob = await track.exportDataAsBlob()
        var arrayBuffer = await blob.arrayBuffer()
        var audioBuffer = await soundboardAudioContext.decodeAudioData(arrayBuffer)
        var channelData = audioBuffer.getChannelData(0)
        var sampleRate = audioBuffer.sampleRate
        for (let i = 0; i < channelData.length; i += Math.floor(sampleRate * 0.05)) {
          if (channelData[i] > 0) {
            return true
          }
        }
        return false
      }

      /**
       *
       * @param track
       * @returns {boolean} True if the track should be removed, false if it should not be
       */
      var shouldRemoveTrack = async function (track) {
        if (!track) {
          return true
        }
        var userIsOwner = track.get('userId') === app.user.id

        // Handle soundboard tracks
        if (track.get('type') === 'soundboard' && userIsOwner) {
          // Remove if soundboard wasn't used at all or this track is empty
          if (!soundboardUsed) return true
          else {
            return !(await trackHasSound(track))
          }
        }

        // Handle all other tracks

        // TODO: return to this and find a better way to track if track has content
        return false
      }

      /**
       * Promises that resolve when it has been determined if a track should be removed or not
       * Sometimes we have to decode and inspect the contents of a track because soundboards are weird
       * @type {Promise<void>[]}
       */
      var tracksToRemovePromises = []

      // Iterate through all previous tracks
      tracksToRemovePromises.concat(this.recording.tracks.map(async function (track) {
        if (await shouldRemoveTrack(track)) {
          await queueTrackRemoval(track, self.recording.tracks)
        }
      }))
      // Iterate through all current tracks
      tracksToRemovePromises.concat(app.user.tracks.map(async function (track) {
        if (!track) return
        // set track durations
        track.set({ duration: self.timer.duration(), recordingSessionEnded: true })

        if (await shouldRemoveTrack(track)) {
          await queueTrackRemoval(track, app.user.tracks)
        } else {
          self.showUserTrack(track)
        }
      }))

      this.recordingFinishedPromise = Promise.all(tracksToRemovePromises).then(async function () {
        // Close the audio context, it is no longer needed
        await soundboardAudioContext.close()

        if (soundboardUsed) {
          // Clean up local storage, after all tracks have been confirmed to be dealt with
          try {
            localStorage.removeItem(app.project.recorder.recording.id + '-hasSoundboard')
            console.log('Removing soundboard state for recording ' + app.project.recorder.recording.id)
          } catch (e) {
            console.warn('Error removing soundboard state for recording ' + app.project.recorder.recording.id, e)
          }
        }
      }).catch(function (error) {
        console.error(error)
        soundboardAudioContext.close()
      })
    },

    stopMediaRecorder: function (mediaRecorder) {
      // if the mediaRecorder is paused, stop it first
      if (mediaRecorder.state === 'paused') {
        mediaRecorder.resume()
      }

      if (mediaRecorder.state === 'recording') {
        mediaRecorder.stop()
      } else {
        console.error('MediaRecorder in unexpected state when stopping: ', this.mediaRecorder)
      }
    },

    checkAllUsersHealthCheckStatus: function () {
      return this.healthCheckManager.checkAllUsersStatus()
    },

    checkAllUsersHealthCheckPassed: function () {
      return this.healthCheckManager.checkAllPassed()
    },

    abortRecordingAndReportError: function (err) {
      var self = this
      // if we get this outside of a recording, ignore it
      // it might happen if after the recording has finished we retry a chunk and it fails
      // we should not abort the recording because it's already done
      if (!this.get('isRecording')) return

      app.user.trigger('initializationFailure', app.user, err.toString())
      utils.notify('error', 'Critical error occurred. Aborting now. Error Details: ' + err)
      dbg('Aborting recording')

      var analyticsErrorMessage = 'Unknown Critical Error.'
      if (err.toString() && err.toString() !== '{}') {
        analyticsErrorMessage = err.toString()
      }

      analytics.track('AbortedRecording', {
        userId: app.user.id,
        reason: analyticsErrorMessage,
        recordingId: this.recording.id,
        duration: this.get('duration') || this.recording.get('duration')
      })

      app.user.tracks.forEach(function (track) {
        self.set({ initializedForRecording: false })
      })
    },

    /**
     * Change events
     */
    isRecordingChange: function (model, isRecording) {
      console.log('isRecordingChange: ', isRecording)
      if (app.user.isHost()) {
        if (isRecording) {
          this.project.call.startSfuBackup()
        } else {
          this.project.call.stopSfuBackup()
        }
      }
    },

    recordingChange: function (model, recording) {
      this.timer.reset()
      this.timer.set({ duration: recording.get('duration') })
      this.recording = recording
    },

    trackProcessingChange: function (track, processing) {
      // when the file has started processing then the upload has finished
      if (processing) {
        dbg(track.get('format') + ' has started processing')
        this.finishOldTracks(track.get('type'))
      }
    },

    audioInputChange: function (audioInput, previous) {
      localStorage.setItem('audioInputId', audioInput)
      console.log('Audio input changed to: ', audioInput)

      // on first visit, the user/guest will get the mic access permission modal
      // after he accepts this will change from empty string to an actual deviceId
      // in that case we don't want to stop the microphone
      if (!previous) {
        return
      }

      // if we are recording, abort
      if (this.get('isRecording')) {
        this.abortRecordingAndReportError(new Error('Main audio input unplugged. Aborting recording.'))
        return
      }

      if (this.get('micArmed')) {
        this.stopMicrophone()
      }
    },

    audioOutputChange: function (audioOutput) {
      if (!audioOutput) return console.error('No Audio Output Id')
      this.setLocalStreamSink(audioOutput)
    },

    videoInputChange: function (videoInput) {
      localStorage.setItem('videoInputId', videoInput)
      dbg('video input changed to: ' + videoInput)

      // if we are recording, abort
      if (this.get('isRecording')) {
        this.abortRecordingAndReportError(new Error('Main video input changed. Aborting recording.'))
      }

      if (this.get('micArmed')) {
        this.stopMicrophone()
      }
    },

    mutedChange: function (user, muted) {
      if (muted) {
        console.log('User muted')
        this.muteMicrophone()
      } else {
        console.log('User unmuted')
        this.unmuteMicrophone()
      }
    },

    greenroomChanged: function (model, value) {
      // if it changed to false, then the user left the green room
      if (!value) {
        // if the current recording isRecording, then start
        if (this.recording.get('isRecording')) {
          dbg('Starting to record automatically')
          this.handleStartRecording()

          this.checkRecordingPausedState()
        }
      }
    },

    /**
     * Called when the wavRecording setting has changed on the user settings model
     * We need this hack because before starting to record, only the host can tell the guests if wav recordings are on
     * After starting to record, we set the wavRecording flag on the recording model, so we can read it from there. See initialize function
     * We might not want to set that flag on recording creation because the user might change the setting after creating a recording
     */
    wavRecordingSettingChange: function () {
      // this should never happen becaue during recording we are reading the wavRecording setting
      // from the recording model
      if (this.get('isRecording')) {
        console.error('wavRecording setting change during recording')
        return
      }

      // if the audio pipeline worker is initialized,
      // terminate it and recreate it with the new settings
      if (this.audioPipeline) {
        console.log('Recreating audioPipeline worker.')
        this.initAudioRecordingPipelinePromise = null
        this.audioPipeline.terminate()
        // this returns a promise but should be ok
        this.initAudioRecordingPipeline()
      }
    },

    /**
     * Called when the setting for the video res has changed
     * Right now, we just need to disconnect the mic
     */
    videoRecordingResolutionChanged: function () {
      this.stopMicrophone()
    },

    /**
     * Checks the recording model paused attribute and if true, pause the recording
     */
    checkRecordingPausedState: function () {
      var self = this

      // if the recording is already paused on join, paused immediately after starting to record
      if (this.recording.get('paused') && this.isRecordingPromise) {
        this.isRecordingPromise.then(function () {
          self.handlePause()
        })
      }
    },

    pausedChange: function (model, paused) {
      console.log('Recorder pause property changed to: ' + paused)
      if (app.user.isHost()) {
        if (paused) {
          this.project.call.pauseSfuBackup()
        } else {
          this.project.call.resumeSfuBackup()
        }
      }
    },

    /**
     * Microphone related methods
     */
    startMicrophone: function () {
      dbg('Starting microphone')
      this.set({ 'micArmed': true })

      if (this.get('isRecording')) {
        console.error('The user\'s microphone became disconnected while recording. Falling back to next available mic.')
      }

      // if the mic should be muted when we get the stream
      if (app.user.get('muted')) {
        this.muteMicrophone()
      }
    },

    stopMicrophone: function () {
      if (this.microphone) {
        this.microphone.disconnect(this.compressor)
      }

      this.compressor.disconnect()
      this.input.disconnect()
      this.set({ 'micArmed': false })
      dbg('Stopped microphone')
    },

    muteMicrophone: function () {
      // TODO: move this to redux
      // always get the latest audio track
      var audioTrack = this.project.userMedia.stream.getAudioTracks()[0]
      if (audioTrack) {
        audioTrack.enabled = false
      }
      dbg('Muted microphone')
    },

    unmuteMicrophone: function () {
      // TODO: move this to redux
      // always get the latest audio track
      var audioTrack = this.project.userMedia.stream.getAudioTracks()[0]
      if (audioTrack) {
        audioTrack.enabled = true
      }
      dbg('Unmuted microphone')
    },

    /**
     * Audio stream related methods
     */
    gotLocalStream: function (stream) {
      var self = this

      if (!this.get('micArmed')) this.startMicrophone()

      // ideally we would initialize this inside initialize() but the app is not fully setup then so we do it here
      this.hasSoundboard = app.user.isHost() && app.user.settings.get('soundboard')

      if (!this.preparingPromise) {
        return this.prepareToRecord()
          .then(function () {
            if (self.recording.get('isRecording') && !app.user.get('greenroom')) {
              dbg('Starting to record automatically')
              self.handleStartRecording()

              self.checkRecordingPausedState()
            }
          })
      }
    },

    addStreamTrackEventListeners: function () {
      var self = this
      var stream = this.project.userMedia.stream

      // loop through all the tracks
      stream.getTracks().forEach(function (track) {
        // add event listeners for when the tracks end and abort the recording if so
        track.addEventListener('ended', function () {
          console.warn('Track ended', track.kind, track.label)
          setTimeout(() => {
            // abort the recording only in the next event loop cycle
            // this is to prevent closing the tab aborting the recording
            // since the track ended event is sometimes raised in these
            // situations
            // (the setTimeout won't run after the tab closes)
            self.abortRecordingAndReportError(new Error('Input track ended'))
          })
        })
        track.addEventListener('mute', function (event) {
          console.warn('Possible media stream recorder problem: track muted', event)
        })
        track.addEventListener('unmute', function (event) {
          console.warn('Possible media stream recorder problem: track unmuted', event)
        })
      })
    },

    setLocalStreamSink: function (audioOutput) {
      app.localAudioOutEl.setSinkId(audioOutput.deviceId)
    },

    /**
     * Track related methods
     */
    prepareTracks: function () {
      var self = this

      return utils.promiseSerial([
        self.createUserTracks.bind(self),
        self.initializeTracksForRecording.bind(self)
      ]).catch(utils.logRethrow)
    },

    createUserTracks: function () {
      dbg('createUserTracks')

      // User must have opted in to wav recording; but must also have access to the wav recording feature.
      var wavRecording = app.user.settings.get('wavRecording')
      // User must have opted in to soundboard; but must also have access to the soundboard feature.
      var hasSoundboard = app.user.isHost() && app.user.settings.get('soundboard') && app.user.getFeature('soundboard')
      var promises = []

      // if the user has soundboard on
      // make the soundboard the fist track so it's more proeminent
      if (hasSoundboard) {
        promises.push(this.createSoundboardTrack())
      }

      // if enabled the wav goes first in the pipeline since it doesn't do any encoding
      if (wavRecording) {
        promises.push(this.createWavTrack())
      }

      // always create an mp3 track
      promises.push(this.createMp3Track())

      if (this.hasVideoRecording) {
        promises.push(this.createVideoTrack())
      }

      return Promise.all(promises).then(function () {
        return app.user.tracks
      }).catch(utils.logRethrow)
    },

    initializeTracksForRecording: function (tracks) {
      var self = this
      dbg('initializeTracksForRecording')

      // var usePacing = this.shouldUsePacing()
      var usePacing = false

      // does this need to be run with promiseSerial?
      return Promise.all(tracks.map(function (track) {
        return track.initForRecording({
          audioPipeline: self.audioPipeline,
          uploadWorker: self.uploadWorker,
          usePacing: usePacing
        })
      })).then(function (tracks) {
        dbg('Initialized tracks: ', tracks.map(function (track) { return track.get('format') + ':' + track.get('type') + ':' + track.id }).join(' '))
        return tracks
      }).catch(utils.logRethrow)
    },

    createMp3Track: function () {
      return app.user.getTrack('mp3') || app.user.createTrack({ format: 'mp3' })
    },

    createWavTrack: function () {
      return app.user.getTrack('wav') || app.user.createTrack({ format: 'wav' })
    },

    createSoundboardTrack: function () {
      return app.user.getTrack('mp3', 'soundboard') || app.user.createTrack({ format: 'mp3', type: 'soundboard' })
    },

    createVideoTrack: function () {
      var format = CanvasRecorder.hasH264Support ? 'mov' : 'webm'
      return app.user.getTrack(format, 'video') || app.user.createTrack({ format: format, type: 'video' })
    },

    addTrackEventListeners: function (tracks) {
      var self = this
      tracks.forEach(function (track) {
        // if any of the tracks fires the abort event, stop the recording
        track.on('abort', function () {
          // for now, only the upload fires this event
          self.abortRecordingAndReportError('Problem with uploading.')
        })
      })
      return tracks
    },

    /**
     * used to search for and upload old tracks
     * they might appear if the user refreshes the page during a recording
     * each track should care about it's own type
     */
    finishOldTracks: function (type) {
      var self = this
      type = type || 'microphone'

      console.log('Started finalizing old tracks for type:', type)
      // get all the non video tracks from this recording,
      // for this user, that are not finalized and are not uploading
      var userTracks = this.recording.tracks.filter(function (track) {
        return track.get('userId') === app.user.get('_id') &&
          !track.get('finalized') &&
          !track.get('uploading') &&
          !track.get('forced') &&
          track.get('type') === type
      })
      console.log('Found', userTracks.length, 'tracks')

      var nextTrack = userTracks[0]

      if (nextTrack) {
        nextTrack.forceFinalizeUpload().then(function () {
          console.log('Successfully finalized track', type)
          self.finishOldTracks(type)
        })
          .catch(function (err) {
            console.error('Error uploading ', nextTrack.get('format') + ':' + nextTrack.id, ' skipping for now: ', err.toString())

            self.finishOldTracks(type)
          })
      } else {
        console.log('Finished uploading all the old tracks for this user.')
      }
    },

    /**
     * Recording model methods
     */
    createRecording: function (cb) {
      var model = this
      var recording = this.recording.toJSON()
      recording.cloudDrive = this.project.get('cloudDrive')
      recording.name = recording.name || 'Recording ' + model.project.recordings.length
      this.socket.emit('recordings:create', recording, function (err, recording) {
        model.recording.set(recording)
        cb(err, recording)
      })
    },

    updateRecording: function () {
      var duration = this.timer.duration()
      this.recording.set({ duration: duration })
      if (app.user.isHost()) {
        var data = {
          _id: this.recording.id,
          duration: duration
        }
        this.socket.emit('recordings:update', data, function (err, data) {
          if (err) console.error(err)
        })
      }
    },

    /**
     * adds the track to be shown in the ui as it uploads
     * @param track
     */
    showUserTrack: function (track) {
      this.recording.tracks.add(track)
    },

    /**
     * Other methods
     */
    /**
     * called when the user wants to start a new recording the close tab protection is active
     * if the user confirms the popup, remove the protection and call the mode's startOver again
     */
    showStartOverModal: function () {
      var self = this
      var startOverModal = new zc.views.ModalView({
        addClass: 'start-over-modal',
        model: new Backbone.Model({
          title: 'Refresh page?',
          text: 'If you start a new recording you might lose data that is currently being processed. Are you sure?'
        }),
        ChildView: zc.views.ConfirmView,
        force: true,
        callback: function (confirmed) {
          if (confirmed) {
            self.project.trigger('stopCloseTabProtection')
            self.startOver(true)
          }
          startOverModal.exit()
        }
      })
      startOverModal.render()
    },

    startOver: function (newRecording) {
      var model = this
      // if the close tab protection is on then the page is doing something
      if (this.project.get('closeTabProtection')) {
        dbg('Tried to start over but close tab protection was on')
        this.showStartOverModal()
        return
      }

      this.socket.emit('startOver', { newRecording: newRecording }, function (err) {
        // the error might appear if the host is on the page and the project is archived
        if (err) {
          utils.notify('error', 'There was an error trying to perform this action, please refresh the page and try again.')
          return
        }
        model.trigger('startOver')
      })
    },

    hasStartedRecording: function () {
      return this.get('isRecording') || this.recording.get('duration') || this.hasFinishedRecording()
    },

    hasFinishedRecording: function () {
      return (this.recording.get('duration') || this.recording.tracks.length) && !this.recording.get('isRecording')
    },

    addWakeLock: async function () {
      try {
        this.wakeLock = await navigator.wakeLock.request('screen')
        this.wakeLock.addEventListener('release', () => {
          console.log('Wake Lock was released')
        })
        console.log('Wake Lock is active')
      } catch (err) {
        console.warn('Could not set lock screen guard', err.name, err.message)
      }
    },

    removeWakeLock: async function () {
      if (!this.wakeLock) {
        return
      }
      try {
        await this.wakeLock.release()
        this.wakeLock = null
      } catch (err) {
        console.warn('Could not release wake lock', err.name, err.message)
      }
    },

    /**
     * Used to get the sample rate of the current stream
     * @return {Promise}
     */
    getSampleRate: async function () {
      var self = this
      var mediaStream = this.project.userMedia.stream

      if (this._sampleRatePromise) return this._sampleRatePromise

      if (!mediaStream) {
        console.warn('Tried to compute sample rate without a local stream')
        return 0
      }

      this._sampleRatePromise = new Promise(function (resolve, reject) {
        // if we have webm pcm support, use media recorder
        if (AudioRecorder.mimeTypeSupport) {
          if (!self.audioPipeline) return Promise.reject(new Error('Audio pipeline not initialized'))

          var callback = function (e) {
            if (e.data.command === 'webmInfo') {
              var samplerate = e.data.data.sample_rate

              app.user.attributes.platform.sampleRate = samplerate || app.actx.sampleRate

              self.audioPipeline.removeEventListener('message', callback)

              resolve(samplerate)
            }
          }
          self.audioPipeline.addEventListener('message', callback)

          AudioRecorder.getSingleChunk(mediaStream).then(function (data) {
            self.audioPipeline.postMessage({
              command: 'getWebmInfo',
              data: data
            })
          }).catch(reject)
        } else {
          // if no pcm support
          var destination = self.actx.createMediaStreamDestination()
          var processor = self.actx.createScriptProcessor(512, 1, 1)
          processor.onaudioprocess = function (e) {
            try {
              self.input.disconnect(processor)
              processor.disconnect(destination)
            } catch (e) { }

            app.user.attributes.platform.sampleRate = e.inputBuffer.sampleRate

            console.log('Microphone sample rate (ff):', e.inputBuffer.sampleRate)
            resolve(e.inputBuffer.sampleRate)
          }
          self.input.connect(processor)
          processor.connect(destination)
        }
      })

      return this._sampleRatePromise
    },

    pause: function () {
      if (this.mediaRecorder) {
        this.mediaRecorder.pause()
      }
      if (this.timer) {
        this.timer.pause()
      }

      if (this.soundboardMediaRecorder) {
        this.soundboardMediaRecorder.pause()
      }

      console.log('Recording paused')
    },

    resume: function () {
      if (this.mediaRecorder) {
        this.mediaRecorder.resume()
      }
      if (this.timer) {
        this.timer.resume()
      }

      if (this.soundboardMediaRecorder) {
        this.soundboardMediaRecorder.resume()
      }

      console.log('Recording resumed')
    },

    getCurrentRecordingDetails: function () {
      var self = this
      var projectId = self.project.id
      var recordingId = self.recording.id
      var memberIds = app.project.lobby.users.map(m => m.toJSON()._id)
      return {
        projectId,
        recordingId,
        memberIds
      }
    },
    initiateStartRecordingTransaction: function () {
      var self = this
      var currentRecordingDetails = self.getCurrentRecordingDetails();
      /** @type {typeof import('@zencastr/socket-transactions').recordingTransactionsClient}  */
      (recordingTransactionsClient).startTransaction({
        actionType: 'start',
        recordingId: currentRecordingDetails.recordingId,
        roomId: currentRecordingDetails.projectId,
        members: currentRecordingDetails.memberIds
      }).then(function () {
        console.log('Everyone started recording')
      }, function (error) {
        var errorMessage = ''
        if (error.isTimeout) {
          console.warn('STRNC: Transaction timed out waiting for some of the participants to report they actually started recording (' + getDisplayNamesByIds(error.membersWhoDidntComplete || [], app.project.lobby) + ').')
          return
        } else if (error.isInitiatorTimeout) {
          console.warn('STRNC: Transaction for start recording timed out waiting for socket server to reply')
          return
        } else {
          errorMessage = 'Participant ' + getDisplayNamesByIds([error.failedMemberId], app.project.lobby) + ' is having a problem: ' + error.description + '. We are stopping the recording to prevent content loss.'
        }
        utils.notify('error', errorMessage)
        console.warn('Start recording transaction failed: ', error)
      })
    },
    initiateStopRecordingTransaction: function () {
      var self = this
      var currentRecordingDetails = self.getCurrentRecordingDetails();
      /** @type {typeof import('@zencastr/socket-transactions').recordingTransactionsClient}  */
      (recordingTransactionsClient).startTransaction({
        actionType: 'stop',
        members: currentRecordingDetails.memberIds,
        recordingId: currentRecordingDetails.recordingId,
        roomId: currentRecordingDetails.projectId
      }).then(function () {
        console.log('Everyone confirmed they stopped recording')
      }).catch(function (error) {
        var errorMessage = ''
        if (error.isTimeout) {
          console.warn('STRNC: Transaction timed out waiting for some of the participants to report they actually stopped recording (' + getDisplayNamesByIds(error.membersWhoDidntComplete || [], app.project.lobby) + ').')
          return
        } else if (error.isInitiatorTimeout) {
          console.warn('STRNC: Transaction for stop recording timed out waiting for socket server to reply')
          return
        } else {
          errorMessage = 'Participant ' + getDisplayNamesByIds([error.failedMemberId], app.project.lobby) + ' is having a problem: ' + error.description + ". We couldn't validate that the recording stopped for them."
        }
        utils.notify('error', errorMessage)
        console.warn('Stop recording transaction failed:', error)
      })
    },
    initiatePauseTransaction: async function () {
      var self = this
      var currentRecordingDetails = self.getCurrentRecordingDetails();
      /** @type {typeof import('@zencastr/socket-transactions').recordingTransactionsClient}  */
      (recordingTransactionsClient).startTransaction({
        actionType: 'pause',
        recordingId: currentRecordingDetails.recordingId,
        roomId: currentRecordingDetails.projectId,
        members: currentRecordingDetails.memberIds
      }).then(function () {
        console.log('Everyone paused the recording')
      }, function (error) {
        var errorMessage = ''
        if (error.isTimeout) {
          console.warn('STRNC: Transaction timed out waiting for some of the participants to report they actually paused recording (' + getDisplayNamesByIds(error.membersWhoDidntComplete || [], app.project.lobby) + ').')
          return
        } else if (error.isInitiatorTimeout) {
          console.warn('STRNC: Transaction for pause recording timed out waiting for socket server to reply')
          return
        } else {
          errorMessage = 'Participant ' + getDisplayNamesByIds([error.failedMemberId], app.project.lobby) + ' is having a problem: ' + error.description + '. We are stopping the recording to prevent content loss.'
        }
        utils.notify('error', errorMessage)
        console.warn('Pause recording transaction failed', error)
      })
    },
    initiateResumeTransaction: async function () {
      var self = this
      var currentRecordingDetails = self.getCurrentRecordingDetails();
      /** @type {typeof import('@zencastr/socket-transactions').recordingTransactionsClient}  */
      (recordingTransactionsClient).startTransaction({
        actionType: 'resume',
        recordingId: currentRecordingDetails.recordingId,
        roomId: currentRecordingDetails.projectId,
        members: currentRecordingDetails.memberIds
      }).then(function () {
        console.log('Everyone resumed the recording')
      }, function (error) {
        var errorMessage = ''
        if (error.isTimeout) {
          console.warn('STRNC: Transaction timed out waiting for some of the participants to report they actually resumed recording (' + getDisplayNamesByIds(error.membersWhoDidntComplete || [], app.project.lobby) + ').')
          return
        } else if (error.isInitiatorTimeout) {
          console.warn('STRNC: Transaction for resume recording timed out waiting for socket server to reply')
          return
        } else {
          errorMessage = 'Participant ' + getDisplayNamesByIds([error.failedMemberId], app.project.lobby) + ' is having a problem: ' + error.description + '. We are stopping the recording to prevent content loss.'
        }
        utils.notify('error', errorMessage)
        console.warn('Resume recording transaction failed', error)
      })
    },

    startCountdown: function () {
      var self = this
      // if we are already displaying the popup, ignore subsequent calls
      if (this.get('currentCountdown')) return

      var allHealthChecksStatus = this.checkAllUsersHealthCheckStatus()
      var passable = (allHealthChecksStatus === 'passed' || allHealthChecksStatus === 'warning')
      if (!passable) {
        if (allHealthChecksStatus === 'pending') {
          return utils.notify('alert', 'Please wait until all health checks have completed before starting a recording', { ttl: 3000 })
        } else {
          return utils.notify('error', 'Unable to start a recording with failed health checks')
        }
      }

      if (!app.socket.connected) {
        utils.notify('error', 'Error: You cannot begin a recording if you are disconnected from the server.')
        return
      }

      // let the others in the room know we are counting down
      if (app.user.isHost()) {
        // let others update their countdown ui
        this.socket.emit('user:countdownStarted', { userId: app.user.get('_id') })
        this.project.call.prepareSfuBackup(this.recording.id, app.project.recordings.length)
      }

      // start creating the tracks
      var trackPromise = this.prepareTracks()

      // update the ui with the timer
      this.set({ currentCountdown: 3 })

      this._countdownInterval = setInterval(function () {
        var currentCount = self.get('currentCountdown') - 1
        self.set({ currentCountdown: currentCount })
        if (currentCount === 0) {
          clearInterval(self._countdownInterval)

          // make sure that all tracks are done being created
          trackPromise.then(function startRecording () {
            if (self.recording.id) {
              try {
                localStorage.setItem(app.project.recorder.recording.id + '-hasSoundboard', false)
              } catch (e) {
                console.warn(e)
              }
              // if I'm host start a transaction to start recording -> a message will arrive to all participants in the recording
              // if not host, then end
              if (app.user.isHost()) {
                self.initiateStartRecordingTransaction()
              }
            } else {
              self.createRecording(function (err, recordingId) {
                if (err) {
                  utils.notify('error', 'Error creating recording: ' + err)
                } else {
                  try {
                    localStorage.setItem(app.project.recorder.recording.id + '-hasSoundboard', false)
                  } catch (e) {
                    console.warn(e)
                  }

                  self.handleStartRecording()
                }
              })
            }
          }).catch(function (e) {
            console.error(e)
            self.abortRecordingAndReportError(new Error('Could not start recording.'))
          }).finally(function stopAndClearCountdown () {
            self.set({ currentCountdown: null })
            clearInterval(self._countdownInterval)
          })
        }
      }, 1000)
    },

    /**
     * Used to get different different details about the user's device to help with metrics
     */
    saveHardwareMetrics: async function () {
      var userDetails = Object.assign({}, app.user.get('platform'))
      var devices = this.userMedia.devices.deviceList
      devices = devices.map(function (device) {
        return Object.assign({}, device, { label: device.label.toLowerCase() })
      })
      userDetails.audioinput = devices.filter(function (device) { return device.kind === 'audioinput' })
      userDetails.videoinput = devices.filter(function (device) { return device.kind === 'videoinput' })
      userDetails.audiooutput = devices.filter(function (device) { return device.kind === 'audiooutput' })

      var preferredDevices = await this.userMedia.devices.preferredDevices
      userDetails.preferredDevices = JSON.parse(JSON.stringify(preferredDevices))

      var battery = await navigator.getBattery()

      userDetails.battery = {
        charging: battery.charging,
        chargingTime: battery.chargingTime,
        dischargingTime: battery.dischargingTime,
        level: battery.level
      }

      userDetails.videoCard = utils.getVideoCardInfo()

      if (performance.getEntries()[0]) {
        userDetails.http = performance.getEntries()[0].nextHopProtocol
      }

      userDetails.userId = app.user.id
      userDetails.recordingId = this.recording.id
      userDetails.projectId = this.project.id

      analytics.track('HardwareMetrics', userDetails)
    },

    uploadDebugData: function (debugData) {
      // get the mp3 track (everyone has one)
      var track = app.user.getTrack('mov', 'video') || app.user.getTrack('mp3')
      if (!track) {
        console.error('Could not find any mov/mp3 files for this user')
        return
      }

      // upload in the same folder as the rest of the recording
      var path = this.project.get('ownerId') + '/' + track.getUploadFolder() + '/'
      // create one file per participant
      path += track.get('filename') + '.bin'
      var mimeType = 'application/octet-stream'

      fetch('/api/cloud-storage/upload-url', { // TODO: is this still called or is it dead code?
        method: 'post',
        referrerPolicy: 'origin',
        credentials: 'same-origin',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          mimeType: mimeType,
          projectId: app.project.id,
          path: path,
          action: 'write'
        })
      }).then(function (res) {
        if (res.ok) return res.text()
        console.error(new Error('Create upload url request failed: ' + res.status))
      }).then(function (url) {
        // upload
        var uploader = new ResumableUploadGcs({
          url: url,
          contentType: mimeType
        })

        // the only chunk, so mark it as last
        return uploader.uploadChunk(debugData, true).then(() => {
          console.log('Debug data uploaded')
        })
      }).catch(utils.logRethrow)
    },

    /**
     * Returns if a soundboard track was used
     * TODO: Ensure this state persists after the initial recording
     * @returns {boolean}
     */
    isSoundboardUsed: function () {
      try {
        var storageValue = localStorage.getItem(this.recording.id + '-hasSoundboard')
        // LocalStorage has a value that is not false, or does not exist
        return (Boolean(storageValue) && storageValue !== 'false') || !storageValue
      } catch (e) {
        console.warn(e)
        return false
      }
    },

    shouldUsePacing: function () {
      var usePacing = false
      var variation

      try {
        // if query param is present
        var queryParams = utils.getQueryStringParams()
        if ('pacing' in queryParams) {
          variation = queryParams['pacing'] === 'true' ? 'pacing' : 'no_pacing'
          optimizelyClientInstance.setForcedVariation('pacing_test', app.user.id, variation)
        }

        if (optimizelyClientInstance) {
          // if we already have it set using the query param
          variation = variation || optimizelyClientInstance.activate('pacing_test', app.user.id)
          // if he is on the pacing variation of this test
          if (variation === 'pacing') {
            // if the user should use pacing, but the browser does not support pacing
            if (ResumableUploadGcs && !ResumableUploadGcs.doesSupportStreamBody()) {
              console.warn('Pacing is not supported in this browser')
            } else {
              usePacing = true
              console.log('User will use pacing')
            }
          }
        }
      } catch (e) {
        console.error('Could not instatiate optimizely', e)
      }

      analytics.track('UploadPacing', {
        userId: app.user.id,
        recordingId: this.recording.id,
        projectId: this.project.id,
        usesPacing: usePacing
      })

      return usePacing
    }
  })
})()
