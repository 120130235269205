/* globals zc app */

/** @typedef { import('../../services/user_media.service').SupportedResolution } SupportedResolution */
/** @typedef { { value: SupportedResolution; forced: boolean } } VideoRecordingResolution */

(function () {
  'use strict'

  zc.views.VideoRecordingResolutionSettingView = zc.views.DropdownSelectView.extend({
    initialize: function (options) {
      this.settings = options.settings

      this.listenTo(this.settings, 'change:videoRecordingResolution', this.updateSelection)

      this._initialize(options)
      this.createModel({
        iconClass: 'ic-resolution',
        selectState: 'success',
        defaultErrorTitle: ''
      })

      const { value } = this.getVideoRecordingResolutionSetting()

      const items = [
        {
          id: '720',
          title: 'HD',
          subTitle: '(1280x720)',
          selected: value === '720'
        }
      ]

      const allowedResolutions = app.user.getAllowedResolutions()
      if (allowedResolutions['1080']) items.push({
        id: '1080',
        title: 'Full HD',
        subTitle: '(1920x1080)',
        selected: value === '1080'
      })
      if (allowedResolutions['4k']) items.push({
        id: '2160',
        title: '4k',
        subTitle: '(3840x2160)',
        selected: value === '2160'
      })

      this.model.set('items', items)
    },

    /** @type { () => VideoRecordingResolution } */
    getVideoRecordingResolutionSetting: function () {
      return this.settings.get('videoRecordingResolution')
    },

    /** @type { () => void } */
    updateSelection: function () {
      const { value } = this.getVideoRecordingResolutionSetting()
      this.setSelected(value)
    },

    /** @type { (item: DropdownItem) => void } */
    onItemSelect: function (item) {
      /** @type { VideoRecordingResolution } */
      const videoRecordingResolution = {
        value: item.id,
        forced: true
      }

      this.settings.set({ videoRecordingResolution })

      console.log('Changed resolution setting: ', videoRecordingResolution)

      utils.notify('alert', 'Please reload the page or restart your browser to ensure the update takes effect.')
    }
  })
})()
