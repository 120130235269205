/* globals zc _ Backbone */

(function () {
  'use strict'

  zc.views.FeedbackSubscriptionView = Backbone.View.extend({
    initialize: function (options) {
      this.callback = options.callback || function () {}
    },

    template: _.template($('.feedback-subscription-template').html()),

    className: 'confirm-prompt feedback-subscription',

    defaults: {
      title: 'Title',
      text: 'Text',
      confirmText: 'Confirm',
      cancelText: 'Cancel'
    },

    events: {
      'click .confirm': 'confirm',
      'click .cancel': 'cancel'
    },

    confirm: function () {
      var reason = this.$('textarea').val()

      // if we don't have a reason don't allow to continu
      if (reason) {
        this.callback(true, reason)
      } else {
        this.$('textarea').addClass('required')
      }
    },

    cancel: function () {
      this.callback(false)
    },

    render: function () {
      var attrs = _.extend({}, this.defaults, this.model.toJSON())
      this.$el.html(this.template(attrs))
      return this
    }
  })
})()
