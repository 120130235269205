/* globals zc Backbone app */

(function () {
  'use strict'

  zc.models.ProjectGroup = Backbone.Model.extend({
    initialize: function (attrs, options) {
      options = options || {}
      this.projects = options.projects || new zc.collections.ProjectLinks()
      this.pagination = options.pagination || new zc.models.Pagination()

      this.listenTo(this.pagination, 'change:page', this.fetchProjects)
      this.listenTo(this.pagination, 'change:perPage', this.fetchProjects)
      this.on('change:archived', this.fetchProjects)
    },

    defaults: {
      archived: false
    },

    attrs: function () {
      var attrs = this.toJSON()
      return attrs
    },

    fetchProjects: function () {
      var self = this
      var page = this.pagination.get('page')
      var perPage = this.pagination.get('perPage')
      var archived = this.get('archived')
      console.log('Page: ', page, 'PerPage: ', perPage, 'Archived: ', archived)
      var promise = this.projects.fetchUserProjects(app.user.id, page, perPage, archived)
      promise.then(function (res) {
        self.pagination.set({totalItems: res.totalItems})
        // self.projects.calcProjectsLocalStorageUsed()
        // console.log(res.projects[0])
      })
    }
  })
})()
