/* globals zc Backbone */

(function () {
  'use strict'

  zc.collections.Users = Backbone.Collection.extend({
    model: zc.models.User,

    getByPeerId: function (peerId) {
      return this.findWhere({peerId: peerId})
    },

    getVoipConnected: function () {
      return this.where({isVoipConnected: true})
    },

    getVoipConnectedPeers: function () {
      return this.filter((user) => {
        return !user.isLocal() && user.get('isVoipConnected')
      })
    }

  })
})()
