/* globals zc Backbone app utils servars */

(function () {
  'use strict'

  zc.views.CheckSubscriptionView = Backbone.View.extend({
    initialize: function () {},
    /**
     * used to check if the trial period for this account has ended
     * @return {[type]} [description]
     */
    checkSubscription: function () {
      var self = this

      const isGuest = app.user.get('login') === null

      if (!isGuest) {
        var subscription = app.user.get('subscription')

        // They don't really have a subscription unless it has a plan
        if (subscription && subscription.plan) {
          var plan = subscription.plan
          // if the trial has ended and the user doesn't have a payment source
          var isPremium = !!plan.amount
          var isTrialing = subscription.status === 'trialing'
          var hasPaymentSource = !!app.user.get('paymentSource')
          var hasCanceled = !!subscription.canceledAt
          if (isPremium && !isTrialing && !hasPaymentSource && !hasCanceled) {
            console.error('Expired trial period')

            var trialEndedPopup = new zc.views.ModalView({
              model: new Backbone.Model({
                title: 'Thank you for trialing Zencastr!',
                text: 'Please provide a payment method to start your paid subscription to the <strong>' + plan.name + '</strong> plan.<br><br>',
                confirmText: 'Add Payment Method',
                cancelText: 'Change Plan'
              }),
              force: true,
              ChildView: zc.views.ConfirmView,
              callback: function (confirmed) {
                if (confirmed) {
                  var billing = app.user.billing
                  billing.set({email: app.user.get('email'), panelLabel: 'Add Payment Source'})
                  billing.createPaymentSourceForm(function (token) {
                    billing.updateCustomer({
                      user: app.user,
                      paymentSource: token
                    }).then(function (customer) {
                      app.user.paymentSource.set(token.card)
                      trialEndedPopup.exit()
                    })
                  }).open()
                } else {
                  window.location.href = servars.creatorPlatformUrl + '/pricing'
                }
              }
            })

            trialEndedPopup.render()
          }
        }
      }
    },

    renderPlanUsage: function () {
      const isGuest = app.user.get('login') === null
      if (!isGuest) {
        this.planUsageView = new zc.views.PlanUsageView({model: app.user, el: this.$planUsage})
        this.planUsageView.render()
      }
    },

    render: function () {
      this.checkSubscription()
      this.renderPlanUsage()
      return this
    }
  })
})()
