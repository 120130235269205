/* globals zc Backbone */

(function () {
  'use strict'

  zc.views.ContextMenuView = Backbone.View.extend({
    initialize: function (options) {
      this.options = new Backbone.Collection(options.options)
      this.pos = options.pos
      this.handleBodyClick = this.handleBodyClick.bind(this)
    },

    events: {
      'click .context-menu-option': 'exit'
    },

    className: 'context-menu',

    renderOptions: function () {
      var self = this
      this.$el.empty()
      this.options.forEach(function (option) {
        self.renderOption(option)
      })
    },

    renderOption: function (option) {
      var optionView = new zc.views.ContextMenuOptionView({model: option})
      this.$el.append(optionView.render().el)
    },

    position: function () {
      this.$el.css({
        left: this.pos[0] + window.scrollX,
        top: this.pos[1] + window.scrollY
      })
    },

    addClickAwayListener: function () {
      $('body').on('click', this.handleBodyClick)
      $('body').on('contextmenu', this.handleBodyClick)
    },

    removeClickAwayListener: function () {
      $('body').off('click', this.handleBodyClick)
      $('body').off('contextmenu', this.handleBodyClick)
    },

    handleBodyClick: function (e) {
      var $target = $(e.target)
      if (!$target.closest(this.$el).length && !$target.is(this.$el)) {
        this.exit()
      }
    },

    exit: function () {
      this.removeClickAwayListener()
      this.remove()
    },

    render: function () {
      this.renderOptions()
      this.position()

      this.addClickAwayListener()

      return this
    }
  })
})()
