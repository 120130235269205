/* globals zc _ Backbone $ */

(function () {
  'use strict'

  zc.views.InviteView = Backbone.View.extend({
    template: _.template($('.invite-template').html()),

    className: 'invite',

    events: {
      'blur .email-input': 'inviteBlur',
      'blur .name-input': 'inviteBlur'
    },

    /**
     * Event handler used for both email and name validation
     * @param  {Object} e jq Dom event
     */
    inviteBlur: function (e) {
      var val = e.target.value
      // if (!val) return

      var $el = $(e.target)
      // if this is the email or name input
      var inputType = $el.hasClass('email-input') ? 'email' : 'name'

      var valid = inputType === 'email' ? this.model.validateEmail(val) : this.model.validateName(val)
      if (!valid) {
        $el.addClass('form-error')
      } else {
        $el.removeClass('form-error')
        this.model.set(inputType, val)
      }
    },

    render: function () {
      this.$el.html(this.template(this.model.attrs()))
      return this
    }
  })
})()
