/* globals zc _ Backbone */

(function () {
  'use strict'

  zc.views.DropboxReceiverPageView = Backbone.View.extend({
    initialize: function () {
      this.listenTo(this.model, 'enter', this.enter)
      this.listenTo(this.model, 'exit', this.exit)
    },

    template: _.template($('.dropbox-receiver-page-template').html()),

    className: 'dropbox-receiver-page',

    events: {
    },

    enter: function () {
      this.$el.css({opacity: 0})
      this.$el.animate({opacity: 1})
    },

    exit: function () {
      this.$el.remove()
    },

    render: function () {
      this.$el.html(this.template(this.model))
      return this
    }
  })
})()
