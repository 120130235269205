/* globals zc _ Backbone */

(function () {
  'use strict'

  zc.views.HealthCheckItemView = Backbone.View.extend({
    template: _.template($('.health-check-item-template').html()),

    className: 'health-check-item',

    getStatus: function () {
      var passed = this.model.get('passed')
      var level = this.model.get('level')
      if (passed) return 'passed'
      if (level === 'critical') return 'failed'
      if (level === 'warning') return 'warning'
    },

    render: function () {
      var attrs = this.model.toJSON()
      attrs.status = this.getStatus()
      this.$el.html(this.template(attrs))
      return this
    }
  })
})()
