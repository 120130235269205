/* globals zc _ Backbone app */

(function () {
  'use strict'

  zc.views.MediaSettingsView = Backbone.View.extend({
    initialize: function (options) {
      this.settings = options.settings
      this.callback = options.callback
      this.devices = this.model.userMedia.devices
    },

    template: _.template($('.media-settings-template').html()),

    className: 'media-settings',

    events: {
      'click .submit': 'handleSubmitClick',
      'click #hd-rec-switch': 'handleRecordingSwitchClick'
    },

    handleSubmitClick: function () {
      this.callback()
    },

    handleRecordingSwitchClick: function (ev) {
      var val = ev.target.checked ? 1080 : 720
      app.user.settings.set({
        videoRecordingResolution: {value: val, forced: true}
      })
    },

    /** @type {(enabled: boolean) => void} */
    handleVoipChange: function (enabled) {
      if (enabled) {
        this.model.project.call.startCall()
      } else {
        this.model.project.call.endCall()
      }
    },

    renderMediaDeviceSelect: function () {
      this.renderMicSelect()
      this.renderCameraSelect()
      this.renderAudioOutView()
    },

    renderMicSelect: function () {
      this.micSelectView = new zc.views.MediaDeviceSelectView({
        devices: this.devices,
        mediaType: 'audioIn',
        smallMode: true
      })
      this.micSelectView.render()
      this.$('.mic-container').append(this.micSelectView.el)
    },

    renderCameraSelect: function () {
      this.cameraSelectView = new zc.views.MediaDeviceSelectView({
        devices: this.devices,
        mediaType: 'videoIn',
        smallMode: true
      })
      this.cameraSelectView.render()
      this.$('.camera-container').append(this.cameraSelectView.el)
    },

    renderAudioOutView: function () {
      this.audioOutView = new zc.views.MediaDeviceSelectView({
        devices: this.devices,
        mediaType: 'audioOut',
        smallMode: true
      })
      this.audioOutView.render()
      this.$('.speaker-container').append(this.audioOutView.el)
    },

    renderMonitorSetting: function () {
      var monitorSetting = new zc.views.MonitorSettingView({
        project: this.model,
        smallMode: true,
        el: this.$monitorSetting
      })

      monitorSetting.render()
    },

    renderEchoCancellationSetting: function () {
      var echoCancellationSetting = new zc.views.EchoCancellationSettingView({
        settings: this.settings,
        smallMode: true,
        el: this.$echoCancellationSetting
      })

      echoCancellationSetting.render()
    },

    renderVoipSetting: function () {
      const voipSetting = new zc.views.VoipSettingView({
        settings: this.settings,
        smallMode: true,
        el: this.$voipSetting,
        onChange: this.handleVoipChange.bind(this),
      })

      voipSetting.render()
    },

    renderVideoRecordingResolutionSetting: function () {
      const videoRecordingResolutionSetting = new zc.views.VideoRecordingResolutionSettingView({
        settings: this.settings,
        smallMode: true,
        el: this.$videoRecordingResolutionSetting,
      })

      videoRecordingResolutionSetting.render()
    },

    render: function () {
      var attrs = this.model.attrs()
      this.$el.html(this.template(attrs))

      this.$mediaDeviceSelect = this.$('.media-device-select')
      this.$echoCancellationSetting = this.$('.echo-cancellation-container')
      this.$monitorSetting = this.$('.monitor-container')
      this.$voipSetting = this.$('.voip-container')
      this.$videoRecordingResolutionSetting = this.$('.resolution-container')

      this.renderMediaDeviceSelect()
      this.renderEchoCancellationSetting()
      this.renderMonitorSetting()
      this.renderVoipSetting()
      this.renderVideoRecordingResolutionSetting()
      return this
    }
  })
})()
