/* globals zc _ utils */

(function () {
  'use strict'

  zc.views.UserInfoView = zc.views.FormView.extend({
    template: _.template($('.user-info-template').html()),

    className: 'user-info-form form',

    values: function () {
      return {
        firstName: utils.sanitizeText(this.$firstNameInput.val().trim()),
        lastName: utils.sanitizeText(this.$lastNameInput.val().trim()),
        displayName: utils.sanitizeText(this.$displayNameInput.val().trim()),
        email: this.$emailInput.val().toLowerCase()
      }
    },

    url: function () {
      return '/api/forms/user-info/' + this.model.id
    },

    callback: function (err) {
      if (err) {
        utils.notify('error', err)
      } else {
        utils.notify('success', 'Successfully updated user info', {ttl: 3000})
      }
    },

    validateFirstName: function (name) {
      var validRegex = new RegExp(/^.{1,20}$/)

      if (!name) {
        return 'Please enter your first name'
      }

      if (!validRegex.test(name)) {
        return 'Your first name must be between 1 and 20 characters in length'
      }

      if (!utils.validate.isName(name)) {
        return 'Your first name cannot contain numbers or special characters'
      }
    },

    validateLastName: function (name) {
      var validRegex = new RegExp(/^.{1,20}$/)

      if (!name) return false // last names aren't required

      if (!validRegex.test(name)) {
        return 'Your last name must be between 1 and 20 characters in length'
      }

      if (!utils.validate.isName(name)) {
        return 'Your last name cannot contain numbers or special characters'
      }
    },

    validateDisplayName: function (displayName) {
      var validRegex = new RegExp(/^[a-zA-Z0-9_ ]{3,20}$/)

      if (!displayName) return false // not required

      if (!validRegex.test(displayName)) {
        return 'Display names must be between 3 and 20 characters in length and alphanumeric'
      }
    },

    validateEmail: function (email) {
      if (!utils.validate.isEmail(email)) {
        return 'Please enter a valid email address'
      }
    },

    validateForm: function (values) {
      var validFirstNameError = this.validateFirstName(values.firstName)
      if (validFirstNameError) {
        this.$firstNameInput.addClass('form-error')
        return validFirstNameError
      }
      this.$firstNameInput.removeClass('form-error')

      var validLastNameError = this.validateLastName(values.lastName)
      if (validLastNameError) {
        this.$lastNameInput.addClass('form-error')
        return validLastNameError
      }
      this.$lastNameInput.removeClass('form-error')

      var validDisplayNameError = this.validateDisplayName(values.displayName)
      if (validDisplayNameError) {
        this.$displayNameInput.addClass('form-error')
        return validDisplayNameError
      }
      this.$displayNameInput.removeClass('form-error')

      var validEmailError = this.validateEmail(values.email)
      if (validEmailError) {
        this.$emailInput.addClass('form-error')
        return validEmailError
      }
      this.$emailInput.removeClass('form-error')

      return false
    },

    render: function () {
      this.$el.html(this.template(this.model.toJSON()))
      this.$error = this.$('.error')
      this.$firstNameInput = this.$('.first-name-input')
      this.$lastNameInput = this.$('.last-name-input')
      this.$displayNameInput = this.$('.display-name-input')
      this.$emailInput = this.$('.email-input')
      return this
    }
  })
})()
