/* globals zc _ Backbone app Siema analytics */

(function () {
  'use strict'

  zc.views.GuestOfferView = zc.views.BaseView.extend({
    initialize: function (options) {
      this.callback = options.callback || function () {}
    },

    template: _.template($('.guest-offer-template').html()),

    className: 'guest-offer carousel-modal',

    defaults: {
      title: 'Title',
      text: 'Text',
      confirmText: 'Confirm',
      cancelText: 'Cancel'
    },

    events: {
      'click .signup': 'renderSignupModal',
      'click .carousel-nav': 'cancel',
      'click .no-thanks': 'exit'
    },

    renderSignupModal: function () {
      this.cancel()
      this.signupModalView = new zc.views.ModalView({
        addClass: 'modal signup-modal',
        model: new Backbone.Model(app.user.toJSON()),
        noBlurBackground: true,
        ChildView: zc.views.SignupFormView,
        callback: this.signupCallback.bind(this)
      })
      analytics.track('GuestSignUp', {email: app.user.get('email'), recordingId: this.model.get('recordingId')})
      this.signupModalView.render()
    },

    signupCallback: function (err, user) {
      if (err) return console.log(err)

      this.signupModalView.exit()
      // window.session.loggedIn = true
      // window.session.user = user
      app.router.navigate('/dashboard', {trigger: true})
    },

    cancel: function () {
      this.callback(false)
      clearInterval(this.interval)
    },

    startCarousel: function () {
      var self = this
      this.carousel = new Siema({
        selector: this.$carousel[0],
        perPage: 1,
        loop: true
      })
      this.interval = setInterval(function () {
        self.carousel.next()
        self.$indexes.removeClass('active').eq(self.carousel.currentSlide).addClass('active')
      }, 4000)
    },

    render: function () {
      this._render()
      this.$carousel = this.$('.carousel')
      this.$indexes = this.$('.carousel-indexes i')
      var self = this
      setTimeout(function () {
        self.startCarousel()
      }, 1)
      return this
    }
  })
})()
