/* globals zc _ app */

(function () {
  'use strict'

  zc.views.BillingPageView = zc.views.PageView.extend({
    initialize: function (options) {
      zc.views.PageView.prototype.initialize.call(this, options)
      this.billing = new zc.models.Billing()
    },

    template: _.template($('.billing-template').html()),

    className: 'billing-page page',

    prepareInvoice: function (invoice) {
      if (invoice.lines.data.length > 1) {
        console.warn('More than one item in invoice')
      }

      var data = []
      _.each(invoice.lines.data, function (item) {
        // only display items with positive values
        if (item.amount > 0) {
          data.push({
            start: item.period.start * 1000,
            end: item.period.end * 1000,
            created: invoice.date * 1000,
            name: item.plan.name,
            total: invoice.total / 100,
            currency: item.currency,
            description: item.description
          })
        }
      })

      return data
    },

    renderBillingHistory: function () {
      var self = this

      var html = []
      var monthView = {}
      _.each(app.user.subscription.invoices, function (month) {
        console.log(month)

        // this will return a list of items
        // sometimes there will be only one
        var invoice = self.prepareInvoice(month)

        _.each(invoice, function (el) {
          monthView = new zc.views.BillingMonthView({invoice: el, billingAddress: self.model.get('billingAddress')})
          html.push(monthView.el)
        })
      })

      this.$billingHistory.append(html)
    },

    render: function () {
      this._render()

      this.$billingHistory = this.$('.billing-history')

      this.renderBillingHistory()

      return this
    }
  })
})()
