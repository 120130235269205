/* globals zc _ Backbone app debug utils */

(function () {
  'use strict'
  var dbg = debug('zc:healthCheckBarView')

  zc.views.HealthCheckBarView = Backbone.View.extend({

    template: _.template($('.health-check-bar-template').html()),
    className: 'user-footer',
    events: {
      'click .health-check-status-item': 'toggleHealthCheckLog'
    },
    initialize: function () {
      this.listenTo(this.model.criticalHealthChecks, 'reset', this.healthChecksReset)
      this.listenTo(this.model.warningHealthChecks, 'reset', this.healthChecksReset)
      this.listenTo(this.model.criticalHealthChecks, 'reset', this.renderStatus)
      this.listenTo(this.model.warningHealthChecks, 'reset', this.renderStatus)
      this.listenTo(this.model.audioInput, 'change', this.renderMicLabel)
      this.listenTo(this.model.tracks, 'change:bytesRecorded change:bytesSaved change:percentSaved', this.renderTrackSaveProgress)
      this.listenTo(this.model.tracks, 'change:bytesRecorded change:bytesUploaded change:percentUploaded', this.renderTrackUploadProgress)
    },

    toggleHealthCheckLog: function () {
      if (this.$healthCheckLog.hasClass('hidden')) {
        this.showHealthCheckLog()
      } else {
        this.hideHealthCheckLog()
      }
    },

    showHealthCheckLog: function () {
      this.$healthCheckStatusItem.addClass('expanded')
      this.$healthCheckLog.removeClass('hidden')
    },

    hideHealthCheckLog: function () {
      this.$healthCheckStatusItem.removeClass('expanded')
      this.$healthCheckLog.addClass('hidden')
    },

    showUserFooter: function () {
      this.$footer.removeClass('hidden')
    },

    hideUserFooter: function () {
      this.$footer.addClass('hidden')
      this.hideHealthCheckLog()
    },

    healthChecksReset: function () {
      var status = this.model.getHealthCheckStatus()

      // if there are warnings or failures
      // then we want to show the footer automatically
      // and show log in case of failure
      // Only on the first render though
      var firstTimeRendering = !!this.$criticalHealthChecks.is(':empty')
      if (firstTimeRendering) {
        if (status === 'warning') {
          this.showUserFooter()
        } else if (status === 'failed') {
          this.showUserFooter()
          this.showHealthCheckLog()
        }
      }

      this.renderHealthChecks()
    },

    renderTrackSaveProgress: function (track) {
      if (track.get('format') !== 'mp3') return // we only want to render mp3 tracks here
      var bytesRecorded = track.get('bytesRecorded')
      var bytesSaved = track.get('bytesSaved')
      // var percentSaved = track.get('percentSaved')
      var percentSaved = Math.min(Math.round(bytesSaved / bytesRecorded * 100), 100)
      dbg('renderPercentSaved - ', percentSaved, '% - bytesRecorded: ', bytesRecorded, ' - bytesSaved: ', bytesSaved)
      var percentString = percentSaved + '%'
      var fraction = utils.formattedDiskSpace(bytesSaved) + ' / ' + utils.formattedDiskSpace(bytesRecorded)
      dbg('saveProgress - percentString: ', percentString, ' - fraction: ', fraction)
      this.localBackupProgressText.textContent = fraction
      this.localBackupProgressBar.style.width = percentString
    },

    renderTrackUploadProgress: function (track) {
      if (track.get('format') !== 'mp3') return // we only want to render mp3 tracks here
      var bytesRecorded = track.get('bytesRecorded')
      var bytesUploaded = track.get('bytesUploaded')
      var percentUploaded = track.get('percentUploaded')
      dbg('renderPercentUploaded - ', percentUploaded, '% - bytesRecorded: ', bytesRecorded, ' - bytesUploaded: ', bytesUploaded)
      var percentString = percentUploaded + '%'
      var fraction = utils.formattedDiskSpace(bytesUploaded) + ' / ' + utils.formattedDiskSpace(bytesRecorded)
      dbg('uploadProgress - percentString: ', percentString, ' - fraction: ', fraction)
      this.cloudBackupProgressText.textContent = fraction
      this.cloudBackupProgressBar.style.width = percentString
    },

    renderMicLabel: function () {
      var micLabel = this.model.attrs().micLabel
      this.$micLabel.text(micLabel)
      this.$micLabel.attr('title', micLabel)
    },

    renderHealthChecks: function () {
      if (app.user.isHost() || this.model.isLocal()) {
        this.renderCriticalHealthChecks()
        this.renderWarningHealthChecks()
        this.renderHealthCheckStatus()
      }
    },

    renderCriticalHealthChecks: function () {
      var self = this
      this.$criticalHealthChecks.empty()
      this.model.criticalHealthChecks.forEach(function (check) {
        var healthCheckView = new zc.views.HealthCheckItemView({model: check})
        self.$criticalHealthChecks.append(healthCheckView.render().el)
      })
    },

    renderWarningHealthChecks: function () {
      var self = this
      this.$warningHealthChecks.empty()
      this.model.warningHealthChecks.forEach(function (check) {
        var healthCheckView = new zc.views.HealthCheckItemView({model: check})
        self.$warningHealthChecks.append(healthCheckView.render().el)
      })
    },

    renderHealthCheckStatus: function () {
      var status = this.model.getHealthCheckStatus()
      this.$healthCheckStatusItem
        .removeClass('passed failed warning unknown')
        .addClass(status)
      this.$healthCheckStatus.text(status)
    },

    render: function () {
      this.$el.html(this.template(this.model.attrs()))

      // footer status items
      this.$footer = this.$el
      this.$micLabel = this.$('.selected-mic .item-content')
      this.$healthCheckStatusItem = this.$('.health-check-status-item')
      this.$healthCheckStatus = this.$('.health-check-status-item .status')
      this.$localStorageStatusItem = this.$('.local-storage.status-item')
      this.$localBackupStatusItem = this.$('.local-backup.status-item')
      this.$cloudBackupStatusItem = this.$('.cloud-backup.status-item')

      this.localStorageStatus = this.$('.local-storage.status-item .quota-free').get()[0]
      this.localBackupProgressBar = this.$('.local-backup.status-item .progress-bar').get()[0]
      this.cloudBackupProgressBar = this.$('.cloud-backup.status-item .progress-bar').get()[0]
      this.localBackupProgressText = this.$('.local-backup.status-item .progress-text').get()[0]
      this.cloudBackupProgressText = this.$('.cloud-backup.status-item .progress-text').get()[0]

      // health check log
      this.$healthCheckLog = this.$('.health-check-log')
      this.$criticalHealthChecks = this.$('.health-check-items.critical')
      this.$warningHealthChecks = this.$('.health-check-items.warnings')

      this.renderHealthChecks()

      this.renderMicLabel()
      this.renderHealthChecks()
    }
  })
})()
