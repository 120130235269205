/* globals zc _ Backbone */

(function () {
  'use strict'

  zc.views.PaginationView = Backbone.View.extend({
    initialize: function () {
      this.listenTo(this.model, 'change:totalItems', this.render)
      this.listenTo(this.model, 'change:page', this.render)
      this.listenTo(this.model, 'change:perPage', this.render)
    },

    template: _.template($('.pagination-template').html()),

    events: {
      'click .page-button': 'pageButtonClick'
    },

    pageButtonClick: function (e) {
      var $target = $(e.target)
      var page = $target.attr('page')
      this.loadPage(page)
    },

    loadPage: function (page) {
      this.model.set({page: Number(page)})
    },

    render: function () {
      this.$el.html(this.template(this.model.attrs()))
      return this
    }
  })
})()
