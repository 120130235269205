/* globals zc zc2  */

(function () {
  'use strict'

  zc.views.MonitorSettingView = zc.views.DropdownSelectView.extend({
    initialize: function (options) {
      // A note to future devs, project here is actually the recorder
      this.project = options.project

      this.watcher = new zc2.utils.ReduxWatcher(zc2.store)
      this.watcher.watch('media_manager.monitor', this.updateMonitorSelection.bind(this))

      this._initialize(options)
      this.createModel({
        iconClass: 'ic-monitor',
        selectState: 'success',
        defaultErrorTitle: ''
      })
      var monitorEnabled = Number(zc2.store.getState().media_manager.monitor)
      var items = [
        {id: '1', title: 'Enabled', selected: monitorEnabled === 1},
        {id: '0', title: 'Disabled', selected: monitorEnabled === 0}
      ]
      this.model.set('items', items)
    },

    updateMonitorSelection: function (newVal) {
      // Number(true) == 1
      this.setSelected(Number(newVal))
    },

    onItemSelect: function (item) {
      var on = !!Number(item.id)
      zc2.store.dispatch(zc2.actions.media_manager.UPDATE_MONITOR(on))
      console.log('Changed monitor setting: ', on ? 'on' : 'off')
    }
  })
})()
