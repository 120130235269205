/* globals zc _ Backbone */

(function () {
  zc.views.PostproductionCreditBundleView = Backbone.View.extend({
    initialize: function () {
      this.listenTo(this.model, 'change:selected', this.renderSelectedState)
    },

    template: _.template($('.postproduction-credit-bundle-template').html()),

    className: 'postproduction-credit-bundle credit-bundle',

    events: {
      'click': 'select'
    },

    select: function () {
      this.model.trigger('select', this.model)
    },

    renderSelectedState: function () {
      var selected = this.model.get('selected')

      if (selected) {
        this.$el.addClass('selected')
      } else {
        this.$el.removeClass('selected')
      }
    },

    render: function () {
      this.$el.html(this.template(this.model.toJSON()))

      this.renderSelectedState()

      return this
    }
  })
})()
