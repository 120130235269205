/* globals zc Backbone _ app utils */
(function () {
  'use strict'

  /**
   * View used to show the progress of a postproduction
   * we can about the following states
   * progress
   * processing
   * completedAt
   * error
   *
   * Steps:
   * 1. send to auphonic
   * 2. send to cloud storage
   * 3. send email
   * 4. start video mixing
   * 5. done
   *
   * It uses a TrackView to show progress/error state and when it's completed it creates two new track models
   * so the user can download the mix and the mov file
   */

  zc.views.PostproductionView = Backbone.View.extend({
    initialize: function (options) {
      this.listenTo(this.model, 'change:processing', this.processingChange)
      this.listenTo(this.model, 'change:error change:errorMessage', this.render)

      _.bindAll(this, 'refreshStatus')
    },

    template: _.template($('.postproduction-template').html()),

    className: 'postproduction',

    events: {},

    processingChange: function (model, processing) {
      if (processing) {
        this.$el.addClass('processing')
      } else {
        this.$el.removeClass('processing')
        // remove the track view the shower the progress
        this.$tracks.empty()
        // and show show the tracks to download
        this.renderPostproductionTracks()
      }
    },

    refreshStatus: function () {
      var self = this
      // if this view is no longer present in the dom (eg the user moved to a different recording)
      // stop polling for data
      if (!document.body.contains(this.el)) {
        clearInterval(self.pollId)
        return
      }

      app.socket.emit('postproduction:read', { _id: this.model.id }, function (err, postproduction) {
        if (err) utils.notify('error', err)

        if (!postproduction) {
          return self.$processingStatus.text('Waiting in Queue...')
        }

        // we need these 2 to update the progress
        self.model.set(postproduction)

        // if it's finished or there was an error
        if (postproduction.videoMixPath || postproduction.error || postproduction.errorMessage) {
          self.model.set({
            uploading: false,
            finalized: false,
            processing: false,
            downloading: false,
            completedAt: postproduction.completedAt,
            error: postproduction.error || postproduction.errorMessage
          })
          clearInterval(self.pollId)
        } else {
          self.renderProgress()
        }
      })
    },

    showUploading: function () {
      this.$processingStatus.text('Transferring to Cloud...')
    },

    hideButtons: function () {
      this.$('button').hide()
    },

    showButtons: function () {
      this.$('button').show()
    },

    pollStatus: function () {
      this.pollId = setInterval(this.refreshStatus, 5 * 1000)
    },

    errorChange: function (model, error) {
      if (error) {
        this.showError(error)
      } else {
        this.hideError()
      }
    },

    showError: function (error) {
      this.$el.removeClass('fixing-error')
      this.$el.addClass('has-error')
      this.$errorMessage.text(error)
    },

    hideError: function () {
      this.$el.removeClass('has-error')
    },

    errorDependencyChange: function () { /* kept to override base class */ },
    checkError: function () { /* kept to override base class */ },

    hasError: function () {
      return !!this.model.get('errorMessage')
    },

    hasAudioError: function () {
      return this.model.get('status') === 2
    },

    hasVideoError: function () {
      return this.hasError() && this.model.get('status') === 3
    },

    getErrorMessage: function () {
      var attrs = this.model.attrs()
      return attrs.errorMessage
    },

    renderPostproductionTracks: function () {
      var model = this.model

      // show the view to download the mp3 mix
      var audioModel = new zc.models.Track({
        finalized: true,
        format: this.model.get('format') || 'mp3',
        duration: model.get('duration'),
        isPostproduction: true,
        path: ['/' + app.user.id, this.model.getFinalMixFolder(), this.model.get('filename')].join('/')
      })

      this.renderTrackView(audioModel)

      // if postp had a video mix, also add that to the view
      if (model.get('videoMixPath')) {
        const videoFormat = model.get('videoMixPath').split('.').pop() || 'mp4'
        var videoModel = new zc.models.Track({
          finalized: true,
          format: videoFormat,
          type: 'video',
          duration: model.get('duration'),
          isPostproduction: true,
          path: model.get('videoMixPath')
        })

        this.renderTrackView(videoModel)
      }
    },

    renderInProgress: function () {
      this.model.set({ uploading: true }, { silent: true })

      this.renderTrackView()

      this.renderProgress()
    },

    renderTrackView: function (model) {
      model = model || this.model

      var trackView = new zc.views.TrackView({
        model: model,
        postproduction: true
      })
      this.$tracks.append(trackView.render().el)
    },

    renderProgress: function () {
      var progress = 25

      // we sent it to cloud storage
      if (this.model.get('deliveredAt')) {
        progress = 80
        // if auphonic is done
      } else if (this.model.get('status')) {
        progress = 50
      }

      this.model.set({ progress: progress })
    },

    renderAudioError: function () {
      this.renderTrackView()
      this.model.set('error', 'Oops! Something went wrong: ' + this.getErrorMessage())
      this.showError(this.model.get('error'))
    },

    renderVideoError: function () {
      var tempVideoModel = new zc.models.Track({ error: 'Video Mixing Failed!' })
      this.renderTrackView(this.model)
      this.renderTrackView(tempVideoModel)
    },

    render: function () {
      var attrs = this.model.attrs()
      this.$el.html(this.template(attrs))
      this.$duration = this.$('.duration')
      this.$errorMessage = this.$('.error-message')
      this.$processingStatus = this.$('.processing-status')
      this.$progressBar = this.$('.progress-bar')
      this.$progressPercent = this.$('.progress-percent')
      this.$tracks = this.$('.tracks')

      if (attrs.processing) {
        this.$el.addClass('processing')
        this.pollStatus()
      }

      if (attrs.deliveredAt) {
        this.$el.addClass('finalized')
      }

      if (this.model.get('uploading')) {
        this.showUploading()
      }

      // if postproduction is done, show the tracks
      if (this.model.get('completedAt')) {
        this.renderPostproductionTracks()
      } else if (this.hasAudioError()) {
        this.renderAudioError()
      } else if (this.hasVideoError()) {
        this.renderVideoError()
      } else {
        this.renderInProgress()
      }

      return this
    }

  })
})()
