/* globals zc _ Backbone app utils */

(function () {
  zc.views.BuyPostproductionCreditsView = Backbone.View.extend({
    initialize: function (options) {
      this.callback = options.callback
      this.creditBundles = new Backbone.Collection()

      this.listenTo(this.creditBundles, 'select', this.selectCreditBundle)
      this.listenTo(this.creditBundles, 'add', this.renderCreditBundle)
      this.listenTo(this.creditBundles, 'reset', this.renderCreditBundles)
      this.listenTo(app.user.billing, 'loading', this.showLoading)
      this.listenTo(app.user.billing, 'doneLoading', this.hideLoading)

      this.fetchCreditBundles()
    },

    template: _.template($('.buy-postproduction-credits-template').html()),

    className: 'buy-postproduction-credits',

    events: {
      'click .purchase': 'submitPurchase'
    },

    blur: function () {
      this.$el.addClass('blur')
    },

    focus: function () {
      this.$el.removeClass('blur')
    },

    selectCreditBundle: function (creditBundle) {
      this.creditBundles.forEach(function (creditBundle) {
        creditBundle.set('selected', false)
      })

      creditBundle.set('selected', true)
      this.selectedBundle = creditBundle
      this.enablePurchase()
    },

    enablePurchase: function () {
      this.$purchase.removeClass('disabled')
    },

    purchaseSelection: function () {
      var self = this
      var bundleId = this.selectedBundle.id
      app.user.billing.purchasePostproductionCreditBundle(bundleId)
        .then(function (res) {
          self.callback(null, {purchasedCredits: res.purchasedCredits})
        }).catch(function (err) {
          self.callback(err)
        })
    },

    purchaseConfirmed: function (confirmed) {
      this.confirmPurchaseModalView.exit()
      if (confirmed) {
        this.purchaseSelection()
      } else {
        console.log('Not Confirmed')
      }
    },

    fetchCreditBundles: function () {
      var self = this
      app.socket.emit('postproductionCreditBundles:read', function (err, creditBundles) {
        if (err) return utils.notify('error', err)
        self.creditBundles.reset(creditBundles)
      })
    },

    showLoading: function () {
      this.$purchase.addClass('disabled').text('Purchasing...')
    },

    hideLoading: function () {
      this.$purchase.removeClass('disabled').text('Purchase')
    },

    submitPurchase: function () {
      var self = this
      this.checkPaymentSource(function (err, paymentSource) {
        if (err) return utils.notify('error', err)
        if (paymentSource) {
          self.confirmPurchase(self.purchaseConfirmed)
        }
      })
    },

    checkPaymentSource: function (cb) {
      var paymentSource = app.user.get('paymentSource')
      if (paymentSource) {
        cb(null, paymentSource)
      } else {
        this.getPaymentSource(cb)
      }
    },

    getPaymentSource: function (cb) {
      var self = this
      this.model.createPaymentSourceForm(function (token) {
        self.model.updateCustomer({
          user: app.user,
          paymentSource: token
        }).then(function (customer) {
          console.log(customer)
          console.log(token)
          self.model.set(token.card)
          cb(null, token)
        })
      }).open({panelLabel: 'Use This Card'})
    },

    confirmPurchase: function (cb) {
      var price = this.selectedBundle.get('price')
      var credits = this.selectedBundle.get('credits')
      var title = 'Confirm Purchase'
      var text = '<p>Please confirm that you would like to purchase ' + credits + ' postproduction hour' + (credits > 1 ? 's ' : ' ') +
                 'for a <strong>one-time</strong> payment of ' +
                 '<strong>$' + Number(price / 100).toFixed(2) + '</strong></p>'

      this.confirmPurchaseModalView = new zc.views.ModalView({
        addClass: 'confirm-prompt-modal',
        model: new Backbone.Model({title: title, text: text}),
        ChildView: zc.views.ConfirmView,
        callback: cb.bind(this)
      })

      this.confirmPurchaseModalView.render()
    },

    renderCreditBundles: function () {
      var self = this
      this.creditBundles.forEach(function (creditBundle) {
        self.renderCreditBundle(creditBundle)
      })
    },

    renderCreditBundle: function (creditBundle) {
      var creditBundleView = new zc.views.PostproductionCreditBundleView({model: creditBundle})
      this.$creditBundles.append(creditBundleView.render().el)
    },

    render: function () {
      this.$el.html(this.template(this.model.attrs()))
      this.$creditBundles = this.$('.credit-bundles')
      this.$purchase = this.$('.purchase')

      this.renderCreditBundles()

      return this
    }
  })
})()
