/* globals zc _ Backbone utils moment */

(function () {
  'use strict'

  zc.models.Timer = Backbone.Model.extend({
    initialize: function (attrs) {
      this.startTime = 0
      this.stopTime = 0
      this.lastPauseTime = 0
      this.pauses = []

      if (attrs && attrs.duration) {
        this.startTime = moment()
        this.stopTime = this.startTime.add(attrs.duration)
      }
    },

    start: function () {
      this.startTime = moment()
    },

    pause: function () {
      this.lastPauseTime = moment()
    },

    resume: function () {
      if (this.lastPauseTime) {
        var pauseMs = moment().diff(this.lastPauseTime)
        this.pauses.push(pauseMs)
        this.lastPauseTime = 0
      }
    },

    stop: function () {
      this.stopTime = moment()
    },

    duration: function () {
      var grossMs
      var totalPauseMs = 0

      if (!this.startTime) return 0

      if (this.stopTime) {
        grossMs = this.stopTime.diff(this.startTime)
      } else if (this.lastPauseTime) {
        grossMs = this.lastPauseTime.diff(this.startTime)
      } else {
        grossMs = moment().diff(this.startTime)
      }

      _.each(this.pauses, function (pauseMs) {
        totalPauseMs += pauseMs
      })

      var netMs = grossMs - totalPauseMs

      return netMs
    },

    formattedDuration: function () {
      return utils.msToClock(this.duration())
    },

    hmsFormattedDuration: function () {
      return utils.msToHms(this.duration(), true)
    },

    reset: function () {
      this.startTime = 0
      this.stopTime = 0
      this.lastPauseTime = 0
      this.pauses = []
    }
  })
})()
