/* globals zc zc2 _ Backbone utils app */

(function () {
  'use strict'

  zc.views.ProjectView = zc.views.PageView.extend({
    initialize: function (options) {
      zc.views.PageView.prototype.initialize.call(this, options)

      this.userMedia = this.model.userMedia

      this.listenTo(app.user, 'change:cameraOn', this.cameraOnChange)
      this.listenTo(this.model.recorder, 'change:isRecording', this.isRecordingChange)
      this.listenTo(this.model.recorder, 'change:recording', this.recordingChange)

      this.listenTo(this.model.recorder.recording.tracks, 'change:processing', this.processingChange)
      this.listenTo(this.model, 'alreadyJoined', this.alreadyJoined)

      this.listenTo(this.model, 'startCloseTabProtection', this.startCloseTabProtection)
      this.listenTo(this.model, 'stopCloseTabProtection', this.stopCloseTabProtection)
      this.listenTo(this.model, 'localBackup', this.localBackup)

      this.listenTo(this.model, 'showAudioProcessingPopup', this.showAudioProcessingPopup)
      this.listenTo(this.model, 'hideAudioProcessingPopup', this.hideAudioProcessingPopup)
      this.listenTo(this.model, 'showCloseWarning', this.showCloseWarning)
      this.listenTo(this.model, 'requestAudioPlayback', this.requestAudioPlayback)

      this.listenTo(this.model.recordings, 'click', this.recordingLinkClick)

      if (this.model.recorder.get('isRecording') || this.model.recorder.recording.get('isRecording')) {
        this.startCloseTabProtection()
      }

      this.userMedia.on('streamAvailable', this.localStreamAdded.bind(this))
      this.model.call.on('newPeer', this.lobbyUsersChange.bind(this))
      this.model.call.on('manualDisconnect', this.lobbyUsersChange.bind(this))
      this.model.call.on('peerClosed', this.lobbyUsersChange.bind(this))
    },

    template: _.template($('.project-template').html()),

    className: 'project page',

    localStreamAdded: function (newStream) {
      this.renderLocalVideo(newStream)
    },

    lobbyUsersChange: function () {

      this.localVideoView && this.renderLocalVideo(this.model.userMedia.stream)
    },

    recordingLinkClick: function (recording) {
      this.model.recordings.map(function (rec) {
        if (rec !== recording) {
          rec.set({ active: false })
        }
      })

      var isLast = recording.collection.indexOf(recording) === recording.collection.length - 1
      var urlComponents = [this.model.get('owner'), this.model.get('slug')]

      if (!isLast) {
        urlComponents.push(recording.id)
      }

      var url = urlComponents.join('/')
      app.router.navigate(url)
      this.model.recorder.set({ recording: recording })
    },

    recordingChange: function (model, recording) {
      recording.set({ active: true })
      this.renderLobby()
    },

    /**
     * @param videoRecordingMode {"enabled" | "disabled" | "displayOnly"}
     */
    updateVideoElements: function (videoRecordingMode) {
      if (videoRecordingMode === 'disabled') {
        // TODO: Actually rebuild the media recorder and/or refresh the page
        this.$localVideo.addClass('hide')
        this.$mainLocalVideo.parent().addClass('hide')
        // Operate on peerVideos' parent to avoid conflicting with styles specific to .peer-videos
        this.$peerVideos.parent().addClass('hide')
      } else {
        this.$localVideo.removeClass('hide')
        this.$mainLocalVideo.parent().removeClass('hide')
        // Operate on peerVideos' parent to avoid conflicting with styles specific to .peer-videos
        this.$peerVideos.parent().removeClass('hide')
      }
    },

    cameraOnChange: function (model, cameraOn) {
      if (cameraOn) {
        this.$localVideo.addClass('camera-on')
      } else {
        this.$localVideo.removeClass('camera-on')
      }
    },

    isRecordingChange: function (model, isRecording) {
      var isHost = app.user.isHost()
      if (isRecording) {
        this.disableRecordingLinks()
        this.startCloseTabProtection()
        if (isHost) {
          this.hideHeader()
        }
        this.optimizeAnimations()
      } else {
        this.enableRecordingLinks()
        if (isHost) {
          this.showHeader()
        }
        this.unoptimizeAnimations()
      }
    },

    optimizeAnimations: function () {
      app.animationFrameManager.optimize()
    },

    unoptimizeAnimations: function () {
      app.animationFrameManager.unoptimize()
    },

    hideHeader: function () {
      this.recordingHeaderView.toggleHeader('hide')
      this.recordingHeaderView.toggleLogo('hide')
    },

    showHeader: function () {
      this.recordingHeaderView.toggleHeader('show')
      this.recordingHeaderView.toggleLogo('show')
    },

    showCloseWarning: function () {
      var self = this

      // if the modal is already up, exit
      if (this.closeWarningModalView) return

      this.closeWarningModalView = new zc.views.ModalView({
        addClass: 'close-warning-modal',
        model: new Backbone.Model({
          title: 'WAIT!! Please Don\'t Close This Tab',
          text: 'Your audio can only finish uploading if the browser tab remains open. ' +
            'You will receive a notification when your uploads are complete. ' +
            '<span class="tip">You only need to wait for your own tracks to finish.</span>',
          confirmText: 'Ok, I\'ll Wait',
          cancelText: null
        }),
        ChildView: zc.views.ConfirmView,
        callback: function (confirmed) {
          self.closeWarningModalView.exit()
        }
      })

      this.closeWarningModalView.$el.addClass('wait-for-uploads-modal')

      this.closeWarningModalView.render()
    },

    requestAudioPlayback: function () {
      var self = this
      var requestAudioPlaybackModal = new zc.views.ModalView({
        addClass: 'request-audio-playback-modal',
        force: true,
        model: new Backbone.Model({
          title: 'Enable Audio Playback',
          text: 'Click below to allow audio playback from Zencastr',
          confirmText: 'Allow Audio Playback',
          cancelText: false
        }),
        ChildView: zc.views.ConfirmView,
        callback: function (confirmation) {
          if (!confirmation) {
            console.error('Audio playback request rejected')
          }

          requestAudioPlaybackModal.exit()

          self.model.audioPlaybackAllowed()
        }
      })

      requestAudioPlaybackModal.render()
    },

    disableRecordingLinks: function () {
      this.model.recordings.setAll({ disabled: true })
    },

    enableRecordingLinks: function () {
      this.model.recordings.setAll({ disabled: false })
    },

    processingChange: function (track, processing) {
      // We only care when processing=true because that means that this track has been uploaded
      // When processing=false that means the track has been downloaded to the client, and we don't need to notify for that
      if (!processing) return

      // When all host and guest tracks are uploaded, notify host
      if (app.user.isHost() && this.model.lobby.areAllTracksUploaded()) {
        utils.notify('success', 'All tracks have finished uploading.')
      }

      if (app.user.ownsTrack(track) && app.user.areAllTracksUploaded()) {
        this.stopCloseTabProtection()
        if (this.closeWarningModalView) {
          this.closeWarningModalView.exit()
        }

        // Show offer modal to guests
        if (!app.user.loggedIn()) {
          this.showGuestOffer(track)
        }
      }
    },

    showGuestOffer: function (track) {
      var self = this
      // If the recording was aborted, do not show modal
      if (!self.model.recorder.get('aborted')) {
        this.guestOfferModal = new zc.views.ModalView({
          addClass: 'guest-offer-modal',
          model: new Backbone.Model({ recordingId: track ? track.get('recordingId') : undefined }),
          ChildView: zc.views.GuestOfferView,
          callback: function (confirmed) {
            self.guestOfferModal.exit()
          }
        })

        this.guestOfferModal.render()
      }
    },

    alreadyJoined: function () {
      var self = this
      var alreadyJoinedModal = new zc.views.ModalView({
        addClass: 'request-audio-playback-modal',
        force: true,
        model: new Backbone.Model({
          title: 'Opened in another tab',
          text: 'It seems you\'ve opened this link in another tab.',
          confirmText: 'End other session',
          cancelText: false
        }),
        ChildView: zc.views.ConfirmView,
        callback: function (confirmation) {
          if (confirmation) {
            self.model.endOtherSessions().then(function () {
              alreadyJoinedModal.exit()
            })
          }
        }
      })

      alreadyJoinedModal.render()
    },

    disableNavLinksRouting: function () {
      $('.nav').on('click', this.stopNavLink)
    },

    enableNavLinksRouting: function () {
      $('.nav').off('click', this.stopNavLink)
    },

    stopNavLink: function (e) {
      e.stopPropagation()
    },

    startCloseTabProtection: function () {
      this.model.set('closeTabProtection', true)
      app.tabProtectionService.enableTabProtection()
    },

    stopCloseTabProtection: function () {
      this.model.set('closeTabProtection', false)
      app.tabProtectionService.disableTabProtection()
    },

    localBackup: function () {
      var localBackupPopupView = new zc.views.LocalBackupPopupView({
        model: this.model
      })
      localBackupPopupView.render()
    },

    showRecordingTips: function (cb) {
      var recordingTipsPopupView = new zc.views.RecordingTipsPopupView({ model: this.model, callback: cb })
      recordingTipsPopupView.render()
    },

    startAudioPlayback: function () {
      this.model.startAudioPlayback()
    },

    renderRecorder: function () {
      if (app.user.isHost()) {
        var recorderView = new zc.views.RecorderView({ model: this.model.recorder, el: this.$recorder })
        recorderView.render()
      }
    },

    renderRecordingLinks: function () {
      var view = this
      this.model.recordings.each(function (recording, i) {
        view.renderRecordingLink(recording, i)
      })
      if (!this.model.recordings.length) {
        this.$recordings.parent().addClass('empty')
      } else {
        this.$recordings.parent().removeClass('empty')
      }
    },

    renderRecordingHeader: function () {
      if (app.user.isHost()) {
        this.recordingHeaderView = new zc.views.ProjectHeaderView({
          configType: 'recording',
          model: this.model.recorder,
          el: this.$header
        })
      } else {
        this.$header.addClass('guest-header')
        this.recordingHeaderView = new zc.views.GuestHeaderView({ model: this.model, el: this.$header })
      }
      this.recordingHeaderView.render()
    },

    renderRecordingLink: function (recording, i) {
      var recordingLinkView = new zc.views.RecordingLinkView({ model: recording, index: i })
      this.$recordings.prepend(recordingLinkView.render().el)
    },

    renderPeerVideos: function () {
      var videoArrayView = new zc.views.PeerVideosView({
        model: this.model.lobby,
        project: this.model,
        el: this.$peerVideos
      })
      videoArrayView.render()
    },

    renderLocalVideo: function (mediaStream) {
      if (!mediaStream) return // nothing to render

      var $localVideoEl = this.getLocalVideoEl()
      // Use .parent here because localVideoView.$el is supposed to be the child of $localVideoEl
      var needsRender = !(this.localVideoView && (this.localVideoView.$el.parent().is($localVideoEl)))
      if (needsRender) {
        this.removeLocalVideo()

        this.localVideoView = new zc.views.UserVideoView({
          id: app.user.id,
          mediaStream: mediaStream
        })
        var el = this.localVideoView.render().el
        $localVideoEl.append(el)
      } else {
        this.localVideoView.streamAdded(null, mediaStream)
      }
    },

    getLocalVideoEl: function () {
      var connectedUsers = zc2.store.getState().users.remoteUsers
      if (!Object.keys(connectedUsers).length) {
        this.$mainLocalVideo.parent().removeClass('empty')
        return this.$mainLocalVideo
      } else {
        this.$mainLocalVideo.parent().addClass('empty')
        return this.$localVideo
      }
    },

    removeLocalVideo: function () {
      this.localVideoView && this.localVideoView.remove()
    },

    renderLobby: function () {
      if (this.lobbyView) {
        this.lobbyView.cleanup()
      }

      this.lobbyView = new zc.views.LobbyView({ model: this.model.lobby, project: this.model, el: this.$lobby })
      this.lobbyView.render()
    },

    renderChatAndFootnotes: function () {
      var chatView = new zc.views.ChatAndFootnotesView({ model: this.model, el: this.$rightSidebar })
      chatView.render()
    },

    showAudioProcessingPopup: function () {
      if (!this.audioProcessingModal) {
        this.audioProcessingModal = new zc.views.ModalView({
          model: this.model,
          ChildView: zc.views.AudioProcessingView,
          force: true
        })
        this.audioProcessingModal.render()
      }
    },

    hideAudioProcessingPopup: function () {
      if (this.audioProcessingModal) {
        this.audioProcessingModal.exit()
      }
    },

    removeBuffer: function (buffer) {
      var bufferEl = this.$('#buffer-' + buffer.id)
      bufferEl.remove()
    },

    /**
     * Method used to show an error for crome 88, mac os users that they might have issues during recording
     */
    checkCompatibility: function () {
      var hasGreenScreenIssue = utils.hasGreenScreenIssue()

      if (hasGreenScreenIssue) {
        utils.notify('error', 'We\'ve detected that your browser configuration has an issue that will affect recording video on Zencastr. <br> To continue recording without problems, please enable hardware acceleration in your browser.')
      }
    },

    checkSubscription: function () {
      new zc.views.CheckSubscriptionView({}).render()
    },

    render: function () {
      this.$el.html(this.template(this.model.attrs()))
      this.$header = this.$('> .header-container')
      this.$recorder = this.$('.recorder')
      this.$mainLocalVideo = this.$('.main-local-video')
      this.$localVideo = this.$('.local-video')
      this.$peerVideos = this.$('.peer-videos')
      this.$lobby = this.$('.lobby')
      this.$rightSidebar = this.$('.right-input-container')
      this.$buffers = this.$('.buffers')
      this.$recordings = this.$('.recordings')

      this.renderRecordingHeader()
      this.renderRecorder()
      this.renderChatAndFootnotes()

      if (this.model.recorder.recording) {
        this.$el.addClass('videoMode-' + this.model.recorder.recording.get('videoRecordingMode'))
      }

      if (app.user.isHost() && this.model.recordings.length > 1) {
        this.renderRecordingLinks()
      }

      // start local audio playback here once the view is intantiated
      // so we can respond to errors with ui if needed
      this.startAudioPlayback()

      this.renderLobby()

      this.updateVideoElements(this.model.recorder.recording.get('videoRecordingMode'))
      // Put the elements down in case we need to use them later.
      this.renderPeerVideos()
      // Make sure we have a stream before we do anything
      // an event will render this later if we are waiting on the stream
      if (this.model.userMedia.stream) {
        this.renderLocalVideo(this.model.userMedia.stream)
      }

      // disabled for now
      // we were having issues with user doing single page nav to
      // the pricing page and their application state is all screwed
      this.disableNavLinksRouting()

      // Prompt for credit card if pro and missing payment source
      // We wait a bit to make sure it's always on top of other modals -- except video onboard
      setTimeout(this.checkSubscription, 500)

      this.checkCompatibility()
      this.mainVideoGridPlacement = new zc2.services.GridPlacement(this.$mainLocalVideo[0])

      return this
    }
  })
})()
