/* globals zc _ Backbone */

(function () {
  'use strict'

  zc.views.AudioProcessingView = Backbone.View.extend({

    // initialize: function(options) {
    //   zc.views.PopupView.prototype.initialize.call(this, options);
    // },

    className: 'audio-processing',

    template: _.template($('.audio-processing-template').html()),

    render: function () {
      this.$el.html(this.template(this.model.toJSON()))
      return this
    }
  })
})()
