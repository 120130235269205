/* globals zc _ Backbone app */

(function () {
  'use strict'

  zc.views.PostproductionTrackGroupView = zc.views.BaseView.extend({
    initialize: function (opts) {
      this.name = opts.name
      this.userId = opts.userId
      this.model = this.model || new Backbone.Model()
      this.model.set({
        isSoundboardTrack: (this.model.get('name') || '').toLowerCase() === 'soundboard'
      })

      this.listenTo(this.model, 'change:selected', this.renderGroupSelectedState)
      this.listenTo(this.model.tracks, 'change:selected', this.childSelectionChange)
      this.listenTo(this.model, 'change:empty', this.emptyChange)
      // Create a space for trackViews to live
      this.trackViews = []
    },

    template: _.template($('.postproduction-track-group-template').html()),

    className: 'postproduction-track-group',

    events: {
      'change input.soundboard-enabled': 'toggleGroupSelected',
      'click .track-label': 'toggleGroupExpanded',
      'click .tracks-header': 'renderTracksCollapsed',
      'click .mp3-radio': 'selectMp3Format',
      'click .wav-radio': 'selectWavFormat'
    },

    /*
     * Group Selection handlers
     */
    toggleGroupSelected: function (e) {
      var MAX_TRACKGROUPS_PER_POSTPROD = 6  // This limit applies only to vidoes and doesnt include the soundboard
      var isAudioOnlyRecording = app.project.recorder.recording.get('videoRecordingMode') !== 'enabled'  // true both when 'disabled' and 'isAudioOnly'

      var $maxTracks = $('#max-track-groups')
      if ($maxTracks.hasClass('pulseAnimation')) { // Take off possible error animation class from previous toggle attempt
        $maxTracks.removeClass('pulseAnimation')
      }

      if (!this.model.get('isSoundboardTrack') && !isAudioOnlyRecording) { // Dont count soundboard and audio recordings for max check
        if (e.currentTarget.checked) {
          var howManySelectedSoFar = this.model.collection.models.reduce(
            function (acc, trackGroupModel) {
              if (trackGroupModel.get('selected') && !trackGroupModel.get('isSoundboardTrack')) return acc + 1
              else return acc
            }, 0
          )

          if (howManySelectedSoFar >= MAX_TRACKGROUPS_PER_POSTPROD) {
            $maxTracks.addClass('pulseAnimation')
            e.currentTarget.checked = false
            return
          }
        }
      }

      this.model.set({selected: e.currentTarget.checked})
    },

    renderGroupSelectedState: function () {
      var selected = this.model.get('selected')
      this.$checkbox[0].checked = selected
      if (selected) {
        this.renderGroupSelected()
      } else {
        this.renderGroupUnselected()
      }
    },

    renderGroupSelected: function () {
      this.$el.removeClass('unselected')
      this.$el.addClass('selected')

      this.selectDefaults()
    },

    renderGroupUnselected: function () {
      this.$el.removeClass('selected')
      this.$el.addClass('unselected')

      // Deselect all tracks when the group is deselected
      this.model.tracks.forEach(function (track) {
        track.set('selected', false)
      })
      this.cleanLabelClasses()
      this.renderTracksCollapsed()
      this.updateTracksText()
    },

    /*
     * Track rendering handlers
     */
    renderTracks: function () {
      // If we have previously rendered tracks
      if (this.trackViews.length) {
        // Remove all track view render fragments
        this.$tracks.empty()
        // Delete references to old trackViews
        this.trackViews = []
      }
      var self = this
      // Group Tracks
      var tracksByFormat = this.getGroupedTracks()
      for (var format in tracksByFormat) {
        if (!this.formatContainers.hasOwnProperty(format)) {
          // Do not throw an error to continue rendering as expected
          console.warn('Unexpected format found in postproduction modal!', format)
          return
        }
        this.formatContainers[format].empty()

        tracksByFormat[format].forEach(function (track) {
          var newView = new zc.views.PostproductionTrackView({model: track})
          self.trackViews.push(newView)
          newView.render()
          self.formatContainers[format].append(newView.$el)
        })
      }
    },

    toggleGroupExpanded: function () {
      if (this.model.get('empty')) {
        return
      }
      if (this.$trackContainer.hasClass('expanded')) {
        this.renderTracksCollapsed()
      } else {
        this.renderTracksExpanded()
      }
    },

    renderTracksExpanded: function () {
      if (this.model.get('selected')) {
        this.$trackContainer.addClass('expanded')
        this.model.trigger('miniModalOpen')
      }
    },

    renderTracksCollapsed: function () {
      this.$trackContainer.removeClass('expanded')
      this.model.trigger('miniModalClose', this.$trackContainer)
    },

    /*
     * Track format handlers
     */

    selectMp3Format: function () {
      this.radioButtons.wav.removeClass('selected')
      this.radioButtons.mp3.addClass('selected')

      this.formatContainers.wav.hide()
      this.formatContainers.mp3.removeAttr('style')
      this.deselectAllInFormat('wav')
      this.selectLongestTrackInFormat('mp3')

      this.model.trigger('formatChange')
    },

    selectWavFormat: function () {
      this.radioButtons.mp3.removeClass('selected')
      this.radioButtons.wav.addClass('selected')

      this.formatContainers.mp3.hide()
      this.formatContainers.wav.removeAttr('style')
      this.deselectAllInFormat('mp3')
      this.selectLongestTrackInFormat('wav')

      this.model.trigger('formatChange')
    },

    /*
     * Label handlers
     */
    cleanLabelClasses: function () {
      this.$trackLabel.removeClass('ok')
      this.$trackLabel.removeClass('error')
    },

    updateTracksText: function () {
      var tracksByFormat = this.getGroupedTracks()
      if (
        !tracksByFormat['mov'] &&
        !tracksByFormat['mp3'] &&
        !tracksByFormat['wav']
      ) {
        // There are no valid tracks currently available
        this.cleanLabelClasses()
        this.$trackLabel.text('No tracks available!')
        this.$trackLabel.addClass('error')
        this.model.set('empty', true)
        return
      } else if (this.model.get('empty')) {
        this.model.set('empty', false)
      }

      if (!this.model.get('selected')) {
        this.cleanLabelClasses()
        this.$trackLabel.text('No tracks selected.')
        return
      }

      // Identify number of selected tracks for each format
      var formatTexts = []
      for (var format in tracksByFormat) {
        // Count selected tracks
        var count = tracksByFormat[format].filter(function (track) {
          return track.get('selected')
        }).length
        // Generate string
        if (count) {
          formatTexts.push(count + ' ' + format + (count > 1 ? 's' : ''))
        }
      }
      if (formatTexts.length) {
        this.$trackLabel.removeClass('error')
        this.$trackLabel.addClass('ok')
        this.$trackLabel.text(formatTexts.join(', '))
      } else {
        this.$trackLabel.removeClass('ok')
        this.$trackLabel.addClass('error')
        this.$trackLabel.text('No tracks selected.')
      }
    },

    /*
     * General Logic
     */
    selectDefaults: function () {
      var tracksByFormat = this.getGroupedTracks()

      // If we have wav tracks
      if (tracksByFormat.hasOwnProperty('wav')) {
        this.selectWavFormat()
      } else {
        // Assume that we have at least an mp3 track
        this.selectMp3Format()
      }

      if (tracksByFormat.hasOwnProperty('mov')) {
        this.selectLongestTrackInFormat('mov')
      }
      this.updateTracksText()
    },

    selectLongestTrackInFormat: function (format) {
      var tracksByFormat = this.getGroupedTracks()
      // mov is untrustworthy and should be ignored
      // instead listen to the ever reliable and honest mp3 format
      if (format === 'mov' && tracksByFormat['mp3']) {
        var longestMp3 = _.max(tracksByFormat['mp3'], function (x) {
          return x.getDuration()
        })
        var index = tracksByFormat['mp3'].indexOf(longestMp3)
        if (tracksByFormat[format].length > index) {
          tracksByFormat[format][index].set('selected', true)
        } else {
          console.warn('There are more MP3 files than MOVs and then longest MP3 file falls outside the list of MOV files')
        }
      } else {
        if (tracksByFormat.hasOwnProperty(format)) {
          // Lodash max duration, select max result
          _.max(tracksByFormat[format], function (x) {
            return x.getDuration()
          }).set('selected', true)
        } else {
          console.warn('Attempted to select longest track in non-existent format!', format)
        }
      }
    },

    deselectAllInFormat: function (format) {
      var tracksByFormat = this.getGroupedTracks()
      if (tracksByFormat.hasOwnProperty(format)) {
        tracksByFormat[format].forEach(function (track) {
          track.set('selected', false)
        })
      } else {
        console.warn('Attempted to deselect tracks in non-existent format!', format)
      }
    },

    getGroupedTracks: function () {
      var activeTracks = this.model.tracks.models.filter(function (track) {
        return !track.get('error')
      })
      return _.groupBy(activeTracks, function (track) {
        return track.get('format')
      })
    },

    childSelectionChange: function (track, selected) {
      // Only react when selected it true to prevent infinite looping
      if (selected) {
        var tracksByFormat = this.getGroupedTracks()
        var format = track.get('format')

        if (format && tracksByFormat.hasOwnProperty(format)) {
          tracksByFormat[format].forEach(function (t) {
            if (t !== track) {
              t.set('selected', false)
            }
          })
        } else {
          console.warn('Recieved a selected change event from an invalid format!', format)
        }
        this.updateTracksText()
      }
    },

    emptyChange: function (model, empty) {
      if (empty) {
        this.$checkboxContainer.addClass('disabled')
        this.$checkbox.attr('disabled', true)
        this.model.set('selected', false)
      } else {
        this.$checkboxContainer.removeClass('disabled')
        this.$checkbox.removeAttr('disabled')
      }
    },

    render: function () {
      this._render()
      this.$tracks = this.$('.tracks')
      this.$el.addClass('square')
      this.$trackLabel = this.$('.track-label')
      this.$checkboxContainer = this.$('.checkbox')
      this.$checkbox = this.$('.checkbox input[type=checkbox]')
      this.$trackContainer = this.$('.track-container')
      this.formatContainers = {
        mp3: this.$('.mp3-container'),
        mov: this.$('.mov-container'),
        wav: this.$('.wav-container')
      }
      this.$videoContainer = this.$('.video')
      this.$audioContainer = this.$('.audio')

      this.formatContainers.mp3.hide()
      this.formatContainers.wav.hide()

      this.radioButtons = {
        mp3: this.$('.mp3-radio'),
        wav: this.$('.wav-radio')
      }

      var tracksByFormat = this.getGroupedTracks()

      // Reset mp3 container
      this.radioButtons['mp3'].removeAttr('style')
      this.radioButtons['mp3'].removeClass('solo')

      // Reset wav container
      this.radioButtons['wav'].removeAttr('style')
      this.radioButtons['wav'].removeClass('solo')

      if (!tracksByFormat.hasOwnProperty('wav')) {
        // Hide wav selection
        this.radioButtons['wav'].hide()
        // Hide mp3 radio button
        this.radioButtons['mp3'].addClass('solo')
      } else if (!tracksByFormat.hasOwnProperty('mp3')) {
        // Hide mp3 selection
        this.radioButtons['mp3'].hide()
        // Hide wav radio button
        this.radioButtons['wav'].addClass('solo')
      }

      if (!tracksByFormat.hasOwnProperty('mov')) {
        this.$videoContainer.hide()
      } else {
        this.$videoContainer.removeAttr('style')
      }

      this.updateTracksText()
      this.renderGroupSelectedState()
      this.renderTracks()
      return this
    }
  })
})()
