/* globals $ zc _ debug app Backbone utils analytics optimizelyClientInstance */
(function () {
  'use strict'

  var dbg = debug('zc:trackView')

  /**
   * Used to render the box view for each recording file or postproduction
   * model: zc.models.Track
   */
  zc.views.TrackView = Backbone.View.extend({
    initialize: function (options) {
      if (options.postproduction) {
        this.template = _.template($('.postproduction-track-pill-template').html())
      }

      this.isPostproduction = Boolean(options.postproduction)
      this.onTrackClickExternal = options.onTrackClicked
      this.onUploadingFinished = options.onUploadingFinished

      this.listenTo(this.model, 'change:downloading', this.downloadingChange)
      // when the track has finished uploading just rerender
      this.listenTo(this.model, 'change:progress', this.progressChange)
      this.listenTo(this.model, 'change:uploading', this.render)
      this.listenTo(this.model, 'change:finalized', this.render)
      this.listenTo(this.model, 'change:processing', this.render)
      this.listenTo(this.model, 'change:error', this.render)
    },

    template: _.template($('.track-template').html()),

    className: 'track',

    events: {
      'click ': 'onTrackClick',
      'click .finalize': 'forceFinalize',
      'click .download-backup': 'downloadLocalBackup',
      'click .track-format': 'toggleSelected'
    },

    onTrackClick: function (e) {
      if (this.onTrackClickExternal) {
        this.onTrackClickExternal(this)
      }

      // only a finalized track should be allowed to download
      // also, don't allow double downloads
      if (!this.model.get('finalized') || this.model.get('downloading')) return

      console.log('Clicked to download the track:', this.model.get('format'))

      analytics.track('UserDownloadedFile', {
        fileType: this.model.get('format'),
        userId: app.user.id,
        fileSize: this.model.get('size'),
        recordingId: app.project.recorder.recording.id
      })

      this.model.downloadFile()
    },

    recoverLocalBackup: function () {
      var self = this
      var path = this.model.get('path')
      return function () {
        self.model.downloadFromLocal(path)
          .catch(function () {
            utils.notify('alert', 'No local backup could be found for this track.', { ttl: 3000 })
          })
      }
    },

    /**
     * method used to update the track UI when a file is processing before upload
     * @param  {Track}    model       The Track model
     * @param  {Boolean}  processing  Processing status
     */
    processingChange: function (model, processing) {
      dbg(this.model.get('format'), this.model.id, ' processingChange ', processing)
      if (processing) {
        this.showProcessing()
      } else {
        this.hideProcessing()
      }
    },

    showProcessing: function () {
      this.hideUploading()
      this.hideError()
      this.hideFinalized()
      this.$el.addClass('processing')
    },

    hideProcessing: function () {
      this.$el.removeClass('processing')
    },

    /**
     * method used to update the track UI when a file is uploading
     * @param  {Track}    model       The Track model
     * @param  {Boolean}  uploading   Processing status
     */
    uploadingChange: function (model, uploading) {
      dbg('%s:%s:uploadingChange:%s', this.model.get('format'), this.model.id, uploading)
      if (uploading) {
        this.showUploading()
      } else {
        this.updateLoadingDone()
        this.hideUploading()
      }
    },

    showUploading: function () {
      // to make sure these states don't overlap
      this.hideError()
      this.hideFinalized()
      this.hideProcessing()
      this.$el.addClass('uploading')
    },

    hideUploading: function () {
      this.$el.removeClass('uploading')
    },

    toggleSelected: function () {
      var selected = !this.model.get('selected')
      this.model.set({ selected: selected })

      // don't use normal event listeners so we don't change the state other track views
      if (selected) {
        this.select()
      } else {
        this.unselect()
      }
    },

    select: function () {
      this.$el.removeClass('unselected')
      this.$el.addClass('selected')
    },

    unselect: function () {
      this.$el.removeClass('selected')
      this.$el.addClass('unselected')
    },

    downloadingChange: function (model, downloading) {
      dbg('%s:%s:downloadingChange:%s', this.model.get('format'), this.model.id, downloading)
      if (downloading) {
        this.showDownloading()
      } else {
        this.hideDownloading()
        this.model.set({ progress: 0 })
      }
    },

    showDownloading: function () {
      this.$el.addClass('downloading')
    },

    hideDownloading: function () {
      this.$el.removeClass('downloading')
    },

    updateLoadingDone: function () {
      if (this.onUploadingFinished) {
        this.onUploadingFinished(this.model)
      }
    },

    showFinalized: function () {
      this.hideProcessing()
      this.hideUploading()
      this.hideError()
      this.updateLoadingDone()
      this.$el.addClass('finalized')
    },

    hideFinalized: function () {
      this.$el.removeClass('finalized')
    },

    forceFinalize: function () {
      console.log('Clicked to force finalize the track:', this.model.get('format'))
      var self = this

      function finalizeTrack () {
        analytics.track('ForceFinalize', {
          userId: app.user.id,
          trackId: self.model.id,
          trackFormat: self.model.get('format'),
          trackType: self.model.get('type'),
          recordingId: self.model.get('recordingId')
        })
        self.model.forceFinalizeUpload()
        self.$el.addClass('fixing-error')
        self.$errorMessage.text('Finalizing...')

        if (window.optimizelyClientInstance) {
          optimizelyClientInstance.track('ForceFinalizedFile', app.user.id)
        }
      }

      // If user is host, prompt them for tracks that are not theirs or soundboard to ensure they do not accidentally finalize
      //    tracks that are not yet finished uploading.
      if (app.user.isHost() && this.model.get('type') !== 'soundboard' && this.model.get('userId') !== app.user.id) {
        let confirmView = new zc.views.ModalView({
          model: new Backbone.Model({
            title: 'Are you sure?',
            text: '<p>⚠️ It is recommended that the guest returns to the page and clicks "Retry upload" on this track first. Finalizing for the guest now will make content that has been uploaded at this point downloadable, but that may result in partial content. Proceed?</p>',
            confirmText: 'Proceed',
            cancelText: 'Nevermind'
          }),
          force: true,
          ChildView: zc.views.ConfirmView,
          callback: function (confirmed) {
            confirmView.exit()
            if (!confirmed) return
            finalizeTrack()
          }
        })
        confirmView.render()
      } else {
        finalizeTrack()
      }
    },

    downloadLocalBackup: function () {
      console.log('Clicked to download local backup')
      analytics.track('DownloadLocalBackup', {
        userId: app.user.id,
        trackId: this.model.id,
        trackFormat: this.model.get('format'),
        trackType: this.model.get('type'),
        recordingId: this.model.get('recordingId')
      })

      this.model.downloadFromLocal()
    },

    /**
     * method used to update the progress when a file is uploading
     * @param  {Track} model    The Track model
     * @param  {Number} progress The progress
     */
    progressChange: function (model, progressPercent) {
      var progressPercentString = progressPercent + '%'
      dbg(this.model.get('format'), this.model.id, 'progressChange', progressPercent)
      this.$progressBar.css({ width: progressPercentString })
      this.$progressPercent.text(progressPercentString + ' Uploaded')
    },

    showError: function () {
      this.hideFinalized()
      this.$el.removeClass('fixing-error')
      // Check if track is postproduction or recorded track
      if (this.model.get('productionId')) {
        this.$errorMessage.text('Error: Failed Postproduction')
      } else {
        this.$errorMessage.text('Error: Unfinalized track')
      }
      this.$el.addClass('has-error')
    },

    hideError: function () {
      this.$el.removeClass('show-retry')
      this.$el.removeClass('has-error')
    },

    render: function () {
      var force = (this.model.get('userId') !== app.user.id) && app.user.isHost()
      this.$el.html(this.template(Object.assign({force: force}, this.model.attrs())))
      this.$progressBar = this.$('.progress-bar')
      this.$status = this.$('.status')
      this.$progressPercent = this.$('.progress-percent')
      this.$errorMessage = this.$('.error-message')

      if (this.isPostproduction) {
        this.$el.addClass('postproduction-track')
      }

      // add format class
      this.$el.addClass(this.model.get('format'))
      // add a special css class to distinguish the soundboard track
      this.$el.addClass(this.model.get('type') + '-track')

      if (this.model.get('finalized')) {
        this.showFinalized()
      } else if (this.model.get('uploading')) {
        this.showUploading()
      } else if (this.model.get('processing')) {
        this.showProcessing()
      } else if (this.model.get('error')) {
        this.showError()
      } else {
        // TODO: error should be the default state so it should never reach this point
        this.hideError()
      }

      // handle selectable states
      if (this.model.get('selectable')) {
        this.$el.addClass('selectable')
        if (this.model.get('selected')) {
          this.$el.addClass('selected')
        } else {
          this.$el.addClass('unselected')
        }
      }

      return this
    }
  })
})()
