/* globals zc _ Backbone app utils Siema */

(function () {
  var layoutData = {
    'horizontal': {
      layout: 'horizontal',
      description: 'Side-by-side videos with removal of left and right spacing. Provides a balanced look.',
      maxParticipants: 6,
      minParticipants: 1
    },
    'side-by-side': {
      layout: 'side-by-side',
      description: 'Side-by-side video with no cropping to the original video',
      maxParticipants: 6,
      minParticipants: 1
    },
    'vertical': {
      layout: 'vertical',
      description: 'Places videos in a vertical orientation. Perfect for social media.',
      maxParticipants: 6,
      minParticipants: 1
    },
    'zen': {
      layout: 'zen',
      description: 'Places the active speaker in the full screen with other participants in smaller screens.',
      maxParticipants: 3,
      minParticipants: 2
    }
  }

  zc.views.PostproductionConfigView = Backbone.View.extend({
    initialize: function (options) {
      this.callback = options.callback
      this.tracks = this.model.tracks
      this.trackGroups = new Backbone.Collection()

      this.listenTo(this.model, 'change:loudnessTarget', this.loudnessTargetChange)
      this.listenTo(this.model, 'change:leveler', this.levelerChange)
      this.listenTo(this.model, 'change:gate', this.noiseGateChange)
      this.listenTo(this.model, 'change:crossGate', this.crossGateChange)
      this.listenTo(app.user.stats, 'change:usedCredits', this.renderPostproductionTime)
      this.listenTo(app.user, 'change:onetimePostproductionCredits', this.renderPostproductionTime)
      this.listenTo(this.trackGroups, 'change:selected', this.trackGroupSelectedChange)
      this.listenTo(this.trackGroups, 'miniModalOpen', this.openMiniModal)
      this.listenTo(this.trackGroups, 'miniModalClose', this.closeMiniModal)
      this.listenTo(this.trackGroups, 'formatChange', this.trackFormatChange)
      this.msPerCredit = 3600000 // 1 hour
      this.pricePerCredit = 300

      this.longPauseThreshold = null
      this.longPauseReplace = null

      this.trackViews = {}     // storage for trackviews so we can access them
    },

    template: _.template($('.postproduction-config-template').html()),

    className: 'postproduction-config',

    events: {
      'click': 'checkForExit',
      'click .close': 'exit',
      'click section h3': 'toggleAccordian',
      'click .produce': 'toggleModalState',
      'click .help': 'toggleModalState',
      'change #standard-settings': 'toggleAdvancedOptions',
      'change #advanced-settings': 'toggleAdvancedOptions',
      'change #loudness-target-input': 'loudnessTargetInputChange',
      'change #leveler-input': 'levelerInputChange',
      'change #noise-gate-input': 'noiseGateInputChange',
      'change #cross-gate-input': 'crossGateInputChange',
      'change #separate-tracks-input': 'separateTracksInputChange',
      'change #transcript-input': 'transcriptInputChange',
      'change #transcript-language': 'transcriptInputLanguageChange',
      'click .buy-more-time': 'renderBuyPostproductionCreditsModal',
      'click .run-postproduction': 'clickRunPostproduction',
      'click .pp-layout': 'handleLayoutClick',
      'keypress #threshold-input': 'stopKeyPress',
      'keypress #replace-input': 'stopKeyPress',
      'input #threshold-input': 'thresholdInputChange',
      'input #replace-input': 'replaceInputChange',
      'input #long-pause-remove-on': 'longPauseOn',
      'input #long-pause-remove-off': 'longPauseOff'
    },

    openMiniModal: function () {
      this.$groupScrollContainer.css({ 'overflow-y': 'hidden' })
      // Scroll hack to prevent even hackier positioning code
      this.oldScrollHeight = this.$groupScrollContainer.scrollTop()
      this.$groupScrollContainer.scrollTop(0)
    },

    closeMiniModal: function () {
      this.$groupScrollContainer.css({ 'overflow-y': 'auto' })
      this.$groupScrollContainer.scrollTop(this.oldScrollHeight)
      delete this.oldScrollHeight
    },

    trackFormatChange: function () {
      var mp3Selected = this.trackGroups.filter({ selected: true }).some(function (trackGroup) {
        return trackGroup.tracks.models.some(function (track) {
          return track.get('format') === 'mp3' && track.get('selected') === true
        })
      })

      if (mp3Selected) {
        this.$separateTracksOptions.hide()
      } else {
        this.$separateTracksOptions.show()
      }
    },

    toggleAccordian: function (e) {
      var $section = $(e.currentTarget).parents('section')
      if ($section.hasClass('closed')) {
        $section.removeClass('closed')
      } else {
        $section.addClass('closed')
      }
    },

    toggleModalState: function (e) {
      if (e) e.preventDefault()
      var introEl = this.$el.find('.intro')
      var buildEl = this.$el.find('.build')
      if (introEl.hasClass('active')) {
        introEl.removeClass('active')
        buildEl.addClass('active')
        if (this.interval) {
          clearInterval(this.interval)
          this.interval = undefined
        }
      } else {
        buildEl.removeClass('active')
        introEl.addClass('active')
        this.startCarousel()
      }
      this.setUserHasSeenHelp(true)
    },

    toggleAdvancedOptions: function (e) {
      var showStandardSettings = e.currentTarget.value === 'standard'
      var self = this
      if (showStandardSettings) {
        this.$advancedOptions.removeClass('expanded')
        this.$options.hide()
      } else {
        this.$advancedOptions.addClass('expanded')
        self.$options.css({ opacity: 0, display: 'flex' })
        this.$options.animate({ opacity: 1 }, 300)
      }
    },

    transcriptInputChange: function (e) {
      this.model.set('transcript', e.target.checked)
      // set default value for the language
      this.model.set('transcriptLanguage', 'en')

      // this.$languageContainer.slideToggle()
      this.renderPostproductionTime()
    },

    transcriptInputLanguageChange: function (e) {
      this.model.set('transcriptLanguage', e.target.value)
    },

    loudnessTargetInputChange: function (e) {
      this.model.set('loudnessTarget', e.target.value)
    },

    loudnessTargetChange: function (model, loudnessTarget) {
      var $selected = this.$loudnessTargetInput.find(':selected')
      if ($selected.val() !== loudnessTarget) {
        $selected.attr('selected', false)
        this.$loudnessTargetInput.find('option[value=' + loudnessTarget + ']').attr('selected', true)
      }
    },

    levelerInputChange: function (e) {
      this.model.set('leveler', !!e.target.checked)
    },

    levelerChange: function (model, leveler) {
      this.$levelerInput.prop('checked', leveler)
    },

    noiseGateInputChange: function (e) {
      this.model.set('gate', !!e.target.checked)
    },

    noiseGateChange: function (model, gate) {
      this.$noiseGateInput.prop('checked', gate)
    },

    crossGateInputChange: function (e) {
      this.model.set('crossGate', !!e.target.checked)
    },

    separateTracksInputChange: function (e) {
      this.model.set('separateTracks', !!e.target.checked)
    },

    crossGateChange: function (model, crossGate) {
      this.$crossGateInput.prop('checked', crossGate)
    },

    getNeededPostproductionTime: function () {
      var threeMinutes = 60 * 1000 * 3 // 3 minute minimum
      var selectedTracks = this.tracks.where({ selected: true })
      var minMinutes = selectedTracks.length ? threeMinutes : 0

      var neededTime = selectedTracks
        .map(function (track) {
          return track.getDuration()
        }).reduce(function (a, b) {
          return Math.max(a, b)
        }, 0)

      // if the user wants to run transcription
      if (this.model.get('transcript')) {
        neededTime += selectedTracks
          .map(function (track) {
            return track.getDuration()
          }).reduce(function (a, b) {
            return a + b
          }, 0)
      }

      neededTime = Math.max(neededTime, minMinutes)

      return neededTime
    },

    getNeededPostproductionCredits: function () {
      return this.getNeededPostproductionTime() / this.msPerCredit
    },

    trackGroupSelectedChange: function () {
      this.renderPostproductionTime()

      var totalSelected = this.trackGroups.models.filter(function (trackGroup) {
        return trackGroup.get('selected') && trackGroup.get('name') !== 'soundboard'
      }).length

      var needsLayoutChange = false

      this.$('.pp-layout').each(function (index, layoutEl) {
        var layoutSpec = layoutData[layoutEl.id]
        if (!layoutSpec) throw new Error('Missing specification for selected layout!')
        var layoutMax = Number(layoutSpec.maxParticipants || '0')
        var layoutMin = Number(layoutSpec.minParticipants || '0')
        try {
          // We need to ignore changes until we are finished hydrating component state.
          // Mid-Hydration state is full of lies.
          if (this.hasRendered) {
            // Post-Render Logic
            if ((layoutMax < totalSelected) ||
              (layoutMin > totalSelected)) {
              layoutEl.setAttribute('disabled', '')
              if (layoutEl.classList.contains('active')) {
                layoutEl.classList.remove('active')
                needsLayoutChange = true
              }
            } else {
              layoutEl.removeAttribute('disabled')
            }
          }
        } catch (e) {
          // Ignore this error, just means max
          console.warn('Error encountered while attempting to validate participant count to what is allowed for layouts', e)
        }
      }.bind(this))

      if (needsLayoutChange) {
        var options = this.$('.pp-layout:not([disabled])').first()
        if (!options.length) {
          this.selectedLayout = '' // Prevent sending completely invalid data to the backend
          throw new Error('Missing a valid layout!')
        }
        this.selectLayout(options[0])
      }
    },

    selectLongestTrackInEachGroup: function (trackGroups) {
      // TEMPORARY: we are now only selecting the longest track in each group,
      // until we get stitching feature in postproduction

      for (var i = 0; i < trackGroups.length; i++) {
        // Group by format
        var groupsByFormat = _.groupBy(trackGroups[i].tracks.models, function (track) { return track.get('format') })

        // Eval longest
        for (var format in groupsByFormat) {
          // Do not select both mp3 and wav, that's not allowed
          // Default to selecting only wav when both are present
          if (format === 'mp3' && groupsByFormat.hasOwnProperty('wav')) {
            continue
          }
          // Lodash max duration, select max result
          _.max(groupsByFormat[format], function (x) {
            // use getDuration instead of get('duration') because pre-refresh tracks will lie
            return x.getDuration()
          }).set('selected', true)
        }
      }
    },

    unselectAllTracks: function () {
      this.tracks.forEach(function (track) {
        track.set({ selected: false })
      })
    },

    clickRunPostproduction: function () {
      if (!this.validatePostproduction()) { return }

      if (this.price) {
        this.renderConfirmPurchase()
      } else {
        this.runCallBackForNoPrice()
      }
    },

    runCallBackForNoPrice () {
      var selectedTrackGroups = this.trackGroups.where({ selected: true })
      var neededCredits = this.getNeededPostproductionCredits()

      var args = {
        selectedTrackGroups: selectedTrackGroups,
        credits: neededCredits,
        layout: this.selectedLayout,
        longPauseThreshold: this.longPauseThreshold,
        longPauseReplace: this.longPauseReplace,
        centeredCrop: !this.$('input[name=auto-face-crop]').prop('checked'), // centered crop is when the checkbox is not checked
        skipOverlayonZen: this.$('input[name=skip-overlay-on-zen]').prop('checked')
      }
      this.callback(args)
    },

    validatePostproduction: function () {
      var selectedTrackGroups = this.trackGroups.where({ selected: true })
      var error = false
      var neededCredits = this.getNeededPostproductionCredits()
      var availableCredits = app.user.getAvailablePostproductionCredits()

      if (!selectedTrackGroups.length) {
        error = 'You must select at least one track'
      } else if (neededCredits > availableCredits) {
        error = 'You don\'t have sufficient postproduction time available'
      } else if (!this.selectedLayout && !this.model.get('separateTracks') && this.model.get('videoRecordingMode') === 'enabled') {
        error = 'You must select a video layout'
      }

      if (error) { this.showError(error) }

      return !error
    },

    showError: function (err) {
      this.$error.text(err)
    },

    buyPostproductionCreditsCallback: function (err, res) {
      if (err) return utils.notify('error', err)
      var purchasedCredits = res.purchasedCredits
      app.user.incPostproductionCredits(purchasedCredits)
      this.buyPostproductionCreditsModalView.exit()
    },

    calcPurchasePrice: function (timeToPurchase) {
      var creditsToPurchase = timeToPurchase / this.msPerCredit
      var price = Math.round(creditsToPurchase * this.pricePerCredit)
      return price
    },

    purchaseCredits: function (timeToPurchase) {
      var self = this

      var numCredits = timeToPurchase / 1000 / 60 / 60

      // minimum of 1 credit
      // var creditsToPurchase = Math.max(numCredits, 1)

      app.user.billing.purchasePostproductionCredits(numCredits)
        .then(function (charge) {
          var selectedTracks = self.tracks.where({ selected: true })
          self.callback({ selectedTracks: selectedTracks, credits: numCredits })
        })
    },

    confirmPurchaseCallback: function (confirmed) {
      this.confirmPurchaseModalView.exit()
      if (confirmed) {
        this.purchaseCredits(this.timeToPurchase)
      } else {
        console.log('Not Confirmed')
      }
    },

    renderConfirmPurchase: function () {
      var price = this.price
      var title = 'Confirm Purchase'
      var text = 'Please confirm that you would like to make a ' +
        '<strong>one-time</strong> payment of ' +
        '<strong>$' + Number(price / 100).toFixed(2) + '</strong>'

      this.confirmPurchaseModalView = new zc.views.ModalView({
        className: 'modal confirm-purchase-modal',
        model: new Backbone.Model({ title: title, text: text }),
        ChildView: zc.views.ConfirmView,
        callback: this.confirmPurchaseCallback.bind(this)
      })

      this.confirmPurchaseModalView.render()
    },

    renderBuyPostproductionCreditsModal: function () {
      this.buyPostproductionCreditsModalView = new zc.views.ModalView({
        addClass: 'buy-postproduction-credits-modal',
        model: app.user.billing,
        ChildView: zc.views.BuyPostproductionCreditsView,
        callback: this.buyPostproductionCreditsCallback.bind(this)
      })
      this.buyPostproductionCreditsModalView.render()
    },

    renderPostproductionTime: function () {
      var totalCredits = app.user.getTotalPostproductionCredits()
      var availableCredits = app.user.getAvailablePostproductionCredits()
      var totalTime = totalCredits * this.msPerCredit
      var availableTime = availableCredits * this.msPerCredit
      var neededTime = this.getNeededPostproductionTime()
      // neededTime = 60 * 60 * 1000 * 4.11;

      if (neededTime > availableTime) {
        this.$el.addClass('out-of-time')
        this.timeToPurchase = Math.max(neededTime - availableTime, 60 * 60 * 1000) // min of 1 hr
        this.timeToPurchase = neededTime - availableTime
        this.price = this.calcPurchasePrice(this.timeToPurchase)
        // this.price = Math.max(this.price, 99);
        this.renderPaidPostproduction()
      } else {
        this.$el.removeClass('out-of-time')
        this.timeToPurchase = 0
        this.price = 0
      }

      var highTime = totalTime + neededTime

      var availableWidth = Math.min((availableTime / highTime) * 100, 100)
      this.$neededTimeLabel.html(utils.msToHms(availableTime) + ' Available &nbsp;&#8226;&nbsp; ' + utils.msToHms(neededTime) + ' Needed')
      this.$availableTimeHms.css({ width: '' + availableWidth + '%' })

      var neededWidth = Math.min(highTime * 100, 100)
      // this.$neededTimeHms.html(utils.msToHms(neededTime))
      this.$neededTimeHms.css({ width: '' + neededWidth + '%' })
    },

    renderPaidPostproduction: function () {
      this.$runPaid.text('Run Postproduction For $' + Number(this.price / 100).toFixed(2))
    },

    renderTrackGroup: function (trackGroup, name, makeDefaultSelection, userId) {
      var trackGroupModel = new Backbone.Model({ name: name, selected: false, userId: userId })
      var trackCollection = new Backbone.Collection(trackGroup)
      trackGroupModel.tracks = trackCollection
      this.trackGroups.add(trackGroupModel)
      var trackGroupView = new zc.views.PostproductionTrackGroupView({ model: trackGroupModel })

      this.$trackGroups.append(trackGroupView.render().el)
      var hasValidTracks = trackGroupModel.tracks.length && trackGroupModel.tracks.reduce(function (c, v) {
        return !v.get('error') || c
      }, false)
      if (makeDefaultSelection && hasValidTracks) {
        // Do this after render to ensure listeners do their job correctly
        trackGroupModel.set({ selected: true })
      }
    },

    renderTracks: function () {
      var self = this
      var localUserId = app.user.id

      // group tracks by username
      var groupedTracks = _.groupBy(this.tracks.models, function (track) { return track.attributes.userId })

      // see if the host has a soundboard track
      var soundboardTrack = []
      var hostTracks = groupedTracks[localUserId]
      groupedTracks[localUserId] = hostTracks.filter(function (track) {
        if (track.get('type') === 'soundboard') {
          soundboardTrack.push(track)
          return false
        }
        return true
      })

      if (soundboardTrack.length) {
        self.renderTrackGroup(soundboardTrack, 'soundboard', true)
      }

      var selectedTracks = 0
      Object.entries(groupedTracks).forEach(function (trackPair) {
        // No tracks, skip
        if (!trackPair[1].length) return

        // User's id
        var userId = trackPair[0]

        var displayName = userId
        var user = app.project.lobby.users.find(function (user) {
          return user.id === userId
        })
        if (user) {
          displayName = user.get('displayName')
        } else {
          user = app.project.recorder.recording.participants.find(function (u) { return u.get('userId') === userId })
          displayName = user.get('username')
        }
        self.renderTrackGroup(groupedTracks[userId], displayName, userId === localUserId || selectedTracks++ < 3, userId)
      })

      if (Object.keys(groupedTracks).length < 3) {
        this.$groupScrollContainer.addClass('small')
      }
    },

    renderTrack: function (track) {
      var trackView = new zc.views.PostproductionTrackView({ model: track })
      this.trackViews[track.id] = trackView
      var $track = trackView.render().$el
      this.$tracks.append($track)
    },

    remove: function () {
      this.tracks.forEach(function (track) {
        track.set({ selectable: false, selected: false })
      })
    },

    startCarousel: function () {
      var self = this
      if (!this.carousel) {
        this.carousel = new Siema({
          selector: this.$carousel[0],
          perPage: 1,
          loop: true
        })
      }
      if (!this.interval) {
        this.interval = setInterval(function () {
          self.carousel.next()
          self.$indexes.removeClass('active').eq(self.carousel.currentSlide).addClass('active')
        }, 4000)
      }
    },

    setUserHasSeenHelp: function (val) {
      try {
        localStorage.setItem(app.user.id + '-seenPostProductionHelp', val)
      } catch (e) {
        console.warn(e)
      }
    },

    userHasSeenHelp: function () {
      try {
        return localStorage.getItem(app.user.id + '-seenPostProductionHelp') || false
      } catch (e) {
        console.warn(e)
      }
      return false
    },

    handleLayoutClick: function (e) {
      var target = e.currentTarget
      this.selectLayout(target)
    },

    selectLayout: function (target) {
      var layoutSpec = layoutData[target.id]
      var layout = layoutSpec.layout
      // Unset all other active, ensure all are active
      this.$el.find('.pp-layout').removeClass('active').addClass('inactive')
      // Set up our target
      target.classList.remove('inactive')
      target.classList.add('active')
      // update description
      var description = layoutSpec.description
      this.$el.find('.pp-layout-description').text(description)
      // Select the layout
      this.selectedLayout = layout

      var autoCropBox = this.$('input[name=auto-face-crop]')
      if (layout === 'side-by-side') {
        autoCropBox.attr('disabled', 'true')
        autoCropBox.prop('checked', false)
      } else {
        autoCropBox.removeAttr('disabled')
        autoCropBox.prop('checked', true)
      }
      var disableOverlayBox = this.$('input[name=skip-overlay-on-zen]')
      if (layout === 'zen') {
        disableOverlayBox.removeAttr('disabled')
        disableOverlayBox.prop('checked', true)
      } else {
        disableOverlayBox.attr('disabled', 'true')
        disableOverlayBox.prop('checked', false)
      }
    },

    stopKeyPress: function (ev) {
      ev.stopPropagation()
    },

    validateLongPause: function () {
      var hasError = false
      if (isNaN(this.longPauseReplace) || this.longPauseReplace < 0) {
        this.$longPauseReplaceInput.addClass('error')
        hasError = true
      }
      if (isNaN(this.longPauseThreshold) || this.longPauseThreshold < 0) {
        this.$longPauseThresholdInput.addClass('error')
        hasError = true
      }

      if (this.longPauseReplace > this.longPauseThreshold) {
        // Do things
        this.$longPauseThresholdInput.addClass('error')
        this.$longPauseReplaceInput.addClass('error')
        this.$longPauseError.text('"Pause threshold" cannot be less than "Shorten to"').show()
        hasError = true
      }
      if (hasError) {
        this.$run.attr('disabled', true)
        this.$run.addClass('disabled')
      } else {
        this.$longPauseThresholdInput.removeClass('error')
        this.$longPauseReplaceInput.removeClass('error')
        this.$longPauseError.hide()
        this.$run.removeAttr('disabled')
        this.$run.removeClass('disabled')
      }
    },

    thresholdInputChange: function (ev) {
      ev.target.value = ev.target.value.replaceAll(/[^\d.]/g, '').split('.').slice(0, 2).join('.')
      var val = parseFloat(ev.target.value)

      this.longPauseThreshold = val
      this.validateLongPause()
    },

    replaceInputChange: function (ev) {
      ev.target.value = ev.target.value.replaceAll(/[^\d.]/g, '').split('.').slice(0, 2).join('.')
      var val = parseFloat(ev.target.value)

      this.longPauseReplace = val
      this.validateLongPause()
    },

    longPauseOn: function () {
      this.longPauseThreshold = parseFloat(this.$longPauseThresholdInput.val())
      this.longPauseReplace = parseFloat(this.$longPauseReplaceInput.val())
      this.$longPauseThreshold.show()
      this.validateLongPause()
    },

    longPauseOff: function () {
      this.longPauseThreshold = null
      this.longPauseReplace = null
      this.$longPauseThreshold.hide()
      this.$longPauseThresholdInput.removeClass('error')
      this.$longPauseReplaceInput.removeClass('error')
      this.$longPauseError.hide()
      this.$run.removeAttr('disabled')
      this.$run.removeClass('disabled')
    },

    render: function () {
      var attrs = this.model.attrs()
      this.$el.html(this.template(attrs))
      console.log(this.$el)

      this.$trackGroups = this.$('.track-groups')
      this.$error = this.$('.error')
      this.$timeStats = this.$('.time-stats')
      this.$availableTimeHms = this.$('.available-time-hms')
      this.$availableTimeBar = this.$('.available-time .bar')
      this.$neededTimeHms = this.$('.needed-time-hms')
      this.$neededTimeBar = this.$('.needed-time .bar')
      this.$neededTimeLabel = this.$('.needed-time-label')

      this.$run = this.$('.run-postproduction')
      this.$runPaid = this.$('.run-paid-postproduction')

      this.$advancedOptions = this.$('.advanced-options')
      this.$options = this.$('.options')
      this.$loudnessTargetInput = this.$('#loudness-target-input')
      this.$levelerInput = this.$('#leveler-input')
      this.$noiseGateInput = this.$('#noise-gate-input')
      this.$crossGateInput = this.$('#cross-gate-input')
      this.$separateTracksOptions = this.$('.separate-tracks')
      this.$groupScrollContainer = this.$('.select-tracks .section-inner')

      // long pause removal
      this.$longPauseThreshold = this.$('.threshold')
      this.$longPauseThresholdInput = this.$('#threshold-input')
      this.$longPauseReplaceInput = this.$('#replace-input')
      this.$longPauseError = this.$('.error-container')

      app.user.stats.fetch()
      this.renderPostproductionTime()

      this.renderTracks()

      this.$carousel = this.$('.carousel')
      this.$indexes = this.$('.carousel-indexes i')
      var self = this

      var defaultLayout = this.$el.find('.pp-layout.active')
      if (defaultLayout.length) {
        this.selectLayout(defaultLayout[0])
      }

      if (this.userHasSeenHelp()) {
        this.toggleModalState()
      } else {
        setTimeout(function () {
          self.startCarousel()
        }, 1)
      }

      this.hasRendered = true
      // We only check the minimum selected vs total number of tracks until the render is complete
      // Now that we are done, we should call again to make sure we are obeying business logic
      this.trackGroupSelectedChange()
      return this
    }
  })
})()
