/* globals app zc zc2 _ analytics */

(function () {
  'use strict'

  zc.views.MediaDeviceSelectView = zc.views.DropdownSelectView.extend({
    initialize: function (options) {
      _.bindAll(this, 'muteMicrophone', 'toggleCamera')
      this.recorder = options.recorder
      // todo: don't read from global
      this.devices = options.devices
      this.onMediaDeviceChange = options.onMediaDeviceChange || function () { }
      this.setMediaType(options.mediaType)

      this.listenTo(this.recorder, 'change:micArmed', this.micArmedChange)

      this.reduxWatcher = new zc2.utils.ReduxWatcher(zc2.store)
      this.reduxWatcher.watch('media_manager.devices', this.populateDropdown.bind(this))

      this.reduxWatcher.watch('media_manager.preferredDevices.' + options.mediaType + 'put', this.handleExternalPreferredDeviceChange.bind(this))

      this._initialize(options)

      if (this.devices.deviceList) {
        this.populateDropdown()
      }
    },

    events: Object.assign({
      'click .onoff': 'toggleOnOff'
    }, zc.views.DropdownSelectView.prototype.events),

    setMediaType: function (mediaType) {
      this.mediaType = mediaType
      var icon
      switch (this.mediaType) {
        case 'audioIn':
          icon = 'ic-mic'
          this.mediaDeviceKind = 'audioinput'
          this.preferredDeviceKind = 'audioInput'
          this.defaultErrorTitle = 'No microphone found'
          break
        case 'audioOut':
          icon = 'ic-audio-output'
          this.mediaDeviceKind = 'audiooutput'
          this.preferredDeviceKind = 'audioOutput'
          this.defaultErrorTitle = 'No audio output found'
          break
        case 'videoIn':
          icon = 'ic-camera-active'
          this.mediaDeviceKind = 'videoinput'
          this.preferredDeviceKind = 'videoInput'
          this.defaultErrorTitle = 'No camera found'
      }

      this.createModel({
        iconClass: icon,
        selectState: 'loading',
        defaultErrorTitle: this.defaultErrorTitle
      })
    },

    handleExternalPreferredDeviceChange: function (newDevice) {
      if (typeof newDevice === 'string') {
        this.setSelected(newDevice)
      } else if (newDevice.hasOwnProperty('deviceId')) {
        this.setSelected(newDevice.deviceId)
      } else {
        throw new Error('Unexpected input condition found while updating preferredDevices')
      }
    },

    populateDropdown: function () {
      var mediaDeviceKind = this.mediaDeviceKind
      this.model.set({selectState: 'loading'})

      var devices = this.devices.deviceList.filter(function (mediaDevice) {
        return mediaDevice.kind === mediaDeviceKind
      })

      if (devices.length) {
        this.convertMediaDevices(devices)
      } else {
        this.model.set({
          selectState: 'error',
          items: []
        })
      }
    },

    checkStateAndReRender: function (mediaDevicesCurrent, mediaDevicesPrev) {
      var self = this

      // Get only the ids for the applicable devices
      function isApplicable (d) {
        return (d.kind || d.get('kind')) === self.mediaDeviceKind
      }

      var currentModels = mediaDevicesCurrent.filter(isApplicable)
      var previousModels = mediaDevicesPrev.previousModels.filter(isApplicable)

      // If there are not the same amount devices rerender
      // This will work unless somebody can plug in and remove a camera at *exactly* the same time
      if (currentModels.length !== previousModels.length) {
        this.populateDropdown()
      }
    },

    convertMediaDevices: async function (listedDevices) {
      var switchInitialState = false
      var preferredDeviceKind = this.preferredDeviceKind
      var preferred = this.devices.preferredDevices

      var items = listedDevices.map(function (deviceAttrs) {
        var preferredInput = preferred ? preferred[preferredDeviceKind] : null
        var item = {
          id: deviceAttrs.deviceId,
          title: deviceAttrs.label
        }
        // style system defaults
        if (deviceAttrs.deviceId.toLowerCase() === 'default') {
          item.subTitle = 'System default'
        }
        // preselect their preferred mic
        if (preferredInput && preferredInput.deviceId === deviceAttrs.deviceId) {
          item.selected = true
          // presetItem = item
        }
        return item
      })

      switch (this.mediaType) {
        case 'audioIn':
          switchInitialState = !app.user.get('muted')
          break
        case 'videoIn':
          switchInitialState = app.user.get('cameraOn')
          break
      }

      // triggers rerender
      this.model.set({
        items: items,
        switchInitialState: switchInitialState,
        selectState: 'success'
      })
    },

    // implemented in parent
    onItemSelect: async function (mediaItem) {
      var oldLabel = ''
      var preferredDevices = this.devices.preferredDevices

      switch (this.mediaType) {
        case 'audioIn':
          if (preferredDevices.audioInput) {
            oldLabel = preferredDevices.audioInput.label
          } else {
            oldLabel = this.devices.getDefaultDeviceByKind('audioinput').label
          }

          this.audioInputSelectChange(mediaItem)
          break
        case 'audioOut':
          if (preferredDevices.audioOutput) {
            oldLabel = preferredDevices.audioOutput.label
          } else {
            oldLabel = this.devices.getDefaultDeviceByKind('audiooutput').label
          }
          this.audioOutputSelectChange(mediaItem)
          break
        case 'videoIn':
          if (preferredDevices.videoInput) {
            oldLabel = preferredDevices.videoInput.label
          } else {
            oldLabel = this.devices.getDefaultDeviceByKind('videoinput').label
          }
          this.videoInputSelectChange(mediaItem)
          break
      }

      var newLabel = mediaItem.title
      if (oldLabel !== newLabel) {
        analytics.track(
          'SettingsChange',
          {
            settingName: this.mediaType,
            oldDeviceLabel: oldLabel,
            newDeviceLabel: newLabel,
            userId: app.user.id,
            recordingId: app.project.recorder.recording.id,
            projectId: app.project.id
          }
        )
      }
    },

    toggleOnOff: function (e) {
      // prevent checkox from being checked, because we are going set toggle itin the code
      e.preventDefault()
      e.stopPropagation()

      var checkBox = e.currentTarget.getElementsByTagName('input')[0]
      checkBox.checked = !checkBox.checked

      var isOn = checkBox.checked
      var mutedIcon
      switch (this.mediaType) {
        case 'audioIn':
          this.muteMicrophone(isOn)
          mutedIcon = 'ic-mic-off'
          break
        case 'videoIn':
          this.toggleCamera(isOn)
          mutedIcon = 'ic-camera-off'
          break
      }
      if (mutedIcon) {
        this.toggleInputStatus(isOn, mutedIcon)
      }
    },

    toggleInputStatus: function (isOn, mutedClass) {
      var iconClass = this.model.get('iconClass')
      if (isOn) {
        this.$el
          .removeClass('muted')
          .find('.active-icon').removeClass(mutedClass).addClass(iconClass)
      } else {
        this.$el
          .addClass('muted')
          .find('.active-icon').removeClass(iconClass).addClass(mutedClass)
      }
    },

    muteMicrophone: function (switchIsOn) {
      var muted = !switchIsOn
      var user = app.user
      user.set({muted: muted})
      if (user.isLocal()) {
        user.trigger('localUserMutedChange', user, muted)
      } else {
        this.model.trigger('remoteUserMutedChangedLocally', user, muted)
      }
    },

    toggleCamera: function (cameraOn) {
      app.user.set({cameraOn: cameraOn})
    },

    videoInputSelectChange: function (mediaItem) {
      var videoInput = this.devices.getDeviceById(mediaItem.id, 'videoinput')
      // if the input actually changed
      if (this.devices.preferredDevices.videoInput.deviceId !== videoInput.deviceId) {
        this.devices.updatePreferredDevice({videoInput: videoInput})
        this.onMediaDeviceChange()
      }

      console.log('Changed video input', mediaItem.title)
    },

    audioInputSelectChange: function (mediaItem) {
      var audioInput = this.devices.getDeviceById(mediaItem.id, 'audioinput')
      if (this.devices.preferredDevices.audioInput.deviceId !== audioInput.deviceId) {
        this.devices.updatePreferredDevice({audioInput: audioInput})
        this.onMediaDeviceChange()
      }

      console.log('Changed audio input', mediaItem.title)
    },

    audioOutputSelectChange: function (mediaItem) {
      var audioOutput = this.devices.getDeviceById(mediaItem.id, 'audiooutput')
      if (this.devices.preferredDevices.audioOutput.deviceId !== audioOutput.deviceId) {
        this.devices.updatePreferredDevice({audioOutput: audioOutput})
        this.onMediaDeviceChange()
      }

      console.log('Changed audio output', mediaItem.title)
    },

    micArmedChange: function () {
      this.render()
    },

    teardown: function () {
      this.stopListening()
      this.remove()
      this.reduxWatcher.destroy()
    }
  })
})()
