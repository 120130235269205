/* globals zc Backbone app */

(function () {
  'use strict'

  zc.models.Lobby = Backbone.Model.extend({
    initialize: function () {
      this.users = new zc.collections.Users([app.user])
    },

    getHost: function () {
      for (var i = 0; i < this.users.length; i++) {
        var user = this.users.at(i)
        if (user.isHost()) {
          return user
        }
      }
    },
    getUserById: function (id) {
      var users = this.users.toJSON().filter(u => u._id === id)
      if (users.length === 0) { return null }
      if (users.length > 1) {
        console.warn('There\'s more than one user with the same id in the lobby')
      }
      return users[0]
    },
    allMicsArmed: function () {
      var allArmed = true

      this.users.each(function (user) {
        if (!user.get('micArmed')) {
          allArmed = false
        }
      })

      return allArmed
    },
    areAllTracksUploaded: function () {
      return this.users.every(u => u.areAllTracksUploaded())
    }
  })
})()
