/* globals $ zc zc2 _  Backbone app analytics */

(function () {
  'use strict'

  zc.views.NonUserTracksView = Backbone.View.extend({
    initialize: function (options) {
      this.soundboardTracks = []
      this.transcriptModel = new Backbone.Model()

      this.recorder = options.recorder
      this.listenTo(this.model.postproductions, 'add', this.renderPostproduction)
      // listen for all tracks add until we get a soundboard, if we get one
      this.listenTo(app.user.tracks, 'add remove', this.renderSoundboardTrack)
      this.listenTo(this.recorder.recording.tracks, 'add remove', this.renderSoundboardTrack)
      this.listenTo(this.recorder.recording.tracks, 'change:finalized change:error', this.recordingTracksUploadingChange)
      this.listenTo(this.recorder.recording, 'change:transcription', this.transcriptChanged)
      this.listenTo(this.recorder, 'change:isRecording', this.onRecordingChange)
      this.listenTo(app.user.settings, 'change:voip', (model, voipEnabled) => this.renderToggleVoip.bind(this)(voipEnabled))

      this.reduxWatcher = new zc2.utils.ReduxWatcher(zc2.store)
      this.reduxWatcher.watch('call.connected', (connected) => this.renderToggleVoip.bind(this)(undefined, connected))

      _.bindAll(this, 'onSoundboardTrackUploadFinished')

      this.getShowPromise = zc2.creatorApi.showService.getShow(app.project.get('showId')).then(function (data) {
        this.model.set({
          redirUrl: servars.creatorPlatformUrl + '/produce/' + encodeURIComponent(data.slug) + '?roomId=' + encodeURIComponent(app.project.id) + '&recordingId=' + encodeURIComponent(app.project.recorder.recording.id)
        })
      }.bind(this)).catch(function () {
        this.model.set({
          redirUrl: servars.creatorPlatformUrl + '/produce/__playground__?roomId=' + encodeURIComponent(app.project.id) + '&recordingId=' + encodeURIComponent(app.project.recorder.recording.id)
        })
      }.bind(this))
    },

    destroy: function () {
      this.reduxWatcher.destroy()
    },

    template: _.template($('.non-user-tracks-templates').html()),

    className: 'non-user-tracks',

    events: {
      'click .download': 'download',
      'click .toggle-voip': 'toggleVoip',
      'click .finish': 'navigateToCreatorPlatformProduction'
    },

    recordingTracksUploadingChange: function () {
      var tracks = this.recorder.recording.tracks

      var unfinishedTracks = tracks.filter(function (track) {
        var done = (track.get('error') || track.get('finalized')) && !track.get('uploading')
        return !done
      })

      if (unfinishedTracks.length) {
        this.$buttonFinish.hide()
      } else {
        if (this.recorder.recordingFinishedPromise) {
          this.recorder.recordingFinishedPromise.then(function () {
            this.$buttonFinish.fadeIn()
          }.bind(this))
        } else {
          this.$buttonFinish.fadeIn()
        }
      }
    },

    toggleVoip: function () {
      var callState = zc2.store.getState().call
      if (callState.connected) {
        this.recorder.project.call.endCall()
      } else {
        this.recorder.project.call.startCall()
      }
      this.renderToggleVoip()
    },

    renderToggleVoip: function (voipEnabled, callConnected) {
      // `voipEnabled` is only specified when the voip setting changes
      // If its missing, just use app state
      const _enabled = voipEnabled !== undefined ? Boolean(voipEnabled) : app.user.settings.get('voip')

      // Don't render "Hang Up"/"Restart" button when VoIP is disabled
      if (!_enabled) {
        this.$toggleVoip.hide()
        return
      }

      var $text = this.$toggleVoip.find('span')
      if (callConnected) {
        $text.text('Hang Up Call')
      } else {
        $text.text('Restart Call')
      }
      this.$toggleVoip.show()
    },

    navigateToCreatorPlatformProduction: function () {
      // https://local.eh.zencastr.com/produce/__playground__/room/62d8594ffd516f311a770308
      this.getShowPromise.then(function () {
        window.location.href = this.model.get('redirUrl')
      }.bind(this))

    },

    renderPostproductionConfig: function () {
      var self = this
      this.postproductionConfigModal = new zc.views.ModalView({
        addClass: 'postproduction-config-modal',
        model: this.model,
        exit: true,
        ChildView: zc.views.PostproductionConfigView,
        callback: function (args) {
          self.postproductionConfigModal.exit()
          self.createPostproduction(args)
        }
      })
      this.postproductionConfigModal.render()
    },

    createPostproduction: function (args) {
      var self = this

      this.model.trigger('loading:postproduction')
      this.model.createPostproduction(args, function (err, postproduction) {
        self.model.trigger('doneLoading:postproduction')
        console.log(postproduction)
        if (!err && postproduction) {
          analytics.track('Created Postproduction', {
            recordingId: self.recorder.recording.id,
            projectName: self.recorder.project.get('name'),
            projectId: self.recorder.project.id,
            credits: args.credits,
            enable_lp: postproduction.longPauseThreshold !== null,
            set_lp_threshold: (postproduction.longPauseThreshold || 0) * 1000,
            set_lp_shorten: (postproduction.longPauseReplace || 0) * 1000
          })
        }
      })
    },

    /**
     * Used to get new soundboard tracks that we haven't yet added to the view
     * @return {[Array, Array]} Tuple of new tracks and old tracks
     */
    getChangedSoundboardTracks: function () {
      if (this.recorder.recording.id) {
        // id exists as expected
        if (!this.recorder.isSoundboardUsed()) {
          return [[], []]
        }
      }

      var self = this

      // filter just the new soundboard track models
      var newSoundboardTracks = []
      var deadSoundboardTracks = []

      // Removed Tracks
      deadSoundboardTracks = this.soundboardTracks.filter(function (track) {
        return !(track.get('tentative') ||
            track.get('type') !== 'soundboard' ||
            track.get('userId') !== app.user.id) &&
          !self.model.tracks.includes(track)
      })

      // New Tracks
      newSoundboardTracks = this.model.tracks.filter(function (track) {
        return !(track.get('tentative') ||
            track.get('type') !== 'soundboard' ||
            track.get('userId') !== app.user.id) &&
          !self.soundboardTracks.includes(track)
      })

      return [newSoundboardTracks, deadSoundboardTracks]
    },

    renderSoundboardTrack: function (track) {
      var self = this

      // if we get a track, but it's not a soundboard, ignore
      if (track && track.get('type') !== 'soundboard') return

      var changedSoundboardTracks = this.getChangedSoundboardTracks()
      // TODO: Destructuring
      var newSoundboardTracks = changedSoundboardTracks[0]
      var deadSoundboardTracks = changedSoundboardTracks[1]

      if (!newSoundboardTracks.length && !deadSoundboardTracks.length) {
        return
      }

      newSoundboardTracks.map(function (track) {
        self.addSoundboardTrack(track)
      })
      deadSoundboardTracks.map(function (track) {
        self.removeSoundboardTrack(track)
      })
    },

    addSoundboardTrack: function (track) {
      if (!this.soundboardTrackLookup) {
        this.soundboardTrackLookup = {}
      }

      var el
      if (!Object.keys(this.soundboardTrackLookup).includes(track.id)) {
        var soundboardTrackView = new zc.views.TrackView({
          model: track,
          onUploadingFinished: this.onSoundboardTrackUploadFinished
        })
        soundboardTrackView.render()
        el = soundboardTrackView.$el
      } else {
        el = this.soundboardTrackLookup[track.id]
      }

      this.$soundboardTracks.append(el)
      this.soundboardTrackLookup[track.id] = el
      // add to this array to keep track of rendered soundboard-tracks
      this.soundboardTracks.push(track)

      return soundboardTrackView
    },

    removeSoundboardTrack: function (track) {
      if (!this.soundboardTrackLookup) {
        console.warn('Cannot remove soundboard track without lookup!')
        return
      }
      var el = this.soundboardTrackLookup[track.id]
      if (!el) {
        console.warn('Track not found in track lookup!')
        return
      }
      el.remove()
      this.soundboardTracks = this.soundboardTracks.filter(function (t) { return t.id !== track.id })
    },

    onSoundboardTrackUploadFinished: function () {
      this.showSoundBoardTracks()
    },

    showTracks: function () {
      this.showSoundBoardTracks()
      this.$afterRecording.show()
    },

    showSoundBoardTracks: function () {
      // if we already have tracks added to the view
      if (this.soundboardTracks.length && this.recorder.isSoundboardUsed()) {
        this.$soundboardTrackContainer.show()
      }
    },

    renderPostproductions: function () {
      var self = this
      this.$postproductions.empty()
      this.model.postproductions.each(function (postproduction) {
        self.renderPostproduction(postproduction)
      })
    },

    renderPostproduction: function (postproduction) {
      if (app.user.isHost()) {
        var postproductionView = new zc.views.PostproductionView({ model: postproduction })
        this.$postproductions.append(postproductionView.render().el)
      }
    },

    /**
     * used to render the transcript pill
     */
    renderTranscript: function () {
      var transcriptionEligible = app.user.isHost() && app.user.getFeature('transcriptionAllowed')
      var transcriptionAvailable = transcriptionEligible && this.model.get('transcription')
      var transcriptionMissing = transcriptionEligible && !this.model.get('transcription') && app.project.recorder.recording.hasFinished()

      if (transcriptionAvailable || transcriptionMissing) {
        this.transcriptModel.set(this.model.get('transcription'))
        this.$transcriptions.children().remove()
        if (transcriptionMissing) this.transcriptModel.set('status', 'missing')
        var transcriptView = new zc.views.TranscriptionView({ model: this.transcriptModel })
        this.$transcriptions.append(transcriptView.render().el)
      }
    },

    transcriptChanged: function (model) {
      this.transcriptModel.set(model.get('transcription'))
      this.renderTranscript()
    },

    onRecordingChange: function (model, isRecording) {
      if (isRecording === false) {
        this.$buttonFinish.hide()
        this.showTracks()
      }
    },

    render: function () {
      this.$el.html(this.template(this.model.attrs()))
      this.$postproductions = this.$('.postproductions')
      this.$transcriptions = this.$('.transcription')
      this.$soundboardTrackContainer = this.$('.soundboard-track-cont')
      this.$soundboardTracks = this.$('.soundboard-tracks')
      this.$afterRecording = this.$el.find('.after-recording-actions')
      this.$toggleVoip = this.$('.toggle-voip')
      this.$buttonFinish = this.$('button.finish')

      this.renderPostproductions()
      this.renderSoundboardTrack()
      this.renderTranscript()
      this.renderToggleVoip()

      if (this.recorder.hasFinishedRecording()) {
        this.showTracks()
      }
    }
  })
})()
