/* globals zc Backbone utils */

(function () {
  'use strict'

  var nameRegex = new RegExp(/^.{1,20}$/)

  zc.models.Invite = Backbone.Model.extend({
    defaults: {
      email: null,
      name: null
    },

    validate: function () {
      return this.validateEmail(this.get('email')) && this.validateName(this.get('name'))
    },

    /**
     * Used to validate an email address
     * @param  {String} email
     * @return {Boolean}
     */
    validateEmail: function (email) {
      return email && utils.validate.isEmail(email)
    },

    /**
     * Used to validate an invitee
     * @param  {String} name
     * @return {Boolean}
     */
    validateName: function (name) {
      return name && nameRegex.test(name)
    },

    attrs: function () {
      var attrs = this.toJSON()
      return attrs
    }
  })
})()
