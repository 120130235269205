/* globals zc _ Backbone */

(function () {
  'use strict'

  zc.views.TooltipView = Backbone.View.extend({
    initialize: function (options) {
      this.parent = options.parent

      _.bindAll(this, 'checkWindowClick')
    },

    className: 'tooltip',

    toggle: function () {
      if (this.$el.is(':visible')) {
        this.hide()
      } else {
        this.show()
      }
    },

    show: function () {
      var self = this

      this.$el.show()

      // use jquery's UI position
      // note: jq can't position hidden el
      this.$el.position({
        my: 'bottom',
        at: 'top-10',
        of: this.parent
      })

      setTimeout(function () {
        $(window).on('click', self.checkWindowClick)
      }, 0)
    },

    hide: function () {
      this.$el.hide()
      $(window).off('click', this.checkWindowClick)
    },

    checkWindowClick: function (e) {
      if (!$.contains(this.$el.get(0), e.target)) {
        this.hide()
      }
    },

    _render: function (content) {
      // this.$el.html(this._template())

      // add the content
      this.$el.append(content)
      // add attach it to dom
      $('body').append(this.$el)

      return this
    }
  })
})()
