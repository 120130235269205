/* globals zc _ Backbone app */

(function () {
  'use strict'

  zc.views.RecordingView = Backbone.View.extend({
    initialize: function (options) {
      this.onTrackClicked = options.onTrackClicked
      this.userToMatch = options.userToMatch
      this.uploadedTrackIds = []
      this.isListeningForTrackChanges = false
      this.listenTo(app.project.recorder, 'change:isRecording', this.recorderRecordingChange)

      // We're double sure we're recording.
      if (app.project.recorder.get('isRecording')) {
        this.startListeningForTrackChanges()
      }

      _.bindAll(this, 'onTrackUploadFinished')
    },

    template: _.template($('.recording-template').html()),

    className: 'recording',

    getTracksForUser: function () {
      var userIdToMatch = this.userToMatch.get('_id')
      return this.model.tracks.getTracksForUser(userIdToMatch)
    },

    recorderRecordingChange: function (recorder, isRecording) {
      if (isRecording && !this.isListeningForTrackChanges) {
        this.startListeningForTrackChanges()
      }
    },

    startListeningForTrackChanges: function () {
      this.listenTo(this.model.tracks, 'add remove', this.renderTracks)
      this.isListeningForTrackChanges = true
    },

    renderTracks: function () {
      // If we are recording, or counting down for a recording, do not show tracks
      if (app.project.recorder.get('isRecording') || app.project.recorder.get('currentCountdown')) {
        this.unrenderTracks()
        return
      }
      var tracks = this.getTracksForUser() || []
      // group tracks by format
      var groupedTracks = {
        'mp3': [],
        'wav': [],
        'mov': [],
        'webm': []
      }

      tracks.forEach(function (track) {
        groupedTracks[track.get('format')].push(track)
      })

      // Empty the tracks div so we do not have multiple instances of the same track groups
      this.unrenderTracks()

      for (var format in groupedTracks) {
        if (!groupedTracks[format].length) continue

        var trackView = new zc.views.TrackGroupView({
          format: format,
          tracks: groupedTracks[format]
        })
        this.$tracks.append(trackView.render().el)
      }

      // reset which tracks we have set as uploaded
      this.uploadedTrackIds = []
    },

    unrenderTracks: function () {
      this.$tracks.empty()
    },

    onTrackUploadFinished: function (trackModel) {
      var allTracks = this.getTracksForUser() || []
      var trackId = trackModel.get('_id')

      if (this.uploadedTrackIds.indexOf(trackId) === -1) {
        this.uploadedTrackIds.push(trackId)
      }
      if (allTracks.length && this.uploadedTrackIds.length === allTracks.length) {
        this.$tracks.addClass('finished-all')
      }
    },

    hideTracks: function () {
      this.$tracks.css({'display': 'none'})
    },

    showTracks: function () {
      this.$tracks.css({'display': 'block'})
    },

    toggleVisibility: function (showOrHide) {
      if (showOrHide === 'show') {
        this.$el.removeClass('hidden')
      } else {
        this.$el.addClass('hidden')
      }
    },

    render: function () {
      this.$el.html(this.template(this.model.attrs()))
      this.$tracks = this.$('.tracks')

      if (!this.model.get('isRecording')) {
        this.renderTracks()
      }

      return this
    }
  })
})()
