/* globals zc zc2 _ Backbone  app utils servars */

(function () {
  'use strict'

  /* model: zc.models.User */
  zc.views.UserVideoView = zc.views.BaseView.extend({
    initialize: function (options) {
      this.model = new Backbone.Model()
      this.userId = options.id
      this.onRemove = options.onRemove

      this.mediaEl = document.createElement('video')
      // so we don't hear ourself
      this.mediaEl.muted = this.userId === app.user.id
      // autoplay el so we don't have to call play()
      this.mediaEl.autoplay = true

      this.mediaStream = options.mediaStream
      if (this.mediaStream) {
        this.mediaEl.srcObject = this.mediaStream
      }

      this.reduxWatcher = new zc2.utils.ReduxWatcher(zc2.store)

      this.updateAvatarUrl()
      this.checkForUserInLobby()

      this.listenTo(app.project.lobby.users, 'add', this.checkForUserInLobby.bind(this))

      // don't add state listeners for local user
      if (this.userId !== app.user.id) {
        this.reduxWatcher.watch('users.remoteUsers.' + this.userId + '.consumers', this.consumerChange.bind(this))
        this.reduxWatcher.watch('users.remoteUsers.' + this.userId, this.sfuUserChange.bind(this))
      }

      this.reduxWatcher.watch('media_manager.preferredDevices.audioOutput', this.audioOutputChange.bind(this))

      // TODO: Identify if there is a way to not use getState here
      var currentPreferredOutput = zc2.store.getState().media_manager.preferredDevices.audioOutput
      if (currentPreferredOutput) {
        this.mediaEl.setSinkId(currentPreferredOutput.deviceId)
      }
    },

    /**
     * @type {zc.models.User | undefined}
     */
    user: undefined,

    renderArgs: {displayName: ''},

    template: _.template($('.user-video-template').html()),

    className: 'user-video',

    events: {
      'click': 'handleVideoClick'
    },

    sfuUserChange: function (u) {
      if (typeof u === 'undefined') {
        // user has left
        this.tearDown()
      }
    },

    /**
     * Socket User Bootstrapping
     *
     * This is needed because we are trying to decouple this view from the
     *  zencastr socket server; instead using that only to supplement the view
     *  SFU State should be preferred here.
     */
    checkForUserInLobby: function () {
      if (app.user.id === this.userId) {
        this.subscribeToUser(app.user)
      } else {
        const user = app.project.lobby.users.get(this.userId)
        if (user) {
          this.subscribeToUser(user)
        }
      }
    },

    /**
     * Set up event listeners, ensure that state is properly synced
     * This should be called when a user enters the Zencastr Socket Lobby
     * __or__
     * When initializing the component
     * @param u {zc.models.User}
     */
    subscribeToUser: function (u) {
      this.listenTo(u, 'change:handRaised', this.handRaisedChange)
      this.listenTo(u, 'change:cameraOn', this.cameraChange)
      this.listenTo(u, 'change:cameraConnected', this.cameraConnectedChange)
      this.listenTo(u, 'remove', this.unsubscribeFromUser)
      this.user = u
      this.updateAvatarUrl()
      this.renderArgs.displayName = u.get('displayName')

      if (this.rendered) {
        if (u.get('cameraOn')) {
          this.showCamera()
        } else {
          this.hideCamera()
        }

        this.$('.name').text(this.renderArgs.displayName)
      }
    },

    /**
     * Remove all event listeners and properly clean up
     * This should be called when a user leaves the Zencastr Socket Lobby
     * @param u {zc.models.User}
     */
    unsubscribeFromUser: function (u) {
      this.stopListening(u, 'change:handRaised')
      this.stopListening(u, 'change:cameraOn')
      this.stopListening(u, 'change:cameraConnected')
      this.stopListening(u, 'remove')
    },

    updateAvatarUrl: function () {
      // Load user avatar or use placeholder
      const { mediaUrl } = servars
      const placeholderAvatarUrl = new URL('/static/avatar-placeholder-1.png', mediaUrl).toString()

      const id = this.userId
      const isGuest = id === undefined || id.startsWith('guest')

      // If guest, or user unavailable, use placeholder
      // Otherwise get imageUrl API
      if (isGuest || !this.user) {
        this.renderArgs.avatarUrl = placeholderAvatarUrl
        this.$('camera-off .img').attr('src', this.renderArgs.avatarUrl)
      } else if (this.user) {
        zc2.creatorApi.userService.getUserByUsername(this.user.get('username')).then((function (user) {
          if (user.imageUrl) {
            this.renderArgs.avatarUrl = user.imageUrl
          } else {
            this.renderArgs.avatarUrl = placeholderAvatarUrl
          }
          this.$('camera-off .img').attr('src', this.renderArgs.avatarUrl)
        }).bind(this))
      }
    },


    /**
     * Event listeners
     */

    handleVideoClick: function () {
      if (this.user && this.user.get('handRaised') && app.user.isHost()) {
        this.user.set('handRaised', false)
      }
    },

    audioOutputChange: function (newOutput) {
      if (!newOutput) return
      this.mediaEl.setSinkId(newOutput.deviceId)
    },

    handRaisedChange: function (user, isHandRaised) {
      if (isHandRaised) {
        this.$el.addClass('hand-raised')
      } else {
        this.$el.removeClass('hand-raised')
      }
    },

    cameraChange: function (user, isCameraOn) {
      if (isCameraOn) {
        var videoTracks = this.mediaEl.srcObject.getVideoTracks()
        if (videoTracks.length) {
          var track = videoTracks[0]
          if (track.muted) {
            // Do things
            this.showLoadingState()
            var hook = function () {
              this.hideLoadingState()
              track.removeEventListener('unmute', hook)
            }.bind(this)
            track.addEventListener('unmute', hook)
          }
        }
        this.showCamera()
      } else {
        this.hideCamera()
      }
    },

    cameraConnectedChange: function (user, isCameraConnected) {
      if (isCameraConnected) {
        this.$el.removeClass('camera-disconnected')
        this.showCamera()
        this.showLoadingState()
      } else {
        this.$el.addClass('camera-disconnected')
        this.hideCamera()
      }
    },

    streamAdded: function (user, mediaStream) {
      if (this.mediaEl.srcObject.id === mediaStream.id) return
      this.hideLoadingState()
      this.mediaStream = mediaStream
      this.mediaEl.srcObject = this.mediaStream
    },

    consumerChange: function (consumers) {
      if (consumers) {
        // Transform to array for tracks
        var tracks = consumers.map(function (consumer) { return consumer.value.track })

        this.mediaEl.srcObject = new MediaStream(tracks)
        if (this.rendered) {
          if (this.user && this.user.get('cameraOn')) {
            this.showCamera()
          } else {
            this.hideCamera()
          }
          this.hideLoadingState()
        }

      }
    },

    /**
     * Internal methods
     */

    remove: function () {
      this.reduxWatcher.destroy()
      this._removeElement()
    },

    createVideoWrapper: function (doc) {
      /* creates dom structure:
          <div class='video-wrapper'>
            <div class='video-vertical-center'>
                <video ...>
            </div>
          </div>
      */

      var videoContainer = doc.createElement('div')
      videoContainer.classList.add('video-wrapper')

      var videoCenter = doc.createElement('div')
      videoCenter.classList.add('video-vertical-center')
      videoCenter.appendChild(this.mediaEl)

      videoContainer.appendChild(videoCenter)

      return videoContainer
    },

    renderVideo: function ($el) {
      this.$el.append($el)
    },

    isCameraConnected: function () {
      return this.user && this.user.get('cameraConnected')
    },

    showCamera: function () {
      if (!this.isCameraConnected()) return
      this.$cameraOff.css('opacity', '0')
      this.$cameraOff.css('visibility', 'hidden')
      this.$video.css('visibility', 'visible')
    },

    hideCamera: function () {
      this.$cameraOff.css('opacity', '1')
      this.$cameraOff.css('visibility', 'visible')
      this.$video.css('visibility', 'hidden')
    },

    tearDown: function () {
      this.$el.empty()
      if (this.user) this.unsubscribeFromUser(this.user)
      this.remove()
      this.reduxWatcher.destroy()
      if (this.onRemove) {
        this.onRemove()
      }
    },

    showLoadingState: function () {
      if (this.userId === app.user.id) return
      $(this.$videoContainer).hide()
      if (!this.stateContainer) {
        var stateContainer = document.createElement('div')
        stateContainer.classList.add('loading')
        stateContainer.classList.add('state')
        var stateImage = document.createElement('img')
        stateImage.src = utils.cdnUrl('/media/images/beta/camera_states/video-loading.svg')
        var stateText = document.createElement('span')
        stateText.innerText = 'Loading Video...'
        stateContainer.appendChild(stateImage)
        stateContainer.appendChild(stateText)

        var stateWrapper = document.createElement('div')
        stateWrapper.classList.add('state-container')
        stateWrapper.appendChild(stateContainer)
        this.$el.append(stateWrapper)
        this.stateContainer = stateWrapper
      }
      var container = $(this.stateContainer)
      container.show()
    },

    hideLoadingState: function () {
      if (this.userId === app.user.id) return
      $(this.$videoContainer).show()
      if (this.stateContainer) {
        $(this.stateContainer).hide()
      }
    },

    render: function () {
      this.rendered = true
      this._render(this.renderArgs)
      this.$video = this.$('video')
      this.$cameraOff = this.$('.camera-off')
      this.$videoContainer = this.createVideoWrapper(document)
      this.renderVideo(this.$videoContainer)

      if (this.mediaStream) {
        this.hideLoadingState()
      } else {
        this.showLoadingState()
      }

      if (this.user && this.user.get('cameraOn')) {
        this.showCamera()
      } else {
        this.hideCamera()
      }

      if (this.isCameraConnected()) {
        this.$el.removeClass('camera-disconnected')
      } else {
        this.hideCamera()
        this.$el.addClass('camera-disconnected')
      }

      if (!this.mediaStream) {
        var remoteUser = zc2.store.getState().users.remoteUsers[this.id]
        var consumers = remoteUser.consumers
        this.consumerChange(consumers)
      }

      return this
    }
  })
})()
