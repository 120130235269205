/* globals zc _ Backbone app utils */

(function () {
  'use strict'

  zc.views.ProjectLinkView = Backbone.View.extend({
    initialize: function () {
      this.listenTo(this.model, 'remove', this.remove)
      this.listenTo(this.model, 'change:localStorageUsed', this.render)
      this.listenTo(this.model, 'change:deletedAt', this.render)
    },

    template: _.template($('.project-link-template').html()),

    className: 'project-link',

    events: {
      'click .archive': 'archiveClick',
      'click .unarchive': 'unarchiveProject'
    },

    archiveClick: function () {
      var self = this

      var text = 'Archiving will remove this project from your dashboard and delete any of its locally backed up audio.  Files on your cloud drive will not be affected.'
      var localStorageUsed = this.model.get('localStorageUsed')
      if (localStorageUsed) {
        var formattedDiskSpace = this.model.getFormattedDiskSpace(localStorageUsed)
        text += '<br><br>This will *PERMANENTLY* delete this episode\'s local backups and create ' + formattedDiskSpace + ' of free space.'
      }

      var confirmPromptModal = new zc.views.ModalView({
        addClass: 'confirm-prompt-modal',
        ChildView: zc.views.ConfirmView,
        model: new Backbone.Model({
          title: 'Archive This Episode?',
          text: text
        }),
        callback: function (confirmed) {
          if (confirmed) {
            self.archiveProject()
          }
          confirmPromptModal.exit()
        }
      })

      confirmPromptModal.render()
    },

    archiveProject: function () {
      var self = this
      app.socket.emit('projects:archive', {_id: this.model.id}, function (err, res) {
        if (err) return utils.notify('error', err)
        // if everything was ok
        self.model.deleteLocalStorage()
        self.model.collection.remove(self.model)
      })
    },

    unarchiveProject: function () {
      var self = this
      app.socket.emit('projects:unarchive', {_id: this.model.id}, function (err, res) {
        if (err) return utils.notify('error', err)
        // if everything was ok
        self.model.set({deletedAt: null})
      })
    },

    remove: function () {
      var view = this
      this.$el.fadeOut(function () {
        view.$el.remove()
      })
    },

    showArchivedState: function () {
      var archived = !!this.model.get('deletedAt')
      if (archived) {
        this.$el.addClass('archived')
      } else {
        this.$el.removeClass('archived')
      }
    },

    render: function () {
      this.$el.html(this.template(this.model.attrs()))
      this.$delete = this.$('.delete')

      this.showArchivedState()

      return this
    }
  })
})()
