/* globals zc _ */

(function () {
  'use strict'

  zc.views.BillingMonthView = zc.views.BaseView.extend({

    initialize: function (options) {
      this.options = options
      return this.render(options)
    },

    template: _.template($('.billing-month-template').html()),

    events: {
      'click .print': 'print'
    },

    print: function () {
      var template = _.template($('.print-invoice-template').html())
      $('.invoice-container').html(template(this.options))

      window.print()
    },

    render: function () {
      this._render()
      return this
    }
  })
})()
