/* globals zc _ Backbone moment utils */

(function () {
  'use strict'

  zc.models.ProjectLink = Backbone.Model.extend({
    initialize: function (attrs) {
      this.recordings = new zc.collections.Recordings(attrs.recordings, {project: this})
    },

    defaults: {
      localStorageUsed: 0
    },

    attrs: function () {
      var attrs = this.toJSON()

      attrs.formattedLocalStorageUsed = this.getFormattedDiskSpace(attrs.localStorageUsed)

      attrs.createdAtReadable = attrs.createdAt ? moment(attrs.createdAt).format('dddd MMM Do YYYY') : null
      attrs.durationReadable = utils.msToHms(attrs.duration || this.totalDuration())
      attrs.recordingCount = this.recordings.length

      attrs.archived = !!attrs.deletedAt
      attrs.deletedAtReadable = attrs.archived ? moment(attrs.deletedAt).format('dddd MMM Do YYYY') : null

      return attrs
    },

    getFormattedDiskSpace: function (spaceUsed) {
      return utils.formattedDiskSpace(spaceUsed)
    },

    totalDuration: function () {
      var total = 0
      _.each(this.get('recordings'), function (recording) {
        total += recording.duration
      })
      return total
    },

    calcLocalStorageUsed: function () {
      var self = this
      var promises = self.recordings.map(function (recording) {
        return recording.calcLocalStorageUsed()
      })

      return Promise.all(promises).then(function (sizes) {
        var size = _.reduce(sizes, function (a, b) { return a + b }, 0)

        console.log('Project Local Storage Used: ', size)
        self.set({localStorageUsed: size})

        return size
      }).catch(function (err) {
        console.log('ERR: ', err)
        return 0
      })
    },

    deleteLocalStorage: function () {
      var self = this

      var promises = self.recordings.map(function (recording) {
        return recording.deleteLocalStorage()
      })

      return Promise.all(promises)
    }
  })
})()
