/* globals $ zc _  app Backbone */

(function () {
  'use strict'

  zc.views.KickUserView = zc.views.TooltipView.extend({
    template: _.template($('.kick-user-template').html()),

    // className: 'retry-track',

    events: {
      'click button': 'kickClick'
    },

    kickClick: function (e) {
      e.preventDefault()
      e.stopPropagation()
      this.renderConfirmKickModal()
    },

    kickUser: function () {
      if (app.user.isHost()) {
        this.model.collection.remove(this.model)
        app.socket.emit('kickUser', {userId: this.model.id})
      }
    },

    renderConfirmKickModal: function () {
      var view = this
      var modalView = new zc.views.ModalView({
        addClass: 'confirm-kick-modal',
        model: new Backbone.Model({
          title: 'Kick user',
          text: 'Are you sure you want to kick this user?',
          confirmText: 'Confirm',
          cancelText: 'Cancel'
        }),
        ChildView: zc.views.ConfirmView,
        callback: function (result) {
          if (result) {
            view.kickUser()
          }
          modalView.exit()
        }
      })

      modalView.render()
    },

    render: function () {
      // this.$el.html()
      this.child = this.template({})

      this._render(this.child)
    }
  })
})()
