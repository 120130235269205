/* globals zc _  app utils session servars jwtClient */

(function () {
  'use strict'

  zc.views.ProjectHeaderView = zc.views.BaseView.extend({
    template: _.template($('.project-header-template').html()),
    events: {
      'click .media-settings': 'renderMediaSettings',
      'click .invite': 'showInviteLink',
      'click .upgrade': 'renderPlansModal',
      'click .logout-button': 'logout'
    },

    initialize: function (options) {
      var config
      switch (options.configType) {
        case 'recording':
          config = this.createRecordingConfig()
          break
        case 'dashboard':
          config = this.createDashboardConfig()
          break
        case 'login':
          config = this.createLoginConfig()
          break
        case 'errorPage':
          config = this.createErrorConfig()
          break
        case 'empty':
          config = this.createEmptyConfig()
          break
      }
      this.config = config
      this.message = options.message

      if (Object.keys(this.config.room).length) {
        this.listenTo(app.project.lobby.users, 'change add remove change:isVoipConnected', this.renderParticipantCount)
      }

      this.model.set({
        showTitle: '',
        showImgUrl: utils.cdnUrl('')
      })

      if (app.project) {
        zc2.creatorApi.showService.getShowByProjectId(app.project.id, app.project.get('ownerId'))
          .then(function (show) {
            if (show) {
              this.model.set({ showTitle: show.title })
              if (show.imageUrl) {
                this.model.set({ showImgUrl: show.imageUrl })
              } else {
                this.model.set({ showImgUrl: utils.cdnUrl('/media/images/playground-placeholder.png') })
              }
            } else {
              this.model.set({
                showTitle: 'Playground',
                showImgUrl: utils.cdnUrl('/media/images/playground-placeholder.png')
              })
            }
          }.bind(this))
      } else {
        this.model.set({
          showTitle: 'Playground',
          showImgUrl: utils.cdnUrl('/media/images/playground-placeholder.png')
        })
      }
      this.listenTo(this.model, 'change:showTitle', function () {
        this.$('.showTitle').text(this.model.get('showTitle'))
      }.bind(this))
      this.listenTo(this.model, 'change:showImgUrl', function () {
        this.$('.showinfo img').attr('src', this.model.get('showImgUrl'))
      }.bind(this))

    },

    renderParticipantCount: function () {
      if (this.participantCountEl) {
        this.participantCountEl.text(app.project.lobby.users.getVoipConnected().length)
      }
    },

    createRecordingConfig: function () {
      var isHost = app.user.isHost()
      var settings = this.getDefaultConfig()
      settings.showDashboardLink = session.loggedIn
      settings.showSupportLink = true
      settings.showInviteLink = isHost
      settings.showAudioSettings = true
      settings.showCloudDrive = isHost
      settings.showUpgrade = session.user && session.user.subscription && session.user.subscription.planBillingId === 'hobbyist'
      settings.showLoginLink = !session.loggedIn
      settings.showVideoRecordingModeIcons = true
      settings.videoRecordingMode = app.project.recorder.recording.get('videoRecordingMode')
      settings.room = {
        occupied: app.project.lobby.users.getVoipConnected().length,
        max: servars.roomSize
      }

      return settings
    },

    createDashboardConfig: function () {
      var settings = this.getDefaultConfig()

      settings.showBlogLink = false
      settings.showSupportLink = false
      settings.showLoginLink = !session.loggedIn
      settings.showAccountLink = false
      settings.showClassicLink = false
      settings.showRecordingControls = false
      settings.showShow = false
      return settings
    },

    createLoginConfig: function () {
      var settings = this.getDefaultConfig()

      settings.showBlogLink = false
      settings.showPricingLink = false
      settings.showSupportLink = false
      settings.showLoginLink = false
      settings.showAccountLink = false
      settings.showShow = false
      settings.showRecordingControls = false
      return settings
    },

    createErrorConfig: function () {
      var settings = this.getDefaultConfig()

      settings.showBlogLink = false
      settings.showPricingLink = false
      settings.showSupportLink = false
      settings.showLoginLink = !session.loggedIn
      settings.showAccountLink = false
      return settings
    },

    createEmptyConfig: function () {
      var settings = this.getDefaultConfig()
      settings.showLoginLogout = false
      return settings
    },

    getDefaultConfig: function () {
      return {
        showDashboardLink: false,
        showSupportLink: false,
        showPricingLink: false,
        showInviteLink: false,
        showAudioSettings: false,
        showCloudDrive: false,
        showUpgrade: false,
        showLoginLink: false,
        /**
         * Determines if the login/logout button should be shown at all
         */
        showLoginLogout: false,
        showAccountLink: false,
        showBlogLink: false,
        showClassicLink: false,
        showVideoRecordingModeIcons: false,
        showRecordingControls: true,
        showShow: true,
        room: {},
        /*
          We are cheating, and reusing this template for both project and guest now;
          This means we will have some nothingburgers to appease underscore templating
         */
        headerType: undefined
      }
    },

    renderMediaSettings: function (e) {
      e.preventDefault()
      console.log('Opened settings modal')
      this.mediaSettingsModal = new zc.views.ModalView({
        addClass: 'media-settings-modal',
        model: this.model,
        settings: app.user.settings,
        callback: this.mediaSettingsCallback.bind(this),
        ChildView: zc.views.MediaSettingsView
      })

      this.mediaSettingsModal.render()
    },

    mediaSettingsCallback: function () {
      console.log('Closed settings modal')
      this.mediaSettingsModal.exit()
    },

    renderPlansModal: function () {
      this.plansModalView = new zc.views.ModalView({
        addClass: 'plans-modal',
        model: app.user.billing,
        ChildView: zc.views.PlansView,
        callback: this.planSelectCallback.bind(this),
        showCouponForm: true
      })
      this.plansModalView.render()
    },

    planSelectCallback: function (err, subscription) {
      if (err) {
        utils.notify('error', err)
      } else {
        utils.notify('success', 'You are now subscribed to the ' + subscription.plan.name + ' plan')
      }
      this.plansModalView.exit()
    },

    toggleLogo: function (showOrHide) {
      if (showOrHide === 'show') {
        this.$logo.show()
      } else {
        this.$logo.hide()
      }
    },

    toggleHeader: function (showOrHide) {
      if (showOrHide === 'show') {
        this.$header.css({ 'display': '' })
      } else {
        this.$header.css({ 'display': 'none' })
      }
    },

    showInviteLink: function () {
      var inviteLinkModalView = new zc.views.ModalView({
        addClass: 'invite-link-modal',
        ChildView: zc.views.InviteFormView,
        exit: true,
        collection: new zc.collections.Invites([{}, {}, {}]),
        callback: function () {
          inviteLinkModalView.exit()
        }
      })
      inviteLinkModalView.render()
    },
    logout: function () {
      jwtClient.logout()
      window.location.href = '/'
    },
    render: function () {
      this._render(this.config)
      this.$header = this.$('> header')
      // @TODO update with the proper selector
      this.$logo = this.$header.find('.logo-grad use')
      if (this.message) {
        this.$('.header-message').text(this.message)
      }

      if (this.config.room) {
        this.participantCountEl = this.$el.find('.participant-count')
      }

      this.$profileMenuContainer = this.$('.profile-menu-container')
      this.profileMenu = new zc.views.HeaderProfileMenu()

      this.$profileMenuContainer.append(this.profileMenu.render().el)
    }
  })
})()
