/* globals servars analytics zc zc2 app debug _ Backbone utils Player localStorage AudioContext AbortController */

(function () {
  'use strict'

  var dbg = debug('zc:project')

  zc.models.Project = Backbone.Model.extend({
    initialize: function (attrs, options) {
      // could this line be why we have a bunch of empty recordings?
      attrs.recordings = (attrs.recordings && attrs.recordings.length) ? attrs.recordings : [new zc.models.Recording({projectId: this.id, videoRecordingMode: this.get('defaultVideoRecordingMode')})]

      this.socket = app.socket

      dbg('Initializing project')

      // top level audio nodes
      this.actx = app.actx = attrs.actx || new AudioContext() // {sampleRate: 44100}) // setting sample rate causes pitch shift and static when it differs from hardware on chrome 74

      this.soundboardOut = app.soundboardOut = app.actx.createGain()

      this.soundboardOut.channelCountMode = 'explicit'
      this.soundboardOut.channelInterpretation = 'speakers'
      this.soundboardOut.channelCount = 1

      // wire up the local out to a audio element so we can change output devices
      // using the setSinkId workaround
      this.outputDestination = this.actx.createMediaStreamDestination()
      this.outputDestination.channelCount = 1

      // hack to prevent this playback frequeny/latency issue: https://bugs.chromium.org/p/chromium/issues/detail?id=638823
      var empty = this.actx.createBufferSource()
      empty.connect(this.outputDestination)

      // Hack to prevent playback from randomly changing speed/pitch: https://bugs.chromium.org/p/chromium/issues/detail?id=1157478#c8
      this.startLoopSilence()

      // TODO: Use video el instead?? https://github.com/WebAudio/web-audio-api/issues/445
      this.localAudioOutEl = app.localAudioOutEl = document.createElement('audio')
      this.localAudioOutEl.srcObject = this.outputDestination.stream

      this.recordings = new zc.collections.Recordings(attrs.recordings, {parse: true, project: this})
      var activeRecordingId = options && options.activeRecordingId
      var recording = this.recordings.get(activeRecordingId) || this.recordings.at(this.recordings.length - 1)

      // TODO: do we still need this?
      recording.set({active: true})

      app.player = new Player(this.actx)
      app.player.loadSamples([
        {name: 'error', url: utils.cdnUrl('/media/sounds/classic-bloop.wav')},
        {name: 'userRaisedHand', url: utils.cdnUrl('/media/sounds/think-ping.wav')},
        {name: 'userLoweredHand', url: utils.cdnUrl('/media/sounds/thats-it.wav')},
        {name: 'userJoined', url: utils.cdnUrl('/media/sounds/think-ping.wav')}
      ])

      this.userMedia = new zc2.services.UserMedia(zc2.store)

      this.userMedia.on('streamError', function (e) {
        var errorMessage = 'Error accessing microphone or webcam'

        if (e.name === 'NotReadableError') {
          errorMessage = 'There was a problem accessing your mic or camera. Another program like Google hangouts / Skype / or Zoom might be accessing your hardware. Please close those other programs and ensure the mic/camera is connected correctly.'
        }

        utils.notify('error', errorMessage)
      })

      this.soundboardService = new zc2.services.SoundboardService({
        enabled: app.user.getFeature('soundboard') && app.user.id === this.get('ownerId')
      })

      this.call = new zc2.services.Call({
        roomId: this.id,
        userId: app.user.id,
        // TODO: Find a way to provide actual username after guests leave the greenroom
        // userName: app.user.get('displayName'),
        userName: app.user.id,
        store: zc2.store,
        actions: zc2.actions,
        userMedia: this.userMedia,
        soundboardService: this.soundboardService,
        videoRecordingMode: recording.get('videoRecordingMode'),
        callIsRecording: recording.get('isRecording'),
        isHost: this.get('ownerId') === app.user.id
      })
      this.call.on('connected', function () {
        app.socket.emit('voip:ready')

        analytics.track('SfuSocketChange', {
          connected: true,
          userId: app.user.id,
          projectId: this.id,
          recordingId: recording.id
        })
      })
      this.call.on('disconnected', function () {
        analytics.track('SfuSocketChange', {
          connected: false,
          userId: app.user.id,
          projectId: this.id,
          recordingId: recording.id
        })
      })

      this.call.on('connectionstatechange', function (state) {
        var connectionState = state === 'connected' || state === 'failed' ? state : null
        if (!connectionState) return

        analytics.track('SfuConnectionChange', {
          state: connectionState,
          userId: app.user.id,
          projectId: this.id,
          recordingId: recording.id
        })
      })

      if (app.user.getFeature('soundboard')) {
        // soundboard sample playback
        this.soundboardOut.connect(this.outputDestination)

        var userSamples = app.user.get('soundboardSamples')
        if (Boolean(userSamples) && userSamples.length) {
          this.soundboardSamples = new zc.collections.Samples(userSamples)
        } else {
          this.soundboardSamples = new zc.collections.Samples([
            { _id: '0123', name: 'Intro / Outro', url: utils.cdnUrl('/media/sounds/Upbeat Intro.wav'), loop: true, gain: 0.5 },
            { _id: '1243', name: 'Dramatic Piano', url: utils.cdnUrl('/media/sounds/piano.mp3'), loop: true },
            { _id: '2345', name: 'Drums', url: utils.cdnUrl('/media/sounds/drums.wav') },
            { _id: '3456', name: 'Ballpark', url: utils.cdnUrl('/media/sounds/ballpark.wav') }
          ])
        }

        this.migrateLegacySoundboards()

        if (app.user.id === this.get('ownerId')) {
          this.soundboardStreamDestination = this.actx.createMediaStreamDestination()
          this.soundboardOut.connect(this.soundboardStreamDestination)
          this.soundboardService.updateStream(this.soundboardStreamDestination.stream)
        }
      }

      this.canvasWorker = new Worker(servars.canvasWorker.url)

      this.lobby = new zc.models.Lobby()
      this.recorder = new zc.models.Recorder({recording: recording}, {actx: this.actx, project: this, socket: this.socket})
      this.chat = new zc.models.Chat()

      this.buffers = new zc.collections.Buffers([this.recorder.buffer])

      console.log('Audio context samplerate: ', this.actx.sampleRate)

      this.roomJoined = new Promise(function (resolve) {
        this.resolveRoomJoined = resolve
      }.bind(this))

      this.listenTo(app.user, 'change:localStorageQuota', this.localStorageQuotaChange)
      this.listenTo(app.user, 'initializationFailure', this.initializationFailure)
      this.listenTo(app.user.settings, 'change', this.userSettingsChange)
      this.listenTo(app.user.audioInput, 'change', this.userAudioInputChange)
      this.listenTo(app.user, 'change:cameraOn', this.localUserCameraOnChange)
      this.listenTo(app.user.criticalHealthChecks, 'reset', this.healthChecksChange)
      this.listenTo(app.user.warningHealthChecks, 'reset', this.healthChecksChange)
      this.listenTo(app.user.tracks, 'add', this.shareTrackWithRoom)

      this.listenTo(this.recorder, 'change:micArmed', this.micArmedChange)
      this.listenTo(this.recorder, 'change:playing', this.playingChange)
      this.listenTo(this.recorder, 'change:paused', this.pausedChange)
      this.listenTo(this.recorder.buffer, 'change:wav', this.sendWavAudioStream)

      this.listenTo(this.lobby.users, 'add', this.createUserBuffer)
      this.listenTo(this.lobby.users, 'add remove', this.checkAllArmed)
      this.listenTo(this.lobby.users, 'change:micArmed', this.checkAllArmed)
      this.listenTo(this.lobby.users, 'change:handRaised', this.handRaisedChange)
      this.listenTo(this.lobby.users, 'remoteUserMutedChangedLocally', this.remoteUserMutedChangedLocally)
      this.listenTo(this.lobby.users, 'localUserMutedChange', this.localUserMutedChange)
      this.listenTo(this.lobby.users, 'remoteUserCameraChanged', this.remoteUserCameraOnChange)

      this.listenTo(this.recorder, 'startOver', this.startOver)
      this.listenTo(this.chat, 'localTypingStarted', this.localChatTypingStarted)
      this.listenTo(this.chat, 'localTypingStopped', this.localChatTypingStopped)
    },

    urlRoot: '/projects',

    defaults: {
      name: 'Default Project',
      duration: 0,
      cloudDrive: null, // dropbox or google drive
      recording: null, // recording object
      isRecording: false,
      closeTabProtection: false, // if the beforeunload event listener is attached to the window
      allArmed: false,
      actxIsActive: false,
      defaultVideoRecordingMode: 'displayOnly',
      sfuRegion: ''
    },

    attrs: function () {
      var attrs = this.toJSON()
      attrs.project = this
      return attrs
    },

    /**
     * This is a hack to prevent random speed/pitch changes in audio playback
     * https://bugs.chromium.org/p/chromium/issues/detail?id=1157478#c8
     * https://soundplant.org/wavesurfer/srcObject_demo_silence.html
     */
    startLoopSilence: function () {
      this.silentSource = this.actx.createBufferSource()

      const silentBuffer = new AudioBuffer({
        length: 1,
        numberOfChannels: 1,
        sampleRate: 44100,
      })
      const silentArray = new Float32Array([0])
      silentBuffer.copyToChannel(silentArray, 0)
      this.silentSource.buffer = silentBuffer

      this.silentSource.connect(this.soundboardOut)
      this.silentSource.loop = true
      this.silentSource.start(0)
    },

    startAudioPlayback: function () {
      var self = this
      return this.localAudioOutEl.play().then(function () {
        console.log('Local Audio Playback Started Successfully')
        dbg('Local Audio Playback Started Successfully')

        if (app.actx.state === 'suspended') {
          self.trigger('requestAudioPlayback')
        }
      }).catch(function (err) {
        console.error('Error Starting Local Audio Playback', err)
        self.trigger('requestAudioPlayback')
      })
    },

    migrateLegacySoundboards: function () {
      var legacySoundboard = JSON.parse(localStorage.getItem('soundboardSamples') || '[]')

      if (legacySoundboard && Array.isArray(legacySoundboard) && legacySoundboard.length) {
        legacySoundboard.forEach(function (soundboard) {
          var soundboardExists = this.soundboardSamples.some(function (sample) {
            return soundboard.name === sample.get('name')
          })

          if (soundboardExists) {
            // Sample with same name is already on the user, skip
            console.log('Skipping soundboard "' + soundboard.name + '", duplicate found')
            return
          }
          // No duplicates from this point forward

          console.log('Migrating legacy soundboard')
          this.soundboardSamples.add(Object.assign(soundboard, { legacy: true }))
          // Extract from local storage, upload to GCP
        }.bind(this))
        localStorage.removeItem('soundboardSamples')
      }
    },

    audioPlaybackAllowed: function () {
      if (app.actx.state === 'suspended') {
        app.actx.resume()
      }

      // start sound for local audio out
      app.localAudioOutEl && app.localAudioOutEl.play()
      // start sound for monitor if it is enabled
      this.recorder && this.recorder.monitorService && this.recorder.monitorService.reinitializeIfNeeded()

      this.recorder.trigger('audioContextResumed')
    },

    localChatTypingStarted: function () {
      this.socket.emit('chat:typingStarted')
    },

    localChatTypingStopped: function () {
      this.socket.emit('chat:typingStopped')
    },

    calcLocalStorageUsed: function () {
      var self = this
      return new Promise(function (resolve, reject) {
        var promises = self.recordings.map(function (recording) {
          return recording.calcLocalStorageUsed()
        })

        Promise.all(promises).then(function (sizes) {
          resolve(_.reduce(sizes, function (a, b) { return a + b }, 0))
        }, function (err) {
          reject(err)
        })
      })
    },

    recoverTracks: function () {
      // if the guest wants to recover tracks for the project

      // two actions will be taken
      // 1. force finalize any track associated with the user
      // we need to call this for each track type. right now these are the only two
      // this.recorder.finishOldTracks('microphone')
      // this.recorder.finishOldTracks('video')

      // 2. if we find other tracks for this project that are present/saved locally
      //  try and finalize them (upload or force download)
      this.lookForOldTracks()
    },

    /**
     * Used when a user (guest most likely) goes to a recover link for a project
     * Looks for tracks that are saved locally in the browser but don't belong to the current user
     */
    lookForOldTracks: function () {
      var self = this

      /**
       * upload a track and not matter the result, got to the next one
       * @param  {Object} track The track model
       */
      var uploadTrack = async function (track) {
        console.log('Started finalizing track', track.id, track.get('format'), track.get('type'))

        // set the userId so the track can be displayed and uploaded without problems
        // in theory the track belongs to this user but he/she had a different id when it was created
        track.set('userId', app.user.id)

        // force the track to be unfinalized
        // this is needed to help with tracks that are force finalized by the host
        // and we still have a local backup
        track.set({finalized: false})
        track.updateUpload({finalized: false})

        // self.recorder.recording.tracks.add(track)
        self.recorder.recording.tracks.trigger('add', track)

        track.forceFinalizeUpload().then(function () {
          console.log('finished track', track.id)
        }).catch(function () {
          console.error('error reuploading track', track.id)
        })
      }

      // go through each track for each recording to see if we have something saved locally
      this.recordings.each(function (rec) {
        rec.tracks.each(function (track) {
          if (track.get('format') === 'mp3' || track.get('format') === 'wav') {
            track.persistentStore.getDbMeta().then(function (dbMeta) {
              // if there is something saved in the DB
              if (dbMeta && dbMeta.totalBytes > 0) {
                uploadTrack(track)
              }
            })
          }

          if (track.get('format') === 'wav' || track.get('format') === 'mov') {
            track.memoryStore.getBytesSaved().then(function (bytes) {
              if (!bytes) return

              uploadTrack(track)
            })
          }
        })
      })
    },

    initializationFailure: function (user, err) {
      this.recorder.set({isRecording: false, aborted: true})
      this.trigger('stopCloseTabProtection', this)
      this.socket.emit('user:initialization:failure', {user: user, error: err, aborted: true})
    },

    /**
     * Called when the recorder fires the startOver event
     */
    startOver: function () {
      app.router.navigate([this.get('owner'), this.get('slug')].join('/'))
      this.reloadProjectPage()
    },

    healthChecksChange: function () {
      var data = {
        userId: app.user.id,
        criticalHealthChecks: app.user.criticalHealthChecks.toJSON(),
        warningHealthChecks: app.user.warningHealthChecks.toJSON()
      }

      this.socket.emit('change:healthChecks', data)
    },

    localStorageQuotaChange: function (user, localStorageQuota) {
      // only the guests send this events, and only the host should receive them
      if (!app.user.isHost()) {
        var data = {
          userId: user.id,
          localStorageQuota: localStorageQuota,
          hostId: this.get('ownerId')
        }

        this.socket.emit('change:localStorageQuota', data)
      }
    },

    handRaisedChange: function (user, handRaised) {
      if (user.isLocal() || (app.user.isHost() && !handRaised)) {
        var data = {userId: user.id, handRaised: handRaised}
        this.socket.emit('user:change:handRaised', data)
      }
    },

    /**
     * If the user has opened a link multiple times, this method can be used to other other sessions
     */
    endOtherSessions: function () {
      var self = this
      return new Promise(function (resolve, reject) {
        self.socket.emit('endOtherSession', {roomId: self.id}, function (err) {
          if (err) {
            console.error(err)
            reject(err)
            return
          }
          resolve()
          self.joinRoom()
        })
      })
    },

    reloadProjectPage: function () {
      console.time('alertReloading')
      utils.notify('alert', 'Reloading project...')
      console.timeEnd('alertReloading')
      var href = window.location.pathname
      if (!app.user.loggedIn()) {
        href += utils.setQueryStringParam(['username', app.user.get('username')])
      }
      window.location.href = href
    },

    localUserMutedChange: function (user, muted) {
      this.socket.emit('user:change:muted', {userId: user.id, muted: muted})
    },

    /**
     * When the host changes the camera for a guest
     * @param  {Object} user     User model
     * @param  {Boolean} cameraOn if camera is on/off
     */
    remoteUserCameraOnChange: function (user, cameraOn) {
      this.socket.emit('user:change:cameraOn', {userId: user.id, cameraOn: cameraOn})
    },

    localUserCameraOnChange: function (user, cameraOn) {
      this.socket.emit('user:change:cameraOn', {userId: user.id, cameraOn: cameraOn})
    },

    remoteUserMutedChangedLocally: function (user, muted) {
      this.socket.emit('user:change:muted', {userId: user.id, muted: muted})
    },

    createUserBuffer: function (user) {
      var buffer = new zc.models.Buffer({_id: user.id}, {actx: this.actx})
      this.buffers.add(buffer)
    },

    userSettingsChange: function (settings) {
      this.socket.emit('change:settings', {
        settings: settings.toJSON()
      })
    },

    userAudioInputChange: function (audioInput) {
      this.socket.emit('change:audioInput', {
        audioInput: audioInput.toJSON()
      })
    },

    micArmedChange: function (recorder, micArmed) {
      app.user.set('micArmed', micArmed)
      this.socket.emit('change:micArmed', {micArmed: micArmed})
    },

    checkAllArmed: function () {
      // check to see if all users are armed and ready to record
      var allArmed = false

      var statuses = this.lobby.users.map(function (user) {
        return user.get('micArmed')
      })

      if (statuses.indexOf(false) < 0) {
        allArmed = true
      }

      this.recorder.set({allArmed: allArmed})
    },

    playingChange: function (model, playing) {
      this.buffers.each(function (buffer) {
        buffer.set({playing: playing})
      })
    },

    pausedChange: function (model, paused) {
      app.user.set({paused: paused})
      this.socket.emit('change:paused', {
        recordingId: this.recorder.recording.id,
        paused: paused
      })
    },

    joinRoom: async function (rejoin) {
      var project = this

      if (!project.id) {
        console.error('Project id missing when trying to joinRoom', project.id, project.attributes)
      }

      // check the local cache for the region
      if (!this.get('sfuRegion')) {
        this.set('sfuRegion', await this.getUserRegion())
      }

      console.log('Joining room')
      this.socket.once('roomJoined', this.resolveRoomJoined)
      this.socket.emit('joinRoom', {
        id: project.id,
        user: app.user.toExtendedJSON(),
        region: this.get('sfuRegion'),
        rejoin: rejoin || false
      }, function (err) {
        if (err) {
          utils.notify('alert', 'Could not connect to server. Please try again')
        }
      })
    },

    rejoinRoom: async function (cb) {
      cb = cb || function () {}
      var project = this
      var socket = this.socket
      if (!project.id) {
        console.error('Project id missing when trying to joinRoom', project.id, project.attributes)
      }

      if (!this.get('sfuRegion')) {
        this.set('sfuRegion', await this.getUserRegion())
      }

      console.log('Rejoining room')
      socket.emit('rejoinRoom', {id: project.id, user: app.user.toExtendedJSON(), region: this.get('sfuRegion')}, cb)
    },

    getUserRegion: async function () {
      if (!servars.sfuRegionEndpoint) {
        return ''
      }

      var controller = new AbortController()

      var pid = setTimeout(() => {
        console.warn('Timeout reached for get SFU region call.')
        controller.abort()
      }, 5000)

      return fetch(servars.sfuRegionEndpoint, {
        method: 'GET',
        mode: 'cors',
        cache: 'no-cache',
        credentials: 'omit',
        signal: controller.signal
      }).then(async function (response) {
        var json = await response.json()
        if (json.region) {
          return json.region
        } else {
          throw new Error('Missing region argument from response')
        }
      }).catch(function (err) {
        console.error('Could not get region for user', err)
        return ''
      }).finally(function () {
        clearTimeout(pid)
      })
    },

    shareTrackWithRoom: function (track) {
      app.socket.emit('track:created', {
        track: track.toJSON()
      })
    },

    addRoomMembers: function (members) {
      members.forEach(this.addRoomMember.bind(this))
    },

    addRoomMember: function (member) {
      console.log('New user joined the room', member.username)
      var user = this.lobby.users.get(member._id)

      if (!user) {
        user = new zc.models.User(member)
        this.lobby.users.add(user)
        // this.disableControlsIfNeeded(user)
      }

      user.set({
        socketConnected: true,
        isRecording: member.isRecording,
      })

      // if this user is the host
      if (app.user.isHost()) {
        var videoRecordingResolution = app.user.settings.get('videoRecordingResolution')
        // if we are not recording, we have more than 2 participants, the user doesn't specifically want HD and we are not already on 720
        // drop to 720
        if (!this.recorder.get('isRecording') && this.lobby.users.length > 2 && !videoRecordingResolution.forced && videoRecordingResolution.value !== 720) {
          app.user.settings.set('videoRecordingResolution', {
            value: 720,
            forced: false
          })

          utils.notify('alert', 'For best recording experience on larger calls, your recorded video resolution has been downgraded to 720p. <br> This setting can be toggled in the settings menu but it is currently not recommended')
        }
      } else {
        // else, if the host joined the room
        if (user.isHost()) {
          var host = user
          // sync settings with host
          app.user.settings.set(host.settings.toJSON())

          // The host has joined the room. If a recording hasn't
          // already taken place, then get prepared to record
          // make sure we have access to the audio stream first
          if (!this.recorder.hasStartedRecording() && this.call && this.call.localMedia && this.call.localMedia.mediaStream) {
            this.recorder.prepareToRecord()
          }
        }
      }
    },

    startDevices: async function () {
      // fetch the device list
      await this.userMedia.devices.updateDevices()
      // get the stream
      await this.userMedia.requestNewStream()
    },

    // ----------------------
    // Testing
    // ----------------------

    testTrack: function (format, seconds) {
      var self = this
      seconds = seconds || 60 * 60 * 1.7 // 1.5 hours
      var byteLength = seconds * 44100 * 2 // byteLength for 16bit audio at 44100 hz
      console.log('Testing Track: 16bit 44100hz')
      console.log('Seconds: ', seconds, 'ByteLength: ', byteLength)
      this.testCreateExistingTrack(format)
        .then(function (track) {
          return self.testTrackSaveAudioToStore(track, track.testGenerateAudio(byteLength))
        })
        .then(function (track) {
          self.testTrackDownloadFromLocal(track)
        })
    },

    testCreateTrack: function (format) {
      return new zc.models.Track({
        format: format,
        recordingId: self.recorder.recording.id
      })
    },

    testTrackSaveAudioToStore: function (track, audio) {
      return new Promise(function (resolve, reject) {
        track.testSaveAudio(audio).then(function (totalSavedBytes) {
          resolve(track)
        }, reject)
      })
    },

    testTrackDownloadFromLocal: function (track) {
      return new Promise(function (resolve, reject) {
        console.log('Downloading From Local')
        track.downloadFromLocal()
      })
    },

    testTrackUpload: function () {
      return new Promise(function (resolve, reject) {

      })
    }
  })
})()
