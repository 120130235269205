/* globals zc Backbone utils app */

(function () {
  'use strict'

  zc.collections.ProjectLinks = Backbone.Collection.extend({
    url: '/projects',

    model: zc.models.ProjectLink,

    calcProjectsLocalStorageUsed: function () {
      // Create a new empty promise
      var sequence = Promise.resolve()

      // Loop over each file, and add on a promise to the
      // end of the 'sequence' promise.
      this.forEach(function (projectLink) {
        // Chain one computation onto the sequence
        sequence = sequence.then(function () {
          return projectLink.calcLocalStorageUsed()
        }).then(function (result) {
          // doSomething(result) // Resolves for each file, one at a time.
        }).catch(function (err) {
          utils.notify('error', err)
        })
      })

      // This will resolve after the entire chain is resolved
      return sequence
    },

    fetchUserProjects: function (ownerId, page, perPage, archived) {
      var self = this
      page = page || 0
      perPage = perPage || 10
      archived = archived || false
      this.trigger('loading')
      return new Promise(function (resolve, reject) {
        app.socket.emit('projects:readPage', {query: {ownerId: ownerId}, options: {deleted: archived, page: page, perPage: perPage}}, function (err, res) {
          self.trigger('doneLoading')
          if (err) {
            utils.notify('error', err)
            reject(err)
          } else {
            self.reset(res.projects)
            self.calcProjectsLocalStorageUsed()
            resolve(res)
          }
        })
      })
    }
  })
})()
