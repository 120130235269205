/* globals zc Backbone */

(function () {
  'use strict'

  zc.collections.Samples = Backbone.Collection.extend({
    model: zc.models.Sample,

    stopAll: function () {
      this.forEach(function (sample) {
        sample.stop()
      })
    }
  })
})()
