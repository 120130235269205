/* globals zc _ Backbone */

(function () {
  'use strict'

  zc.collections.Recordings = Backbone.Collection.extend({
    initialize: function (recordings, options) {
      this.project = options.project
    },

    model: zc.models.Recording,

    parse: function (recordings) {
      var parsed = []
      _.each(recordings, function (recording) {
        // filter out 0 duration recordings that just get in the way unless it is the new recording
        var isLast = recordings.indexOf(recording) === recordings.length - 1
        var hasUploads = recording.uploads && recording.uploads.length
        var isEmpty = !recording.duration && !hasUploads
        if (!isEmpty || isLast) {
          parsed.push(recording)
        }
      })

      return parsed
    },

    setAll: function (attrs) {
      this.each(function (recording) {
        recording.set(attrs)
      })
    }
  })
})()
