/* globals zc _ Backbone app analytics utils */

(function () {
  'use strict'

  zc.views.RecorderView = Backbone.View.extend({
    initialize: function () {
      /**
       * Flag to keep track if we've showed the local backup error
       * only show it once
       * @type {Boolean}
       */
      this.localbackupFailedMesasge = false

      this.listenTo(this.model.recording, 'change:allUploaded', this.allUploadedChange)
      this.listenTo(app.user.tracks, 'localbackup:failedWrite', this.failedLocalBackup)

      this.listenTo(this.model, 'change:isRecording', this.isRecordingChange)
      this.listenTo(this.model, 'change:paused', this.pausedChange)
      this.listenTo(this.model, 'change:recording', this.render)
      this.listenTo(this.model, 'change:currentCountdown', this.onCountdown)
      this.listenTo(this.model, 'loading:postproduction', this.showPostproductionLoading)
      this.listenTo(this.model, 'doneLoading:postproduction', this.hidePostproductionLoading)
      this.listenTo(this.model, 'enableControls', this.enableControls)
      this.listenTo(this.model, 'disableControls', this.disableControls)
      this.listenTo(this.model.call, 'showAccessing', this.showAccessingMediaDevices)
      this.listenTo(this.model.call, 'hideAccessing', this.hideAccessingMediaDevices)
      this.listenTo(this.model.call, 'samplePlaybackDestinationReady', this.samplePlaybackDestinationReady)

      this.listenTo(this.model.project.lobby.users, 'add change:greenroom remove', this.shouldShowWaitingForMics)
    },

    template: _.template($('.recorder-template').html()),

    events: {
      'click .record': 'recordClicked',
      'click .stop': 'stopRecording',
      'click .new-recording': 'startOver',
      'click .start-over': 'startOver',
      'click .pause': 'togglePause',
      'click .activate-mic': 'activateMicClick',
      'change #video-mode': 'videoModeChange'
    },

    activateMicClick: function (e) {
      e.preventDefault()
      this.model.call.getLocalStream()
    },

    videoModeChange: function (e) {
      // Disable videoModeSelect to prevent changes once we set the changes in motion.
      this.videoModeDropdown.$el.attr('disabled', '')
      this.model.recording.set('videoRecordingMode', e.id)
    },

    showInviteLink: function () {
      var inviteLinkModalView = new zc.views.ModalView({
        addClass: 'invite-link-modal',
        ChildView: zc.views.InviteFormView,
        exit: true,
        collection: new zc.collections.Invites([{}, {}, {}]),
        callback: function () {
          inviteLinkModalView.exit()
        }
      })
      inviteLinkModalView.render()
    },

    showPostproductionLoading: function () {
      this.$postproduction.addClass('loading')
    },

    hidePostproductionLoading: function () {
      this.$postproduction.removeClass('loading')
    },

    /**
     * Called when a user joins, leaves or establishes a connection
     * If all users are out of the green room, the host should be able to start a recording
     */
    shouldShowWaitingForMics: function () {
      if (this.model.get('isRecording') || this.model.get('currentCountdown')) return

      // check if any user is in the green room
      var inGreenRoom = this.model.project.lobby.users.filter(function (user) {
        return user.get('greenroom')
      }).length

      if (inGreenRoom > 0 && !this.model.recording.get('isRecording')) {
        this.showWaitingForMics()
      } else {
        this.hideWaitingForMics()
      }
    },

    allUploadedChange: function (model, allUploaded) {
      if (allUploaded) {
        this.allowDownload()
        this.allowPostproduction()
      }
    },

    showWaitingForMics: function () {
      this.$controls.addClass('waiting-for-mics')
    },

    hideWaitingForMics: function () {
      this.$controls.removeClass('waiting-for-mics')
    },

    recordClicked: function (e) {
      zc2.log.info('Start recording button clicked')

      e.preventDefault()
      var isRecording = !this.model.get('isRecording')

      if (isRecording) {
        console.log('Clicked "Start recording"')
        var countdownResult = this.model.startCountdown()
        // startCountdown will return a value if it creates a notification, and undefined if it finishes correctly
        // We can utilize that to identify if the function gave a notification, and prevent a swap to the duration
        //    in the event that it did. (This is likely because health checks were not complete)
        if (countdownResult === undefined) {
          this.$videoModeBlock.hide()
          this.renderDuration()
        }
      }
    },

    onCountdown: function (recorderModel, countdownValue) {
      var $timer = this.$el.find('.countdown-timer')
      if (typeof countdownValue === 'number') {
        // countdown from 3. 3 is the first time the countdown is called
        if (countdownValue === 3) {
          this.$recordingVisuals.addClass('counting')
        }
        $timer.text(countdownValue)
      } else {
        // null == countdown aborted
        this.$recordingVisuals.removeClass('counting')
      }
    },

    stopRecording: function (e) {
      zc2.log.info('Stop recording button clicked')

      e.preventDefault()
      this.model.initiateStopRecordingTransaction()
    },

    isRecordingChange: function (model, isRecording) {
      if (isRecording) {
        this.startedRecording()
      } else {
        this.stoppedRecording()
      }
    },

    startedRecording: function () {
      this.showRecordingState()
      this.timerView.startPollingTimer()
      analytics.track('Started Recording', {
        projectId: app.location.id,
        projectName: app.location.get('name'),
        recordingId: app.location.recorder.recording.id
      })
      this.startRecordingAnalyticsInterval()

      window.addEventListener('unload', this.onUnload)
    },

    stoppedRecording: function () {
      this.showFinishedState()
      this.timerView.stopPollingTimer()
      analytics.track('Completed Recording', {
        projectId: app.location.id,
        projectName: app.location.get('name'),
        recordingId: app.location.recorder.recording.id,
        duration: this.model.timer.duration()
      })
      this.stopRecordingAnalyticsInterval()
      window.removeEventListener('unload', this.onUnload)
    },

    startRecordingAnalyticsInterval: function () {
      var self = this
      this.recordingAnalyticsInterval = setInterval(function () {
        if (app.location.recorder) {
          analytics.track('Recording In Progress', {
            projectId: app.location.id,
            projectName: app.location.get('name'),
            recordingId: app.location.recorder.recording.id,
            duration: self.model.timer.duration()
          })
        } else {
          self.stopRecordingAnalyticsInterval()
        }
      }, 1000 * 60 * 5) // Every five minutes.  This keeps sessions alive for real-time analytics.
    },

    stopRecordingAnalyticsInterval: function () {
      this.recordingAnalyticsInterval && clearInterval(this.recordingAnalyticsInterval)
    },

    showRecordingState: function () {
      this.$el.removeClass('finished')
      this.$el.addClass('is-recording')
      this.$audioSettings.addClass('disabled')
      this.$stop.removeClass('disabled')
      this.$pause.removeClass('disabled')
      this.$pause.removeClass('paused')
      this.$inviteLinkWrapper.removeClass('visible')
    },

    showPausedState: function () {
      this.$pause.addClass('paused')
    },

    showFinishedState: function () {
      this.inFinishedState = true
      this.$el.removeClass('is-recording')
      this.$el.addClass('finished')
      this.$audioSettings.removeClass('disabled')
      this.$invite.hide()
      this.allowDownload()
      this.$addFootnote.hide()

      if (this.model.recording.canAllowPostproduction()) {
        this.$postproduction.show()
      }
    },

    togglePause: function (e) {
      e.preventDefault()
      if (this.model.get('paused')) {
        zc2.log.info('Resume recording button clicked')
        this.model.initiateResumeTransaction()
      } else {
        zc2.log.info('Pause recording button clicked')
        this.model.initiatePauseTransaction()
      }
    },

    pausedChange: function (model, paused) {
      if (paused) {
        this.timerView.stopPollingTimer()
        this.showPausedState()
      } else {
        this.timerView.startPollingTimer()
        this.showRecordingState()
      }
      analytics.track('PauseChange', {
        paused: paused,
        projectId: app.project.id,
        recordingId: app.project.recorder.recording.id,
        userId: app.user.id
      })
    },

    startOver: function (e) {
      e.preventDefault()
      var recordings = this.model.project.recordings
      // get the last recording and see if that one has not started
      var createNewRecording = !recordings.at(recordings.length - 1).hasNotStarted()

      if (createNewRecording) {
        analytics.track('New recording', {
          userId: app.user.id,
          projectId: app.project.id,
          recordingId: this.model.recording.id,
          videoRecordingMode: this.model.recording.get('videoRecordingMode')
        })
      }
      this.model.startOver(createNewRecording)
    },

    hideFirstRow: function () {
      var firstRowHeight = this.$firstRowTools.outerHeight()
      this.$firstRowTools.css({ 'margin-top': -firstRowHeight })
    },

    onUnload: function () {
      var movTrack = app.user.getTrack('mov', 'video')
      var webmTrack = app.user.getTrack('webm', 'video')
      if (movTrack) {
        movTrack.pageUnloaded()
      }

      if (webmTrack) {
        webmTrack.pageUnloaded()
      }
    },

    play: function () {
      this.model.set({ playing: true })
    },

    allowRecording: function () {
      this.enableControls()
      this.$notRecording.show()
    },

    disallowRecording: function () {
      this.$record.addClass('disabled')
      // this.disableControls();
    },

    allowDownload: function () {
      this.$viewOnCloudDrive.show()
    },

    allowPostproduction: function () {
      this.$postproduction.css({ display: 'block', opacity: 0 })
      this.$postproduction.animate({ opacity: 1 })
    },

    enableControls: function () {
      this.$controls.removeClass('disabled')
    },

    failedLocalBackup: function () {
      if (this.localbackupFailedMesasge) return

      // we're also logging inside the worker
      console.error('Error saving to local backup')

      this.localbackupFailedMesasge = true

      var message = 'There seems to be a problem with accessing the storage on this device. '
      message += 'In case of an error, a local backup for this recording might be incomplete.'
      utils.notify('alert', message, { ttl: 7000 })
    },

    disableControls: function () {
      this.$controls.addClass('enabled')
    },

    setRoleClass: function () {
      if (app.user.isHost()) {
        this.$el.addClass('host')
      } else {
        this.$el.addClass('guest')
      }
    },

    showAccessingMediaDevices: function () {
      var self = this
      // if we are already displaying the popup, ignore subsequent calls
      if (this.accessingMediaDevicesModal && this.showAccessingMediaTimeout) return

      this.accessingMediaDevicesModal = new zc.views.ModalView({
        addClass: 'accessing-media-devices-modal',
        model: this.model.recorder,
        force: true,
        ChildView: zc.views.AccessingMediaDevicesView
      })

      this.showAccessingMediaTimeout = setTimeout(function () {
        self.accessingMediaDevicesModal.render()
      }, 2000)
    },

    hideAccessingMediaDevices: function () {
      clearTimeout(this.showAccessingMediaTimeout)

      // only call exit if the modal has been previously rendered
      if (this.accessingMediaDevicesModal) {
        this.accessingMediaDevicesModal.exit()
        this.accessingMediaDevicesModal = undefined
        this.showAccessingMediaTimeout = undefined
      }
    },

    renderDuration: function () {
      this.$durationBlock.removeAttr('style')
      this.timerView = new zc.views.TimerView({ model: this.model.timer, el: this.$timer })
      this.timerView.render()
    },

    renderSoundboard: function () {
      this.soundboardView = new zc.views.SoundboardView({
        el: this.$soundboard,
        collection: this.model.project.soundboardSamples
      })

      this.soundboardView.render()
    },

    setupVidemodeDropdown: function () {
      // Correctly populate dropdown's initial value
      var recordingMode = this.model.recording.get('videoRecordingMode')

      this.videoModeDropdown = new zc.views.DropdownSelectView({
        items: [
          { id: 'enabled', title: 'Record Video, Record Audio', subtitle: '', selected: recordingMode === 'enabled' },
          { id: 'displayOnly', title: 'Show Video, Record Audio', subtitle: '', selected: recordingMode === 'displayOnly' },
          { id: 'disabled', title: 'No Video, Record Audio', subtitle: '', selected: recordingMode === 'disabled' }
        ],
        selectState: '',
        iconClass: false,
        defaultErrorTitle: '',
        smallMode: true,
        hideArrows: true
      })
      this.videoModeDropdown.render()
      this.videoModeDropdown.$el.addClass('video-mode-dropdown')
      this.$('.video-mode-wrapper').append(this.videoModeDropdown.$el)
      this.videoModeDropdown.onItemSelect = this.videoModeChange.bind(this)
    },

    renderVideoMode: function () {
      this.$videoModeBlock.removeAttr('style')
      this.$durationBlock.hide()
    },

    render: function () {
      var attrs = this.model.attrs()
      this.$el.html(this.template(attrs))
      this.topBlock = this.$('.top-tools')
      this.$audioSettings = this.$('.media-settings')
      this.$recordingVisuals = this.$('.recording-visuals')
      this.$oscope = this.$('.oscilloscope')
      this.$frequencyAnalyser = this.$('.frequency-analyser')
      this.$controls = this.$('.controls')
      this.$invite = this.$('.invite')
      this.$actions = this.$('.actions')
      this.$startOver = this.$('.start-over')
      this.$record = this.$('.record')
      this.$pause = this.$('.pause')
      this.$stop = this.$('.stop')
      this.$resume = this.$('.resume')
      this.$viewOnCloudDrive = this.$('.view-on-cloud-drive')
      this.$timer = this.$('.timer')
      this.$postproduction = this.$('.postproduction')
      this.$inviteLinkWrapper = this.$('.invite-link-wrapper')
      this.$addFootnote = this.$('.add-footnote')
      this.$inviteLink = this.$('.invite-link')
      this.$firstRowTools = this.$('.top-tools .first-row')
      this.$raiseHand = this.$('.raise-hand')
      this.$soundboard = this.$('.soundboard')
      this.$videoModeBlock = this.$('.video-mode-block')
      this.$durationBlock = this.$('.duration-block')

      this.setupVidemodeDropdown()

      // We will use the existence of tracks to check if this recording has recorded
      if (this.model.recording.tracks.length) {
        this.renderDuration()
        this.$videoModeBlock.hide()
      } else {
        this.renderVideoMode()
      }

      if (app.user.isHost()) {
        if (app.user.getFeature('soundboard') && app.user.settings.get('soundboard')) {
          this.renderSoundboard()
        }
      }
      this.setRoleClass()

      // removed finished class from root element
      // if we should continue to show the finished state
      // it will be added again below
      this.$el.removeClass('finished')
      if (this.model.hasFinishedRecording()) {
        this.showFinishedState()
      } else {
        this.shouldShowWaitingForMics()
      }

      return this
    }
  })
})()
