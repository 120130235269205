/* globals zc Backbone app utils PersistentStore debug CloudStore servars */

(function () {
  'use strict'

  var dbg = debug('zc:sample')

  /**
   * Model used to represent a sound on the Soundboard
   * @param  {Object} attrs    The initial attribute, it should at least have: _id, name and format
   */
  zc.models.Sample = Backbone.Model.extend({
    initialize: function (attrs, options) {
      // in case the attrs doesn't contain a format
      var format = attrs.format || this.get('format')

      this.filename = attrs.filename || attrs.name + '.legacy.' + attrs.format // Legacy soundboard tracks don't have filenames
                                                                               // Something is better than nothing
      this.actx = app.actx
      this.output = this.actx.createGain()

      this.cloudStore = new CloudStore({
        provider: 'gcs',
        name: attrs.name,
        format: attrs.format,
        mimeType: attrs.mimeType,
        gatewayUploadCreatorUrl: servars.gatewayRecordingUploadUrl
      })

      // Used to load from an old persistentStore which used a different naming convention
      if (attrs.legacy) {
        this.migrateFromIdb(attrs, format)
      } else {
        this.persistentStore = new PersistentStore({parentId: attrs.format + '_' + attrs.name, format: format})
        if (!attrs.createdFromFileUpload && (attrs._id || attrs.url)) {
          this.set('loading', true)
          this.fetchArrayBuffer()
        }
      }

      /**
       * Keep track if we are in the process of stopping
       * @type {Boolean}
       */
      this.stopping = false

      this.set('playing', false)

      this.on('change:loop', this.updateLoop)
      this.on('change:gain', this.handleGainChange)

      this.on('remove', this.handleRemove)
    },

    defaults: {
      name: null,
      url: null,
      playing: false,
      loop: false,
      loaded: false,
      format: 'pcm',

      attack: 0,
      decay: 0,
      gain: 0.2,
      release: 2
    },

    attrs: function () {
      var attrs = this.toJSON()
      return attrs
    },

    /**
     * Used to take soundboard tracks that were never on gcp and put them into gcp
     */
    migrateFromIdb: function (attrs, format) {
      this.persistentStore = new PersistentStore({ parentId: attrs._id, format: format })
      this.persistentStore.extractData(true).then(async function (data) {
        if (data.length) {
          var newStore = new PersistentStore({ parentId: attrs.format + '_' + attrs.name, format: format })

          this.persistentStore = newStore
          await this.saveLocalArrayBuffer(data[0])
          this.cloudStore.mimeType = 'audio/' + format
          await this.saveRemoteArrayBuffer(data[0])

          if (attrs._id || attrs.url) {
            this.set('loading', true)
            this.fetchArrayBuffer()
          }
        } else {
          console.log(data)
          utils.notify('notify', 'Failed to upload ' + attrs.name + ' please remove and re-add this sample.', {}, 'Soundboard Upload Failed')
        }
      }.bind(this)).catch(function (error) {
        console.error(error)
        utils.notify('notify', 'Failed to upload ' + attrs.name + ' please remove and re-add this sample.', {}, 'Soundboard Upload Failed')
      })
    },

    /**
     * called when the model is removed
     * it will stop the audio if it's playing
     * and it will remove its audiostore
     */
    handleRemove: function () {
      var self = this
      this.stop()
      // Make sure the url exists, and contains the userId
      // If it doesn't contain the userId, the user is not allowed to delete it, so skip
      // If there is no url, it hasn't been uploaded, so skip
      if (this.get('url') && this.get('url').includes(app.user.id)) {
        self.cloudStore.delete(app.project.id, this.getRemotePath(), true)
      }
      return self.persistentStore.dbDelete()
    },

    handleGainChange: function (model, gain) {
      this.output.gain.value = gain
      // the values differ a little
      if (this.output.gain.value.toFixed(4) !== gain.toFixed(4)) {
        // this is for firefox mainly
        // using setTargetAtTime seems to break linearRampToValueAtTime
        this.output.gain.setValueAtTime(gain, this.actx.currentTime)
      }
    },

    /**
     * used when initiliazing the model
     * it tries to fetch the buffer from idb and if nothing is found
     * it looks for the url
     */
    fetchArrayBuffer: function () {
      var self = this
      var url = this.get('url')
      var format = self.get('format')
      var headerSize = format === 'wav' ? 44 : 0

      self.fetchLocalArrayBuffer().then(function (arrayBuffer) {
        if (arrayBuffer && arrayBuffer.byteLength > headerSize) {
          return self.loadAudioBuffer(arrayBuffer)
        } else if (url) {
          // if we have an url, load it from there
          // and then save it locally
          return self.fetchRemoteArrayBuffer(url)
            .then(self.saveLocalArrayBuffer.bind(self))
            .then(self.loadAudioBuffer.bind(self))
        }
      })
    },

    /**
     * used by fetchArrayBuffer to try and read the audio file from idb
     * @param  {Function} cb Callback
     */
    fetchLocalArrayBuffer: function () {
      var self = this

      dbg('FetchLocalBuffer', this.attributes)

      return self.persistentStore.export(true).then(async function (data) {
        var arrayBuffer = await data.arrayBuffer()
        if (!arrayBuffer) {
          // return empty arrayBuffer early if there is no audio
          return new ArrayBuffer()
        }

        // new uploaded sounds will come as an ArrayBuffer
        if (arrayBuffer instanceof ArrayBuffer) {
          return arrayBuffer
        } else {
          // sounds that are already in the soundboard will come as a Blob
          // saved on the audio attribute
          return new Promise(function (resolve, reject) {
            if (arrayBuffer.audio instanceof Blob) {
              var fileReader = new FileReader()
              fileReader.onload = function () {
                // delete what we have saved in the audiostore
                self.handleRemove()

                resolve(this.result)
              }
              fileReader.readAsArrayBuffer(arrayBuffer.audio)
            } else {
              resolve(new ArrayBuffer())
            }
          })
        }
      }).catch(function () {
        // return empty arrayBuffer early if this fails (no audio)
        return new ArrayBuffer()
      })
    },

    /**
     * used by fetchArrayBuffer to try and read the audio file from idb
     * @param  {String}   url The url of the file
     * @param  {Function} cb  Callback
     */
    fetchRemoteArrayBuffer: async function (url) {
      dbg('FetchRemoteBuffer', this.attributes)

      this.set('loaded', false)
      try {
        return await utils.audio.fetchArrayBuffer(url)
      } catch (e) {
        console.warn('Error encountered with existing soundboard url, attempting to create new soundboard url')
        console.warn(e)
        try {
          var newUrl = await this.createDownloadUrl()
          console.log('New Soundboard Url: ' + newUrl)
          return await utils.audio.fetchArrayBuffer(newUrl)
        } catch (e2) {
          utils.notify('alert', 'Failed to load soundboard sample "' + this.get('name') + '"')
          console.error('Failed to get soundboard with new download url, failing!')
          console.error(e2)
          throw e2
        }
      }
    },

    /**
     * Used to save an audio ArrayBuffer to indexDb as well as Gcp
     * @param {ArrayBuffer} arrayBuffer The audio file buffer
     * @returns {Promise<ArrayBuffer>} The input ArrayBuffer, to enable chaining
     */
    saveArrayBuffer: async function (arrayBuffer) {
      await this.saveRemoteArrayBuffer(arrayBuffer)
      await this.saveLocalArrayBuffer(arrayBuffer)
      return arrayBuffer
    },

    /**
     * used to save an audio ArrayBuffer file to idb
     * @param  {ArrayBuffer} arrayBuffer The audio file buffer
     * @returns {Promise<ArrayBuffer>} The input ArrayBuffer, to enable chaining
     */
    saveLocalArrayBuffer: function (arrayBuffer, blobify) {
      // This is done to mimic the way existing audio data is saved to indexedDb
      var localData = arrayBuffer
      if (blobify === undefined || blobify) {
        localData = {
          audio: new Blob([arrayBuffer])
        }
        if (Array.isArray(arrayBuffer)) {
          localData.audio = new Blob(arrayBuffer)
        }
      }

      return this.persistentStore.add(localData)
        .then(function () {
          return arrayBuffer
        })
    },

    getRemotePath: function () {
      return 'soundboard/' + app.user.id + '/' + this.filename
    },

    createDownloadUrl: async function () {
      var path = this.getRemotePath()
      return this.cloudStore.createDownloadUrl(app.project.id, path, true)
    },

    /**
     * used to save an audio ArrayBuffer file to gcp bucket
     * @param {ArrayBuffer} arrayBuffer The audio file buffer
     * @returns {Promise<ArrayBuffer>} The input ArrayBuffer, to enable chaining
     */
    saveRemoteArrayBuffer: async function (arrayBuffer) {
      var path = this.getRemotePath()
      var uploadUrl = await this.cloudStore.createUploadUrl(app.project.id, undefined, undefined, undefined, path, true)
      await this.cloudStore.upload({ url: uploadUrl, blob: new Blob([arrayBuffer]), offset: 0 })
      this.set('url', await this.createDownloadUrl())
      return arrayBuffer
    },

    /**
     *  used when uploading a new file, or reading from idb
     *  this creates a AudioBuffer that can be played back
     *  using the web audio api
     * @param  {ArrayBuffer}  arrayBuffer
     */
    loadAudioBuffer: function (arrayBuffer) {
      var self = this
      return this.actx.decodeAudioData(arrayBuffer).then(function (audioBuffer) {
        self.set('loaded', true)
        self.audioBuffer = audioBuffer
        return audioBuffer
      }).catch(console.error)
    },

    /**
     * used to start playing the sound
     * it will create a createBufferSource and connect it further
     */
    play: function () {
      var self = this

      if (!this.audioBuffer) {
        utils.notify('alert', 'This file couldn\'t be played. Please remove it and try and add it back again.')
        return
      }
      this.set('playing', true)

      this.source = this.actx.createBufferSource()
      this.source.buffer = this.audioBuffer

      this.source.channelCountMode = 'explicit'
      this.source.channelInterpretation = 'speakers'
      this.source.channelCount = 1

      this.source.loop = this.get('loop')

      this.output.gain.value = 0
      this.source.connect(this.output)

      this.source.onended = function () {
        self.set('playing', false)
      }

      this.source.start(0)
      this.output.gain.linearRampToValueAtTime(this.get('gain'), this.actx.currentTime + this.get('attack'))

      /** Trigger a flag to mark this recording as having had soundboard samples **/
      if (app.project.recorder.get('isRecording') && !app.project.recorder.recording.get('paused')) {
        try {
          localStorage.setItem(app.project.recorder.recording.id + '-hasSoundboard', true)
        } catch (e) {
          console.warn(e)
        }
      }
    },

    /**
     * used to stop an audio if it's playing
     * it will fade down over a period of 2 seconds
     */
    stop: function () {
      var self = this
      if (this.get('playing') && !this.stopping) {
        var release = this.get('release')
        this.stopping = true
        this.trigger('release', self)

        this.output.gain.linearRampToValueAtTime(0, app.actx.currentTime + release)
        try {
          setTimeout(function () {
            self.source.stop()
            self.set('playing', false)
            self.stopping = false
          }, release * 1000)
        } catch (err) {
          console.error(err)
          self.set('playing', false)
          self.stopping = false
        }
      }
    },

    _trigger: function () {
      if (this.get('playing')) {
        this.stop()
      } else {
        this.play()
      }
    },

    updateLoop: function (model, loop) {
      if (this.source) {
        this.source.loop = loop
      }
    },

    connect: function (destination, channel) {
      this.output.connect(destination, channel)
    },

    disconnect: function (arg) {
      this.output.disconnect(arg)
    }
  })
})()
