/* globals zc debug _ Backbone analytics */

(function () {
  'use strict'

  var dbg = debug('zc:healthCheckManager')

  zc.models.HealthCheckManager = Backbone.Model.extend({
    initialize: function (attrs, options) {
      this.healthCheckInterval = options.healthCheckInterval || 1000 * 30 // 30 seconds

      var app = this.app = options.app
      var project = this.project = options.project
      var recorder = this.recorder = options.recorder

      // dependencies
      this.listenTo(project, 'change:actxIsActive', this.dependencyChange)
      // this.listenTo(recorder.call.audioInput, 'change', this.dependencyChange)
      this.listenTo(recorder.mediaDevices, 'reset', this.dependencyChange)
      this.listenTo(app.user.tracks, 'add remove', this.dependencyChange)
      this.listenTo(app.user.settings, 'change:wavRecording', this.dependencyChange)
      this.listenTo(recorder, 'sampleRateMismatch', this.dependencyChange)
    },

    dependencyChange: function () {
      var self = this
      var app = this.app
      var recorder = this.recorder
      var requirements = []
      var prepStarted = !!recorder.preparingPromise
      var hasStartedRecording = recorder.hasStartedRecording()
      var insideReCheckHealthWindow = prepStarted && !hasStartedRecording
      dbg('HealthCheck Dependency Change')

      if (insideReCheckHealthWindow && !this.queuedHealthCheckDependencyChange) {
        this.queuedHealthCheckDependencyChange = true

        // we want to wait until prep has finished
        requirements.push(recorder.preparingPromise)

        // and also wait for any previous runs of the health check have finished
        if (this.preRecordingHealthCheckPromise) requirements.push(this.preRecordingHealthCheckPromise)

        Promise.all(requirements).then(function () {
          // clear out past health check results
          app.user.clearHealthChecks()

          // so the UI doesn't thrash around too fast
          setTimeout(function () {
            // start new health check
            self.queuedHealthCheckDependencyChange = false
            self.preRecordingHealthCheck()
          }, 1500)
        })
      }
    },

    checkAllUsersStatus: function () {
      var statuses = this.project.lobby.users.map(function (user) {
        return user.getHealthCheckStatus()
      })

      if (statuses.indexOf('pending') > -1) {
        return 'pending'
      } else if (statuses.indexOf('failed') > -1) {
        return 'failed'
      } else if (statuses.indexOf('warning') > -1) {
        return 'warning'
      } else {
        return 'passed'
      }
    },

    checkAllUsersPassed: function () {
      var allHealthChecksPassed = false
      var checkResults = this.project.lobby.users.map(function (user) {
        return user.didPassHealthCheck()
      })

      console.log('Health Check Statuses: ', checkResults)

      if (checkResults.indexOf(false) < 0) {
        allHealthChecksPassed = true
      }

      return allHealthChecksPassed
    },

    preRecordingHealthCheck: function () {
      console.log('Start pre recording health checks.')
      var app = this.app

      this.preRecordingHealthCheckPromise = Promise.all([
        this.warnings(),
        this.failures()
      ]).then(function (checks) {
        console.log('Finished health checks.')
        app.user.warningHealthChecks.reset(checks[0])
        app.user.criticalHealthChecks.reset(checks[1])

        var warnings = checks[0].filter(function (check) { return !check.passed })
        var criticals = checks[1].filter(function (check) { return !check.passed })

        if (warnings.length) {
          console.warn('Finished health checks with ', warnings.length, 'warnings.')
        }
        if (criticals.length) {
          console.error('Finished health checks with ', criticals.length, 'criticals.')
          console.error('Failed tests:', criticals.map(function (test) { return test.assertion }))
        }

        // outright failures are the first priority
        // if (checks[1].length) throw new Error([''].concat(checks[1]).join('<br/>'))

        // if there are no failures, then deal with any warnings
        // if (checks[0].length) self.handleHealthCheckWarnings(checks[0]) // warnings
        // return {warnings: checks[0], failures: checks[1]}
      }).catch(function (err) {
        // handle health check failure
        // maybe do some stuff here first but be sure to
        // throw an error so we halt the prepareToRecord chain

        throw new Error('A critical issue was encountered while preparing to record: ' + err)
      })

      return this.preRecordingHealthCheckPromise
    },

    warnings: function () {
      // var app = this.app
      // var networkInfo = navigator.connection
      var memory = navigator.deviceMemory
      var isAudioOnly = this.recorder.recording.get('videoRecordingMode') === 'disabled'

      // var minRecFreeGB = isAudioOnly ? 2 : 10
      // var minRecDownlink = 1.5
      // var recNetworkEffectiveType = '4g'
      var minRecMemoryGB = isAudioOnly ? 4 : 8

      var warningChecks = [
        // {assertion: 'It is recommended to have at least ' + minRecFreeGB + 'GB of free local storage space for saving backups <a target="_blank" href="https://support.zencastr.com/en/articles/2775783-it-is-recommended-to-have-at-least-2gb-of-free-local-storage-space-for-saving-backups">support article</a>',
        //   reality: {},
        //   test: function () {
        //     var _self = this
        //     return app.localStorageManager.queryStorageQuota().then(function (quota) {
        //       _self.reality.quota = quota
        //       return quota.free === -1 || quota.free / 1000 / 1000 / 1000 >= minRecFreeGB
        //     })
        //   }},
        // {assertion: 'It is recommended that access to local storage should be persistent <a target="_blank" href="https://support.zencastr.com/en/articles/2775755-it-is-recommended-that-access-to-local-storage-should-be-persistent">support article</a>',
        //   reality: {},
        //   test: function () {
        //     this.reality.storageSupported = !!navigator.storage
        //     this.reality.storagePersisted = navigator.storage && navigator.storage.persisted()
        //     return Promise.resolve(navigator.storage ? navigator.storage.persisted() : true) // return true when not supported so as not to report warnings when we don't know
        //   }},
        // {assertion: 'The recommended minimum network speed is ' + minRecDownlink + 'Mbps down <a target="_blank" href="https://support.zencastr.com/en/articles/2775780-the-recommended-minimum-network-speed-is-1-5-mbps-down">support article</a>',
        //   reality: {},
        //   test: function () {
        //     var networkInfo = navigator.connection
        //     this.reality.downlink = networkInfo ? networkInfo.downlink : -1 // -1 indicates unsupported
        //     return Promise.resolve(networkInfo ? networkInfo.downlink > minRecDownlink : true)
        //   }},
        {assertion: 'The recommended minimum memory is ' + minRecMemoryGB + 'GB ' +
          (isAudioOnly ? ' for an audio only recording ' : '') +
          '<a target="_blank" href="https://support.zencastr.com/en/articles/2775782-the-recommended-minimum-memory-is-4gb">support article</a>',
          reality: {},
          test: function () {
            this.reality.memory = memory || -1 // -1 indicates unsupported
            return Promise.resolve(memory ? memory >= minRecMemoryGB : true)
          }}
        // {assertion: 'It is strongly recommended that the browser should be updated to the latest version <a target="_blank" href="https://support.zencastr.com/en/articles/2775784-it-is-strongly-recommended-that-the-browser-should-be-updated-to-the-latest-version">support article</a>',
        //   reality: {},
        //   test: function () {
        //     this.reality.browserVersion = ua.browser.version
        //     return Promise.resolve(Number(ua.browser.major) >= zc.conf.latestBrowserVersion[ua.browser.name.toLowerCase()])
        //   }},
        // {assertion: 'It is recommended to use an input device with a sample rate that is above 44100hz.',
        //   reality: {},
        //   test: function () {
        //     return new Promise(function (resolve) {
        //       var samplerate = app.user.attributes.platform.sampleRate
        //       resolve(samplerate >= 44100)
        //     })
        //   }}
      ]

      return Promise.all(warningChecks.map(function (check) {
        return check.test().then(function (result) {
          check.level = 'warning'
          check.passed = !!result
          return check
        })
      }))
    },

    failures: function () {
      var app = this.app
      var recorder = this.recorder
      var wavRecording = app.user.settings.get('wavRecording')
      var hasSoundboard = app.user.isHost() && app.user.settings.get('soundboard')
      var hasVideo = recorder.hasVideoRecording
      var expectedTracksLength = 1

      wavRecording && expectedTracksLength++
      hasSoundboard && expectedTracksLength++
      hasVideo && expectedTracksLength++

      // var tracks = app.user.tracks
      // var validFormats = ['wav', 'mp3', 'mov', 'webm']

      var failureChecks = [
        // {assertion: 'The user\'s audio context must be active',
        //   reality: {},
        //   test: function () {
        //     return Promise.resolve(recorder.actx.state === 'running')
        //   }},
        // {assertion: 'The user\'s microphone must properly connected and delivering an active audio signal <a target="_blank" href="https://support.zencastr.com/en/articles/2775744-the-user-s-microphone-must-properly-connected-and-delivering-an-active-audio-signal">support article</a>',
        //   reality: {},
        //   retries: 0,
        //   retryLimit: 5,
        //   test: function () {
        //     var _self = this
        //     var mediaStreamSilent = (function () {
        //       var fftSizeMax = 32768
        //       var fftSizeStash = recorder.analyserNode.fftSize
        //       recorder.analyserNode.fftSize = fftSizeMax // temporarily use largest fft size so we scan as much audio as possible
        //       var floatTimeData = new Float32Array(fftSizeMax)
        //       recorder.analyserNode.getFloatTimeDomainData(floatTimeData)
        //       recorder.analyserNode.fftSize = fftSizeStash // set fft back to stashed size
        //       var peak = Math.max.apply(Math, floatTimeData)
        //       return !peak
        //     })()
        //
        //     var localAudio = recorder.call.localAudio
        //     var mediaStreamActive = localAudio.mediaStream && localAudio.mediaStream.active
        //
        //     this.reality.micArmed = recorder.get('micArmed')
        //     this.reality.localAudio = localAudio
        //     this.reality.mediaStreamType = localAudio.mediaStream instanceof MediaStream ? 'MediaStream' : false
        //     this.reality.mediaStreamActive = mediaStreamActive
        //     this.reality.mediaStreamSilent = mediaStreamSilent
        //
        //     if (mediaStreamSilent && mediaStreamActive && (this.retries++ < this.retryLimit)) {
        //       return new Promise(function (resolve, reject) {
        //         setTimeout(function () {
        //           resolve(_self.test())
        //         }, 1500)
        //       })
        //     } else {
        //       return Promise.resolve(recorder.get('micArmed') && localAudio.mediaStream instanceof MediaStream && localAudio.mediaStream.active && !mediaStreamSilent)
        //     }
        //   }},
        {assertion: 'The pending recording must to be successfully created and saved to the database <a target="_blank" href="https://support.zencastr.com/en/articles/2775742-the-pending-recording-must-be-successfully-created-and-saved-to-the-database">support article</a>',
          reality: {},
          test: function () {
            this.reality.recordingId = recorder.recording.id
            return Promise.resolve(!!recorder.recording.id)
          }}
        // {assertion: 'The user\'s tracks must to be successfully created and saved to the database <a target="_blank" href="https://support.zencastr.com/en/articles/2775740-the-user-s-tracks-must-be-successfully-created-and-saved-to-the-database">support article</a>',
        //   reality: {},
        //   test: function () {
        //     this.reality.ids = tracks.pluck('_id')
        //     this.reality.formats = tracks.pluck('format')
        //     this.reality.expectedTracksLength = expectedTracksLength
        //     var allTracksHaveIds = tracks.filter(function (t) { return !!t.id }).length === expectedTracksLength
        //     var allTracksHaveValidFormat = tracks.filter(function (t) { return validFormats.indexOf(t.get('format')) >= 0 }).length === expectedTracksLength
        //     return Promise.resolve(allTracksHaveIds && allTracksHaveValidFormat)
        //   }},
        // {assertion: 'All tracks must have received a cloud storage upload url <a target="_blank" href="https://support.zencastr.com/en/articles/2775743-all-tracks-must-have-received-a-cloud-storage-upload-url">support article</a>',
        //   reality: {},
        //   test: function () {
        //     this.reality.uploadUrls = tracks.pluck('uploadUrl')
        //     this.reality.expectedTracksLength = expectedTracksLength
        //     return Promise.resolve((tracks.filter(function (t) { return !!t.get('uploadUrl') })).length === expectedTracksLength)
        //   }},
        // {assertion: 'All tracks must have successfully opened a connection to the user\'s local storage for saving backups <a target="_blank" href="https://support.zencastr.com/en/articles/2775741-all-tracks-must-have-successfully-opened-a-connection-to-the-user-s-local-storage-for-backups">support article</a>',
        //   reality: {},
        //   test: function () {
        //     var _self = this
        //     _self.reality.dbMetas = []
        //     return Promise.all(tracks.map(function (t) { return t.persistentStore.getDbMeta() }))
        //       .then(function (dbMetas) {
        //         _self.reality.dbMetas = dbMetas
        //         return _.filter(dbMetas, function (meta) { return Number.isInteger(meta.totalBytes) })
        //       })
        //       .then(function (dbMetaTests) { return dbMetaTests.length === tracks.length })
        //       .catch(function (err) {
        //         if (err === 'TypeError: db.transaction is not a function' || err === 'InvalidStateError: A mutation operation was attempted on a database that did not allow mutations.') {
        //           _self.assertion = 'We were unable to connect to the persistent storage. This could mean you are out of disk space or that you are in Private Browsing mode. Read more <a href="https://support.zencastr.com/system-requirements/can-i-use-zencastr-in-incognito-mode" target="_blank" rel="noopener">here</a>.'
        //         }
        //       })
        //   }},
        // {assertion: 'The sample rate must be within the allowable range (8000hz - 96000hz) <a target="_blank" href="https://support.zencastr.com/en/articles/2775760-the-sample-rate-must-be-within-the-allowable-range-44100hz-96000hz">support article</a>',
        //   reality: {},
        //   test: function () {
        //     return new Promise(function (resolve) {
        //       var samplerate = app.user.attributes.platform.sampleRate
        //       resolve(samplerate >= 8000 && samplerate <= 96000)
        //     })
        //   }}
      ]

      return Promise.all(failureChecks.map(function (check) {
        return check.test().then(function (result) {
          check.level = 'critical'
          check.passed = !!result
          return check
        })
      }))
    },

    /**
     * Health checks that are run specifically during a recording
     */
    midRecordingHealthCheck: function () {
      var app = this.app
      var recorder = this.recorder
      var tracks = app.user.tracks

      var failureChecks = [{
        assertion: 'All tracks must have successfully saved audio to indexedDb',
        test: function () {
          var _self = this
          return Promise.all(tracks.map(function (track) {
            return track.persistentStore.getDbMeta()
              .then(function (dbMeta) {
                // do not test on soundboard mp3 file because it's using vbr
                // and it's hard to predict the file size
                if (track.get('type') === 'soundboard') return true

                // if it's a mp3 12800
                // for wav 88200
                var bytesPerSecond = track.get('format') === 'wav' ? 88200 : 12800

                // get the duration of the recordings, in seconds
                var duration = recorder.timer.duration() / 1000
                // there's a delay between when the data is saved and when this check runs
                // subtract some seconds so we get closer to the save time
                // usually there's a 5 - 8 delay for mp3
                // and 2s for wav
                var timeDifference = 2
                duration -= timeDifference

                // bytes in total for this track
                var total = duration * bytesPerSecond
                var difference = dbMeta.totalBytes / total
                console.log('saving progress', track.get('format'), dbMeta.duration / 1000, dbMeta.totalBytes, difference, total, duration)

                // if the saved audio is less than 90% of what we should have, fails the test
                if (difference < 0.98) return false
                return true
              })
              .catch(function (err) {
                if (err === 'TypeError: db.transaction is not a function' || err === 'InvalidStateError: A mutation operation was attempted on a database that did not allow mutations.') {
                  _self.assertion = 'We were unable to connect to the persistent storage. This could mean you are out of disk space or that you are in Private Browsing mode. Read more <a href="https://support.zencastr.com/system-requirements/can-i-use-zencastr-in-incognito-mode" target="_blank" rel="noopener">here</a>.'
                }
              })
          }))
          .then(function (result) {
            // if all tracks pass
            return result.length === _.countBy(result)['true']
          })
        }}
      ]

      return Promise.all(failureChecks.map(function (check) {
        return check.test().then(function (testResult) {
          check.level = 'critical'
          check.passed = !!testResult
          return check
        })
      }))
    },

    /**
     * Used to start a setInterval that runs the critical health checks + some additional ones
     * it starts with a 30s delay
     */
    startMidRecordingHealthChecks: function () {
      var self = this
      // if we already have an interval set
      // or we are not recording, return
      // we start this health checks with a 30s delay, if the recording stopped in the meantime, exit
      if (this.healthCheckIntervalMethod || !this.get('isRecording')) return

      this.healthCheckIntervalMethod = setInterval(function () {
        Promise.all([
          self.midRecordingHealthCheck()
        ])
        .then(function (tests) {
          // we receive an array of arrays, so we concat
          tests = tests.reduce(function (sum, arr) { return sum.concat(arr) }, [])
          var passedValues = _.countBy(tests, 'passed')

          // if we have at least one false value
          if (passedValues.false) {
            var error = 'Critical health check failed'
            console.error(error)
            self.recorder.abortRecordingAndReportError(error)

            analytics.track('MidRecordingHealthCheck', {
              category: 'HealthCheck',
              label: 'Failed'
            })
          }
        })
        .catch(function (err) {
          console.error('Error running recording health checks', err)
        })
      }, this.healthCheckInterval)
    },

    /**
     * Clears interval after the recording has finished
     */
    stopMidRecordingHealthChecks: function () {
      clearInterval(this.healthCheckIntervalId)
    }
  })
})()
