/* globals zc _ */

(function () {
  'use strict'

  zc.views.KickedView = zc.views.PageView.extend({

    template: _.template($('.kicked-template').html()),

    renderHeader: function () {
      this.recordingHeaderView = new zc.views.ProjectHeaderView({ configType: 'empty', model: this.model, el: this.$header })
      this.recordingHeaderView.render()
    },

    render: function () {
      this.$el.html(this.template(this.model.toJSON()))
      this.$el.addClass('kicked-page')
      // Render header
      this.$header = this.$('> .header-container')
      this.renderHeader()

      return this
    }
  })
})()
