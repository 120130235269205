/* globals zc _ Backbone */

(function () {
  'use strict'

  zc.views.ContextMenuOptionView = Backbone.View.extend({
    initialize: function (options) {

    },

    template: _.template('<%= name %>'),

    className: 'context-menu-option',

    events: {
      'click': 'callback'
    },

    callback: function (e) {
      this.model.get('callback')()
    },

    render: function () {
      this.$el.html(this.template(this.model.toJSON()))
      return this
    }
  })
})()
