/* globals app zc _ session */

(function () {
  'use strict'

  zc.views.GuestHeaderView = zc.views.BaseView.extend({
    template: _.template($('.project-header-template').html()),

    className: 'header-container guest-header',

    events: {
      'click .media-settings': 'renderMediaSettings',
      'click': 'toggleOpen',
      'click .item': 'handleItemClick'
    },

    // model = project model
    initialize: function (options) {
      this.recorder = this.model.recorder
      this.headerType = options.headerType || 'recording' // 'recording' || 'greenroom'
      this.showVideoRecordingModeIcons = true
      this.videoRecordingMode = this.recorder.recording.get('videoRecordingMode')

      this.model.set({ showTitle: 'Playground', showImgUrl: utils.cdnUrl('/media/images/playground-placeholder.png') })

      if (app.project) {
        zc2.creatorApi.showService.getShowByProjectId(app.project.id, app.project.get('ownerId'))
          .then(function (show) {
            if (show) {
              this.model.set({ showTitle: show.title })
              if (show.imageUrl) {
                this.model.set({ showImgUrl: show.imageUrl })
              }
            }
          }.bind(this))
      }
      this.listenTo(this.model, 'change:showTitle', function () {
        this.$('.showTitle').text(this.model.get('showTitle'))
      }.bind(this))
      this.listenTo(this.model, 'change:showImgUrl', function () {
        this.$('.showinfo img').attr('src', this.model.get('showImgUrl'))
      }.bind(this))

      this.listenTo(this.recorder.recording, 'change:isRecording', this.onRecordingChange)
      this.listenTo(this.recorder, 'change:paused', this.onPausedChange)
      this.listenTo(this.recorder, 'change:currentCountdown', this.startCountdown)
      this.listenTo(this.recorder, 'change:isRecording', this.setRecordingStatus)
    },

    renderMediaSettings: function (e) {
      e.preventDefault()
      // CSS is full of lies. Do not trust it.
      if (this.recorder.get('isRecording')) return

      console.log('Opened settings modal')
      // this.$audioSettings.find('.icon').css({transform: 'rotate(-180deg)'});
      this.mediaSettingsModal = new zc.views.ModalView({
        addClass: 'media-settings-modal',
        model: this.recorder.project,
        settings: app.user.settings,
        callback: this.mediaSettingsCallback.bind(this),
        ChildView: zc.views.MediaSettingsView
      })

      this.mediaSettingsModal.render()
    },

    mediaSettingsCallback: function () {
      console.log('Closed settings modal')
      this.mediaSettingsModal.exit()
    },

    onRecordingChange: function (model, isRecording) {
      if (isRecording) {
        this.startedRecording()
      } else {
        this.stoppedRecording()
      }
      this.setRecordingStatus()
    },

    startedRecording: function () {
      this.timerView.startPollingTimer()
      this.setRecordingStatus()
      window.addEventListener('unload', this.onUnload)
    },

    stoppedRecording: function () {
      this.timerView.stopPollingTimer()
      this.setRecordingStatus()
      window.removeEventListener('unload', this.onUnload)
    },

    onUnload: function () {
      var movTrack = app.user.getTrack('mov', 'video')
      var webmTrack = app.user.getTrack('webm', 'video')
      if (movTrack) {
        movTrack.pageUnloaded()
      }

      if (webmTrack) {
        webmTrack.pageUnloaded()
      }
    },

    onPausedChange: function (model, paused) {
      if (paused) {
        this.timerView.stopPollingTimer()
        this.setRecordingStatus()
        this.$header.addClass('paused')
      } else {
        this.timerView.startPollingTimer()
        this.$header.removeClass('paused')
        this.setRecordingStatus()
      }
    },

    renderTimer: function () {
      this.timerView = new zc.views.TimerView({ model: this.recorder.timer, el: this.$timer })
      this.timerView.render()
    },

    startCountdown: function (recorderModel, countdownValue) {
      var $timer = this.$el.find('.count')
      if (typeof countdownValue === 'number') {
        // countdown from 3. 3 is the first time the countdown is called
        if (countdownValue === 3) {
          this.$header.addClass('counting')
        }
        if (countdownValue === 0) {
          this.$header.removeClass('counting')
        }
        $timer.text(countdownValue)
      } else {
        // null == countdown aborted
        this.$header.removeClass('counting')
      }
    },

    setRecordingStatus: function () {
      this.$status.removeClass('recording')
      this.$header
        .removeClass('before-recording')
        .removeClass('after-recording')
        .removeClass('during-recording')

      var vrmText
      var mode = this.recorder.recording.get('videoRecordingMode')
      var isRecording = this.recorder.recording.get('isRecording')
      var hasStartedRecording = this.recorder.hasStartedRecording()

      // if the recording has started
      if (isRecording) {
        this.$status.text('Recording').addClass('recording')

        // also check if the user has actually started recording
        // which might mean the user is on the green room
        if (this.recorder.get('isRecording')) {
          this.$header.addClass('during-recording')
        }
      } else if (!hasStartedRecording) {
        this.$status.text('')
        this.$header.addClass('before-recording')
      } else {
        // the last state it might be that the user finished recording
        this.$status.text('Finished')
        this.$header.addClass('after-recording')
      }

      switch (mode) {
        case 'enabled':
          vrmText = 'Recording audio and video'
          break
        case 'displayOnly':
          vrmText = 'Recording audio only. Viewing video'
          break
        case 'disabled':
          vrmText = 'Recording audio only'
          break
        default:
          vrmText = '' // defensive programming!
          break
      }

      this.$videoRecordingMode.text(vrmText)
    },

    render: function () {
      this._render({
        showDashboardLink: false,

        showPricingLink: false,
        showInviteLink: false,
        showCloudDrive: false,
        showUpgrade: false,
        showLoginLogout: false,
        showRecordingControls: true,
        showShow: true,
        /**
         * Determines if the login/logout button should be shown at all
         */

        showAccountLink: false,
        showBlogLink: false,
        showClassicLink: false,

        showSupportLink: this.headerType !== 'greenroom',
        showAudioSettings: this.headerType !== 'greenroom',

        headerType: this.headerType,
        showLoginLink: !session.loggedIn,
        showVideoRecordingModeIcons: this.showVideoRecordingModeIcons,
        videoRecordingMode: this.videoRecordingMode

      })
      this.$timer = this.$('.timer')
      this.$status = this.$('.status')
      this.$header = this.$('header')
      this.$videoRecordingMode = this.$('.video-recording-mode-text')
      this.renderTimer()
      this.setRecordingStatus()

      // Force evaluation of initial paused state
      this.onPausedChange(this.recorder, this.recorder.get('paused'))

      if (this.headerType !== 'greenroom') {
        this.$profileMenuContainer = this.$('.profile-menu-container')
        this.profileMenu = new zc.views.HeaderProfileMenu()

        this.$profileMenuContainer.append(this.profileMenu.render().el)
      }
    }
  })
})()
