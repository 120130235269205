/* globals zc Backbone */

(function () {
  'use strict'

  zc.models.Message = Backbone.Model.extend({
    initialize: function (opts) {
      var dateTimestamp = new Date(opts.time)
      var dtf = new Intl.DateTimeFormat('en', { hour: 'numeric', minute: 'numeric' })
      var parts = dtf.formatToParts(dateTimestamp)
      this.set('prettyDate', parts.map(function (v) { return v.value }).join(''))
    }
  })
})()
