/* globals zc */

(function () {
  'use strict'

  zc.views.EchoCancellationSettingView = zc.views.DropdownSelectView.extend({
    initialize: function (options) {
      this.settings = options.settings
      this.listenTo(this.settings, 'change:echoCancellation', this.updateSelection)
      this._initialize(options)
      this.createModel({
        iconClass: 'ic-echo',
        selectState: 'success',
        defaultErrorTitle: ''
      })
      var echoEnabled = this.getEchoCancellationSetting()
      var items = [
        {id: '1', title: 'Enabled - Cancelling Echoes', selected: echoEnabled === 1},
        {id: '0', title: 'Disabled - No Echo Cancellation', selected: echoEnabled === 0}
      ]
      this.model.set('items', items)
    },

    getEchoCancellationSetting: function () {
      return Number(this.settings.get('echoCancellation'))
    },

    updateSelection: function () {
      // Number(true) == 1
      this.setSelected(this.getEchoCancellationSetting())
    },

    onItemSelect: function (item) {
      var on = !!Number(item.id)
      this.settings.set({echoCancellation: on})
      console.log('Changed echo cancellation setting: ', on ? 'on' : 'off')
    }
  })
})()
