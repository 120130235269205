/* globals zc _ */

(function () {
  'use strict'

  zc.views.RecordingTipsPopupView = zc.views.PopupView.extend({
    template: _.template($('.recording-tips-popup-template').html()),

    className: 'popup modal vert-centered',

    events: {
      'click .ok': 'okClick'
    },

    okClick: function (e) {
      e.preventDefault()
      this.exit()
    },

    exit: function () {
      // reset model to default
      this.$el.remove()
      $('.app').removeClass('blur')
      this.callback()
    },

    bodyClick: function () {
      // do nothing
    },

    center: function () {
      var marginLeft = -(this.$content.outerWidth() / 2)
      var marginTop = -(this.$content.outerHeight() / 2)
      this.$content.css({marginLeft: marginLeft, marginTop: marginTop})
    },

    render: function () {
      zc.views.PopupView.prototype.render.call(this)
      this.$content = this.$('.content')
      this.center()

      $('.app').addClass('blur')

      return this
    }
  })
})()
