/* globals zc $  _ app utils analytics */

(function (window) {
  'use strict'

  zc.views.DashboardView = zc.views.PageView.extend({
    initialize: function (options) {
      _.bindAll(this, 'renderVideoOnboarding')
      zc.views.PageView.prototype.initialize.call(this, options)
      var couponCode = this.model.get('couponCode')
      var self = this
      this.socket = app.socket
      this.billingModel = new zc.models.Billing()
      if (couponCode) {
        this.model.set('couponApplicationStatus', 'ready')
        this.billingModel.readCoupon(couponCode)
          .then(function (coupon) {
            if (!coupon.valid) {
              return false // no-oop for invalid coupons
            }
            self.billingModel.set({ coupon: coupon })
            self.render()
          })
      }

      this.listenTo(app.cloudDrive, 'cloudDrive:change', this.renderCloudDriveInfo)
      this.listenTo(this.billingModel, 'change:coupon', this.render)
      this.listenTo(this.model, 'change:couponApplicationStatus', this.render)
      this.billingModel.on('loading', function () {
        self.model.set('couponApplicationStatus', 'loading')
      })
    },

    template: _.template($('.dashboard-template').html()),

    className: 'dashboard page',

    events: {
      'change .wav-recording': 'wavRecordingInputChange',
      'change .soundboard-enabled': 'soundboardEnabledInputChange',
      'click .create-project': 'renderProjectCreateModal',
      'click .apply-coupon': 'applyCoupon',
      'click .close-coupon': 'closeCoupon'
    },

    wavRecordingInputChange: function (e) {
      app.user.settings.set({ wavRecording: !!e.target.checked })
    },

    soundboardEnabledInputChange: function (e) {
      app.user.settings.set({ soundboard: !!e.target.checked })
    },

    createProject: function (attrs) {
      attrs.owner = app.user.get('username')
      attrs.ownerId = app.user.id
      attrs.cloudDrive = app.user.has('cloudToken') ? app.user.get('cloudToken').provider : null
      var languagePretty = attrs.language_pretty

      return new Promise(function (resolve, reject) {
        app.socket.emit('projects:create', attrs, function (err, attrs) {
          if (err) {
            if (err.indexOf('must be unique') === -1) { // Don't throw up toast for this error
              utils.notify('error', err)
            }
            reject(err)
            return
          }

          var project = new zc.models.Project(attrs)

          analytics.track('Created Project', {
            projectName: project.get('name'),
            language_code: project.get('language'),
            language: languagePretty,
            videoRecordingMode: project.get('defaultVideoRecordingMode'),
            projectId: project.id
          })

          project.recorder.recording.set({
            projectId: project.id,
            ownerId: app.user.id
          })
          project.recorder.createRecording(function (err) {
            if (err) {
              console.log(err)
              utils.notify('error', err)
              reject(err)
            } else {
              window.location.href = '/' + app.user.get('username') + '/' + project.get('slug')
            }
          })
        })
      })
    },

    renderProjectCreateModal: function () {
      var modalView = new zc.views.ModalView({
        addClass: 'project-create-modal',
        model: this.model,
        ChildView: zc.views.ProjectCreateFormView,
        callback: this.createProject,
        exit: true
      })

      modalView.render()
    },

    allowCreateProject: function () {
      this.$createProject.removeClass('disabled')
    },

    renderProjects: function () {
      var projectGroupView = new zc.views.ProjectGroupView({
        model: this.model.projectGroup,
        collection: this.model.projectGroup.projects,
        el: this.$projects
      })
      projectGroupView.render()

      this.model.projectGroup.fetchProjects()
    },

    renderCloudDriveInfo: function () {
      if (!this.cloudDriveInfoView) {
        this.cloudDriveInfoView = new zc.views.CloudDriveInfoView({ model: app.cloudDrive, el: this.$cloudDriveInfo })
      }
      this.cloudDriveInfoView.render()
    },

    renderPlanUsage: function () {
      this.planUsageView = new zc.views.PlanUsageView({ model: app.user, el: this.$planUsage })
      this.planUsageView.render()
    },

    /**
     * used to check if the trial period for this account has ended
     * @return {[type]} [description]
     */
    checkSubscription: function () {
      new zc.views.CheckSubscriptionView({}).render()
    },

    applyCoupon: function (e) {
      e.preventDefault()
      e.stopPropagation()
      var self = this
      var coupon = this.billingModel.get('coupon')
      try {
        this.billingModel
          .applyCouponCode(coupon.id, app.user.id)
          .then(function (customer) {
            if (customer && customer.discount && customer.discount.coupon.id === coupon.id) {
              self.model.set('couponApplicationStatus', 'success')
            }
          })
          .catch(function (e) {
            self.model.set('couponApplicationStatus', 'error')
            console.log(e)
          })
      } catch (e) {
        analytics.track('Coupon Error', {
          message: e.message
        })
        console.log('Coupon Apply Error', e)
      }
    },

    closeCoupon: function (e) {
      e.preventDefault()
      e.stopPropagation()
      this.billingModel.set('coupon', false)
      this.model.set('couponCode', false)
      this.model.set('couponApplicationStatus', false)
    },

    renderVideoOnboarding: function () {
      if (
        !localStorage.getItem('videoonboarding')
      ) {
        new zc.views.VideoOnboardingView({}).render()
      }
    },

    renderHeader: function () {
      this.recordingHeaderView = new zc.views.ProjectHeaderView({ configType: 'dashboard', model: this.model, el: this.$header })
      this.recordingHeaderView.render()
    },

    render: function () {
      var features = {
        wavRecording: app.user.getFeature('wavRecording'),
        soundboard: app.user.getFeature('soundboard')
      }
      var hasFeatures = Object.values(features).some(function (feature) {
        return Boolean(feature)
      })
      var attrs = Object.assign({}, { coupon: this.billingModel.get('coupon'), features: features, hasFeatures: hasFeatures }, this.model.attrs())
      this.$el.html(this.template(attrs))
      this.$header = this.$('> .header-container')
      this.$projects = this.$('.projects')
      this.$cloudDriveInfo = this.$('.cloud-drive-info')
      this.$planUsage = this.$('.plan-usage')
      this.$createProject = this.$('.create-project')

      this.renderHeader()
      this.renderProjects()
      this.renderPlanUsage()

      this.checkSubscription()

      this.renderCloudDriveInfo()

      setTimeout(this.renderVideoOnboarding, 500)
      return this
    }
  })
})(window)
