/* globals zc _ Backbone moment */

(function () {
  'use strict'
  zc.views.TimerView = Backbone.View.extend({
    initialize: function () {
      this.listenTo(this.model, 'change:duration', this.renderTime)
    },

    className: 'timer',

    template: _.template($('.timer-template').html()),

    startPollingTimer: function () {
      var view = this
      this.timerPollInterval = setInterval(function () {
        view.model.set({duration: view.model.duration()})
      }, 1000)
    },

    stopPollingTimer: function () {
      clearInterval(this.timerPollInterval)
    },

    getFormattedTime: function () {
      var attrs = this.model.toJSON()
      var duration = moment.duration(attrs.duration)
      var hours = duration.get('hours')
      var minutes = duration.get('minutes')
      var seconds = duration.get('seconds')
      var timeElapsed = moment({h: hours, m: minutes, s: seconds})
      var formattedTime = {
        h: timeElapsed.format('HH'),
        m: timeElapsed.format('mm'),
        s: timeElapsed.format('ss')
      }
      return formattedTime
    },

    renderTime: function () {
      var time = this.getFormattedTime()
      this.$hours[0].textContent = time.h
      this.$minutes[0].textContent = time.m
      this.$seconds[0].textContent = time.s
    },

    render: function () {
      var attrs = this.model.toJSON()
      attrs.formattedTime = this.getFormattedTime()
      this.$el.html(this.template(attrs))

      this.$hours = this.$('.hours .digits')
      this.$minutes = this.$('.minutes .digits')
      this.$seconds = this.$('.seconds .digits')

      return this
    }
  })
})()
