/* globals zc Backbone _ utils */

(function () {
  'use strict'

  var VideoOnboardingConfirmView = zc.views.ConfirmView.extend({
    className: 'confirm-prompt video-on-board'
  })

  zc.views.VideoOnboardingView = Backbone.View.extend({
    initialize: function () { },
    title: '',
    text: _.template($('.video-onboard-template').html()),
    image: utils.cdnUrl('/media/images/video-preview.png'),
    renderModal: function () {
      var self = this

      var modal = new zc.views.ModalView({
        addClass: 'video-onboarding-modal',
        model: new Backbone.Model({
          title: self.title,
          text: self.text({
            image: self.image
          }),
          confirmText: 'Got it!',
          cancelText: ''
        }),
        force: true,
        ChildView: VideoOnboardingConfirmView,
        callback: function (confirmed) {
          if (confirmed) {
            localStorage.setItem('videoonboarding', 'true')
            modal.exit()
          }
        }
      })

      modal.render()
    },

    render: function () {
      this.renderModal()
      return this
    }
  })
})()
