/* globals moment zc _ Backbone utils optimizelyClientInstance app analytics PersistentStore PersistentStoreWorkerProxy MemoryStore MemoryStoreWorkerProxy CloudStore CloudStoreWorkerProxy debug */

(function () {
  'use strict'

  var dbg = debug('zc:track')

  zc.models.Track = Backbone.Model.extend({
    name: 'track',

    initialize: async function (attrs, opts) {
      opts = opts || {}
      await this.initStorageProviderOption()

      // looks like only tracks have format
      // we use this model for postproductions also, but they don't need these stores
      if (attrs.format) {
        this.localStoragePartsKey = `b2parts.${attrs._id}`
        this.parts = this.loadUploadedParts()

        this.persistentStore = new PersistentStore({
          name: 'persistentStore',
          parentId: attrs._id,
          format: attrs.format
        })
        this.initPersistentStoreListeners()

        this.memoryStore = new MemoryStore({
          name: name + 'MemoryStore',
          chunkSize: this.get('localStoreChunkSize'),
          format: attrs.format,
          filename: this.get('filename'),
          useFileSystem: this.get('fileSystemSupport')
        })
        this.initMemoryStoreListeners()

        this.cloudStore = new CloudStore({
          provider: this.storageProvider,
          name: 'cloudStore',
          format: attrs.format,
          trackId: attrs._id,
          uploadUrl: attrs.uploadUrl,
          chunkSize: this.uploaderChunkSize(),
          uploadedParts: this.parts,
          gatewayUploadCreatorUrl: servars.gatewayRecordingUploadUrl,
          customHostForFinalizationNotification: servars.customHostForFinalizationNotification,
        })
        this.initCloudStoreListeners()
      }

      // if the track does not have an error
      // and it's not in either the other states
      // default to an error state so the user can retry the track
      // at this point, uploading will be undefined for most tracks (we don't keep track of it in the db)
      // for new tracks it will be true
      if (!attrs.error && !attrs.processing && !attrs.uploading && !attrs.downloading && !attrs.finalized) {
        this.set({ error: 'Unfinalized Track' })
      }

      this.on('change:bytesRecorded', this.bytesRecordedChange)
      this.on('change:bytesSaved', this.bytesSavedChange)
      this.on('change:bytesUploaded', this.bytesUploadedChange)
      this.on('change:percentSaved', this.percentSavedChange)
      this.on('change:percentUploaded', this.percentUploadedChange)
      this.on('change:error', this.reportFinalizationError)
      this.on('change:finalized', this.reportFinalization)

      // uploads and postproduction keep their path in different places
      if (!attrs.path) {
        this.set({ path: attrs.cloudPath })
      }

      // Fix for old postproductions that don't have the format label
      // if the attrs object doesn't have a format, but has the outputFiles
      // then it's a postproduction
      if (!attrs.format && _.isArray(attrs.outputFiles)) {
        // if it has only one outputFiles, then it's a mp3
        if (attrs.outputFiles.length === 1 && attrs.outputFiles[0].format) {
          this.set('format', 'mp3')
        } else
          // else, the sepparate wav files options was used, show the wav label
          if (attrs.outputFiles.length === 2) {
            this.set('format', 'wav')
          }
      }

      this.throttledLogFunction = _.throttle(this.logTrackProgress.bind(this), 5000)
      this.throttledUpdateUpload = _.throttle(this.updateUpload.bind(this), 10000)
    },

    defaults: {
      createdAt: null,
      updatedAt: null,
      deletedAt: null,
      localStorageDeletedAt: null,

      format: 'wav', // 'wav', 'mp3', 'mov'
      type: 'microphone', // microphone, soundboard, video
      audio: null, // audio data
      duration: 0,
      downloading: false,
      path: null, // relative path/filename for track
      url: null, // permanent public url for legacy dropbox tracks
      uploadUrl: null, // temporary signed url for upload
      downloadUrl: null, // temporary signed url for downloads
      error: null,
      size: 0,
      token: null,
      forced: false,
      finalized: false,
      hasContent: false,
      fileSystemSupport: !!navigator.storage && !!navigator.storage.getDirectory,

      // keeping track of what is recorded / saved / uplaoded
      bytesRecorded: 0, // bytes recorded to memoryStore in the current session
      bytesSaved: 0, // bytes saved to persistentStore
      bytesUploaded: 0, // bytes uploaded using cloudStore
      percentSaved: 0, // percent successfully saved to persistentStore
      percentUploaded: 0, // percent successfully uploaded using cloudStore

      progress: 0, // what gets rendered into progress bars in the UI
      selectable: false,
      deferUpload: false, // if true, audio will be saved but not uploaded in chunks as it is received. generally for wav uploads
      uploading: false, // true for the entirety of the uploading process regardless of pauses between chunks
      processing: false, // true if there's additional processing being performed server side (e.g. removal of blurry frames on video)
      recording: false, // true from when the recording is started until the final chunk is passed to the track for upload
      recordingSessionEnded: false, // true after the recording session for this track has been finally ended
      initializedForRecording: false,

      localStoreChunkSize: 65536, // 64kb - should be varied/tested to find optimum size for indexed db upload frequency and size
      chunksRecorded: 0,
      chunksUploaded: 0,
      profiles: null,
      driftStats: null
    },

    initStorageProviderOption: async function () {
      let variation

      const uploadUrl = this.get('uploadUrl')

      if (uploadUrl) {
        // If there is an uploadUrl already, it means this recording is not new and we
        // should keep the previous behavior that was set when the upload URL was created.
        variation = uploadUrl.startsWith('https://storage.googleapis.com') ? 'gcs' : 'b2'
      } else {
        try {
          await optimizelyClientInstance.onReady()

          // if query param is present
          const queryParams = utils.getQueryStringParams()
          const b2SetFromUrl = 'b2_storage' in queryParams && queryParams['b2_storage'] === 'true'
          const b2SetFromOptimizely = optimizelyClientInstance.isFeatureEnabled('b2_storage', this.attributes.hostId, {
            hostId: this.attributes.hostId,
            hostUsername: this.attributes.hostUsername,
            projectId: this.attributes.projectId,
            projectSlug: this.attributes.projectSlug,
            userId: this.attributes.userId,
            username: this.attributes.username,
          })

          if (b2SetFromUrl || b2SetFromOptimizely) {
            console.log('User will use B2 storage provider')
            variation = 'b2'
          } else {
            console.log('User will use GCS storage provider')
            variation = 'gcs'
          }
        } catch (e) {
          console.error('Could not instatiate optimizely', e)
          console.log('User will use GCS storage provider')
          variation = 'gcs'
        }
      }

      analytics.track('StorageProvider', {
        userId: app.user.id,
        hostId: this.attributes.hostId,
        recordingId: this.attributes.recordingId,
        projectId: this.get('projectId'),
        provider: variation
      })

      this.storageProvider = variation
      if (window.Intercom) {
        window.Intercom('update', { storageProvider: this.storageProvider })
      }
    },

    uploaderChunkSize: function () {
      const chunkSize = {
        b2: 5 * 1024 * 1024, // 5MB - the minimum allowable sized chunk for backblaze
        gcs: 256 * 1024, // 256kB - the minimum allowable sized chunk for google cloud upload
      }

      return chunkSize[this.storageProvider]
    },

    attrs: function () {
      var attrs = this.toJSON()

      if (attrs.forced || attrs.duration === 0) {
        attrs.hmsDuration = 'unknown'
      } else {
        // if the format is wav then divide that value by 4
        attrs.hmsDuration = utils.msToHms(attrs.duration)
      }

      attrs.createdAtReadable = attrs.createdAt ? moment(attrs.createdAt).format('dddd MMM Do YYYY - h:mmA') : null
      attrs.updatedAtReadable = attrs.updatedAt ? moment(attrs.updatedAt).format('dddd MMM Do YYYY - h:mmA') : null
      attrs.localStorageDeletedAtReadable = attrs.localStorageDeletedAt ? moment(attrs.localStorageDeletedAt).format('dddd MMM Do YYYY - h:mmA') : null

      attrs.formattedSize = utils.formattedDiskSpace(attrs.size || attrs.offset)
      return attrs
    },

    reportFinalization: function () {
      // Do not report success if error exists
      if (!this.get('error')) {
        analytics.track('TrackFinalized', {
          success: true,
          trackId: this.id,
          recordingId: this.get('recordingId')
        })
      }
    },

    reportFinalizationError: function (model, error) {
      // Do not report error if error does not exist
      if (error) {
        analytics.track('TrackFinalized', {
          success: false,
          trackId: this.id,
          recordingId: this.get('recordingId'),
          error: error
        })
      }
    },

    initPersistentStoreListeners: function () {
      var self = this
      this.persistentStore.on('change:bytesSaved', function (bytesSaved, chunksIn, chunksOut) {
        self.set('bytesSaved', bytesSaved)
        dbg('Saved', bytesSaved, 'to persistentStore')
        // monitor if the idb saves are backing up
        // and log an error every 50 chunks
        var chunksDiff = chunksIn - chunksOut
        if (chunksDiff > 10 && chunksIn % 50 === 0) {
          console.error(self.get('format'), ' persistent store saves are backing up. chunks in/out: ', chunksIn, '/', chunksOut)
        }
      })
    },

    initMemoryStoreListeners: function () {
      var self = this

      this.memoryStore.on('change:bytesRecorded', function (bytesRecorded) { self.set('bytesRecorded', bytesRecorded) })
      this.memoryStore.on('localbackup:failedWrite', function () {
        self.trigger('localbackup:failedWrite')
      })
    },

    initCloudStoreListeners: function (cloudStore) {
      var self = this
      // we have to re-initialize the cloudStore when creating new tracks for recording
      // this makes it easier to stay DRY when re-attaching listeners
      // NOTE: cloudStore uses events, not Backbone.Events
      this.cloudStore.on('change:bytesUploaded', function (bytesUploaded) { self.set('bytesUploaded', bytesUploaded) })
      this.cloudStore.on('chunkUploaded', this.chunkUploaded.bind(this))
      this.cloudStore.on('recreateUploadUrl', function () {
        // create the upload url again
        self.createUploadUrl().then(function () {
          // and reinit the streaming download
          self.cloudStore.initStreamingUpload()
        }).catch(utils.logRethrow)
      })

      this.cloudStore.on('abort', function () {
        self.trigger('abort')
        analytics.track('UploadError', {
          trackId: self.id,
          userId: app.user.id,
          recordingId: self.get('recordingId')
        })
      })
    },

    // -----------------
    // Recording Methods
    // -----------------

    /**
     * This is called to prepare a track to handle input chunks to be recorded
     * This only needs to be called the first time a track is created and used
     * @return {Promise}
     */
    initForRecording: function (uploadWorkerOptions) {
      var self = this

      // we only want to do this part once for a given track
      if (this.initForRecordingPromise) return this.initForRecordingPromise

      console.log('Initalizing', this.get('type'), this.get('format'), 'track for recording.')

      this.audioPipeline = uploadWorkerOptions.audioPipeline

      // message channel used for audio pipeline to talk to the upload worker
      var communicationPort = new MessageChannel()
      communicationPort.port1.start()
      uploadWorkerOptions.inputPort = communicationPort.port2

      this.initForRecordingPromise = this.initAudioPipeline({ uploadPort: communicationPort.port1 })
        .then(function () {
          return self.initUploadWorker(uploadWorkerOptions)
        })
        .then(function () {
          return self.createUploadUrl()
        }).then(function () {
          return self.cloudStore.initStreamingUpload()
        }).then(function () {
          return self
        }).catch(function (err) {
          utils.logRethrowCustom(new Error('Track initialization error'))(err)
        })

      return this.initForRecordingPromise
    },

    initAudioPipeline: function (options) {
      var self = this
      var type = this.get('type')
      var format = this.get('format')

      // name will be made from format and type, eg: mp3microphone
      var name = format + type
      var localStoreChunkSize = this.get('localStoreChunkSize')

      return new Promise(function (resolve, reject) {
        // create worker proxy objects
        // these are representations of the stream nodes which run in the worker
        // they proxy messages to and from the stream nodes and allow us to interact
        // with the nodes in the worker as if they were in the main thread
        self.memoryStore = new MemoryStoreWorkerProxy({
          name: name + 'MemoryStoreWorkerProxy',
          format: format,
          chunkSize: localStoreChunkSize,
          filename: self.get('filename'),
          useFileSystem: self.get('fileSystemSupport')
        })
        self.initMemoryStoreListeners() // listen for upload events

        // get worker proxy message ports
        var memoryStorePort = self.memoryStore.getMessagePort()

        var audioPipeline = self.audioPipeline
        var debugPipeline = false

        var nodes = []
        var transferables = [memoryStorePort, options.uploadPort]

        // only the mp3 has a persistent store = save to iDB
        if (format === 'mp3') {
          self.persistentStore = new PersistentStoreWorkerProxy({ name: name + 'PersistentStoreWorkerProxy', parentId: self.id, format: format })
          // listen for upload events
          self.initPersistentStoreListeners(self.persistentStore)

          var persistentStorePort = self.persistentStore.getMessagePort()

          nodes.push({
            constructorName: 'PersistentStoreStreamNode',
            constructorConf: {
              name: name + 'PersistentStore',
              parentId: self.id,
              format: format,
              messagePort: persistentStorePort,
              debug: debugPipeline
            }
          })

          transferables.push(persistentStorePort)
        }

        nodes.push({
          constructorName: 'MemoryStoreStreamNode',
          constructorConf: {
            name: name + 'MemoryStore',
            chunkSize: localStoreChunkSize,
            format: format,
            messagePort: memoryStorePort,
            debug: debugPipeline,
            filename: self.get('filename'),
            useFileSystem: self.get('fileSystemSupport'),
            uploadPort: options.uploadPort
          }
        })

        // add these nodes to their own pipeline
        audioPipeline.postMessage({
          command: 'addPipeline',
          config: {
            name: name,
            format: format,
            debug: debugPipeline,
            nodes: nodes
          }
        }, transferables)

        resolve()
      }).catch(function (err) {
        utils.logRethrow(err)
      })
    },

    /**
     * Used to initialize the communication between the video track and the upload worker
     */
    initUploadWorker: async function (options) {
      var format = this.get('format')
      var filename = this.get('filename')
      var type = this.get('type')
      var name = format + type

      if (!options.uploadWorker || !options.inputPort) {
        throw new Error('Tried to initialize the track with the uploadWorker but something was missing')
      }

      this.cloudStore = new CloudStoreWorkerProxy({
        provider: this.storageProvider,
        name: name + 'CloudStoreWorkerProxy',
        trackId: this.id,
        format: format,
        uploadedParts: this.parts,
        gatewayUploadCreatorUrl: servars.gatewayRecordingUploadUrl,
        customHostForFinalizationNotification: servars.customHostForFinalizationNotification,
      })
      this.initCloudStoreListeners(this.cloudStore) // listen for upload events

      // get worker proxy message ports
      var cloudStorePort = this.cloudStore.getMessagePort()

      options.uploadWorker.postMessage({
        command: 'addTrack',
        options: {
          provider: this.storageProvider,
          id: this.id,
          filename: filename,
          format: format,
          chunkSize: this.uploaderChunkSize(),
          useFileSystem: this.get('fileSystemSupport'),
          uploadUrl: this.get('uploadUrl'),
          uploadedParts: this.parts,
          gatewayUploadCreatorUrl: servars.gatewayRecordingUploadUrl,
          customHostForFinalizationNotification: servars.customHostForFinalizationNotification,
          usePacing: options.usePacing,
          inputPort: options.inputPort,
          cloudStorePort: cloudStorePort
        }
      }, [options.inputPort, cloudStorePort])
    },

    bytesChange: function () {
      var bytesRecorded = this.get('bytesRecorded')
      var bytesUploaded = this.get('bytesUploaded')
      var bytesSaved = this.get('bytesSaved')

      dbg('Progress for track', this.get('type'), this.get('format'), { bytesRecorded: bytesRecorded, bytesUploaded: bytesUploaded, bytesSaved: bytesSaved })

      this.set({ percentSaved: Math.min(Math.round(bytesSaved / bytesRecorded * 100), 100) })
      this.set({ percentUploaded: Math.min(Math.round(bytesUploaded / (bytesRecorded || bytesSaved) * 100), 99) })
    },

    exportDataAsBlob: async function () {
      var self = this

      // Used to wrap over persistentStore.export() so we can track when it happens
      var exportFromIdb = async function () {
        try {
          return await self.persistentStore.export()
        } catch (e) {
          analytics.track('StoreIsEmpty', {
            trackId: self.id,
            userId: app.user.id,
            recordingId: self.get('recordingId')
          })
          throw e
        }
      }

      // if we have a memory store (and we should always have one)
      // try and export from this
      if (this.memoryStore) {
        try {
          return await this.memoryStore.export()
        } catch (e) {
          // if that fails, try the persistent store
          return exportFromIdb()
        }
      }

      return exportFromIdb()
    },

    /**
     * used to upload the whole file in full
     */
    uploadFull: async function () {
      var self = this
      console.log('Starting full upload of track', this.get('type'), this.get('format'))

      this.set({ processing: true })
      var blob = await this.exportDataAsBlob()

      return self.cloudStore.upload({
        url: self.get('uploadUrl'),
        blob: blob
      })
    },

    confirmFileUpload: function () {
      // var self = this
      var bytesRecorded = this.get('bytesRecorded')
      var bytesUploaded = this.get('bytesUploaded')
      var bytesSaved = this.get('bytesSaved')

      console.log('Confirming Track Upload', this.get('type'), this.get('format'), { bytesRecorded: bytesRecorded, bytesUploaded: bytesUploaded, bytesSaved: bytesSaved })

      if (bytesUploaded < bytesRecorded) {
        console.error('uploaded file size is underweight by: ', bytesRecorded - bytesUploaded)
      }

      this.trigger('uploadCompleted', this)
    },

    chunkUploaded: async function (chunkMeta) {
      this.pushUploadedPart({ partNumber: chunkMeta.partNumber, etag: chunkMeta.etag })

      if (chunkMeta.chunkIndex) {
        this.set({ chunksUploaded: chunkMeta.chunkIndex + 1 })
      }

      dbg('Chunk uploaded. Number:', chunkMeta.chunkIndex + 1, 'Bytes:', chunkMeta.uploadedBytes, 'Track id:', this.id)

      if (chunkMeta.isLastChunk) {
        if (this.storageProvider === 'b2') {
          await this.cloudStore.completeMultipartUpload(this.parts)
          this.cleanUploadedParts()
        }

        dbg('Last chunk received')
        this.confirmFileUpload()

        // set the track in a processing state
        this.set({
          processing: true,
          finalized: false,
          uploading: false
        })

        // just clear all local backup of track on last chunk
        this.deleteLocalStorage()

        // after we upload the last chunk the sw destroys the cloud store
        // so we need to replace the cloud store proxy with a normal cloud store
        this.cloudStore = new CloudStore({
          provider: this.storageProvider,
          name: 'cloudStore',
          format: this.get('format'),
          trackId: this.id,
          uploadUrl: this.get('uploadUrl'),
          chunkSize: this.uploaderChunkSize(),
          uploadedParts: this.parts,
          gatewayUploadCreatorUrl: servars.gatewayRecordingUploadUrl,
          customHostForFinalizationNotification: servars.customHostForFinalizationNotification,
        })
      }

      var attrs = {
        userId: app.user.id,
        offset: chunkMeta.uploadedBytes,
        duration: this.get('duration'),
        size: this.get('bytesUploaded')
      }

      this.throttledUpdateUpload(attrs)
    },

    bytesRecordedChange: function (track, bytesRecorded) {
      // bytesChange sets the percentage properties based on byte data
      this.bytesChange()
    },

    bytesSavedChange: function (track, bytesSaved) {
      // bytesChange sets the percentage properties based on byte data
      this.bytesChange()
      this.throttledLogFunction()
    },

    bytesUploadedChange: function (track, bytesUploaded) {
      // bytesChange sets the percentage properties based on byte data
      this.bytesChange()
      this.throttledLogFunction()
    },

    logTrackProgress: function () {
      var bytesRecorded = this.get('bytesRecorded')
      var bytesUploaded = this.get('bytesUploaded')
      var bytesSaved = this.get('bytesSaved')

      // There was a log here, but it was very noisy and bloating Grafana logs
    },

    percentSavedChange: function (track, percentSaved) {
      var ownsTrack = app.user.ownsTrack(track)
      var isHost = app.user.isHost()

      // if app.user owns the track and is NOT the host
      if (ownsTrack && !isHost) { // don't send a users upload percent back at them if you are the host or it bounces around
        app.socket.emit('track:change:percentSaved', {
          _id: track.id,
          userId: app.user.id,
          bytesRecorded: this.get('bytesRecorded'),
          bytesSaved: this.get('bytesSaved'),
          bytesUploaded: this.get('bytesUploaded'),
          percentSaved: percentSaved
        })
      }
    },

    percentUploadedChange: function (track, percentUploaded) {
      this.set({ progress: percentUploaded })

      dbg(this.get('format') + ':track:percentUploaded:' + track.id, percentUploaded)

      var ownsTrack = app.user.ownsTrack(track)
      if (ownsTrack && !app.user.isHost()) { // don't send a users upload percent back at them if you are the host or it bounces around
        app.socket.emit('track:change:percentUploaded', {
          _id: track.id,
          userId: app.user.id,
          bytesRecorded: this.get('bytesRecorded'),
          bytesSaved: this.get('bytesSaved'),
          bytesUploaded: this.get('bytesUploaded'),
          percentUploaded: percentUploaded
        })
      }
    },

    /**
     * method called when one of the tracks fires the 'forceFinalize' event
     * it tries to read the track from idb and if nothing is found it signals the server to upload
     * @return {Promise}
     */
    forceFinalizeUpload: async function () {
      // change this flag so we know which track was already forced
      this.set({
        forced: true,
        error: null,
        processing: true
      })

      console.log('Starting finalization process for track:', this.id, this.get('format'), this.get('type'))
      try {
        var bytesUploaded = await this.cloudStore.getResumableUploadProgress()
        if (!this.get('bytesUploaded') && bytesUploaded) this.set('bytesUploaded', bytesUploaded)
        console.log('Remote progress:', bytesUploaded)
      } catch (err) {
        // if this failed, then the upload url is in an invalid state
        // finalize from data stored locally
        console.error('Failed to calculate remote server upload size: ', err)
        return this.finalizeUploadFromLocal(true)
      }

      var fsBackup = await this.memoryStore.getBytesSaved()
      if (!this.get('bytesRecorded') && fsBackup) this.set('bytesRecorded', fsBackup)

      // if we have data saved locally in FS
      if (fsBackup) {
        // if more or same is saved remotely
        if (bytesUploaded >= fsBackup) {
          // TODO: we should still take into account, when we have more iDB backup than FS backup
          // edge case that can happen only for mp3s
          return this.finalizeUploadFromServer()
        } else {
          // else, we have more data saved locally
          // the upload url is still valid, so we should just upload the diff
          try {
            return this.finalizeUploadFromLocal(false)
          } catch (err) {
            return this.finalizeUploadFromServer()
          }
        }
      }

      // if we have no data in FS, look in iDB. most likely this is an mp3
      try {
        var localSize = await this.persistentStore.calcStorageUsed()
        if (!this.get('bytesSaved') && localSize) this.set('bytesSaved', localSize)
      } catch (err) {
        console.error('Failed to calculate local storage size: ', err)
        // if it reached this point, we have no FS, cloud or iDB backup
        // show a message and return
        utils.notify('error', 'Unfortunately we couldn\'t find any backup for this track')
        return
      }

      console.log('Data saved in indexedDB:', localSize)

      if (bytesUploaded >= localSize) {
        return this.finalizeUploadFromServer()
      } else {
        try {
          return this.finalizeUploadFromLocal(false)
        } catch (err) {
          return this.finalizeUploadFromServer()
        }
      }
    },

    /**
     * called by forceFinalizeUpload to try and read the audio file from local backup and upload
     * method works in 2 way: full upload or just the diff
     */
    finalizeUploadFromLocal: async function (uploadInFull) {
      console.log('Starting finalization from local. uploadInFull:', uploadInFull)

      uploadInFull = !!uploadInFull

      this.set({ uploading: true })

      // if we should upload the whole track
      if (uploadInFull) {
        // do not create a new path in this case
        try {
          await this.createUploadUrl()
        } catch (err) {
          return utils.logRethrow(err)
        }

        console.log('Finished finalization form local')
        return this.uploadFull()

        // if the file is finalzid from GCS POV, just create a new path an upload fully
      } else if (this.cloudStore.finalized) {
        await this.createVersionedPath()

        return this.finalizeUploadFromLocal(true)

        // else, just upload the diff
      } else {
        var remoteSize = this.cloudStore.bytesUploaded
        // if we don't have this
        if (!remoteSize) {
          remoteSize = await this.cloudStore.getResumableUploadProgress()
        }

        var blob = await this.exportDataAsBlob()

        // this might happen if the user ran out of space
        // but we continued to upload
        if (remoteSize > blob.size) throw new Error('We have more data uploaded')

        // prepopulate the progress of the upload
        this.set({ progress: Math.round(remoteSize / blob.size * 100) })

        var toSend = blob.slice(remoteSize)
        return this.cloudStore.upload({
          url: this.get('uploadUrl'),
          blob: toSend,
          offset: remoteSize
        })
      }
    },

    /**
     * called by forceFinalizeUpload to signal the server that it should upload the file
     */
    finalizeUploadFromServer: async function () {
      var self = this
      let size = undefined

      if (this.storageProvider === 'b2') {
        const result = await this.cloudStore.completeMultipartUpload(this.parts)
        size = result.size
      }

      return new Promise(function (resolve, reject) {
        var data = self.toJSON()
        data.uploadUrl = data.uploadUrl
        data.path = data.path || self.getUploadPath()
        data._id = data._id
        data.forced = true
        data.size = size

        app.socket.emit('forceFinalizeUpload', data, function (err, upload) {
          if (err) {
            utils.notify('error', err)
            self.set({ error: 'Finalization Failed :/' })
            reject(err)
          } else {
            self.cleanUploadedParts()
            self.set(upload)
            resolve(self)
          }
        })
      })
    },

    calcLocalStorageUsed: function () {
      // get the storage used in FS and indexedDB
      return Promise.all([
        this.memoryStore.getBytesSaved(),
        this.persistentStore.calcStorageUsed()
      ]).then(function (res) {
        return res.reduce(function (cur, acc) {
          acc += cur
          return acc
        }, 0)
      })
    },

    deleteLocalStorage: function () {
      console.log('Deleting local storage. Track:', this.get('format'), this.get('type'))
      // delete FS backup, if any
      this.memoryStore.clear()
      // delete iDB backup, if any
      return this.persistentStore.dbDelete()
    },

    downloadFile: async function () {
      var self = this

      this.set({ downloading: true })

      // try local store first, then fallback to cloud download
      try {
        await this.downloadFromLocal()
      } catch (err) {
        console.warn('Unable to export audio from local store. Falling back to download from cloud.')

        try {
          await this.downloadFromCloud()
        } catch (err) {
          // failed to download from cloud also
          console.error(err)
          utils.notify('error', 'There was a problem downloading this track', { ttl: 5000 })
        }
      }

      this.set('progress', 100)
      // fake a download state and then switch back after 2s
      setTimeout(function () {
        self.set({ downloading: false })
      }, 2000)
    },

    downloadFromLocal: async function () {
      var filename = this.get('filename')

      if (!filename) {
        throw new Error('track missing filename')
      }

      console.log('Downloading from local')
      var audioBlob = await this.exportDataAsBlob()
      console.log('Total download size: ', audioBlob.size)

      // if the format is wav, we are actually saving pcm
      // so we need to add the wav header to make it a valid wav file
      if (this.get('format') === 'wav') {
        audioBlob = utils.audio.pcmBlobToWavBlob(audioBlob)
      }

      utils.forceDownload(audioBlob, filename)
    },

    downloadFromCloud: function (path) {
      var self = this
      path = path || this.get('path')

      // if it's postproduction or transcription
      var isPostproduction = this.get('isPostproduction') || this.has('deliveredAt')
      var isLegacyTrack = !this.has('uploadUrl') && !isPostproduction
      var projectId = this.get('projectId') || app.location.id

      // download from dropbox if legacy track
      if (isLegacyTrack) return this.downloadFromDropbox(path)

      console.log('Downloading from the cloud')
      return this.cloudStore.createDownloadUrl(projectId, path).then(function (downloadUrl) {
        return self.downloadUsingPublicUrl(downloadUrl)
      })
    },

    downloadFromDropbox: function (path) {
      console.log('Downloading legacy track')

      // if the track has a public url, try and download from there first
      if (this.has('url')) {
        console.log('Downloading from dropbox')
        return this.downloadUsingPublicUrl(this.get('url'))
      } else {
        return Promise.reject(new Error('Missing Dropbox url. Please contact support@zencastr.com'))
      }
    },

    downloadUsingPublicUrl: function (url) {
      // sometimes this request to Dropbox might throw a CORS error
      // catch that and continue
      var self = this
      return new Promise(function (resolve, reject) {
        if (url.includes('backblaze')) {
          var element = document.createElement('a')
          element.setAttribute('href', url) // when cross-domain the value of the anchor's download attribute is ignored, the browser only respects the content-disposition header in the response
          element.style.display = 'none'
          document.body.appendChild(element)
          // Prevent beforeunload from firing for this element click.
          app.tabProtectionService.enableUnsafeNav()
          element.click()
          // Re-enable beforeunload events
          app.tabProtectionService.disableUnsafeNav()
          document.body.removeChild(element)
          resolve()
        } else {
          window.fetch(url, { method: 'HEAD' }).then(function (response) { // backblaze does not support HEAD requests
            if (response.ok) {
              var name = 'file.' + self.get('format')
              if (self.has('filename')) {
                name = self.get('filename') + '.' + self.get('format')
              } else if (self.has('path')) {
                // hacky way to set a name for the file
                // structure: <projectSlug>/<recordingSlug>/<filename>
                name = self.get('path').match(/[^/]*\/[^/]*\/[^/]*$/)[0]
              }

              var element = document.createElement('a')
              element.setAttribute('href', url)
              element.setAttribute('download', name) // this just gets ignored when the source is from a different domain
              element.style.display = 'none'
              document.body.appendChild(element)

              // Prevent beforeunload from firing for this element click.
              app.tabProtectionService.enableUnsafeNav()
              element.click()
              // Re-enable beforeunload events
              app.tabProtectionService.disableUnsafeNav()

              document.body.removeChild(element)
              resolve()
            } else {
              reject(new Error('Failed to download track.  error code: ' + response.status))
            }
          })
        }
      })
    },

    createUploadUrl: async function () {
      var path = this.get('path')
      var projectId = this.get('projectId')

      if (!path) throw new Error('Cannot create an upload url with out a path')
      if (!projectId) throw new Error('Cannot create an upload url without a projectId')

      // if this is a wav, we'll upload the file as a .pcm
      // then servside processing will change that into a .wav
      if (this.get('format') === 'wav') {
        // split by . to extract everything but the extension
        var newPath = path.split('.').slice(0, -1)
        // add the new extension
        newPath.push('.pcm')
        // rebuild the path
        path = newPath.join('')
      }

      dbg('Creating upload url for track', this.id)
      var url = await this.cloudStore.createUploadUrl(projectId, this.attributes.recordingId, this.attributes._id, this.attributes.hostId, path)
      this.set({ uploadUrl: url })

      await this.updateUpload({ uploadUrl: url })

      return url
    },

    /**
     * CRUD methods
     */

    /**
     * used to update some attribute for the upload db model
     * @param  {Object}   attrs The attribute to change, must contain _id
     * @param  {Function} cb    Callback
     */
    updateUpload: function (attrs) {
      attrs._id = attrs._id || this.id
      return new Promise(function (resolve, reject) {
        app.socket.emit('upload:update', attrs, function (err, uploadResult) {
          if (err) {
            return reject(err)
          }

          resolve(uploadResult)
        })
      })
    },

    deleteUpload: function () {
      console.warn('Removing track', this.id, this.get('format'), this.get('type'))
      app.socket.emit('upload:delete', { _id: this.id }, function (err, uploadId) {
        if (err) {
          console.error('Error while deleting the track: ', err)
        }
      })
    },

    saveDriftStats: function (driftStats) {
      return this.updateUpload({ driftStats: driftStats })
    },

    saveProfiles: function (profiles) {
      profiles.unshift(this.get('resamplerProfile'))
      this.updateUpload({ profiles: profiles })
    },

    /**
     * used to get the duration for the current track
     * @return {Number} The duration in ms
     */
    saveDuration: function (duration) {
      // also update the duration on the model
      this.set('duration', duration)

      var attrs = {
        duration: duration,
        hmsDuration: utils.msToHms(duration)
      }

      return this.updateUpload(attrs)
    },

    saveTrackMetadata: function (metadata) {
      app.socket.emit('uploadMetadata:create', { uploadId: this.id, metadata: metadata }, function (err, uploadId) {
        if (err) {
          console.error('Error while saving metadata: ', err)
        }
      })
    },

    /**
     * Utils methods
     */

    /**
     * used to get the duration for the current track
     * @return {Number} The duration in ms
     */
    getDuration: function () {
      if (this.get('duration')) {
        return this.get('duration')
      } else if (this.get('size')) {
        var duration = 0
        if (this.get('format') === 'mp3') {
          duration = Math.round(this.get('size') / 16) // approximated duration based on 128kbps
        } else if (this.get('format') === 'wav') {
          duration = Math.round(this.get('size') / 90)
        } else if (this.get('format') === 'mov') {
          duration = Math.round(this.get('size') / 2700)
        } else if (this.get('format') === 'webm') {
          duration = Math.round(this.get('size') / 2600)
        }

        return duration
      }

      return 0
    },

    /**
     * Used to create a new path for a file. This is useful when we want to reupload the whole file
     * and we don't want to override what we have already
     */
    createVersionedPath: async function () {
      var path = this.get('path')

      // split by . to extract everything but the extension
      var parsed = path.split('.')
      var newPath = parsed.slice(0, -1)
      // add a prefix to the file name so we know it has been recovered
      newPath.push('-recover-' + Date.now())
      // add back the extension
      newPath.push('.' + parsed.slice(-1))
      // rebuild the path
      path = newPath.join('')

      this.set({ path: path })

      try {
        await this.updateUpload({ path: path })

        await this.createUploadUrl()
      } catch (e) {
        console.warn('Could not create versioned path for track', this.id)
      }
    },

    getFilename: function (format, username, withDuration) {
      format = format || this.get('format')
      username = username || this.get('username')
      // if this track is a soundboard, use that in the file name
      username = this.get('type') === 'soundboard' ? 'soundboard' : username
      username = username.toLowerCase().replace(/[ \\]/g, '-')
      var datetime = this.getFileDatetime()

      var filename = datetime + '--' + username

      if (withDuration) {
        var duration = utils.msToHms(this.getDuration(), true)
        filename += '--' + duration
      }

      return utils.slugify(filename) + '.' + format
    },

    getFileDatetime: function () {
      return moment().format('YYYY-MM-DD--thh-mm-ssa')
    },

    getUploadPath: function (format, username) {
      format = format || this.get('format')
      username = username || this.get('username')
      var filename = this.getFilename(format, username)
      return this.getUploadFolder() + '/' + filename
    },

    getUploadFolder: function () {
      var project = app.location
      return project.recorder.recording.getCloudFolder()
    },

    getFinalMixFilename: function (format) {
      format = format || this.get('format')
      var datetime = this.getFileDatetime()
      return utils.slugify(datetime + '--final-mix') + '.' + format
    },

    getFinalMixFolder: function () {
      return this.getUploadFolder() + '/postproductions'
    },

    getFinalMixPath: function (format) {
      format = format || this.get('format')
      return this.getFinalMixFolder() + '/' + this.getFinalMixFilename(format)
    },

    /**
     * @returns {{ partNumber: number, etag: string }[]}
     */
    loadUploadedParts: function () {
      if (this.storageProvider !== 'b2') return []
      return JSON.parse(localStorage.getItem(this.localStoragePartsKey) || '[]')
    },

    /**
     * @param {{ partNumber: number, etag: string }} part
     */
    pushUploadedPart: function (part) {
      if (this.storageProvider !== 'b2') return
      this.parts.push(part)
      localStorage.setItem(this.localStoragePartsKey, JSON.stringify(this.parts))
    },

    cleanUploadedParts: function () {
      if (this.storageProvider !== 'b2') return
      this.parts = []
      localStorage.removeItem(this.localStoragePartsKey)
    },
  })
})()
