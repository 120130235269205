/* globals zc _ $ Backbone */

(function () {
  'use strict'

  zc.views.CloudDriveInfoView = Backbone.View.extend({
    initialize: function () {
      this.listenTo(this.model, 'change:quota', this.render)
      this.listenTo(this.model, 'change:cloudToken', this.render)
      this.listenTo(this.model, 'change:connecting', this.connectingChange)
    },

    template: _.template($('.cloud-drive-info-template').html()),

    events: {
      'click .disconnect': 'disconnectClick',
      'click .connect-dropbox': 'connectDropbox',
      'click .connect-google-drive': 'connectGoogleDrive'
    },

    connectDropbox: function (e) {
      var $target = $(e.target)
      $target.text('Connecting to Dropbox ...')
      this.model.connectCloudDrive('dropbox')
    },

    connectGoogleDrive: function (e) {
      var $target = $(e.target)
      $target.text('Connecting to Google Drive ...')
      this.model.connectCloudDrive('google')
    },

    connectingChange: function (connecting) {
      if (connecting) {
        this.$el.find('button').addClass('disabled')
      } else {
        this.$el.find('button').removeClass('disabled')
      }
    },

    disconnectClick: function () {
      this.model.disconnect()
    },

    hideLoading: function () {
      this.$el.removeClass('loading')
    },

    render: function () {
      var attrs = this.model.attrs()
      this.$el.html(this.template(attrs))

      if (attrs.quota) {
        this.hideLoading()
      }

      return this
    }
  })
})()
