/* globals zc _ app */

(function () {
  'use strict'

  zc.views.PaymentSourceView = zc.views.BaseView.extend({
    initialize: function () {
      this.listenTo(this.model, 'change', this.render)
    },

    template: _.template($('.payment-source-template').html()),

    events: {
      'click .change-payment-source': 'changePaymentSource'
    },

    changePaymentSource: function () {
      var self = this
      var billing = app.user.billing
      billing.set({email: app.user.get('email'), panelLabel: 'Update Payment Source'})
      billing.createPaymentSourceForm(function (token) {
        billing.updateCustomer({
          user: app.user,
          paymentSource: token
        }).then(function (customer) {
          var card = token.card
          card.expMonth = card.exp_month
          card.expYear = card.exp_year
          self.model.set(card)
        })
      }).open()
    },

    render: function () {
      this._render()
      return this
    }
  })
})()
