/* globals zc _ Backbone */

(function () {
  'use strict'

  zc.views.DropdownSelectView = zc.views.BaseView.extend({
    template: _.template($('.dropdown-select-template').html()),

    className: 'dropdown-select',

    events: {
      'click': 'toggleOpen',
      'click .item': 'handleItemClick'
    },

    initialize: function (options) {
      this._initialize(options)
      this.createModel(options)
    },

    _initialize: function (options) {
      options = options || {}
      _.bindAll(this, 'checkClickWhenOpen', 'reRender')
      this.smallMode = options.smallMode
      this.customSwitchName = options.customSwitchName // pass 'no-toggle' to not allow state change
    },

    createModel: function (options) {
      options = options || {}
      options.items = options.items || []
      options.switchInitialState = options.switchInitialState || false

      // Check on undefined because false is an acceptable value.
      if (options.hideArrows === undefined) {
        options.hideArrows = false
      }

      this.model = new Backbone.Model(options)
      this.listenTo(this.model, 'change:selectState', this.reRender)
    },

    toggleOpen: function (e, forceValue) {
      var items = this.model.get('items')
      if (items.length <= 1) {
        return
      }
      if (forceValue === 'close' || this.$el.hasClass('open')) {
        this.$el.removeClass('open')
        $(window).off('click', this.checkClickWhenOpen)
      } else {
        this.$el.addClass('open')
        $(window).on('click', this.checkClickWhenOpen)
      }
    },

    checkClickWhenOpen: function (e) {
      if (!$.contains(this.$el.get(0), e.target)) {
        this.toggleOpen(null, 'close')
      }
    },

    handleItemClick: function (e) {
      e.preventDefault()
      e.stopPropagation()

      var $item = $(e.currentTarget)
      var id = $item.data('id') + ''
      var items = this.model.get('items')
      var self = this

      items = items.map(function (i) {
        if (('' + i.id === id)) {
          i.selected = true
          self.onItemSelect(i)
        } else {
          i.selected = false
        }
        return i
      })

      this.model.set('items', items)
      this.reRender()
    },

    // could be overloaded by
    // child classes
    onItemSelect: function () {

    },

    setSelected: function (itemId) {
      var items = this.model.get('items')
      items = items.map(function (i) {
        i.selected = ('' + itemId) === i.id
        return i
      })
      this.model.set(items)
      this.reRender()
    },

    reRender: function () {
      this.toggleOpen(null, 'close')
      this.render()
    },

    setError: function () {
      this.model.set('selectState', 'error')
    },

    render: function () {
      this._render({
        customSwitchName: this.customSwitchName
      })
      this.$el.addClass(this.smallMode ? 'small' : 'large').addClass('dropdown-select')
      var items = this.model.get('items')
      if (this.model.get('selectState') === 'success' && (!items || !items.length)) {
        this.setError()
      }
    }
  })
})()
