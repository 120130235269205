/* globals zc zc2 _ Backbone app */

(function () {
  'use strict'
  /**
   * main logic that needs to stay/move is in userChanged()
   */
  zc.views.PeerVideosView = Backbone.View.extend({
    initialize: function (options) {
      this.userChanged = _.debounce(function (users, ...args) {
        /**
         * We need to prevent this function from running concurrently with itself
         * If it does, it could end up with calls that are not respecting each other (e.g. creating an extra view for everybody in the call)
         * This is probably due to some event loop bs
         */
        console.debug("begin", {users, args, isRendering: this.isRendering, peersView: this.peersView})
        if (this.isRendering) {
          console.debug("skip", {users, args, isRendering: this.isRendering, peersView: this.peersView})
        }
        this.isRendering = true

        this.showOrHide(users)

        // for each user in the state, make sure we have UserVideoView instance
        Object.values(users).forEach(function (user) {
          if (this.peersView[user.id]) {
            console.debug(`%cUser ${user.id} already rendered; skipping`, "color: red;")
            return
          }
          console.debug(`%cUser ${user.id} not rendered; rendering`, "color: green;")

          var lobbyUser = this.model.users.find(function (u) { return u.id === user.id })
          this.onStreamAdded(lobbyUser)
        }.bind(this))

        this.isRendering = false
        console.debug("end", {users, args, isRendering: this.isRendering, peersView: this.peersView})
      }.bind(this), 500)


      this.project = options.project
      this.recorder = this.project.recorder
      this.peersView = {}

      this.$window = $(window)
      this.window = window

      this.hookEvents()

      this.reduxWatcher = new zc2.utils.ReduxWatcher(zc2.store)
      this.reduxWatcher.watch('users.remoteUsers', this.userChanged.bind(this))

      // unused but required
      this.resizeService = new zc2.services.GridPlacement(this.el)
      this.showOrHide(zc2.store.getState().users.remoteUsers)

    },

    remove: function () {
      this.$window.off('resize', this.resizeContext)
      this.reduxWatcher.destroy()
    },

    template: _.template($('.peer-videos-template').html()),

    showOrHide: function (users) {
      if (Object.keys(users).length === 0) {
        this.$el.parent().addClass('empty')
      } else {
        this.$el.parent().removeClass('empty')
      }
    },

    userChanged: undefined,

    hookEvents: function () {
      this.resizeContext = () => { this.resizeHandler() }
      this.$window.on('resize', this.resizeContext)
    },

    resizeHandler: function () {
      if (this.tmr) this.window.clearTimeout(this.tmr)
    },

    onStreamAdded: function (user, media) {
      this.renderVideo(user, media)
    },

    renderVideo: function (user, mediaStream) {
      if (user && !user.isLocal()) {
        var videoView = new zc.views.UserVideoView({
          id: user.id,
          mediaStream: mediaStream,
          onRemove: function () {
            console.debug(`%cCleaning up ${user.id}`, "color: yellow;")
            delete this.peersView[user.id]
          }.bind(this),
          animatedBackground: false
        })
        this.peersView[user.id] = videoView
        this.$el.append(videoView.render().el)

      }
    },

    render: function () {
      this.$el.html(this.template(this.model.toJSON()))

      return this
    }
  })
})()
