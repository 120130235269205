/* globals zc Backbone app */

(function () {
  'use strict'

  zc.models.Dashboard = Backbone.Model.extend({
    initialize: function (options) {
      this.projects = new zc.collections.ProjectLinks()
      this.projectGroup = new zc.models.ProjectGroup({page: 0, perPage: 10}, {projects: this.projects})
      this.set('couponCode', options.couponCode)
      this.enableNotifications()
    },

    enableNotifications: function () {
      return Notification.requestPermission().then(function (permission) {
        if (permission === 'granted') {
          console.log('Notification permision granted')
        } else if (permission === 'denied') {
          console.log('Notification permission denied')
        } else if (permission === 'default') {
          console.log('Notification permission dismissed')
        }
        return permission
      })
    },

    attrs: function () {
      var attrs = this.toJSON()
      attrs.model = this
      attrs.cloudDrive = app.cloudDrive
      attrs.settings = app.user.settings.toJSON()
      return attrs
    }
  })
})()
