/* globals zc zc2 _ Backbone app analytics */

(function () {
  'use strict'

  zc.views.LobbyUserView = Backbone.View.extend({
    initialize: function (options) {
      this.recording = options.recording
      this.recorder = options.recorder
      this.project = options.project
      this.userMedia = this.project.userMedia
      this.actx = this.recorder.actx

      this.listenTo(this.model, 'change:micArmed', this.micArmedChange)
      this.listenTo(this.model, 'change:displayName', this.displayNameChange)
      this.listenTo(this.model, 'change:cameraOn', this.cameraOnChange)
      this.listenTo(this.model, 'change:muted', this.mutedChange)
      this.listenTo(this.model, 'change:handRaised', this.handRaisedChange)
      // status update listeners
      this.listenTo(this.model, 'change:micArmed', this.renderStatus)
      this.listenTo(this.model, 'change:greenroom', this.renderStatus)

      this.listenTo(this.model, 'change:isRecording', this.isRecordingChange)
      this.listenTo(this.model, 'change:isRecording', this.renderStatus)
      this.listenTo(this.model, 'change:paused', this.renderStatus)

      this.listenTo(this.model, 'remove', this.tearDown)

      this.listenTo(this.model.audioInput, 'change', this.renderStatus)

      this.listenTo(this.model.tracks, 'add', this.renderStatus)
      this.listenTo(this.model.tracks, 'change:path', this.renderStatus)
      this.listenTo(this.model.tracks, 'change:uploading', this.renderStatus)

      this.listenTo(this.model.criticalHealthChecks, 'reset', this.renderStatus)
      this.listenTo(this.model.warningHealthChecks, 'reset', this.renderStatus)

      _.bindAll(this, 'streamAdded', 'renderStatus')

      this.reduxWatcher = new zc2.utils.ReduxWatcher(zc2.store)
      if (this.model.isLocal()) {
        this.userMedia.on('streamAvailable', this.streamAdded)

        // Local SFU Listener
        this.reduxWatcher.watch('call', this.usersChanged.bind(this))

        // Local Socket Listener
        this.socketDisconnectListener = this.usersChanged.bind(this)

        app.socket.on('disconnect', this.socketDisconnectListener)
        app.socket.on('connect', this.socketDisconnectListener)
      } else {
        // Remote SFU Listener
        this.reduxWatcher.watch('users.remoteUsers.' + this.model.id, this.consumerAvailable.bind(this))

        // Remote Socket Listener
        this.listenTo(this.model, 'change:socketConnected', this.usersChanged.bind(this))
      }
    },

    remove: function () {
      this.reduxWatcher.destroy()
      app.socket.off('disconnect', this.socketDisconnectListener)
      app.socket.off('connect', this.socketDisconnectListener)
      Backbone.View.prototype.remove.call(this)
    },

    template: _.template($('.lobby-user-template').html()),

    className: 'lobby-user',

    events: {
      'click .download': 'downloadFile',
      'click .kickable': 'showKickPopover',
      'click .camera-status': 'toggleCamera',
      'click .mic-status': 'toggleMuted',
      'click .raised-hand': 'toggleHand',
      'click .user-footer-handle': 'toggleUserFooter'
    },

    toggleUserFooter: function () {
      const footerCurrentlyClosed = this.$footer.hasClass('hidden')

      // Rotate triangle icon 180 degrees when footer is open
      const status = this.model.getHealthCheckStatus()
      if (status === 'pending') {
        const newAngle = footerCurrentlyClosed ? 180 : 0
        $(this.$statusIcon).find(`.pending`).css('transform', `rotate(${newAngle}deg)`)
      }

      if (footerCurrentlyClosed) {
        this.healthCheckBar.showUserFooter()
      } else {
        this.healthCheckBar.hideUserFooter()
      }
    },

    toggleCamera: function () {
      var cameraOn = this.model.get('cameraOn')
      this.model.set({cameraOn: !cameraOn})

      var user = this.model
      // if user is not local (ie. the host changed for the guest) we fire this event
      if (!user.isLocal()) {
        this.model.trigger('remoteUserCameraChanged', user, !cameraOn)
      }
    },

    cameraOnChange: function (user, cameraOn) {
      this.setCameraAccess()
      this.renderCameraStatus()
    },

    displayNameChange: function (user, value) {
      value = value.toLowerCase()
      this.$username.text(value).attr('title', value.toUpperCase())
    },

    handRaisedChange: function (user, handRaised) {
      if (handRaised) {
        if (app.user.isHost() && user.id !== app.user.id) {
          this.$raisedHand.removeClass('disabled')
        }
        this.$el.addClass('hand-raised')
        app.player.play('userRaisedHand')
      } else {
        if (app.user.isHost() && user.id !== app.user.id) {
          this.$raisedHand.addClass('disabled')
        }
        this.$el.removeClass('hand-raised')
        app.player.play('userLoweredHand')
      }
    },

    toggleHand: function () {
      var handRaised = this.model.get('handRaised')
      this.model.set({handRaised: !handRaised})

      // only fire this event on hand raise
      if (!handRaised) {
        analytics.track('Raise Hand', {
          projectId: this.project.id,
          recordingId: this.project.recorder.recording.id,
          userId: app.user.id,
          username: app.user.get('username')
        })
      }
    },

    updateStatusTooltip: function (content) {
      this.$('.status-indicator .simple-tooltip').text(content)
    },

    usersChanged: function () {
      this.$el.removeClass('offline')
      this.$el.removeClass('partial')
      this.$el.removeClass('disconnected')

      const currentState = zc2.store.getState()
      const hasRemoteSfuUsers = Boolean(Object.values(currentState.users.remoteUsers).length)
      const correctSfuState = (currentState.call.shouldBeConnected === currentState.call.connected) || (!currentState.call.constructor && !hasRemoteSfuUsers)

      const userHasTracksInRecording = this.recording.tracks.getTracksForUser(this.model.get('_id')).length > 0
      const userHasTracks = this.model.tracks.length > 0

      if (this.model.get('greenroom')) {
        // user is in greenroom
        // status doesn't really matter

        this.$el.addClass('disconnected')
        this.updateStatusTooltip('User in greenroom')
        return
      }

      const socketUser = this.model.isLocal() ? app.socket.connected : Boolean(this.model.get('socketConnected'))
      const sfuUser = this.model.isLocal() ? correctSfuState : Boolean(this.sfuUser)

      if (socketUser && sfuUser) {
        // Green
        this.updateStatusTooltip('Connected to Zencastr')
        // no-op
      } else if (
        (!socketUser && sfuUser) || (socketUser && !sfuUser)
      ) {
        // User is either connected to sockets or sfu, but not both
        this.$el.addClass('partial')
        this.updateStatusTooltip('User is experiencing some connection issues but recording is being saved locally')
      } else if (!socketUser && !sfuUser) {
        // Red
        this.updateStatusTooltip('User is offline and not connected to Zencastr')
        if (userHasTracksInRecording || userHasTracks) {
          this.$el.addClass('offline')
        } else {
          this.$el.addClass('disconnected')
        }
      }
    },

    consumerAvailable: function (newRemoteUser) {
      if (newRemoteUser) {
        this.sfuUser = newRemoteUser
        if (newRemoteUser.consumers) {
          this.model.set({ greenroom: false, isVoipConnected: true })
          // use .some instead of .forEach so we can escape
          newRemoteUser.consumers.some(function (consumerResolvable) {
            var consumer = consumerResolvable.value
            if (consumer.kind === 'audio') {
              this.streamAdded(new MediaStream([consumer.track]))
              return true
            }
            return false
          }.bind(this))
        }
      } else {
        this.sfuUser = undefined
      }
      this.usersChanged()
    },

    streamAdded: function (stream) {
      this.startWaveform(stream)
      this.setCameraAccess()
    },

    uploadingChange: function (model, uploading) {
      if (uploading) {
        this.showUploading()
        // this.showTrack();
      } else {
        this.hideUploading()
      }
    },

    micArmedChange: function (model, micArmed) {
      this.renderMicArmedState()
      this.setMuteAccess()
    },

    showMicArmed: function () {
      this.$el.addClass('armed')
      this.$micStatus.find('span').text('Mic On')
    },

    hideMicArmed: function () {
      this.$el.removeClass('armed')
      this.$micStatus.find('span').text('Mic Off')
    },

    renderMicArmedState: function () {
      var micArmed = this.model.get('micArmed')
      if (micArmed) {
        this.showMicArmed()
      } else {
        this.hideMicArmed()
      }
    },

    renderCameraStatus: function () {
      var cameraOn = this.model.get('cameraOn')

      if (cameraOn) {
        this.showCameraOn()
      } else {
        this.showCameraOff()
      }
    },

    showCameraOn: function () {
      this.$el.addClass('camera-on')
    },

    showCameraOff: function () {
      this.$el.removeClass('camera-on')
    },

    toggleMuted: function () {
      var muted = !this.model.get('muted')

      this.model.set({muted: muted})

      var user = this.model

      if (user.isLocal()) {
        this.model.trigger('localUserMutedChange', user, muted)
      } else {
        this.model.trigger('remoteUserMutedChangedLocally', user, muted)
      }
    },

    mutedChange: function (user, muted) {
      this.renderMuted()
      this.setMuteAccess()
    },

    setMuteAccess: function () {
      var micArmed = this.model.get('micArmed')
      var isMuted = this.model.get('muted')
      // host can only mute the user, not unmute
      var hasPermission = this.model.id === app.user.id || (app.user.isHost() && !isMuted)

      if (micArmed && hasPermission) {
        this.$micStatus.removeClass('disabled')
      } else {
        this.$micStatus.addClass('disabled')
      }
    },

    renderMuted: function () {
      var muted = this.model.get('muted')

      if (muted) {
        this.showMuted()
      } else {
        this.showUnmuted()
      }
    },

    showMuted: function () {
      this.$el.addClass('muted')
      this.$micStatus.find('span').text('Muted')
    },

    showUnmuted: function () {
      this.$el.removeClass('muted')
      this.$micStatus.find('span').text('Mic on')
    },

    isRecordingChange: function (model, isRecording) {
      // this.buffer.set({isRecording: isRecording})
      this.bufferView.isRecordingChange(isRecording)

      if (!isRecording) {
        this.recordings.renderTracks()
        this.showRecordings()
        this.toggleFinishedRecordingMode(true)
      } else {
        this.toggleFinishedRecordingMode(false)
      }
    },

    setRaisedHandAccess: function () {
      var hasPermission = this.model.id === app.user.id
      if (hasPermission) {
        this.$raisedHand.removeClass('disabled')
      } else {
        this.$raisedHand.addClass('disabled')
      }
    },

    setCameraAccess: function () {
      var cameraOn = this.model.get('cameraOn')
      var isSelf = this.model.id === app.user.id
      var hasPermission = isSelf || (app.user.isHost() && cameraOn)
      var localUserHasCamera = this.userMedia.devices.getDevicesByKind('videoinput').length > 0

      if (isSelf && !localUserHasCamera) {
        this.$cameraStatus.addClass('disabled')
      } else if (hasPermission) {
        this.$cameraStatus.removeClass('disabled')
      } else {
        this.$cameraStatus.addClass('disabled')
      }
    },

    tearDown: function (model) {
      this.bufferView.tearDown()
      this.remove()
    },

    renderStatus: function () {
      var status = this.model.getStatus()
      this.$userStatus.text(status)
      this.renderStatusIcon()
    },

    startWaveform: function (stream) {
      this.bufferView.startDrawingWaveformTail(stream)
    },

    showRecordings: function () {
      this.recordings.toggleVisibility('show')
    },

    toggleFinishedRecordingMode: function (recordingFinished) {
      if (recordingFinished) {
        this.$el.addClass('recording-finished')
        this.recordings.toggleVisibility('show')
      } else {
        this.$el.removeClass('recording-finished')
        this.recordings.toggleVisibility('hide')
      }
    },

    renderBuffer: function () {
      this.bufferView = new zc.views.BufferView({
        // model: this.buffer,
        canvasWorker: this.project.canvasWorker,
        el: this.$buffer,
        actx: this.actx,
        // isLocal: this.model.isLocal(),
        analyser: this.analyser
      })
      this.bufferView.render()

      this.bufferView.isRecordingChange(this.model.get('isRecording'))
    },

    renderHealthCheckBar: function () {
      this.healthCheckBar = new zc.views.HealthCheckBarView({model: this.model, el: this.$footer})
      this.healthCheckBar.render()
    },

    renderRecordings: function () {
      this.recordings = new zc.views.RecordingView({
        model: this.recording,
        el: this.$recordingContainer,
        userToMatch: this.model
      })
      this.recordings.render()
    },

    renderKick: function () {
      this.kickUser = new zc.views.KickUserView({model: this.model, parent: this.$('.user-meta')})
      this.kickUser.render()
    },

    resetStatusIcon: function() {
      this.$statusIcon.removeClass('passed warning failed pending unknown')
      this.$statusIcon.children().css('display', 'none')
    },

    showStatusIcon: function(status) {
      this.resetStatusIcon()
      this.$statusIcon.addClass(status)
      $(this.$statusIcon).find(`.${status}`).css('display', 'initial')
    },

    renderStatusIcon: function() {
      const status = this.model.getHealthCheckStatus()
      this.showStatusIcon(status)
    },

    showKickPopover: function (e) {
      e.preventDefault()
      e.stopPropagation()
      this.kickUser.toggle()
    },

    render: function () {
      this.$el.html(this.template(this.model.attrs()))
      this.$userStatus = this.$('.user-status')
      this.$username = this.$('.username')
      this.$track = this.$('.track')
      this.$frequencyAnalyser = this.$('.frequency-analyser')
      this.$buffer = this.$('.buffer')
      this.$cameraStatus = this.$('.camera-status')
      this.$micStatus = this.$('.mic-status')
      this.$micLabel = this.$('.selected-mic .item-content')
      this.$recordingStatus = this.$('.recording-status')
      this.$recordingStatusText = this.$recordingStatus.find('.status-text')
      this.$paused = this.$('.paused')
      this.$uploading = this.$('.uploading')
      this.$percentUploaded = this.$('.percent-uploaded')
      this.$download = this.$('.download')
      this.$kick = this.$('.kick')
      this.$volume = this.$('.volume')
      this.$raisedHand = this.$('.raised-hand')
      // footer status items
      this.$footer = this.$('.user-footer')

      this.$statusIcon = this.$('.status-icon')

      // recordings
      this.$recordingContainer = this.$('.recording-container')
      this.renderRecordings()

      if (app.user.isHost()) {
        this.renderKick()
      }

      this.renderMicArmedState()
      this.renderMuted()
      this.setMuteAccess()
      this.setRaisedHandAccess()
      this.setCameraAccess()
      if (app.user.isHost() || this.model.isLocal()) {
        this.renderHealthCheckBar()
      }
      this.renderStatus()
      this.renderCameraStatus()
      this.toggleFinishedRecordingMode(this.recorder.hasFinishedRecording())
      this.usersChanged()
      this.renderBuffer()

      // if this is the local user and we already have the stream for it
      // this is useful when guests leave the green room
      if (this.model.isLocal() && this.userMedia.stream) {
        this.streamAdded(this.userMedia.stream)
      }

      this.$el.attr('id', 'lobby-user-' + this.model.get('_id'))

      // Check initial state
      var currentState = zc2.store.getState()
      if (this.model.id in currentState.users.remoteUsers) {
        this.consumerAvailable(currentState.users.remoteUsers[this.model.id])
      }

      return this
    }
  })
})()
