/* globals zc _ Backbone app utils analytics */

(function () {
  'use strict'

  zc.views.SoundboardView = Backbone.View.extend({
    initialize: function () {
      this.listenTo(this.collection, 'reset', this.samplesReset)
      this.listenTo(this.collection, 'add', this.samplesAdd)
      this.listenTo(this.collection, 'remove', this.samplesRemove)

      this.listenTo(this.collection, 'change:_id', this.saveSamples)
      this.listenTo(this.collection, 'change:name', this.saveSamples)
      this.listenTo(this.collection, 'change:format', this.saveSamples)
      this.listenTo(this.collection, 'change:loop', this.saveSamples)
      this.listenTo(this.collection, 'change:url', this.saveSamples)
      this.listenTo(this.collection, 'change:gain', this.saveSamples)

      $(window).on('keypress', this.hotkeyListener.bind(this))
    },

    className: 'soundboard',

    template: _.template($('.soundboard-template').html()),

    events: {
      'change .file-input': 'fileSelected'
    },

    hotKeys: {
      '50': 1,
      '51': 2,
      '52': 3,
      '53': 4,
      '54': 5,
      '55': 6,
      '56': 7,
      '57': 8,
      '48': 9,
      '49': 0
    },

    /**
     * function called when the user chooses an audio file to upload to the soundboard
     * it reads the file, creates a new Sample model, adds that to the collection and renders the soundboard
     * @param  {Event}  e
     */
    fileSelected: function (e) {
      var self = this


      // ========================
      // Create Sample model
      // ========================
      // Read in the image file as a binary string.
      var file = e.target.files[0]

      // check if the user choosed an audio file
      if (file.type.split('/')[0] !== 'audio') {
        utils.notify('alert', 'Please choose an audio file to upload')
        return
      }

      /**
       * the id to be used for the new model
       * @type {String}
       */
      var id = utils.slugify(file.name)
      var oldModel = this.collection.findWhere({_id: id})

      // if we found a model with the same id, then we already have it
      // don't allow multipe upload of the same file
      if (oldModel) {
        utils.notify('alert', 'This file has already been added to your soundboard')
        return
      }

      /**
       * Create a new Sample model to be added to the collection
       * @type {zc.models.Sample}
       */
      var model = new zc.models.Sample({
        _id: id,
        name: file.name.replace(/\.[^.]*$/, ''),
        filename: file.name,
        format: file.type.split('/')[1],
        mimeType: file.type,
        /**
         * This prevents calling loadAudioBuffer twice. We don't want it called in Sample.initialize
         * since we are calling it here in FileReader.onload
         */
        createdFromFileUpload: true,
      })

      this.collection.add(model)
      // rerender the entire soundboard
      this.renderSamples()


      // ========================
      // Set up FileReader
      // ========================
      var reader = new FileReader()

      reader.onerror = function (err) {
        utils.notify('error', err)
      }
      reader.onprogress = function (e) {
        console.log('Upload Progress: ', e.loaded, e.total)
        if (e.lengthComputable) {
          var percentUploaded = Math.round((e.loaded / e.total) * 100)
          console.log('Percent Loaded: ', percentUploaded)
          model.set('percentUploaded', percentUploaded)
        }
      }
      reader.onabort = function (e) {
        utils.notify('alert', 'File read cancelled')
      }
      reader.onloadstart = function (e) {
        console.log('Loading File')
        model.set('uploading', true)
      }
      reader.onload = function (e) {
        console.log('File Loaded')
        var arrayBuffer = e.target.result
        model.set('uploading', false)
        return model.saveArrayBuffer(arrayBuffer).then(function (arrayBuffer) {
          console.log('Loading arrayBuffer in FileReader')
          model.loadAudioBuffer(arrayBuffer)
        }).catch(function (error) {
          utils.notify('alert', 'Failed to add new soundboard sound')
          console.error(error)
          self.collection.remove(model)
          self.renderSamples()
        })
      }

      // ========================
      // Read file
      // ========================
      reader.readAsArrayBuffer(file)
    },

    samplesReset: function () {
      this.saveSamples()
      this.renderSamples()
    },

    samplesAdd: function (sample) {
      this.saveSamples()
      this.renderSample(sample)

      analytics.track('Soundboard Sample Added', {
        userId: app.user.id,
        projectId: app.project.id,
        recordingId: app.project.recorder.recording.id
      })
    },

    samplesRemove: function (sample) {
      this.saveSamples()
      this.renderSamples()

      analytics.track('Soundboard Sample Removed', {
        userId: app.user.id,
        projectId: app.project.id,
        recordingId: app.project.recorder.recording.id
      })
    },

    saveSamples: _.debounce(function () {
      app.socket.emit('user:update:soundboard', {soundboardSamples: this.collection.toJSON(), userId: app.user.id})
    }, 1000),

    hotkeyListener: function (e) {
      var index = this.hotKeys[e.keyCode]
      if (typeof index === 'number') {
        var sample = this.collection.at(index)

        if (sample) {
          sample.connect(app.soundboardOut)
          sample._trigger()
        }
      }
    },

    /**
     * the main render function in this view
     * it goes goes through each model in the collection and calls renderSample
     */
    renderSamples: function () {
      var self = this
      this.$samples.empty()
      this.collection.forEach(function (sample) {
        // only render models that have and id
        // ignore old, empty models
        if (sample && sample.get('_id')) {
          self.renderSample(sample)
        } else {
          // remove empty samples
          self.collection.remove(sample)
        }
      })

      if (this.collection.length < 11) {
        var html = '<div class="sample empty"><i class="ic ic-plus"></i><input type="file" class="file-input" name="sample" accept="audio/*"></div>'
        this.$samples.append(html)
      }
    },

    /**
     * Used the create the view for each sample in the collection
     * @param  {Object} sample    Instance of zc.models.Sample
     */
    renderSample: function (sample) {
      var sampleView = new zc.views.SampleView({model: sample})
      this.$samples.append(sampleView.render().el)
    },

    render: function () {
      this.$el.html(this.template())
      this.$samples = this.$('.samples')

      this.renderSamples()

      return this
    }
  })
})()
