/* globals zc _ */

(function () {
  'use strict'
  const SUPPORT_EMAIL = 'mailto:support@zencastr.com'
  const SUPPORT_URL = 'https://support.zencastr.com'

  zc.views.HeaderProfileMenu = zc.views.BaseView.extend({
    template: _.template($('.header-profile-menu-template').html()),

    events: {
      'click .handle': 'clickHandle',
      'click .logout': 'logout',
      'click .login': 'login',
      'click .help': 'clickHelpHandle',
      'click .support': 'clickSupportButton',
      'click .help-center': 'clickHelpCenterButton'
    },

    initialize: function () {
      const { mediaUrl } = servars
      const placeholderAvatarUrl = new URL('/static/avatar-placeholder-1.png', mediaUrl).toString()

      this.model = new Backbone.Model({ visible: false, avatarUrl: placeholderAvatarUrl })

      // Load user avatar or use placeholder

      if (app.user) {
        var updateUserAvatar = function () {
          this.model.set('avatarUrl', app.user.get('avatar') || placeholderAvatarUrl)
        }.bind(this)

        if (app.user) {
          updateUserAvatar()
        }
        this.listenTo(app.user, 'change:avatar', function () {updateUserAvatar()})
      }

    },
    ///////////
    // Other Logic
    ///////////

    logout: function () {
      jwtClient.logout()
      window.location.href = '/'
    },

    login: function () {
      window.location.href = servars.creatorPlatformUrl + '/login'
    },

    clickSupportButton: function () {
      if (window.Intercom) {
        window.Intercom('show')
      } else {
        console.warn(`Unable to open Intercom modal on ${window.location.href}, directing user to support email instead`)
        window.open(SUPPORT_EMAIL)
      }
    },

    clickHelpCenterButton: function () {
      window.open(SUPPORT_URL)
    },

    //////////
    // View Logic
    //////////
    /**
     * @param e {MouseEvent}
     */
    handleClickOutside: function (e) {
      if (!this.el.contains(e.target)) {
        this.hide()
      }
    },

    show: function () {
      this.$el.addClass('visible')
      this.model.set({ visible: true })
      $(window).on('click', this.handleClickOutside.bind(this))
    },

    hide: function () {
      this.$el.removeClass('visible')
      this.model.set({ visible: false })
      $(window).off('click', this.handleClickOutside.bind(this))
    },

    clickHandle: function () {
      if (this.model.get('visible')) {
        this.hide()
      } else {
        this.show()
      }
    },

    //////////
    // View Logic (Help Section)
    //////////

    handleHelpClickOutside: function (e) {
      if (!this.el.contains(e.target)) {
        this.hideHelp()
      }
    },

    showHelp: function () {
      this.$help.addClass('visible')
      this.model.set({ 'help-visible': true })
      $(window).on('click', this.handleHelpClickOutside.bind(this))
    },

    hideHelp: function () {
      this.$help.removeClass('visible')
      this.model.set({ 'help-visible': false })
      $(window).off('click', this.handleHelpClickOutside.bind(this))
    },

    clickHelpHandle: function () {
      if (this.model.get('help-visible')) {
        this.hideHelp()
      } else {
        this.showHelp()
      }
    },

    render: function () {
      this._render()
      this.$el.addClass('header-profile-menu')
      this.$help = this.$('.help-menu')
      return this
    }
  })
})()
