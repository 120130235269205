/* globals zc _ Backbone */

(function () {
  'use strict'

  zc.views.BannerView = Backbone.View.extend({
    initialize: function (options) {
      this.html = options.html
    },

    className: 'banner-container',

    template: _.template($('.banner-template').html()),

    events: {
      'click .exit': 'close'
    },

    close: function () {
      this.remove()
    },

    render: function () {
      this.$el.html(this.template({html: this.html}))
      return this
    }
  })
})()
