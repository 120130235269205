/* globals zc _ Backbone utils */

(function () {
  'use strict'

  zc.views.PostproductionTrackView = Backbone.View.extend({
    initialize: function (opts) {
      this.listenTo(this.model, 'change:selected', this.renderSelectedState)
    },

    template: _.template($('.postproduction-track-template').html()),

    className: 'postproduction-track track',

    events: {
      'click': 'onClick'
    },

    onClick: function () {
      this.select()
    },

    select: function () {
      this.model.set({selected: true})
    },

    toggleSelected: function () {
      var selected = this.model.get('selected')
      this.model.set({selected: !selected})
    },

    renderSelectedState: function () {
      var selected = this.model.get('selected')
      if (selected) {
        this.renderSelected()
      } else {
        this.renderUnselected()
      }
    },

    renderSelected: function () {
      this.$el.removeClass('unselected')
      this.$el.addClass('selected')
    },

    renderUnselected: function () {
      this.$el.removeClass('selected')
      this.$el.addClass('unselected')
    },

    /**
     * Returns date formatted for display
     * @param dateObj
     * @returns {string} Date as string formatted `HH:mm12, MM:DD:YY`
     */
    getTimestamp: function (dateObj) {
      function padIfNeeded (num) {
        if (num < 10) return '0' + num
        else return num
      }
      var date = new Date(dateObj)
      var output = ''

      // Get 12-hour hour with padding 0 if needed
      output += padIfNeeded(date.getHours() % 12) + ':'
      // Get minutes with padding 0 if needed
      output += padIfNeeded(date.getMinutes())
      // Detect AM/PM, after midnight is 0, so < 12 suffices
      output += (date.getHours() < 12 ? 'AM' : 'PM') + ', '
      // Get date, offset month by one because javascript is silly and truncate for final 2 digits of the year
      output += date.getDate() + '.' + padIfNeeded(date.getMonth() + 1) + '.' + date.getFullYear().toString().substr(2)

      return output
    },

    render: function () {
      var timestamp = this.getTimestamp(this.model.get('createdAt'))
      var semanticDuration = utils.msToHms(this.model.getDuration())
      var templateData = Object.assign(this.model.attrs(), {timestamp: timestamp, semanticDuration: semanticDuration})
      this.$el.html(this.template(templateData))
      this.renderSelectedState()
      return this
    }
  })
})()
