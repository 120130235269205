/* globals zc _ Backbone app */

(function () {
  'use strict'

  zc.views.LobbyView = Backbone.View.extend({
    initialize: function (options) {
      _.bindAll(this, 'onRecordingChange')
      this.allUsersInRecording = {}
      this.allUsersInRecordingViews = {}
      this.project = options.project
      this.recorder = this.project.recorder
      this.recording = this.recorder.get('recording')
      this.call = this.project.call

      this.listenTo(this.model.users, 'add', this.userAdded)
      this.listenTo(this.model.users, 'reset', this.renderUsers)
      this.listenTo(this.recorder, 'change:isRecording', this.onRecordingChange)
    },

    template: _.template($('.lobby-template').html()),

    userAdded: function (user) {
      this.setUserModelTypeForParticipants()
      this.renderUser(user)
    },

    setUserModelTypeForParticipants: function () {
      var self = this
      var participants = this.recording.get('participants')

      // clear all of the current users
      this.allUsersInRecording = {}
      // set the users that are already on the call to purposefully
      // take precedence of any participant models created later
      this.model.users.map(function (user) {
        if (user.get('_id') === app.user.get('_id')) {
           // set the voip connected status of the host here
           // because the voip status is not set unless someone else
           // joins the call
          user.set({isVoipConnected: true})
        }
        self.allUsersInRecording[user.get('_id')] = user
      })

      // create user models for participants not in the room
      // so you can create lobby user views and group their tracks by user
      participants.forEach(function (participant) {
        var userInCall = self.model.users.find(function (u) { return u.get('_id') === participant.userId })
        if (userInCall) {
          self.allUsersInRecording[userInCall.get('_id')] = userInCall
        } else if (!userInCall && app.user.isHost()) {
          var participantUser = new zc.models.User({
            _id: participant.userId,
            isVoipConnected: false,
            username: participant.username
          })
          self.allUsersInRecording[participant.userId] = participantUser
        }
      })
    },

    renderUsers: function () {
      var self = this
      this.allUsersInRecordingViews = {}
      this.setUserModelTypeForParticipants()
      var models = Object.keys(this.allUsersInRecording).map(function (id) { return self.allUsersInRecording[id] })

      models.forEach(function (user) {
        self.renderUser(user)
      })
    },

    renderUser: function (user) {
      var buffer = this.project.buffers.get(user.id)
      var uid = user.get('_id')
      if (this.allUsersInRecordingViews[uid]) {
        this.allUsersInRecordingViews[uid].tearDown()
      }
      this.allUsersInRecordingViews[uid] = new zc.views.LobbyUserView({
        model: user,
        recorder: this.recorder,
        recording: this.recording,
        project: this.project,
        buffer: buffer,
        id: user.id,
        className: user.isLocal() ? 'lobby-user is-local' : 'lobby-user'
      })
      this.allUsersInRecordingViews[uid].render()

      this.$lobbyUsers.append(this.allUsersInRecordingViews[uid].el)
    },

    cleanup: function () {
      this.undelegateEvents()
      this.stopListening()
      this.$nonUserTracks.empty()
      this.$lobbyUsers.empty()
    },

    peerStreamAdded: function (peerMedia, peer) {
      var user = this.model.users.get(peer.id)
      user.media = peerMedia
      console.log('peer stream added', user.get('username'))
      user.set({greenroom: false}) // we know they are out of the greenroom once we get their stream
      user.trigger('streamAdded', user, peerMedia)
    },

    streamMuted: function (peer) {
      var user = this.model.users.get(peer.nick)
      user.set('muted', true)
    },

    streamUnmuted: function (peer) {
      var user = this.model.users.get(peer.nick)
      user.set('muted', false)
    },

    onRecordingChange: function (model, isRecording) {
      if (isRecording) {
        this.$el.addClass('is-recording')
      } else {
        this.$el.removeClass('is-recording')
      }
    },

    renderNonUserTracks: function () {
      this.nonUserTracks = new zc.views.NonUserTracksView({
        recorder: this.recorder,
        model: this.recording,
        el: this.$nonUserTracks
      })
      this.nonUserTracks.render()
    },

    render: function () {
      this.$el.html(this.template(this.model.toJSON()))
      this.$lobbyUsers = this.$('.lobby-users')
      this.$nonUserTracks = this.$('.non-user-tracks')
      this.renderUsers()

      if (app.user.isHost()) {
        this.renderNonUserTracks()
      }

      return this
    }
  })
})()
