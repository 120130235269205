/* globals zc _ app analytics Backbone utils CloudStore */

/**
 * Possible model.status states:
 * processing -> transcription is being loaded
 * failed -> transcription failed to generate
 * completed -> transcription is ready
 * missing -> transcription does not exist at all; this indicates a legacy recording.
 */

(function () {
  'use strict'

  /**
   * Steal this function from the CloudStore without needing to create an entire CloudStore
   */
  var createDownloadUrl = CloudStore.prototype.createDownloadUrl.bind({ origin: utils.getWindowOrigin() })

  zc.views.TranscriptionView = Backbone.View.extend({
    initialize: function (options) {
      this.listenTo(this.model, 'change:status', this.handleStatusChanged)
      this.failedRetryCount = 0
    },

    template: _.template($('.transcription-template').html()),
    trackTemplate: _.template($('.transcription-track-template').html()),
    transcriptMissingTemplate: _.template($('.transcription-missing-template').html()),
    copyTranscriptTemplate: _.template($('.transcription-copy-template').html()),

    events: {
      'click .tracks': 'handleClick'
    },

    /**
     *
     * @param target {HTMLElement}
     * @returns {HTMLElement}
     */
    findTrackParent: function (target) {
      while (!target.classList.contains('track')) {
        target = target.parentElement
        if (!this.$tracks.get(0).contains(target)) {
          throw new Error('Unable to correctly identify click target track!')
        }
      }
      return target
    },

    /**
     * @param e {MouseEvent}
     */
    copyFile: async function (e) {
      e.stopPropagation()
      // Note: This doesn't work in FireFox, so if we ever try to support that this might need to change
      var hasPerms = await navigator.permissions.query({name: 'clipboard-write'})

      if (
        // All preconditions for copying to clipboard
        (hasPerms.state !== 'granted' && hasPerms.state !== 'prompt') || !this.model.has('formattedPaths')
      ) {
        utils.notify('alert', 'Unable to copy transcript, please download it instead.', {ttl: 8000})
        return
      }
      var formattedPath = this.model.get('formattedPaths').find(function (path) {
        return path.format === 'txt'
      })
      if (!formattedPath) {
        utils.notify('alert', 'Unable to copy transcript, please download it instead.', {ttl: 8000})
        return
      }
      try {
        var path = formattedPath.path
        var url = await createDownloadUrl(app.project.id, path, false)
        var data = await fetch(url).then(r => r.text())
        await navigator.clipboard.writeText(data)
        utils.notify('success', 'Transcript copied!')
      } catch (e) {
        console.error(e)
        utils.notify('alert', 'Unable to copy transcript, please download it instead.', {ttl: 8000})
      }
    },

    /**
     * @param e {MouseEvent}
     */
    download: async function (e) {
      e.preventDefault()
      if (!this.model.get('plaintextPath') || (app.project.get('language') !== 'en' && app.project.get('language'))) return
      /**
       * @type {HTMLElement}
       */
      var target = this.findTrackParent(e.target)

      var formattedPaths = this.model.get('formattedPaths')
      var downloadUrl
      var format
      if (target.dataset['format'] && formattedPaths) {
        // Pull the correct path for the format
        /**
         * @type {{format: string, path: string}}
         */
        var pathSet = formattedPaths.find(function (f) {
          return f.format === target.dataset['format']
        })
        if (!pathSet) {
          // Use backward-compatible path
          downloadUrl = await createDownloadUrl(app.project.id, this.model.get('plaintextPath'), false)
          format = 'csv'
        } else {
          downloadUrl = await createDownloadUrl(app.project.id, pathSet.path, false)
          format = pathSet.format
        }
      } else {
        // Use backward-compatible path
        downloadUrl = await createDownloadUrl(app.project.id, this.model.get('plaintextPath'), false)
        format = 'csv'
      }
      var data = await fetch(downloadUrl).then(r => r.text())
      var newUrl = URL.createObjectURL(new Blob([data]))

      var element = document.createElement('a')
      element.setAttribute('href', newUrl)
      element.setAttribute('download', app.project.get('name') + '-transcript.' + format)
      element.style.display = 'none'
      document.body.appendChild(element)

      app.tabProtectionService.enableUnsafeNav()
      element.click()
      app.tabProtectionService.disableUnsafeNav()

      document.body.removeChild(element)

      analytics.track('UserDownloadedFile', {
        fileType: format,
        userId: app.user.id,
        recordingId: app.project.recorder.recording.id
      })
    },

    retryTranscription: function () {
      // Remove the wacky states; present as processing
      this.model.set('status', 'processing')
      this.$retryTooltip.hide()
      app.socket.emit('transcription:restart', app.project.recorder.recording.id, function (uselessTopic, result) {
        if (!result.wasRestartSuccessful) {
          var message = result.errorDetails
          if (this.failedRetryCount++ > 2) {
            message += '<br/>' + 'If you are having trouble, <a onclick="Intercom(\'showNewMessage\', \'Error Received: ' + result.errorDetails + '\')">our support team can help!</a>'
          }
          utils.notify('alert', message)
          this.model.set('status', 'failed')
        }
      }.bind(this))
    },

    /**
     * @param e {MouseEvent}
     */
    showRetry: function (e) {
      this.$retryTooltip.parent = this.findTrackParent(e.target)
      this.$retryTooltip.show()
    },

    hideRetry: function () {
      this.$retryTooltip.hide()
    },

    /**
     * @param error {string}
     */
    showError: function (error) {
      this.$('.track:not(.transcript-missing) .error-message').text(error)
      this.$('.track:not(.transcript-missing)').addClass('has-error finalized').removeClass('processing progress-indeterminate')
      this.$('.track:not(.transcript-missing) .track-format').hide()
    },

    handleStatusChanged: function (model) {
      this.$missing.hide()
      this.$copyButton.hide()

      switch (model.get('status')) {
        case 'completed':
        case 'processing':
        case 'failed':
          // Rerender track formats
          if (model.get('formattedPaths')) {
            this.renderFormats(this.model.get('formattedPaths'))
          } else {
            // This is a transcript from prior to the addition of the txt format
            // Fake a "csv" format, with the existing data
            this.renderFormats([{
              format: 'csv',
              path: model.get('plaintextPath')
            }])
          }

          if (model.get('status') === 'failed') {
            this.showError('Transcription Error')
          } else if (model.get('status') === 'completed') {
            this.$copyButton.show()
            this.$tracks.append(this.$copyButton)
          }
          break
        case 'missing':
          this.$tracks.empty()
          this.$tracks.append(this.$missing)
          this.$missing.show()
          break
      }
    },

    /**
     * @param e {MouseEvent}
     */
    handleClick: function (e) {
      switch (this.model.get('status')) {
        case 'missing': return this.retryTranscription()
        case 'failed':
          this.hideRetry()
          return this.showRetry(e)
        case 'completed':
          var target = this.findTrackParent(e.target)
          if (target.classList.contains('transcript-copy')) {
            // do a copy
            return this.copyFile(e)
          } else {
            return this.download(e)
          }
      }
    },

    /**
     * @param format {format: string, path: string}
     */
    renderFormat: function (format) {
      var container
      if (this.formatContainers[format.format] instanceof HTMLElement) {
        container = this.formatContainers[format.format]
      } else {
        container = document.createElement('div')
        this.formatContainers[format.format] = container
      }

      if (!this.$tracks.get(0).contains(this.formatContainers[format.format])) {
        this.$tracks.append(container)
      }

      // Render the template to give us a known base point to mutate based on state
      container.innerHTML = this.trackTemplate({ format: format.format })

      // Create Tooltips here

      // Mark as processing if needed
      switch (this.model.get('status')) {
        case 'processing':
          $(container).find('.track-format span').text('processing')
          break
        case 'completed':
          container.children[0].classList.remove('processing', 'progress-indeterminate')
          container.children[0].classList.add('finalized')
          break
      }
    },

    /**
     * @param formats { {format: string, path: string}[] }
     */
    renderFormats: function (formats) {
      // If the map doesn't exist, create it (first render)
      if (!this.formatContainers) {
        /**
         * @type {Record<string, HTMLElement>}
         */
        this.formatContainers = {}
      }

      formats.forEach(this.renderFormat.bind(this))
    },

    renderCopyButton: function () {

    },

    render: function () {
      if (app.project.get('language') !== 'en' && Boolean(app.project.get('language'))) {
        this.$el.hide()
        // If the language is not supported we shouldn't care about *anything*
        this.stopListening()
        return this
      }

      if (this.$el.text() === '') {
        // Only gen on first render
        this.$el.html(this.template(this.model.toJSON()))
        this.$tracks = this.$('.tracks')
        this.$tracks.append(this.transcriptMissingTemplate())
        this.$missing = this.$tracks.find('.transcript-missing')
        this.$tracks.append(this.$missing)
        this.$missing.hide()
      }

      if (!this.$retryTooltip) {
        this.$retryTooltip = new zc.views.TooltipView({
          parent: document.body
        })

        this.$retryTooltip.render('')
        this.$retryTooltip.$el.html(_.template($('.transcript-retry-tooltip-template').html())())
        this.$retryTooltip.$('.retry-button').on('click', this.retryTranscription.bind(this))

        document.body.append(this.$retryTooltip.$el[0])
      }

      if (!this.$copyButton) {
        var container = document.createElement('div')
        container.innerHTML = this.copyTranscriptTemplate()
        this.$copyButton = $(container)
        this.$tracks.append(this.$copyButton)
      }

      this.handleStatusChanged(this.model)

      return this
    }
  })
})()
