/* globals zc _ */

(function () {
  'use strict'

  zc.views.CountdownView = zc.views.BaseView.extend({
    initialize: function (attrs) {
      this.listenTo(this.model, 'change:count', this.countChange)
    },

    className: 'countdown',
    template: _.template($('.countdown-template').html()),

    countChange: function (model, count) {
      this.$count.text(count)
    },

    render: function () {
      this._render()
      this.$count = this.$('.count')

      return this
    }
  })
})()
