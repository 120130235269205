/* globals zc Backbone app utils */

/** @typedef { { wavRecording: boolean; recurringPostproductionCredits: number; soundboard: boolean; videoResolution: '1080p' | '4k' } } PlanFeatures */

(function () {
  'use strict'

  zc.models.Plan = Backbone.Model.extend({
    defaults: {
      name: null,
      amount: 1000, // amount in cents $10
      billingId: null,
      currency: 'usd',
      interval: 'month',
      intervalCount: 1,
      trialPeriodDays: 14,
      /** @type { import('@zencastr/libcore').IPlanFeatures } */
      features: {
        wavRecording: false,
        recurringPostproductionCredits: 0,
        soundboard: false,
        videoResolution: '720p' // default to 720p
      }
    },

    attrs: function () {
      var attrs = this.toJSON()
      return attrs
    },

    save: function () {
      if (this.id) {
        this.update(this.attrs())
      } else {
        this.create(this.attrs())
      }
    },

    create: function (attrs) {
      var self = this
      app.socket.emit('plan:create', attrs, function (err, plan) {
        if (err) return utils.notify('error', err)
        utils.notify('success', 'Successfully Created')
        self.set(plan)
      })
    },

    update: function (attrs) {
      app.socket.emit('plan:update', attrs, function (err, result) {
        if (err || !result.nModified === 1) return utils.notify('error', err)
        utils.notify('success', 'Successfully Updated')
      })
    }
  })
})()
