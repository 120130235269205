/* globals zc _ Backbone utils */

(function () {
  'use strict'

  zc.views.ChatAndFootnotesView = Backbone.View.extend({

    template: _.template($('.chat-and-footnote-template').html()),

    events: {
      'click nav button': 'switchTabsFromElm',
      'click .download-footnotes': 'downloadFootnotes'
    },

    className: 'chat',

    initialize: function () {
      _.bindAll(this, 'onFootnoteHotkey')
    },

    switchTabsFromElm: function (e) {
      this.switchTabs($(e.currentTarget).hasClass('ch'))
    },

    downloadFootnotes: function () {
      var text = ''
      this.model.recorder.recording.footnotes.map(function (footnote) {
        text += utils.msToHms(footnote.get('time')) + ': ' + footnote.get('text') + '\n'
      })

      var blob = new Blob([text], {type: 'text/plain'})
      utils.forceDownload(blob, 'footnotes.txt')
    },

    switchTabs: function (isChatTab) {
      this.$tabs.removeClass('active')

      if (isChatTab) {
        this.chatView.$el.addClass('active')
        this.footnotesView.$el.removeClass('active')
        this.$tabs.filter('.ch').addClass('active')
      } else {
        this.chatView.$el.removeClass('active')
        this.footnotesView.$el.addClass('active')
        this.$tabs.filter('.fn').addClass('active')
      }
    },

    onFootnoteHotkey: function () {
      this.switchTabs(false)
    },

    renderChat: function () {
      this.chatView = new zc.views.ChatView({model: this.model.chat, el: this.$chat})
      this.chatView.render()
    },

    renderFootnotes: function () {
      this.footnotesView = new zc.views.FootnotesView({model: this.model.recorder, el: this.$footnotes, onFootnoteHotkey: this.onFootnoteHotkey})
      this.footnotesView.render()
    },

    render: function () {
      this.$el.html(this.template(this.model.toJSON()))
      this.$chat = this.$('.chat')
      this.$footnotes = this.$('.footnotes')
      this.$tabs = this.$('.tabs button')
      this.renderChat()
      this.renderFootnotes()
    }
  })
})()
