/* globals servars app zc _ Backbone AudioRecorder */

(function () {
  'use strict'

  zc.views.BufferView = Backbone.View.extend({
    initialize: function (options) {
      this.actx = options.actx
      this.analyser = this.actx.createAnalyser() // analyser node to share with buffer view and peak meter view
      this.analyser.smoothingTimeConstant = 0.85

      this.canvasId = Math.round(Math.random() * 1000000)

      this.floatTimeData = new Float32Array(1024)
      this.canvasWorker = options.canvasWorker

      /**
       * Reference to the local rtc connection that we use to render the stream
       * @type {RTCPeerConnection}
       */
      // this.localConnection = null

      this.useWorker = this.canvasWorker && window.OffscreenCanvas

      this.disableWaveDrawing = localStorage.getItem('disableWaveDrawing')
      this.pullMethod = !localStorage.getItem('enableWavesWithMR')
    },

    className: 'buffer',

    template: _.template($('.buffer-template').html()),

    /**
     * Used to setup the web worker that does the computing
     */
    setupCanvasWorker: function () {
      var self = this

      this.canvasWorker.addEventListener('message', function (ev) {
        if (ev.data.canvasId !== self.canvasId) return

        if (ev.data.command === 'pull') {
          // transferable buffers would not have been a good idea in this case
          self.analyser.getFloatTimeDomainData(self.floatTimeData)

          ev.ports[0].postMessage({
            audio: self.floatTimeData,
            width: self.wavCanvas.width,
            isRecording: self.isRecording
          })
          return
        }

        if (ev.data.error) {
          console.error(ev.data.error)

          // stop using the worker for this view
          self.canvasWorker = null
          // in order to start using the old method we need to create a new canvas
          // which is basically a clone of the existing one
          // after calling transferControlToOffscreen() we can't get a new context
          // so we need this small hack
          var clone = self.$wavCanvas.clone()
          self.$wavCanvas.replaceWith(clone)
          self.wavCanvas = clone[0]

          if (this.mediaRecorder) {
            this.mediaRecorder.stop()
          }
        }
      })

      // we need to use defer because the el doesn't actually have any height/width
      _.defer(function () {
        // we set the height/width here. we can't use resizeBuffer because we don't have a buf canvas
        // and we need to set them before transferControlToOffscreen()
        self.wavCanvas.height = self.$el.height()
        self.wavCanvas.width = self.$el.width()

        var offscreen = self.wavCanvas.transferControlToOffscreen()

        // add the canvas
        self.canvasWorker.postMessage({
          command: 'add-canvas',
          decoderUrl: servars.canvasWorker.deppendencies,
          // create a unique id for this canvas
          canvasId: self.canvasId,
          canvas: offscreen,
          pullMethod: self.pullMethod
        }, [offscreen])

        self.$wavCanvas.css('opacity', '') // remove previously set inline opacity style to reveal canvas
      })
    },

    /**
     * Old way of drawing on canvas
     */
    setupBufferCanvas: function () {
      var self = this

      this.wctx = this.wavCanvas.getContext('2d', {alpha: false})

      // create clone buffer canvas for translations
      this.bufCanvas = document.createElement('canvas')
      this.bctx = this.bufCanvas.getContext('2d', {alpha: false})
      this.bufCanvas.width = this.wavCanvas.width
      this.bufCanvas.height = this.wavCanvas.height

      _.defer(function () {
        self.resizeBuffer()
        self.applyCanvasStyles(self.wctx)
        self.applyCanvasStyles(self.bctx)
        self.wctx.fillRect(0, 0, self.wavCanvas.width, self.wavCanvas.height)
        self.bctx.fillRect(0, 0, self.bufCanvas.width, self.bufCanvas.height)

        self.$wavCanvas.css('opacity', '') // remove previously set inline opacity style to reveal canvas
      })
    },

    /**
     * Called when we want to start drawing
     */
    startDrawingWaveformTail: function (stream) {
      if (this.useWorker) {
        // if wave drawing are disabled. dev option
        if (this.disableWaveDrawing) {
          return
        }

        // if we've setup that the worker will pull data from the stream
        if (this.pullMethod) {
          this.setupPullMethod(stream)
        } else {
          this.setupPushMethod(stream)
        }
      } else {
        this.drawWaveformTailAnimation = this.drawWaveformTailAnimation || {context: this, draw: this.drawWaveformTail}
        app.animationFrameManager.addAnimation(this.drawWaveformTailAnimation)
      }
    },

    // Event calbacks
    isRecordingChange: function (isRecording) {
      this.isRecording = isRecording
      if (this.isRecording) {
        this.recordingStartPrep()
      } else {
        if (this.mediaRecorder && this.mediaRecorder.state === 'recording') {
          this.mediaRecorder.stop()
        }
      }
    },

    tearDown: function () {
      this.drawWaveformTailAnimation && app.animationFrameManager.removeAnimation(this.drawWaveformTailAnimation)
      this.canvasWorker.postMessage({
        command: 'remove',
        canvasId: this.canvasId
      })
      this.remove()
    },
    // End event calbacks

    // Helper methods
    recordingStartPrep: function () {
      // reduce the size of the float time data while recording to optimize for performance
      this.drawX = 0
      this.analyser.fftSize = this.floatTimeData.length
    },

    setupPullMethod: function (stream) {
      var source = this.actx.createMediaStreamSource(stream)
      source.connect(this.analyser)
    },

    setupPushMethod: function (media) {
      var self = this

      var stream = media.value

      if (this.mediaRecorder && this.mediaRecorder.state === 'recording') {
        this.mediaRecorder.stop()
        // tell the canvas worker to reset this canvas
        this.canvasWorker.postMessage({
          command: 'reset',
          canvasId: this.canvasId
        })
      }

      // start the media recorder and start sending audio chunks
      this.mediaRecorder = new AudioRecorder(stream)
      this.mediaRecorder.ondataavailable = async function (e) {
        var data = e.data

        if (!self.canvasWorker) {
          self.mediaRecorder.stop()
        }

        self.canvasWorker.postMessage({
          command: 'audio',
          canvasId: self.canvasId,
          width: self.wavCanvas.width,
          isRecording: self.isRecording,
          blob: data
        })
      }

      // use 60ms chunks because is optimized for opus
      this.mediaRecorder.start(60)
    },

    drawWaveformTail: function (self) {
      var elWidth = self.$el.width()

      if (self.wavCanvas.width !== elWidth) {
        try {
          // bounce the image over to the buffer so we don't loose it when we resize the wavCanvas
          self.bctx.fillRect(0, 0, self.bufCanvas.width, self.bufCanvas.height)
          self.bctx.drawImage(self.wavCanvas, 0, 0)
          self.wavCanvas.width = elWidth
        } catch (e) {}

        try {
          // resizing the canvas seems to clear out the styles so reapply
          self.applyCanvasStyles(self.wctx)
          self.wctx.fillRect(0, 0, self.wavCanvas.width, self.wavCanvas.height)
          self.wctx.drawImage(self.bufCanvas, self.bufCanvas.width - self.bufCanvas.width, 0)
          self.bufCanvas.width = self.wavCanvas.width
          self.applyCanvasStyles(self.bctx)
        } catch (e) {}
      }

      self.analyser.getFloatTimeDomainData(self.floatTimeData)

      if (self.isRecording) {
        // clear the canvas on the first recording call to remove the tail waveform
        if (!self.canvasClearedForRecording) {
          self.wctx.fillRect(0, 0, self.wavCanvas.width, self.wavCanvas.height)
          self.canvasClearedForRecording = true
        }
        self.drawScrollingWaveform(self.floatTimeData)
      } else {
        self.wctx.fillRect(0, 0, self.wavCanvas.width, self.wavCanvas.height)
        self.drawWaveform(self.floatTimeData, 0, self.wavCanvas.width)
      }
    },

    drawWaveform: function (f32, startX, width) {
      var wctx = this.wctx
      var canvas = this.wavCanvas
      var height = canvas.height
      width = width ? Math.max(width, 1) : canvas.width // min width is 1
      width = Math.min(width, canvas.width) // max width is the canvas.width
      var pxPerF32 = width / f32.length
      var pxInc = Math.max(Math.ceil(pxPerF32), 1)
      var x = startX
      this.lastMaxF32 = this.lastMaxF32 || 0

      wctx.beginPath()
      wctx.moveTo(x, height / 2)
      for (var i = 0; i < f32.length; i++) {
        var yOffset = f32[i] * height
        var y = height / 2 + yOffset / 2
        if (i === 0) wctx.moveTo(x, y)
        wctx.lineTo(x, y)
        // wctx.stroke()
        x += pxInc
        if (x >= width) break // break out early if we are past the edge of the canvas
      }
      wctx.stroke()
    },

    drawScrollingWaveform: function (f32) {
      var wctx = this.wctx
      var height = this.wavCanvas.height
      var pxToTravel = 1

      // start shifting the canvas when we reach the end
      if (this.drawX >= this.wavCanvas.width - pxToTravel) {
        this.drawX = this.wavCanvas.width - pxToTravel

        // check to see if the canvas aren't the same size
        // and make bufCanvas match wavCanvas.
        if (this.bufCanvas.width !== this.wavCanvas.width) {
          this.bufCanvas.width = this.wavCanvas.width
          this.applyCanvasStyles(this.bctx)
        }

        this.bctx.fillRect(0, 0, this.bufCanvas.width, this.bufCanvas.height)
        try {
          this.bctx.drawImage(this.wavCanvas, -pxToTravel, 0)
        } catch (e) {}

        try {
          this.wctx.drawImage(this.bufCanvas, 0, 0)
        } catch (e) {}
      }

      var sample = Math.max.apply(Math, f32)

      this.lineHeight = this.lineHeight || 0

      var lineHeight = sample ? Math.max(Math.round(sample * height), this.wctx.lineWidth) : this.wctx.lineWidth

      this.lineHeight = lineHeight // no smoothing

      var x = this.drawX
      var y = (height / 2) - (this.lineHeight / 2)
      var y1 = y + this.lineHeight
      wctx.beginPath()
      wctx.moveTo(x, y)
      wctx.lineTo(x, y1)
      wctx.stroke()

      if (this.drawX < this.wavCanvas.width - this.wctx.lineWidth) this.drawX += this.wctx.lineWidth
    },

    applyCanvasStyles: function (ctx) {
      ctx.fillStyle = '#ffffff'
      ctx.strokeStyle = '#bab0ff'
      ctx.lineWidth = 1
      ctx.lineCap = 'round'
    },

    resizeBuffer: function () {
      this.wavCanvas.height = this.$el.height()
      this.wavCanvas.width = this.$el.width()
      this.bufCanvas.height = this.wavCanvas.height
      this.bufCanvas.width = this.wavCanvas.width
    },

    render: function () {
      this.$el.html(this.template())

      this.$wavCanvas = this.$('.wav-canvas')
      this.$wavCanvas.css('opacity', 0) // hide until we paint it to avoid black flash
      this.wavCanvas = this.$wavCanvas[0]

      if (this.useWorker) {
        this.setupCanvasWorker()
      } else {
        this.setupBufferCanvas()
      }

      return this
    }
  })
})()
