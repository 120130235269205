/* globals servars zc zc2 utils Backbone debug app analytics recordingTransactionsClient jwtClient */
(function () {
  'use strict'

  var dbg = debug('zc:websocket')

  zc.models.SocketConnection = Backbone.Model.extend({
    initialize: function (attrs, options) {
      dbg('Initializing websocket manager')
    },

    defaults: {},

    attrs: function () {
      var attrs = this.toJSON()
      return attrs
    },

    connect: function () {
      zc2.log.info('Connecting to socket')

      var self = this

      const auth = /** @type {import('@zencastr/jwt-fetch-client')} */(jwtClient).hasAccessToken() ? { token: jwtClient.getAccessToken() } : undefined
      var socket = this.socket = zc2.lib.io.connect(window.location.protocol + '//' + servars.websocketDomain + ':' + window.location.port, {
        secure: (window.location.protocol === 'https:'),
        transports: ['websocket'],
        auth
      })
      const socketId = socket.id

      recordingTransactionsClient.init(socket, 6000) // 2.5secs timeout on client

      zc2.services.SocketService.initialize(socket)

      socket.on('connect', function () { // when a successful connection is made
        zc2.log.info('socket_connection `connect`', { socketId })
        self.set({ 'connected': true })
        console.log('Socket connect successful')
      })

      socket.on('connect_error', function (err) { // when a connect attempt throws an error
        zc2.log.warn('socket_connection `connect_error`', { socketId, err })
        console.error('Socket connect error: ', utils.serializeError(err))
        if (err && err.data && err.data.isTokenExpired) {
          const traceId = err && err.data && err.data.traceId
          console.warn(`${traceId} - Closing the socket connection permanently (token expired)`)
          socket.close()
        }
      })

      socket.on('connect_timeout', function (timeout) { // when a connect times out
        zc2.log.warn('socket_connection `connect_timeout`', { socketId, timeout })
      })

      socket.on('reconnecting', function (attempt) { // fired on a successful reconnection
        zc2.log.warn('socket_connection `reconnecting`', { socketId, attempt })
      })

      socket.on('reconnect_attempt', function (attempt) { // fired on a reconnection attempt
        zc2.log.warn('socket_connection `reconnect_attempt`', { socketId, attempt })
      })

      socket.on('reconnect_failed', function () { // fired when couldn't reconnect within reconnectionAttempts
        zc2.log.warn('socket_connection `reconnect_failed', { socketId })
      })

      // when reconnection is successful
      socket.on('reconnect', function (attempt) { // fired on a successful reconnection
        zc2.log.info('socket_connection `reconnect`', { socketId, attempt })
      })

      socket.on('reconnect_error', function (err) { // fired on a reconnect error
        zc2.log.warn('socket_connection `reconnect_error`', { socketId, err })
      })

      socket.on('disconnect', function (reason) {
        zc2.log.info('socket_connection `disconnect`', { socketId, reason })

        self.set({ connected: false })
        if (reason === 'io server disconnect') {
          // the disconnection was initiated by the server and we may need to reconnect manually
          console.warn('Socket disconnect initiated by server')
        }
        console.warn('Socket disconnect. Reason: ', reason)
        if (app.project && app.project.recorder.get('isRecording')) {
          analytics.track('SocketDisconnect', {
            userId: app.user.id,
            recordingId: app.project.recorder.recording.id,
            trackIds: app.user.tracks.models.map(function (track) {
              return track.id
            })
          })
        }
      })

      socket.on('close', function (reason) {
        zc2.log.warn('socket_connection `close`', { socketId, reason })
        // TODO what should we do here?
      })

      socket.on('error', function (err) {
        zc2.log.warn('socket_connection `error`', { socketId, err })
      })

      return this
    },

    initSocketEvents: function (app) {
      var project = app.project
      var socket = this.socket
      const socketId = socket.id

      // in case we get disconnected and reconnect, rejoin room
      socket.on('connect', async function () {
        zc2.log.debug('socket_connection `connect` reconnect', { socketId })
        // wait for joinRoom to complete before we emit any events that were buffered during disconnection
        // this makes sure that the server is ready to handle the events and won't crash the server
        if (socket.sendBuffer.length) {
          var eventBuffer = socket.sendBuffer
          socket.sendBuffer = []
          await project.rejoinRoom(function () {
            socket.sendBuffer = eventBuffer
            socket.emitBuffered()
          })
        } else {
          await project.rejoinRoom()
        }

        // app.set('connected', socket.connected)
        if (project.$disconnectedAlert) {
          project.$disconnectedAlert.remove()
          utils.notify('success', 'Reconnected', { ttl: 1500 })
        }
      })

      socket.on('disconnect', function () {
        zc2.log.debug('socket_connection `disconnect`', { socketId })
        dbg('Disconnected from socket server')
        project.$disconnectedAlert = utils.notify('alert', 'Lost connection to server.  Attempting to reconnect...')
      })

      socket.on('roomJoined', async function (data) {
        zc2.log.debug('socket_connection `roomJoined`', { socketId })
        var isRecording = data.room.isRecording
        var isPaused = data.room.isPaused

        // Update each user to ensure their initial paused state matches that of the recording
        var paused = app.project.recorder.recording.get('paused')
        data.room.members.forEach(function (m) {
          m.paused = paused
        })
        app.user.set('paused', paused)

        console.log('Recording status from join room, isRecording: ', { isRecording: isRecording, isPaused: isPaused })
        console.log('Local recording status recording:', {
          recording: { isRecording: project.recorder.recording.get('isRecording'), paused: project.recorder.recording.get('paused') },
          recorder: { isRecording: project.recorder.get('isRecording'), paused: project.recorder.get('paused') }
        })

        project.addRoomMembers(data.room.members)

        // make sure we are getting this values from sockets
        if (isRecording !== undefined && isPaused !== undefined) {
          var isRecordingOutOfSync = isRecording !== project.recorder.recording.get('isRecording')
          var isPausedOutOfSync = isRecording && isPaused !== project.recorder.recording.get('paused')

          if (isRecordingOutOfSync || isPausedOutOfSync) {
            console.log('Recording states differ after rejoining room, updating local to match server\'s')
            project.recorder.recording.set({ isRecording: isRecording, paused: isPaused })

            // NOTE: recorder's paused actually has 3 states, if you set it to false it assume's there's a recording going on and won't let you start a new recording even when the recording is new
            if (project.recorder.get('micArmed')) {
              await project.recorder.handleStartRecording()
              if (isRecording && isPaused) {
                await project.recorder.handlePause()
              }
            } else {
              console.log('Waiting for getLocalMedia before executing recorder state change')
              project.recorder.once('change:micArmed', async function (micArmed) {
                if (micArmed) {
                  await project.recorder.handleStartRecording()
                  if (isRecording && isPaused) {
                    await project.recorder.handlePause()
                  }
                }
              })
            }

            // make sure the users in the lobby show the correct state
            project.lobby.users.map(function (user) {
              user.set({
                isRecording: isRecording
              })
            })
          }
        }

        if (!project.initiated && !data.room.full) {
          project.call.sfuUrl = data.room.sfuUrl

          if (!app.user.get('greenroom')) {
            project.startDevices()
              .then(function () {
                if (project.lobby.users.length > 1) {
                  project.call.connect().catch(function (error) {
                    console.error(error)
                    utils.notify('error', error.message, {}, 'An error has been encountered')
                  })
                }
              }).catch(function (e) {
                utils.notifyError(e)
              })
          }

          project.initiated = true
        } else if (data.room.full) {
          project.joinPrevented = true
        }
      })

      socket.on('room:hasSpace', async function () {
        zc2.log.debug('socket_connection `room:hasSpace`', { socketId })
        if (project.joinPrevented && !project.initiated) {
          await project.joinRoom(true)
        }
      })

      socket.on('alreadyJoined', function (data) {
        zc2.log.debug('socket_connection `alreadyJoined`', { socketId, data })
        if (data.redirect) {
          window.location.href = '/alreadyJoined'
        } else {
          project.trigger('alreadyJoined')
        }
      })

      socket.on('redirect:home', function () {
        zc2.log.verbose('socket_connection `redirect:home`', { socketId })
        window.location.href = '/dashboard'
      })

      socket.on('voip:users', function (data) {
        zc2.log.debug('socket_connection `voip:users`', { socketId })
        data.users.forEach(function (userData) {
          var lobbyUser = project.lobby.users.get(userData)
          if (lobbyUser) {
            lobbyUser.set(userData)
            lobbyUser.set({ isVoipConnected: true })
          }
        })
      })

      socket.on('voipChange', function (data) {
        zc2.log.debug('socket_connection `voipChange`', { socketId })
        if (!app.user.get('greenroom')) {
          if (data.command === 'endCall') {
            project.call.disconnect()
          } else {
            project.call.reconnect()
          }
        } else {
          project.call.setLeftCall(data.command === 'endCall')
        }
      })

      socket.on('newMember', function (data) {
        zc2.log.debug('socket_connection `newMember`', { socketId, member: data.member })
        project.addRoomMember(data.member)

        // If two members join the greenroom at once, we should not initiate the call until the user has left the greenroom
        if (!app.user.get('greenroom')) {
          project.call.connect().catch(function (error) {
            console.error(error)
            utils.notify('error', error.message, {}, 'An error has been encountered')
          })
        }

        if (app.user.isHost()) {
          analytics.track('GuestJoining', {
            guestId: data.member._id,
            recordingId: project.recorder.recording.id,
            projectId: project.id
          })
        }
      })

      socket.on('memberLeft', function (data) {
        zc2.log.debug('socket_connection `memberLeft`', { socketId, member: data.member })
        console.log('User left the room', data.member.username)
        var user = project.lobby.users.get(data.member._id)
        if (user) {
          var userHasTracksInRecording = project.recorder.recording.tracks.getTracksForUser(user.get('_id')).length > 0
          var userHasTracks = user.tracks.length > 0

          user.set({socketConnected: false})

          // if the user has tracks on this recording, then he took part
          // keep him in the view
          if (userHasTracksInRecording || userHasTracks) {
            // TODO: what should we do here? the offline status is already added
            // TODO: Identify a more diligent way of handling isRecording here; currently there is no way to tell if a user navigated away from the page,
            //  or if their socket connection timed out, so they are probably not on the page, but they *might* still be
            user.set({ isVoipConnected: false, isRecording: false })
          } else {
            // else, he is just a guest, remove him
            project.lobby.users.remove(user)
            project.buffers.remove(project.buffers.get(user.id))
          }
        }
      })

      socket.on('startOver', function () {
        zc2.log.verbose('socket_connection `startOver`', { socketId })
        project.trigger('stopCloseTabProtection')
        project.startOver()
      })

      socket.on('kickUser', function (data) {
        zc2.log.verbose('socket_connection `kickUser`', { socketId, data })
        if (app.user.id === data.userId) {
          project.trigger('stopCloseTabProtection')
          window.location.pathname = '/kicked'
        }
      })

      socket.on('user:change:healthChecks', function (data) {
        zc2.log.verbose('socket_connection `user:change:healthChecks`', { socketId, data })
        var user = project.lobby.users.get(data.userId)

        if (user) {
          user.criticalHealthChecks.reset(data.criticalHealthChecks)
          user.warningHealthChecks.reset(data.warningHealthChecks)
        } else {
          console.warn('Tried to update health checks for non existent user')
        }
      })

      socket.on('user:change:localStorageQuota', function (data) {
        zc2.log.verbose('socket_connection `user:change:localStorageQuota`', { socketId, data })
        var user = project.lobby.users.get(data.userId)

        if (user) {
          user.set({ localStorageQuota: data.localStorageQuota })
        } else {
          console.warn('Tried to update localStorageQuota for non existent user')
        }
      })

      socket.on('user:change:settings', function (data) {
        zc2.log.verbose('socket_connection `user:change:settings`', { socketId, data })
        var user = project.lobby.users.get(data.userId)
        if (user.isHost()) { // the hosts settings rule them all
          app.user.settings.set(data.settings)
        }
      })

      socket.on('user:change:audioInput', function (data) {
        zc2.log.verbose('socket_connection `user:change:audioInput`', { socketId, data })
        var user = project.lobby.users.get(data.userId)
        user.audioInput.set(data.audioInput)
      })

      socket.on('user:change:micArmed', function (data) {
        zc2.log.verbose('socket_connection `user:change:micArmed`', { socketId, data })
        var user = project.lobby.users.get(data.userId)
        if (user) {
          user.audioInput.set(data.audioInput)
          user.set({ micArmed: data.micArmed })
        } else {
          console.warn('Received user:change:micArmed from non existing user', data.userId)
        }
      })

      socket.on('user:change:isRecording', function (data) {
        zc2.log.debug('socket_connection `user:change:isRecording`', { socketId, data: { userId: data.user._id } })
        // get the user that just changed its isRecording
        var user = project.lobby.users.get(data.user._id)

        // make sure that the isRecording change happens on the right recording
        // this may happen if the guest is on an old recording and the host starts to record on the last recording
        if (data.recordingId !== project.recorder.recording.id) {
          var message = ''

          // if the event comes from the host, it means we are a guest
          if (user.isHost()) {
            // we should show an error depending if the recording should start or not
            if (data.isRecording) {
              message = 'There is a problem starting your recording, please refresh your page.'
            } else {
              message = 'There is a problem changing your recording state, please refresh your page.'
            }
          } else if (app.user.isHost()) {
            message = 'A guest could not start recording. They should refresh the page.'
          }

          utils.notify('error', message)

          // it's a good idea to just return here, we don't want to update any models
          return
        }

        var recAttrs = { isRecording: data.isRecording }
        user.set(recAttrs)

        // if the user is the host, then he started the recording
        if (user.isHost()) {
          // Ensure that the user is keeping track of the room's recording state correctly.
          project.recorder.recording.set({ isRecording: recAttrs.isRecording })

          // make sure this user is not on the green room, unless recording is stopping
          if (!app.user.get('greenroom') || !recAttrs.isRecording) {
            project.recorder.set(recAttrs)
          }
        }
      })

      socket.on('user:change:muted', function (data) {
        zc2.log.verbose('socket_connection `user:change:muted`', { socketId, data })
        var user = project.lobby.users.get(data.userId)
        user.set({ muted: data.muted })
        if (user.isLocal()) {
          user.trigger('localUserMutedChange', user, data.muted)
        }
      })

      socket.on('user:change:cameraOn', function (data) {
        zc2.log.verbose('socket_connection `user:change:cameraOn`', { socketId, data })
        var user = project.lobby.users.get(data.userId)
        user.set({ cameraOn: data.cameraOn })
      })

      socket.on('user:change:cameraConnected', function (data) {
        zc2.log.verbose('socket_connection `user:change:cameraConnected`', { socketId, data })
        var user = project.lobby.users.get(data.userId)
        user.set({ cameraConnected: data.cameraConnected })
      })

      socket.on('user:change:paused', function (data) {
        zc2.log.debug('socket_connection `user:change:paused`', { socketId })
        var user = project.lobby.users.get(data.user._id)
        if (!user) return

        user.set({ paused: data.paused })
        if (user.isHost()) {
          project.recorder.set({ paused: data.paused })
        }
      })

      socket.on('user:countdownStarted', function (data) {
        zc2.log.debug('socket_connection `user:countdownStarted`', { socketId })
        var user = project.lobby.users.get(data.userId)
        if (user.isHost()) {
          project.recorder.startCountdown()
        }
      })

      socket.on('user:track:created', function (data) {
        zc2.log.verbose('socket_connection `user:track:created`', { socketId, data })
        var user = project.lobby.users.get(data.userId)
        if (user) user.tracks.add(data.track)
      })

      socket.on('upload:delete', function (data) {
        zc2.log.verbose('socket_connection `upload:delete`', { socketId, data })
        var track = project.recorder.recording.tracks.get(data._id)
        if (track) {
          project.recorder.recording.tracks.remove(track)
        } else {
          console.warn('Attempted to remove non-existent track')
        }
      })

      socket.on('upload:update', function (data) {
        zc2.log.verbose('socket_connection `upload:update`', { socketId, data })
        var track = project.recorder.recording.tracks.get(data._id)

        // just in case this version of the track never got updated as non-tentative
        data.tentative = false

        // when hosts receives this events for a guest they might not contain the uploading flag
        // (it's not present on the db model)
        // in that case if the file is finalized or the processing has started
        // then we can assume that it's not uploading anymore
        if (data.finalized || data.processing === true) {
          data.uploading = false
          data.error = null
        }

        // if host receives this event
        if (app.user.isHost()) {
          var user = project.lobby.users.get(data.userId)
          if (!user) return // nothing to do if there is no user

          if (!track) {
            track = user.tracks.get(data._id)
          }

          if (data.finalized) {
            console.log('host received event that ', data.format, 'track finalized for user', data.userId)
          }

          if (track) {
            track.set(data)
          } else {
            // this is a hacky way of making sure
            // we do not add the soundboard track back again on the host side
            if (data.type === 'soundboard') return

            // create the track if it doesn't exits.  it should
            data.username = user.get('username')
            track = new zc.models.Track(data)
          }

          project.recorder.recording.tracks.add(track) // renders the track in the UI
        } else if (track) {
          // if not a host, and the track is present locally
          track.set(data)
        }
      })

      socket.on('transcription-completed', function (data) {
        zc2.log.verbose('socket_connection `transcription-completed`', { socketId, data })
        if (data.recordingId === project.recorder.recording.id) {
          project.recorder.recording.set('transcription', data.transcription)
        } else {
          var recording = app.project.recordings.find(function (r) { return r.id === data.recordingId })
          if (recording) recording.set('transcription', data.transcription)
        }
      })

      socket.on('track:change:percentSaved', function (data) {
        zc2.log.verbose('socket_connection `track:change:percentSaved`', { socketId, data })
        var user = project.lobby.users.get(data.userId)
        if (!user) return
        var track = user.tracks.get(data._id)
        if (track) {
          track.set({
            bytesRecorded: data.bytesRecorded,
            bytesSaved: data.bytesSaved,
            bytesUploaded: data.bytesUploaded
            // percentSaved: data.percentSaved
          })
        }
      })

      socket.on('track:change:percentUploaded', function (data) {
        zc2.log.verbose('socket_connection `track:change:percentUploaded`', { socketId, data })
        var user = project.lobby.users.get(data.userId)
        if (!user) return
        var track = user.tracks.get(data._id)
        if (track) {
          track.set({
            bytesRecorded: data.bytesRecorded,
            bytesSaved: data.bytesSaved,
            bytesUploaded: data.bytesUploaded
            // percentUploaded: data.percentUploaded
          })
        }
      })

      socket.on('user:initialization:failure', async function (data) {
        zc2.log.verbose('socket_connection `user:initialization:failure`', { socketId, data })
        if (app.user.isHost()) {
          // notify the host
          utils.notify('error', data.user.username + ' ran into an error initializing their session for recording: ' + data.error + '<br /><br />Aborting recording to prevent audio loss.  If this problem persists, please contact support at support@zencastr.com')

          // stop the recording, this should propagate to all parties
          project.recorder.initiateStopRecordingTransaction()
        }
        project.recorder.set({ aborted: data.aborted })
      })

      socket.on('incomingAudio', function (chunk, meta) {
        zc2.log.verbose('socket_connection `incomingAudio`', { socketId })
        // check for existing buffer for user
        var buffer = project.buffers.get(meta.user)

        // create it if it doesn't exist
        if (!buffer) {
          // use the associated user id as the buffer id
          buffer = new zc.models.Buffer({ id: meta.user }, { actx: project.actx })
          project.buffers.add(buffer)
        }

        var f32Chunk = new Float32Array(chunk)

        // push chunks to buffer
        buffer.trigger('newChunk', buffer, f32Chunk)
      })

      socket.on('chat:message', function (message) {
        zc2.log.verbose('socket_connection `chat:message`', { socketId, message })
        project.chat.messages.add(message)
      })

      socket.on('user:chat:typingStarted', function (user) {
        zc2.log.verbose('socket_connection `user:chat:typingStarted`', { socketId, user })
        project.chat.trigger('remoteTypingStarted', project.chat, user)
      })

      socket.on('user:chat:typingStopped', function (user) {
        zc2.log.verbose('socket_connection `user:chat:typingStopped`', { socketId, user })
        project.chat.trigger('remoteTypingStopped', project.chat, user)
      })

      socket.on('user:change:handRaised', function (data) {
        zc2.log.verbose('socket_connection `user:change:handRaised`', { socketId, data })
        var user = project.lobby.users.get(data.userId)
        user.set({ handRaised: data.handRaised })
      })
    }
  })
})()
