/* globals zc _ utils Backbone app */

(function () {
  'use strict'

  zc.views.ProjectCreateFormView = zc.views.FormView.extend({
    initialize: function (options) {
      this.selected = 'enabled' // default

      this.callback = options.callback || this.callback

      if (this.model) {
        this.listenTo(this.model, 'loading', this.showLoading)
        this.listenTo(this.model, 'doneLoading', this.hideLoading)
      }
      this.languageModel = new Backbone.Model({
        options: [
          {
            label: 'Loading...',
            value: ''
          }
        ]
      })

      this.listenTo(this.languageModel, 'change:value', this.languageChanged)
    },

    className: 'project-create-form form',

    template: _.template($('.project-create-form-template').html()),

    events: {
      'click .submit': 'submit',
      'submit form': 'submit',
      'keypress input': 'inputKeypress',
      // parents events above this line; I had to include them
      'keydown input': 'inputKeydown',
      'click .media-inclusion-option': 'handleOptionSelection',
      'mouseover .media-inclusion-option': 'mouseoverOption',
      'mouseout .media-inclusion-option': 'mouseoutOption'
    },

    inputKeydown: function (e) {
      if (this.$projectNameInput.hasClass('has-error')) {
        this.$projectNameInput.removeClass('has-error')
        this.hideError()
      }
      this.inputKeypress(e)
    },

    handleOptionSelection: function (e) {
      var $allOptions = $('.media-inclusion-option')
      var $who = $(e.currentTarget)

      $allOptions.find('img, p').css('opacity', 0.4)
      $allOptions.css('border', '3px solid rgba(255, 255, 255, 0.0)')

      $who.find('img, p').css('opacity', 1.0)
      $who.css('border', '3px solid rgba(64, 113, 255, 0.5)')
      this.selected = $who.data('id')
    },

    mouseoverOption: function (e) {
      var $who = $(e.currentTarget)

      // fully show the images and text in this options box
      $who.find('img, p').css('opacity', 1.0)
    },

    mouseoutOption: function (e) {
      var $who = $(e.currentTarget)

      // fade this option, but not if its not the currently selected one
      if (this.selected !== $who.data('id')) {
        $who.find('img, p').css('opacity', 0.4)
      }
    },

    createProject: function (name) {
      if (utils.slugify(name)) {
        var values = this.values()
        this.callback({name: name, language: values.language, navigatorLanguage: this.navLang, language_pretty: values.language_pretty})
        this.exit()
      } else {
        this.$error.text('Please enter a valid project name')
      }
    },

    values: function () {
      return {
        name: this.$projectNameInput.val(),
        defaultVideoRecordingMode: this.selected,
        language: this.languageModel.get('value'),
        language_pretty: this.$languageSelect.val()
      }
    },

    validateName: function (name) {
      if (!utils.slugify(name)) {
        return 'Please enter a valid project name'
      }

      if (name.length > 1000) {
        return 'Episode names must be shorter than 1000 characters'
      }

      return false
    },

    validateForm: function (attrs) {
      var validNameError = this.validateName(attrs.name)
      if (validNameError) {
        return validNameError
      }

      return false
    },

    submit: function () {
      var self = this
      var values = this.values()

      var validationError = this.validateForm(values)

      if (validationError) {
        this.$projectNameInput.addClass('has-error')
        return this.showError(validationError)
      } else {
        this.$projectNameInput.removeClass('has-error')
      }

      if (!this.languageModel.get('value')) {
        this.$languageSelect.addClass('has-error')
        return this.showError('Please select a language')
      } else {
        this.$languageSelect.removeClass('has-error')
      }

      values.slug = utils.slugify(values.name)

      this.showLoading()
      this.callback(values).catch(function (err) {
        self.hideLoading()
        if (err.indexOf('must be unique') !== -1) {
          self.$projectNameInput.addClass('has-error')
          return self.showError('You already have an episode with that name!  Please choose something unique.')
        }
      })
    },

    languageChanged: function (a) {
      if (a.get('value') !== 'en') {
        this.$('.media-inclusion-option').addClass('no-transcriptions')
      } else {
        this.$('.media-inclusion-option').removeClass('no-transcriptions')
      }
    },

    render: function () {
      var self = this
      zc.views.FormView.prototype.render.call(this)
      this.$projectNameInput = this.$('.project-name-input')
      this.$inputs = this.$('.inputs')
      this.$el.addClass(app.user.hasFreePlan() ? 'free' : 'paid')

      var languageSelectView = new zc.views.FilterableSelectView({
        model: this.languageModel
      })
      languageSelectView.render()

      // Fetch list of languages (it is large so we don't want to inline it)
      fetch('/media/scripts/data/languages.json').then(function (response) {
        return response.json()
      }).then(function (values) {
        // Load the languages into the select
        this.languageModel.set('options', values)
        // Preselect the language for the user's browser, if we can find it.
        this.navLang = navigator.language
        var preselectCode = this.navLang.split('-')[0]
        var preselectVal = values.find(function (val) {
          return val.value === preselectCode
        })
        if (preselectVal) {
          languageSelectView.select(preselectVal.label, preselectVal.value)
        }
      }.bind(this))

      this.$languageSelect = languageSelectView.$('input')
      this.$inputs.append(languageSelectView.$el)
      languageSelectView.$el.css('width', '184px')

      _.defer(function () {
        self.$projectNameInput.focus()
      })

      return this
    }
  })
})()
