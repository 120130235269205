/* globals zc _ utils */

(function () {
  'use strict'

  zc.views.BillingAddressView = zc.views.FormView.extend({

    template: _.template($('.billing-address-template').html()),

    className: 'user-info-form form',

    values: function () {
      return {
        fullName: utils.sanitizeText(this.$fullName.val()),
        businessName: utils.sanitizeText(this.$businessName.val()),
        address: utils.sanitizeText(this.$address.val())
      }
    },

    url: function () {
      return '/api/forms/billing-address/' + this.model.id
    },

    callback: function (err) {
      if (err) {
        utils.notify('error', err)
      } else {
        utils.notify('success', 'Successfully updated billing address.', {ttl: 3000})
      }
    },

    validateForm: function (values) {
      if (!values.fullName) {
        return 'Please enter a name'
      }

      // if (values.businessName) {
      //   return "Please enter your business' name";
      // }

      if (!values.address) {
        return 'Please enter your billing address'
      }

      return false
    },

    render: function () {
      this.$el.html(this.template(this.model.get('billingAddress')))

      this.$error = this.$('.error')

      this.$fullName = this.$('.full-name-input')
      this.$businessName = this.$('.business-name-input')
      this.$address = this.$('.address-input')
      return this
    }
  })
})()
