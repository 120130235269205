/* globals zc Backbone */

(function () {
  'use strict'

  zc.models.Pagination = Backbone.Model.extend({
    initialize: function (attrs, options) {
    },

    defaults: {
      page: 0,
      totalItems: 0,
      perPage: 10
    },

    attrs: function () {
      var attrs = this.toJSON()
      var totalPages = Math.floor(attrs.totalItems / attrs.perPage)
      attrs.next = !!(totalPages && totalPages > (attrs.page))
      attrs.prev = !!attrs.page
      attrs.pages = Math.ceil(attrs.totalItems / attrs.perPage)
      return attrs
    }
  })
})()
